<template>
  <div class="chat-log-content chat-log-content-lead-to-subscription">
    <div class="blur">
      <p>月額プランに登録すると見られるようになります。月額プランに登録すると見られるようになります。月額プランに登録すると見られるようになります。月額プランに登録すると見られるようになります。</p>
    </div>
    <div class="lead-to-subscription-box">
      月額980円で無制限に相談できる<br>
      月額プランに登録して<br>
      医師からの回答を確認する
      <router-link :to="`/chat/${chatLog.chat_id}/subscription`" class="subscriptionButton">
        月額980円プランに登録
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["chatLog"]
  }
</script>

<style scoped>

  .chat-log-content-lead-to-subscription {
    background-color: #00BCD4!important;
    opacity: 0.8;
    position: relative;
    line-height: 30px;
    min-height: 300px!important;
    min-width: 240px!important;
  }
  .blur {
    filter: blur(4px)
  }
  .lead-to-subscription-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }
  .subscriptionButton {
    display: block;
    width: 210px;
    color: #2196f3;
    background: #fff;
    border: 1px solid #2196f3;
    font-size: 12px;
    text-align: center;
    padding: 16px 0;
    margin: 12px auto;
    border-radius: 50px;
    text-decoration: none;
  }
</style>
