<template>
    <div class="c-mainModal">
        <div class="c-mainModal__box">
            <div class="c-mainModal__header">
                予約キャンセル
            </div>
            <div class="c-mainModal__content">
                <p class="p-healthCheckupSupportCancelModal__title">健診サポートをキャンセルしますか？</p>
                <p class="p-healthCheckupSupportCancelModal__msg">一度キャンセルすると再度予約の取り直しになります。</p>
                <a @click="cancelConsultation()" class="c-subButton">
                    予約のキャンセルを確定する
                </a>
                <a @click="closeModal()" class="p-healthCheckupSupportCancelModal__cancel">
                予約したまま確認画面へ戻る
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    cancelConsultation() {
        this.$emit('cancel-consultation')
    },
    closeModal() {
        this.$emit('close-modal')
    },
  }
}

</script>