import axios from 'axios'
import * as rootMutationTypes from '../../mutation-types'
import {LOADING_QUERY, LOADING} from '../../loading'

export const actionTypes = {
  FETCH_QUERY: 'FETCH_CHAT_INTERVIEW_GROUPS',
  FETCH: 'FETCH_CHAT_INTERVIEW_GROUP',
  POST: 'POST_CHAT_INTERVIEW_GROUP',
  BULK_PATCH_GROUP_MASTERS: 'BULK_POST_CHAT_INTERVIEW_GROUP_MASTERS',
  CLEAR: 'CLEAR_CHAT_INTERVIEW_GROUP'
};

export const mutationTypes = {
  SET_QUERY: 'SET_CHAT_INTERVIEW_GROUPS',
  SET_ITEM: 'SET_CHAT_INTERVIEW_GROUP',
  ADD_QUERY: 'ADD_CHAT_INTERVIEW_GROUP',
  CLEAR: 'CLEAR'
};


const state = {
  groups: LOADING_QUERY,
  group: LOADING
};



const getters = {
  query: state => state.groups,
  item: state => state.group
};


const actions = {
  [actionTypes.FETCH_QUERY] ({ commit }) {
    return axios.get('/api/chat_interview/groups')
      .then(response => {
        commit(mutationTypes.SET_QUERY, { groups: response.data });
        return response.data;
      })
      .catch(_response => commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } ) )
  },
  [actionTypes.FETCH] ({commit, state}, id) {
    return axios.get(`/api/chat_interview/groups/${id}`)
      .then(response => {
        commit(mutationTypes.SET_ITEM, { group: response.data })
      })
       .catch(_ => commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } ) )
  },
  [actionTypes.POST] ({ commit }, { group }) {
    return axios.post('/api/chat_interview/groups', { group: group })
      .then(response => {
        commit(mutationTypes.ADD_QUERY, { group: response.data })
      })
      .catch(({ response })  => {
        if (response.status === 422) {
          throw response.data.errors
        }
        commit(rootMutationTypes.PUSH_NOTICE, { message: "作成に失敗しました", type: 'danger' }, { root: true } )
      })
  },
  [actionTypes.BULK_PATCH_GROUP_MASTERS] ({ commit }, {groupId, groupMasters}) {
    return axios.patch(`/api/chat_interview/groups/${groupId}/group_masters`, {group_masters: groupMasters})
      .then(() => true)
      .catch(_ => commit(rootMutationTypes.PUSH_NOTICE, { message: "変更に失敗しました", type: 'danger' }, { root: true } ) )
  },
  [actionTypes.CLEAR] ({commit}) {
    commit(mutationTypes.CLEAR)
  }
};

const mutations = {
  [mutationTypes.SET_QUERY] (state, { groups }) {
    state.groups = groups
  },
  [mutationTypes.SET_ITEM] (state, {group}) {
    state.group = group
  },
  [mutationTypes.ADD_QUERY] (state, { group }) {
    state.groups = [...state.groups, group]
  },
  [mutationTypes.CLEAR] (state) {
    state.group = LOADING
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}

