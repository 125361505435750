<template>
  <div class="doctor-home">
    <div class="container">
      <h1>Dashboard</h1>
      <div class="hub-contents">
        <img src="@/../assets/images/home/online_consultation.png">
        <div class="hub-contents__explanation">
          <p class="hub-contents__title">オンライン医療相談</p>
          <span class="hub-contents__tag--blue">医療相談</span>
          <span class="hub-contents__tag--brown">サプリ相談</span>
          <div class="hub-contents__button-area">
            <a href="/admin/consultations" class="hub-contents__btn--white">相談一覧を開く</a>
          </div>
        </div>
      </div>
      <div class="hub-contents">
        <img src="@/../assets/images/home/online_clinic.png">
        <div class="hub-contents__explanation">
          <p class="hub-contents__title">オンライン診療</p>
          <span class="hub-contents__tag--blue">オンライン診療</span>
          <span class="hub-contents__tag--pink">緊急避妊外来</span>
          <div class="hub-contents__button-area">
            <a href="/online_clinic" class="hub-contents__btn--white">診療一覧を開く</a>
          </div>
        </div>
      </div>
      <div class="hub-contents">
        <img src="@/../assets/images/home/online_clinic.png">
        <div class="hub-contents__explanation">
          <p class="hub-contents__title">健診サポート</p>
          <div class="hub-contents__button-area">
            <a href="/admin/health_checkup_support/consultations" class="hub-contents__btn--white">健診サポート一覧を開く</a>
          </div>
        </div>
      </div>
        <h2>
          コラム
        </h2>
        <div class="column">
          <div class="column-container">
            <a v-for="(item, key) in clilaArticles" :key="key" :class="key % 3 == 1 ? 'column-contents center' : 'column-contents'" :href="`https://search.anamne.com/columns/${item.custom_url}`">
              <img :src="item.img_url" alt="column-image">
              <div class="column-contents-discription">
                <p>{{item.title}}</p>
                <div class="flex">
                  <span class="date">{{item.created_at| moment('YYYY.MM.DD')}}</span>
                </div>
              </div>
            </a>
          </div>
          <a class="link-button" href="https://search.anamne.com/columns/">もっと見る</a>
        </div>
      <h2>
        リソース
      </h2>
      <div class="resource">
        <div class="resource-contents">
          <img src="../../../../assets/images/doctor/resource/first.png" alt="column-first-image">
          <div class="resource-contents-discription">
            <p>医師コミュニティ</p>
            <span>医師コミュニティは５つのグループで構成されています。全体連絡用のグループ以外は参加したいグループを選択可能です。月1開催のドクターズMTGの過去の議事録なども閲覧できます。</span>
            <a class="link-button" href="https://esa-pages.io/p/sharing/12001/posts/615/bfacaaaab5ecad74ec5e.html">開く</a>
          </div>
        </div>
        <div class="resource-contents center">
          <img src="../../../../assets/images/doctor/resource/second.png" alt="column-second-image">
          <div class="resource-contents-discription">
            <p>クラウド医学図書館</p>
            <span>オンライン医療相談やオンライン診療、サプリ外来の対応で参考となる医療情報を提供しています。</span>
            <a class="link-button" href="https://esa-pages.io/p/sharing/12001/posts/619/2c472e63a29da71f7462.html">開く</a>
          </div>
        </div>
        <div class="resource-contents">
          <img src="../../../../assets/images/doctor/resource/third.png" alt="column-third-image">
          <div class="resource-contents-discription">
            <p>紹介</p>
            <span>おうち病院事業にご協力いただける女性医師の方を常に募集しております。下記のリンクをご友人にお伝えいただけるとスムーズです。ご協力よろしくお願いいたします。</span>
            <a class="link-button" href="https://for-doctors.anamne.com/">開く</a>
          </div>
        </div>
        <div class="resource-contents">
          <img src="../../../../assets/images/doctor/resource/fourth.png" alt="column-first-image">
          <div class="resource-contents-discription">
            <p>各種マニュアル</p>
            <span>お仕事を始める際の手続きから、稼働スタートするにあたり必要なオンライン医療相談・サプリ相談、オンライン診療、疾患コンテンツ作成のマニュアルなどが格納されています。</span>
            <a class="link-button" href="https://esa-pages.io/p/sharing/12001/posts/616/5071d899e2e82bd7359a.html">開く</a>
          </div>
        </div>
        <div class="resource-contents center">
          <img src="../../../../assets/images/doctor/resource/fifth.png" alt="column-second-image">
          <div class="resource-contents-discription">
            <p>医療の情報</p>
            <span>医師同士のコミュニケーションを円滑に行えるように登録医師のプロフィール(氏名、顔写真、所在地、専門の診療科)を掲載しております。</span>
            <a class="link-button" href="https://esa-pages.io/p/sharing/12001/posts/617/d0b6acd88b7aab9a9fa6.html">開く</a>
          </div>
        </div>
        <div class="resource-contents">
          <img src="../../../../assets/images/doctor/resource/sixth.png" alt="column-third-image">
          <div class="resource-contents-discription">
            <p>疾患情報コンテンツ</p>
            <span>医療情報特化検索メディアclila(クリラ)に掲載する疾患情報コンテンツの作成マニュアルや進捗管理票表をするめの資料を掲載しております。</span>
            <a class="link-button" href="https://esa-pages.io/p/sharing/12001/posts/618/901ca2543b1bf538a5e2.html">開く</a>
          </div>
        </div>
      </div>
      <div class="google-map-box">
        <h2>
          スケジュール
        </h2>
        <div class="cal_wrapper">
          <div class="googlecal">
            <iframe src="https://calendar.google.com/calendar/embed?src=cs%40anamne.com&ctz=Asia%2FTokyo" style="border: 0"
                    width="800" height="600" frameborder="0" scrolling="no"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import FlashMessage from '@/components/online_clinic/FlashMessage';
  import {mapGetters, mapActions, mapMutations} from 'vuex';
  import * as actionTypes from '@/store/action-types';
  import * as mutationTypes from '@/store/mutation-types';

  export default {
    components: {
      FlashMessage,
    },
    data() {
      return {
        clilaArticlesLimit: 6,
        clilaArticles: []
      }
    },
    computed: {
      ...mapGetters({
        patientCount: 'users/patientCount',
        totalConsultationsCount: 'totalConsultationsCount',
        respondingConsultationsCount: 'respondingConsultationsCount',
      }),
      totalUsersCount() {
        if(this.patientCount){
          return this.patientCount + '人';
        }else{
          '-人'
        }
      },
      allConsultationsCount() {
        if(this.totalConsultationsCount){
          return this.totalConsultationsCount + '件';
        }else{
          '-件'
        }
      },
      newConsultationsCount() {
        if(this.respondingConsultationsCount){
          return this.respondingConsultationsCount + '件';
        }else{
          '-件'
        }
      },
      doctorsNewConsultationsLimitThree() {
        if (this.$store.getters.doctors_new_consultations.length > 0) {
          return this.$store.getters.doctors_new_consultations.slice(0, 3);
        }else{
          return null;
        }
      },

    },
    created() {
      this.fetchDoctorsNewConsultations({});
      this.fetchTotalConsultationsCount({});
      this.fetchRespondingConsultationsCount({});
      this.fetchPatientCount();
      this.fetchClilaArticles();
    },
    methods: {
      ...mapActions({
        fetchTotalConsultationsCount: actionTypes.FETCH_TOTAL_CONSULTATIONS_COUNT,
        fetchRespondingConsultationsCount: actionTypes.FETCH_RESPONDING_CONSULTATIONS_COUNT,
        fetchPatientCount: `users/${actionTypes.FETCH_PATIENT_COUNT}`,
        fetchDoctorsNewConsultations: actionTypes.FETCH_DOCTORS_NEW_CONSULTATIONS,
      }),
      ...mapMutations({
        setConsultations: mutationTypes.SET_CONSULTATIONS,
      }),
      async fetchClilaArticles() {
        try {
          let res = await axios.get('/api/clila', {
            params: { limit: this.clilaArticlesLimit },
          });
          this.clilaArticles = res.data;
        } catch {
          alert('ERROR');
        }
      }
    }
  };
</script>

<style scoped lang="scss">

@media screen and (min-width: 769px) {

  iframe {
    width: 1178px;
    height: 862px;
  }

  .doctor-home {
    padding: 60px 0px 0px 0px;
    background-color: #E5E5E5;

    .container {
      height: 100%;
      width: 1180px;
      margin: 0 auto;

      .hub-contents {
        margin: 0 50px 30px 0;
        vertical-align: top;
        display: inline-block;
        width: 520px;
        height: 393px;
        background: #FFFFFF;
        border-radius: 12px;

        img {
          width: 100%;
          height: 206px;
          border-radius: 12px 12px 0 0;
        }

        &__explanation {
          padding: 10px 20px 24px 20px;
        }

        &__button-area {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
        }

        &__title {
          display: block;
          color: #2196F3;
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 8px;
        }

        &__tag {
          &--blue {
            display: inline-block;
            padding: 4px;
            width: auto;
            border-radius: 4px;
            font-size: 12px;
            margin-right:12px;
            background: #F4FAFE;
            color: #2196F3;
          }

          &--brown {
            display: inline-block;
            padding: 4px;
            width: auto;
            border-radius: 4px;
            font-size: 12px;
            margin-right:12px;
            background: #f4ecec;
            color: #986969;
          }

          &--pink {
            display: inline-block;
            padding: 4px;
            width: auto;
            border-radius: 4px;
            font-size: 12px;
            margin-right:12px;
            background: #fcecf3;
            color: #FC5AA7;
          }
        }

        &__btn {
          &--white {
            margin: 0 auto;
            display: inline-block;
            padding: 10px 0;
            font-size: 16px;
            line-height: 24px;
            width: 332px;
            height: 24px;
            border-radius: 22px;
            text-align: center;
            text-decoration:none;
            background: #FFFFFF;
            border: 1px solid rgba(33, 150, 243, 0.2);
            color: #2196F3;
          }
        }

      }

      .google-map-box {
        padding-bottom: 100px;
      }

      h1 {
        margin: 16px 0 16px 0;
        width: 383px;
        height: 70px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 800;
        font-size: 66px;
        line-height: 99px;
        display: flex;
        align-items: center;
        color: rgba(42, 48, 57, 0.6);
        opacity: 0.1;
      }

      h2 {
        margin: 80px 0 24px 0;
        font-family: Mplus 1 p;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 30px;
        color: #2A3039;
      }

      .column {
        text-align: left;
        font-size: 0;
        margin: 0;
        height: auto;
        width: auto;

        a {
          text-decoration:none;
        }

        .link-button {
          display: block;
          width: 332px;
          height: 44px;
          background: #FFFFFF;
          border-radius: 22px;
          border: 1px solid rgba(33, 150, 243, 0.2);
          box-sizing: border-box;
          margin: 24px 0 0 0;
          margin-left: auto;
          margin-right: auto;
          font-family: Mplus 1 p;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #2196F3;
          text-align: center;
          text-decoration: none;
          line-height: 2.5;
        }

        img {
          width: 360px;
          height: 200px;
        }

        .center {
          margin: 0 50px;
        }

        .column-contents {
          vertical-align: top;
          display: inline-block;
          width: 30.5%;
          height: auto;
          background: white;
          margin-bottom: 40px;

          .column-contents-discription {
            min-height: 104px;
            vertical-align: top;
            width: 100%;
            height: 104px;
            background: white;

            p {
              font-family: Poppins;
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              line-height: 24px;
              color: #2196F3;
              margin: 12px 12px 11px 12px;
            }

            .flex {
              display: flex;

              .tag {
                text-align: center;
                vertical-align: middle;
                display: inline-block;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2196F3;
                background: rgba(33, 150, 243, 0.05);
                border-radius: 12px;
                width: 116px;
                height: 24px;
                padding: 3px 12px 3px 8px;
                margin: 0 0 0 12px;
              }

              .date {
                flex: 1;
                text-align: right;
                display: inline-block;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2196F3;
                text-align: right;
                height: 24px;
                margin: 3px 12px 3px 0;
              }
            }
          }
        }
      }

      .resource {
        text-align: left;
        font-size: 0;
        margin: 0;
        height: auto;
        width: auto;

        img {
          width: 360px;
          height: 170px;
          padding: 20px;
        }

        .center {
          margin: 0 20px !important;
        }

        .resource-contents {
          vertical-align: top;
          display: inline-block;
          width: 380px;
          height: 392px;
          background: white;
          margin: 0 0 86px 0;

          .resource-contents-discription {
            min-height: 104px;
            vertical-align: top;
            width: 100%;
            height: 202px;
            background: white;

            p{
              height: 30px;
            }
            span{
              height: 100px;
            }
            .link-button {
              display: block;
              width: 332px;
              height: 44px;
              background: #FFFFFF;
              border-radius: 22px;
              border: 1px solid rgba(33, 150, 243, 0.2);
              box-sizing: border-box;
              margin: 8px 24px 24px 24px;
              font-family: Mplus 1 p;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              color: #2196F3;
              text-align: center;
              text-decoration: none;
              line-height: 2.5;
            }

            p {
              font-family: Mplus 1 p;
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 30px;
              color: #2A3039;
              margin: 8px 0px 4px 24px;
            }

            span {
              display: block;
              font-family: Mplus 1 p;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: rgba(42, 48, 57, 0.6);
              margin: 0 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .doctor-home {
    padding: 60px 0px 0px 0px;
    background-color: #E5E5E5;

    .container {
      height: 100%;
      width: 100%;
      margin: 0 auto;

      .hub-contents {
        margin: 16px auto;
        vertical-align: top;
        width: 90%;
        height: 393px;
        background: #FFFFFF;
        border-radius: 12px;

        img {
          width: 100%;
          height: 206px;
          border-radius: 12px 12px 0 0;
        }

        &__explanation {
          padding: 10px 20px 24px 20px;
        }

        &__button-area {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
        }

        &__title {
          display: block;
          color: #2196F3;
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 8px;
        }

        &__tag {
          &--blue {
            display: inline-block;
            padding: 4px;
            width: auto;
            border-radius: 4px;
            font-size: 12px;
            margin-right:12px;
            background: #F4FAFE;
            color: #2196F3;
          }

          &--brown {
            display: inline-block;
            padding: 4px;
            width: auto;
            border-radius: 4px;
            font-size: 12px;
            margin-right:12px;
            background: #f4ecec;
            color: #986969;
          }

          &--pink {
            display: inline-block;
            padding: 4px;
            width: auto;
            border-radius: 4px;
            font-size: 12px;
            margin-right:12px;
            background: #fcecf3;
            color: #FC5AA7;
          }
        }

        &__btn {
          &--white {
            margin: 0 auto;
            display: inline-block;
            padding: 10px 0;
            font-size: 16px;
            line-height: 24px;
            width: 332px;
            height: 24px;
            border-radius: 22px;
            text-align: center;
            text-decoration:none;
            background: #FFFFFF;
            border: 1px solid rgba(33, 150, 243, 0.2);
            color: #2196F3;
          }
        }

      }



      .google-map-box {
        width: 90%;
        height: auto;
        margin: 0 auto;
        padding-bottom: 100px;

        .cal_wrapper {
          max-width: 960px;
          margin: 2.0833% auto;
        }

        .googlecal {
          position: relative;
          padding-bottom: 75%;
          height: 0;
        }

        .googlecal iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }

        h2 {
          width: 100% !important;
        }
      }

      h1 {
        margin: 0;
        height: 16px;
        font-size: 66px;
        display: none;
        align-items: center;
        text-indent: 100%;
      }

      h2 {
        width: 90%;
        margin: 48px auto 24px auto;
        font-family: Mplus 1 p;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 30px;
        color: #2A3039;
      }

      .column {
        text-align: left;
        font-size: 0;
        margin: 0;
        height: auto;
        width: auto;

        a {
          text-decoration:none;
        }

        .column-container {
          overflow-x: scroll;
          white-space: nowrap;
        }

        .link-button {
          display: block;
          width: 90%;
          height: 44px;
          background: #FFFFFF;
          border-radius: 22px;
          border: 1px solid rgba(33, 150, 243, 0.2);
          box-sizing: border-box;
          margin: 24px 0 0 0;
          margin-left: auto;
          margin-right: auto;
          font-family: Mplus 1 p;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #2196F3;
          text-align: center;
          text-decoration: none;
          line-height: 2.5;
        }

        img {
          width: 100%;
          height: 200px;
        }

        .column-contents {
          vertical-align: top;
          display: inline-block;
          width: 300px;
          height: auto;
          background: white;
          margin-bottom: 40px;
          margin: 0 5%;

          .column-contents-discription {
            min-height: 104px;
            vertical-align: top;
            width: 100%;
            height: 104px;
            background: white;

            p {
              font-family: Poppins;
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              line-height: 24px;
              color: #2196F3;
              margin: 12px 12px 11px 12px;
              white-space: normal;
            }

            .flex {
              display: flex;

              .tag {
                text-align: center;
                vertical-align: middle;
                display: inline-block;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2196F3;
                background: rgba(33, 150, 243, 0.05);
                border-radius: 12px;
                width: 116px;
                height: 24px;
                padding: 3px 12px 3px 8px;
                margin: 0 0 0 12px;
              }

              .date {
                flex: 1;
                text-align: right;
                display: inline-block;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2196F3;
                text-align: right;
                height: 24px;
                margin: 3px 12px 3px 0;
              }
            }
          }
        }
      }

      .resource {
        text-align: center;
        font-size: 0;

        img {
          width: 260px;
          height: 170px;
          padding: 20px;
        }

        .resource-contents {
          vertical-align: top;
          display: inline-block;
          width: 90%;
          height: auto;
          background: white;
          margin: 0 0 16px 0;

          .resource-contents-discription {
            min-height: 104px;
            vertical-align: top;
            width: 100%;
            height: auto;
            background: white;

            p {
              text-align: left;
              height: auto;
              margin: 8px 0px 4px 16px;
              font-family: Mplus 1 p;
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              line-height: 30px;
              color: #2A3039;
            }

            .link-button {
              display: block;
              width: auto;
              height: 44px;
              background: #FFFFFF;
              border-radius: 22px;
              border: 1px solid rgba(33, 150, 243, 0.2);
              box-sizing: border-box;
              margin: 8px 15px 24px 15px;
              font-family: Mplus 1 p;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              color: #2196F3;
              text-align: center;
              text-decoration: none;
              line-height: 2.5;
            }

            span {
              height: auto;
              text-align: left;
              display: block;
              font-family: Mplus 1 p;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: rgba(42, 48, 57, 0.6);
              margin: 0 16px;
            }
          }
        }
      }
    }
  }
}
</style>
