<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h1>
              問診選択
            </h1>
          </div>
          <base-loading-icon :target="groups" class="modal-body">
            <div class="form-group">
              <label for="group" class="control-label">問診グループ</label>
              <select id="group" v-model="selected" class="form-control">
                <option v-for="group in groups" :key="group.id" :value="group.id">
                  {{group.name}}
                </option>
              </select>
            </div>
            <div class="form-group">
              <button @click="handleSubmit" class="btn btn-primary">問診開始</button>
            </div>
          </base-loading-icon>
          <div class="modal-footer">
            <button class="modal-default-button btn btn-default" @click="historyBack">
              戻る
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import * as Groups from '../store/modules/chatInterview/groups'
  import * as rootActionTypes from '../store/action-types'
  export default {
    name: 'ChatInterviewNewModalModal',
    created() {
      this.fetchGroups()
        .then(gs => { if(gs.length > 0) { this.$data.chatLogForm.chat_interview_group_id = gs[0].id }})
    },
    data() {
      return ({
        chatLogForm: {
          content: '',
          chat_interview_group_id: null,
          log_type: 'interview'
        }
      })
    },
    computed: {
      selected: {
        get() {
          return this.$data.chatLogForm.chat_interview_group_id
        },
        set(value) {
          this.$data.chatLogForm.chat_interview_group_id = value;
          this.$data.chatLogForm.content = this.groups.find(g => g.id === value).name
        }
      },
      ...mapGetters({
        groups: `chatInterview/groups/query`
      })
    },
    methods: {
      historyBack() {
        this.$router.go(-1)
      },
      handleSubmit() {
        this.postChatLog({chatId: this.$route.params.chat_id, chatLog: this.$data.chatLogForm})
          .then(this.historyBack)
      },
      ...mapActions({
        fetchGroups: `chatInterview/groups/${Groups.actionTypes.FETCH_QUERY}`,
        postChatLog: rootActionTypes.POST_CHAT_LOG
      })
    }
  }
</script>

<style scoped lang="scss">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;

      .modal-container {
        width: 300px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;

        @media screen and (min-width:520px)  {
          width: 700px;
        }

        .modal-header h3 {
          margin-top: 0;
          color: #42b983;
        }

        .modal-body {
          margin: 20px 0;
        }

        .modal-default-button {
          float: right;
        }

        .modal-enter {
          opacity: 0;
        }

        .modal-leave-active {
          opacity: 0;
        }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }
</style>
