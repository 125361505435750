import { render, staticRenderFns } from "./ChatFormOption.vue?vue&type=template&id=19c72152&scoped=true&"
import script from "./ChatFormOption.vue?vue&type=script&lang=js&"
export * from "./ChatFormOption.vue?vue&type=script&lang=js&"
import style0 from "./ChatFormOption.vue?vue&type=style&index=0&id=19c72152&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c72152",
  null
  
)

export default component.exports