<template>
  <div class="p-healthCheckupSupportInterview">
    <div class="p-healthCheckupSupportInterview__uploadContainer l-ignoreParentContainer">
      <div class="p-healthCheckupSupportInterview__uploadContainerInner">
        <p>健康診断結果のアップロード</p>
        <div class="p-healthCheckupSupportInterview__uploadButtonArea">
          <div class="p-healthCheckupSupportInterview__uploadButtonRow">
            <div v-for="n in 5" :key="n">
              <input type="file" ref="fileInput" @change="selectedImage($event, n)" accept="image/*">
              <button @click="uploadFile(n)" class="p-healthCheckupSupportInterview__uploadButton" :style="getButtonStyle(n)">
                <div class="p-healthCheckupSupportInterview__uploadButtonNo">
                  <span>{{n}}</span>
                </div>
                <p v-if="isUploading(n)" class="p-healthCheckupSupportInterview__uploadButtonMsg">アップロード中</p>
                <img class="p-healthCheckupSupportInterview__cameraImage" v-if="n == 1" src="@/../assets/images/health_checkup_support/consultations/camera.png" alt="">
                <p v-if="n == 1">必須</p>
                <img class="p-healthCheckupSupportInterview__prevImage" v-if="images[n - 1]" :src="images[n - 1].url" :style="getImageStyle(n)">
              </button>
            </div>
          </div>
          <div class="p-healthCheckupSupportInterview__uploadButtonRow">
            <div v-for="n in 3" :key="n + 5">
              <input type="file" ref="fileInput" @change="selectedImage($event, n + 5)" accept="image/*">
              <button @click="uploadFile(n + 5)" class="p-healthCheckupSupportInterview__uploadButton" :style="getButtonStyle(n + 5)">
                <div class="p-healthCheckupSupportInterview__uploadButtonNo">
                  <span>{{n + 5}}</span>
                 </div>
                <p v-if="isUploading(n + 5)" class="p-healthCheckupSupportInterview__uploadButtonMsg">アップロード中</p>
                <img class="p-healthCheckupSupportInterview__prevImage" v-if="images[(n + 5) - 1]" :src="images[(n + 5) - 1].url" :style="getImageStyle(n + 5)">
              </button>
            </div>
          </div>
          <p class="p-healthCheckupSupportInterview__sampleMsg">※ご本人の診断結果を添付ください。ご本人以外の診断結果へのサポートはできません。</p>
        </div>
        <div class="p-healthCheckupSupportInterview__sample">
          <p class="p-healthCheckupSupportInterview__sampleTitle">写真の例</p>
          <div class="p-healthCheckupSupportInterview__image">
            <img src="@/../assets/images/health_checkup_support/consultations/sample_image_001.png" alt="">
            <img src="@/../assets/images/health_checkup_support/consultations/sample_image_002.png" alt="">
          </div>
          <p class="p-healthCheckupSupportInterview__sampleMsg">※ 記載内容がはっきりと読み取れる画像をお選びください。</p>
        </div>
      </div>
    </div>
    <div class="p-healthCheckupSupportInterview__input">
      <label for="patient_comment" class="p-healthCheckupSupportInterview__label">事前に医師へ伝えたいことや質問があれば入力してください</label>
      <textarea id="patient_comment" v-model="form.patientComment" maxlength="1000" placeholder="例）食生活を改善したいと考えているが、なかなか始められず、続けられる自信もない。どのように改善を考えたら良いか相談したい。"></textarea>
    </div>
    <div class="p-healthCheckupSupportInterview__footer">
      <button class="c-backButton" @click="$emit('moveToPreviousStep'); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答', buttonName: '戻る', userId: currentUser.id, source: currentUser.source })"
              >戻る
      </button>
      <button class="c-mainButton" @click="addConsultationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })"
              :disabled="!isFullyInputed">次へ
      </button>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios';

  export default {
    data() {
      return {
        form: {
          patientComment: null,
        },
        images: new Array(8).fill(null),
        imageTokens: new Array(8).fill(null),
        uploadProgressFlags: new Array(8).fill(false)
      }
    },
    async created() {

    },
    computed: {
      ...mapGetters(['currentUser']),
      isFullyInputed() {
        const hasImageTokens = this.imageTokens.some(token => token !== null);
        const allUploadsCompleted = this.uploadProgressFlags.every(flag => !flag);
        return hasImageTokens && allUploadsCompleted;
      },
    },
    methods: {
      addConsultationData() {
        const data = {
          interview: this.form,
          imageTokens: this.imageTokens
        };
        this.$emit('addConsultationData', data);
      },
      uploadFile(n) {
        this.$refs.fileInput[n-1].click();
      },
      async selectedImage(e, n) {
        e.preventDefault();
        const files = e.target.files;
        const file = files[0]
        const type = file.type

        //拡張子heicとpdfはアップできないように制御
        if (type == 'image/heic' || type == 'application/pdf') {
          alert('.heic、.pdfのファイルはアップロードできません')
          e.currentTarget.value = ''
          return;
        }

        this.$set(this.uploadProgressFlags, n - 1, true);
        this.postImage(file, n)
      },
      async postImage(file, n) {
        try {
          const data = new FormData();
          data.append('image', file)
          const res = await axios.post(`/api/health_checkup_support/images`, data)
          this.$set(this.imageTokens, n - 1, res.data.token);
          this.$set(this.images, n - 1, res.data.image);
          this.$set(this.uploadProgressFlags, n - 1, false);
        } catch {
          alert('ERROR');
        }
      },
      isUploading(n) {
        return this.uploadProgressFlags[n - 1];
      },
      getButtonStyle(n) {
        return this.isUploading(n) ? { backgroundColor: 'grey', opacity: '0.5' } : {};
      },
      getImageStyle(n) {
        return { opacity: this.isUploading(n) ? 0.5 : 1 };
      }
    }
  }
</script>