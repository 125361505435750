<template>
  <div>
    <base-select-field name="interview_type" v-model="interviewType" label-text="質問形式">
      <option value="text">テキスト</option>
      <option value="checkboxes">チェックボックス</option>
      <option value="radio">ラジオボタン</option>
    </base-select-field>

    <base-select-field name="way_type" v-model="wayType" label-text="質問場所">
      <option value="patient_question">プロフィール問診票</option>
      <option value="chat_log">チャット問診</option>
    </base-select-field>

    <base-text-field v-model="message" label-text="メッセージ" :errors="errors.message" name="master_message" ></base-text-field>
    <base-check-box-field v-model="isPublishing" label-text="公開中" name="master_is_publishing"></base-check-box-field>

    <div v-show="master.interview_type !== 'text'" class="panel panel-default">
      <draggable element="div" class="panel-body" v-model="masterItemsOrder">
        <master-item-field v-for="masterItem in master.master_items" :master-item="masterItem" :key="masterItem.id"></master-item-field>
      </draggable>
      <div class="panel-footer">
        <button @click="pushInterviewMasterItem" type="button" class="btn btn-info">選択肢の追加</button>
      </div>
    </div>

    <slot name="submit"></slot>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapGetters, mapMutations } from 'vuex'
  import * as Masters from '../../store/modules/interview/masters'
  import BaseTextField from "../base/Form/BaseTextField";
  import BaseSelectField from "../base/Form/BaseSelectField";
  import BaseCheckBoxField from "../base/Form/BaseCheckBoxField";
  import MasterItemField from '../Interview/MasterForm/MasterItemField'

  export default {
    name: 'MasterForm',
    components: { draggable, BaseCheckBoxField, BaseSelectField, BaseTextField, MasterItemField },

    computed: {
      masterItemsOrder: {
        get() {
          return this.master.master_items;
        },
        set(value) {
          return this.setInterviewMasterItemsPositions({ masterItems: value })
        }
      },
      message: {
        get () { return this.master.message },
        set (value) { return this.setInterviewMasterMessage({ message: value }) },
      },
      interviewType: {
        get () { return this.master.interview_type },
        set (value) { return this.setInterviewMasterInterviewType({ interviewType: value }) },
      },
      wayType: {
        get () { return this.master.way_type },
        set (value) { return this.setInterviewMasterWayType({ wayType: value }) },
      },
      isPublishing: {
        get () { return this.master.is_publishing },
        set (value) { return this.setInterviewMasterIsPublishing({ isPublishing: value }) },
      },
      ...mapGetters({ master: 'interview/masters/get', errors: 'interview/masters/errors' })
    },

    methods: {
      ...mapMutations({
        setInterviewMasterMessage: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTER_MESSAGE}`,
        setInterviewMasterInterviewType: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTER_INTERVIEW_TYPE}`,
        setInterviewMasterWayType: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTER_WAY_TYPE}`,
        setInterviewMasterIsPublishing: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTER_IS_PUBLISHING}`,
        pushInterviewMasterItem: `interview/masters/${Masters.mutationTypes.PUSH_INTERVIEW_MASTER_ITEM}`,
        setInterviewMasterItemsPositions: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTER_ITEMS_POSITIONS}`
      })
    }
  }
</script>

<style scoped lang="scss">
</style>
