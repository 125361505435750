<template>
  <div class="p-healthCheckupSupportConsultationShow">
    <loading :translucent="true" v-show="loading"></loading>
    <progress-circle :currentStep = 6 v-if="previousPathName == '/health_checkup_support/consultations/new'"></progress-circle>
    <flash-message/>

    <div v-if="!isFinished" class="p-healthCheckupSupportConsultationShow__primary">
      <h3 class="p-healthCheckupSupportConsultationShow__title">予約確認</h3>
    <div class="p-healthCheckupSupportConsultationShow__box">
    <p class="p-healthCheckupSupportConsultationShow__boxTitle">はじめるまえにご確認ください</p>
    <p>(1) 本人確認のため「身分証明証」「健康診断書」をお手元ご用意ください</p>
    <p>(2) カメラとマイクの使用を許可してください</p>
    <a href="https://anamne.wraptas.site/" target="_blank" class="p-healthCheckupSupportConsultationShow__help">ヘルプ・よくある質問</a>
    </div>


      <button class="c-mainButton p-healthCheckupSupportConsultationShow__buttonSize" @click="goToWebMtg(); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 予約詳細', buttonName: 'はじめる', userId: currentUser.id, source: currentUser.source })" :disabled="!isConsultationAvailable">はじめる
      </button>
      <p class="p-healthCheckupSupportConsultationShow__cancelText">※ 予約時間5分前になると入れます</p>
      <div v-if="consultation">
        <a v-if="!consultation.within_24h_of_web_mtg" class="p-healthCheckupSupportConsultationShow__cancel" @click="isCancelModal = true; $mixpanel.track('CLICK BUTTON', { page: '健診サポート 予約詳細', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</a>
        <p v-if="consultation.within_24h_of_web_mtg" class="p-healthCheckupSupportConsultationShow__cancelText">予約時間24時間前となったため、キャンセルできません</p>
      </div>
    </div>

    <div class="p-healthCheckupSupportConsultationShow__container l-ignoreParentContainer">
      <div class="p-healthCheckupSupportConsultationShow__form">
        <span class="p-healthCheckupSupportConsultationShow__label">サポートの種類</span>
        <p class="p-healthCheckupSupportConsultationShow__item">{{ typeName }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationShow__form">
        <span class="p-healthCheckupSupportConsultationShow__label">予約日時</span>
        <p class="p-healthCheckupSupportConsultationShow__item">{{ timeSchedule }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationShow__form">
        <span class="p-healthCheckupSupportConsultationShow__label">担当医師</span>
        <div class="p-healthCheckupSupportConsultationShow__img">
          <img :src="doctorImage">
        </div>
        <p class="p-healthCheckupSupportConsultationShow__name">{{ doctorName }}</p>
        <p class="p-healthCheckupSupportConsultationShow__profile">{{ doctorIntroduction }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationShow__form">
        <span class="p-healthCheckupSupportConsultationShow__label">予約名</span>
        <p class="p-healthCheckupSupportConsultationShow__item">{{ patientName }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationShow__form">
        <span class="p-healthCheckupSupportConsultationShow__label">問診内容</span>
        <p class="p-healthCheckupSupportConsultationShow__item">{{ patientComment }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationShow__form">
        <span class="p-healthCheckupSupportConsultationShow__label">決済方法</span>
        <p class="p-healthCheckupSupportConsultationShow__item">{{ paymentMethod }}</p>
      </div>

    </div>

    <div class="p-healthCheckupSupportConsultationShow__footer">
      <router-link class="c-mainButton p-healthCheckupSupportConsultationShow__buttonSize" to="/health_checkup_support/home" @click.native="$mixpanel.track('CLICK BUTTON', { page: '健診サポート 予約詳細', buttonName: 'トップに戻る', userId: currentUser.id, source: currentUser.source })">トップに戻る</router-link>
    </div>

    <cancel-modal @close-modal="closeModal" @cancel-consultation="cancelConsultation" v-if="isCancelModal"></cancel-modal>

  </div>
</template>

<script>
  import axios from 'axios'
  import moment from "moment";
  import 'moment/locale/ja';
  import FlashMessage from '@/components/online_clinic/FlashMessage'
  import Loading from '@/components/Common/Loading';
  import {mapGetters} from "vuex";
  import CancelModal from '@/components/health_checkup_support/modal/Cancel';
  import ProgressCircle from '@/components/health_checkup_support/consultations/ProgressCircle';

  export default {
    data: () => {
      return {
        consultation: null,
        currentDatetime: moment(),
        loading: false,
        isCancelModal: false,
      }
    },
    components: {
      FlashMessage,
      Loading,
      CancelModal,
      ProgressCircle
    },
    computed: {
      typeName() {
        return this.consultation == null ? '読込中...' : this.consultation.type.name
      },
      patientComment() {
        return this.consultation == null ? '読込中...' : this.consultation.patient_comment
      },
      paymentMethod() {
        console.log(this.consultation)
        if (!this.consultation) {
          return '読込中...'
        } else if (this.consultation.coupon_id) {
          return '連携コード'
        } else {
          return 'クレジットカード'
        }
      },
      timeSchedule() {
        if (!this.consultationStart || !this.consultationEnd) return '読込中...'
        const startTime = moment(this.consultationStart).format('HH:mm');
        const endTime = moment(this.consultationEnd).format('HH:mm');
        const day = moment(this.consultationStart).format('YYYY/MM/DD(ddd)');
        return `${day} ${startTime} ~ ${endTime}`
      },
      doctorImage() {
        return this.consultation == null ? '読込中...' : this.consultation.doctor.doctor_profile.image.url
      },
      doctorName() {
        return this.consultation == null ? '読込中...' : `${this.consultation.doctor.doctor_profile.first_name} ${this.consultation.doctor.doctor_profile.last_name} 先生`
      },
      doctorIntroduction() {
        return this.consultation == null ? '読込中...' : `${this.consultation.doctor.doctor_profile.self_introduction}`
      },
      patientName() {
        return this.consultation == null ? '読込中...' : `${this.consultation.patient_family_profile.first_name} ${this.consultation.patient_family_profile.last_name}`
      },
      consultationStart() {
        return this.consultation == null ? null : moment(this.consultation.web_mtg_start)
      },
      consultationEnd() {
        return this.consultation == null ? null : moment(this.consultation.web_mtg_end)
      },
      isConsultationAvailable() {
        if (!this.consultationStart || !this.consultationEnd) return false
        if (this.consultationStart.diff(this.currentDatetime, 'minutes') < 5 && this.consultationEnd > this.currentDatetime) {
          return true;
        } else {
          return false;
        }
      },
      isFinished() {
        if (this.consultationEnd < this.currentDatetime) {
          return true;
        } else {
          return false;
        }
      },
      previousPathName() {
        let url;
        try {
          url = new URL(document.referrer);
          return url.pathname
        } catch (e) {
          console.error("Invalid or empty referrer:", document.referrer);
        }
      },
      ...mapGetters(['currentUser'])
    },
    async created() {
			window.scroll(0, 0)
      await this.fetchConsultation();
    },
    mounted() {
      setInterval(() => {
        this.currentDatetime = moment();
      }, 5000)
    },
    methods: {
      async cancelConsultation() {
        this.loading = true
        try {
          await axios.patch(`/api/health_checkup_support/consultations/${this.$route.params.id}/cancel`);
          this.$localStorage.set('flashType', 'info')
          this.$localStorage.set('flashMessage', '健診サポートをキャンセルしました')
          this.$mixpanel.track('CLICK BUTTON', { page: '健診サポート予約詳細', buttonName: 'キャンセル', userId: this.currentUser.id, source: this.currentUser.source, isCvButton: false });
          window.location.href = '/health_checkup_support/home'
        } catch {
          alert("ERROR")
        } finally {
          this.loading = false
        }
      },
      closeModal () {
        this.isCancelModal = false
      },
      async fetchConsultation() {
        try {
          const consultation = await axios.get(`/api/health_checkup_support/consultations/${this.$route.params.id}`);
          this.consultation = consultation.data
        } catch {
          alert("ERROR")
        }
      },
      goToWebMtg() {
        this.$router.push(`/health_checkup_support/consultations/${this.consultation.id}/web_mtg`)
      },
    },

  }

</script>
