<template>
  <i class="fa fa-2x" aria-hidden="true" id="cy-chat-toggle-button" :class="buttonClass" @click="toggle"></i>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  import * as actionTypes from '../../store/action-types'
  import * as mutationTypes from '../../store/mutation-types'

  export default {
    name: 'ChatToggleButton',
    props: ['isToggleOpen', 'toggle'],
    computed: {
      buttonClass() {
        return this.isToggleOpen ? 'fa-times-circle' : 'fa-plus-circle'
      },
      ...mapGetters(['chat'])
    }
  }
</script>

<style scoped lang="scss">
  i {
    margin-top: 4px;
  }
</style>
