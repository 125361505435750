<template>
  <div class="p-chat__footer">
    <chat-form-option v-if="isToggleOpen && !isFinished" @close="optionClose"
    @clickSubscriptionRequest="$emit('clickSubscriptionRequest')" @subscriptionRequestCancel="$emit('subscriptionRequestCancel')"
    @clickButtonRequest="$emit('clickButtonRequest')" @buttonRequestCancel="$emit('buttonRequestCancel')"
    @clickRoutineMessageRequest="$emit('clickRoutineMessageRequest')" @routineMessageRequestCancel="$emit('routineMessageRequestCancel')"
    @clickFinishRequest="$emit('clickFinishRequest')" @finishRequestCancel="$emit('finishRequestCancel')"/>
    <div v-show="!isStillLoad">
      <chat-message-form :is-toggle-open="isToggleOpen" :toggle="toggle"/>
    </div>
    <div v-show="isFinished">
      <div class="chat-end">
        <span>チャットは終了しました</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as actionTypes from '../../store/action-types'
  import * as mutationTypes from '../../store/mutation-types'

  import Chat from '../../enums/Chat'
  import ChatFormOption from './ChatFormOption'
  import ChatMessageForm from './ChatMessageForm'

  export default {
    name: 'ChatFooterLayout',
    components: { ChatFormOption, ChatMessageForm },
    data: () => {
      return {
        isToggleOpen: false
      }
    },
    computed: {
      isStillLoad() {
        return this.chat.status === undefined
      },
      isFinished() {
        return this.chat.status === Chat.Status.finished
      },
      ...mapGetters(['chat', 'isPatient'])
    },
    methods: {
      toggle() {
        this.isToggleOpen = !this.isToggleOpen;
      },
      optionClose() {
        this.isToggleOpen = false;
      }
    }
  }
</script>