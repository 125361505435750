<template>
  <div>
    <div class="p-HealthCheckupSupportHome__titleBox">
      <p class="p-HealthCheckupSupportHome__mainText">健康診断の結果を元にした、<br>健康的なライフスタイルのサポート</p>
    </div>
    <div class="p-HealthCheckupSupportHome__imageBox">
      <img class="p-HealthCheckupSupportHome__mainImage" src="@/../assets/images/health_checkup_support/home/main.png" alt="">
    </div>
    <div class="p-HealthCheckupSupportHome__serviceCharge">
      <span class="p-HealthCheckupSupportHome__serviceChargeTag">健診サポート1回</span>
      <p>7,700<span>円（税込）</span></p>
    </div>
    <a @click="startHealthCheckupSupport(); $mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: 'ビデオ通話を予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })" class="c-mainButton" :class="{ disabled: !isCompletedLoading }">ビデオ通話を予約する</a>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="consultation && consultation.chat ? consultation.chat.id : null"
    ></notification-button>

    <a href="/health_checkup_support/consultation_histories" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: '過去の健診サポートを確認', userId: currentUser.id, source: currentUser.source })">
      過去の健診サポートを確認
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: '会員情報を確認', userId: currentUser.id, source: currentUser.source })">
      会員情報を確認
    </a>
  </div>
</template>


<script>
import NotificationButton from "@/components/health_checkup_support/home/NotificationButton";
import { mapGetters, mapActions } from 'vuex';
import * as PatientFamilyProfile from  'store/modules/patientFamilyProfiles';
export default {
  name: 'BeforeClinicReservation',
  props: ['consultation'],
  components: {
    NotificationButton
  },
  async created() {
    this.fetchPatientFamilyProfiles()
  },
  computed: {
    currentUrl() {
      return location.href
    },
    isCompletedLoading() {
      return this.patientFamilyProfiles != 'LOADING_QUERY'
    },
    ...mapGetters(['currentUser']),
    ...mapGetters({patientFamilyProfiles: 'patientFamilyProfiles/query'})
  },
  methods: {
    startHealthCheckupSupport() {
      if (this.isCompletedLoading) {
        if (this.patientFamilyProfiles.length > 0) {
          this.$router.push('/health_checkup_support/consultations/new')
        } else {
          this.$router.push({ name: 'patient_family_profile_new', params: {isFromOnlineClinicHome: true}})
        }
      }
    },
    ...mapActions({ fetchPatientFamilyProfiles: `patientFamilyProfiles/${PatientFamilyProfile.actionTypes.FETCH_QUERY}` })
  },
};
</script>
