import { PREFECTURES } from "../const/prefectures";

export const hCollectionToArray = collection => Array.prototype.slice.call(collection);

export const urlWithDeviceOption = function(url) {
  const currentDomain = window.location.hostname;
  
  try {
    const urlDomain = new URL(url).hostname;
    
    if (currentDomain === urlDomain) {
      return { href: url }; 
    } else {
      return { href: url, target: '_blank' };
    }
    
  } catch (e) {
    return { href: url }; 
  }
};

export const pageScrollToBottom = () => {
  window.setTimeout(
    () => window.scrollTo(0, 100000),
    1
  )
};

const urlRegex = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

export const textFilterWithHref = (createElement, _) => {
  return function(text) {
    const stringArray = text.split(/( )/g);
    const elements = stringArray.map((string) => {
      if(urlRegex.test(string)) {
        return createElement('a', { domProps: urlWithDeviceOption(string) }, string) ;
      } else {
        return string ;
      }
    });
    return elements
  }
}

export const findPrefecture = (prefectureId) => {
  return PREFECTURES.find(prefecture => prefecture.code == prefectureId)
}
