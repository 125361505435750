<template>
  <base-nav-tab-item :is-active="isActive">
    <router-link v-bind:to="hrefPath">
      <i class="fa fa-fw" :class="[iconClass]" aria-hidden="true"></i>
      {{bodyText}}
    </router-link>
  </base-nav-tab-item>
</template>

<script>
  const icons = {
    new: 'fa-ambulance ',
    now: 'fa-stethoscope',
    request: 'fa-hourglass-half',
    end: 'fa-thumbs-up',
    deleted: 'fa-close'
  };

  const bodyTexts = {
    new: '新着',
    now: '対応中',
    request: '終了待ち',
    end: '終了済み',
    deleted: '削除済'
  };


  export default {
    name: 'ConsultationsNavListItem',
    props: ['consultationStatus', 'type'],

    computed: {
      isActive() {
        return this.consultationStatus === this.type
      },
      iconClass() {
        return icons[this.type]
      },
      bodyText() {
        return bodyTexts[this.type]
      },
      hrefPath() {
        return `/consultations?status=${this.type}`
      }
    }
  }
</script>

<style scoped>
</style>
