<template>
  <section class="p-onlineClinicWebMtg l-ignoreParentContainer">
    <iframe v-if="webMtgUrl && isRoomOpen" style="background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);;" :src="webMtgUrl" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
  data: () => {
    return {
      examination: null,
    }
  },
  created() {
    this.fetchExamination()
  },
  computed: {
    webMtgUrl() {
      if (this.examination) {
        return this.examination.web_mtg_url_for_patient
      }
    },
    isRoomOpen() {
      if (this.examination){
        const start = this.examination.doctor_schedule.start
        const end = this.examination.doctor_schedule.end
        const roomOpenTime = moment(start).subtract(10, 'minutes').format()
        const roomCloseTime = moment(end).add(15, 'minutes').format()
        const now = moment(new Date()).format()

        if ( now >= roomOpenTime && now <= roomCloseTime ) {
          return true
        } else {
          alert("診療時間外です")
          return false
        }
      }
    },
  },
  methods: {
    async fetchExamination() {
      try {
        const examination = await axios.get(`/api/online_clinic/examinations/${this.$route.params.id}`);
        this.examination = examination.data
      } catch {
        alert("診療情報が取得できませんでした。インターネットへの接続状況をご確認ください。解決しない場合はアナムネまでお問い合わせください")
      }
    },
  }

};
</script>