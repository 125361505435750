<template>
  <a href="" @click={reloadPage} >
    <slot />
  </a>
</template>

<script>
  export default {
    name: 'BaseReloadLink',
    methods: {
      reloadPage: function() {
        location.reload();
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
