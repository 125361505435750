<template>
  <div>
    <notice-alerts></notice-alerts>
    <base-title :prev-path="`/chat_interview/groups/${$route.params.group_id}`">
      <h1>問診項目作成</h1>
    </base-title>
    <chat-interview-master-form :master-form="masterForm" :errors="errors" :errors-children="errorsChildren">
      <template slot="submit">
        <button @click="handleSubmit" class="btn btn-primary">
          作成
        </button>
      </template>
    </chat-interview-master-form>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import {actionTypes}  from '../../store/modules/chatInterview/masters'
  import ChatInterviewMasterForm from "./MasterForm";

  export default {
    name: 'ChatInterviewMasterNew',
    components: {ChatInterviewMasterForm},
    data() {
      return {
        masterForm: {
          group_ids: [this.$route.params.group_id],
          message: "",
          interview_type: 'text',
          interval: 0,
          is_publishing: true,
          master_items: []
        },
        errors: {
        },
        errorsChildren: []
      }
    },
    methods: {
      handleSubmit() {
        this.postMaster({master: this.$data.masterForm})
          .then(() => this.$router.history.push(`/chat_interview/groups/${this.$route.params.group_id}`) )
          .catch((errors) => {
            this.errors = errors.errors;
            this.errorsChildren = errors.children;
          })
      },
      ...mapActions({
        postMaster: `chatInterview/masters/${actionTypes.POST}`
      })
    }
  }
</script>
