<template>
  <div class="p-healthCheckupSupportPatientInformation">
      <div class="p-healthCheckupSupportPatientInformation__label">どなたが健診サポートを受けますか？</div>
      <select v-model="selectedFamilyProfile" class="p-healthCheckupSupportPatientInformation__selectUser">
        <option v-for="patientFamilyProfile in patientFamilyProfiles" :value="patientFamilyProfile" :key="patientFamilyProfile.id">
           {{ patientFamilyProfile.first_name }}{{patientFamilyProfile.last_name}}
        </option>
      </select>

    <div class="p-healthCheckupSupportPatientInformation__container l-ignoreParentContainer">
      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">名前<abbr title="not_entered" v-show="isVisible && !(selectedFamilyProfile.first_name && selectedFamilyProfile.last_name)">未入力</abbr></div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.first_name" required>
          <input disabled type="text" v-model="selectedFamilyProfile.last_name" required>
        </div>
      </div>
      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">名前（フリガナ）</div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.first_name_kana" required>
          <input disabled type="text" v-model="selectedFamilyProfile.last_name_kana" required>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">メールアドレス<abbr title="not_entered" v-show="isVisible && !currentUser.email">未入力</abbr></div>

        <div>
          <input disabled type="email" v-model="this.currentUser.email" required>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">携帯番号</div>
        <div>
          <input disabled type="tel" v-model="selectedFamilyProfile.phone_number" required>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">誕生日<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.birthday">未入力</abbr></div>
        <div>
          <input disabled type="date" v-model="selectedFamilyProfile.birthday" required>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__radio">
        <div class="p-healthCheckupSupportPatientInformation__label">性別<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.sex">未入力</abbr></div>
        <div>
          <label><input disabled type="radio" v-model="selectedFamilyProfile.sex" value="male">男性</label>
          <label><input disabled type="radio" v-model="selectedFamilyProfile.sex" value="female">女性</label>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__radio">
        <div class="p-healthCheckupSupportPatientInformation__label">妊娠</div>
        <div>
          <label><input disabled type="radio" v-model="selectedFamilyProfile.pregnancy" value="yes">している</label>
          <label><input disabled type="radio" v-model="selectedFamilyProfile.pregnancy" value="no">していない</label>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">郵便番号</div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.zip_code" maxlength="7" required>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">ご住所</div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.address" required>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">身長</div>
        <div class="p-healthCheckupSupportPatientInformation__physical">
          <span>
          {{selectedFamilyProfile.height ? selectedFamilyProfile.height + ' cm' : '　'}}
          </span>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">体重</div>
        <div class="p-healthCheckupSupportPatientInformation__physical">
          <span>
            {{selectedFamilyProfile.weight ? selectedFamilyProfile.weight + ' kg' : '　'}}
          </span>
        </div>
      </div>

      <div class="p-healthCheckupSupportPatientInformation__input">
        <div class="p-healthCheckupSupportPatientInformation__label">基礎体温</div>
        <div class="p-healthCheckupSupportPatientInformation__physical">
          <span>
            {{selectedFamilyProfile.basal_body_temperature ? selectedFamilyProfile.basal_body_temperature + ' &#8451;' : '　'}}
            </span>
        </div>
      </div>

      <p v-if="isValid" class="p-healthCheckupSupportPatientInformation__link">
        ※基本情報を変更したい方は<router-link class="c-link" :to="{name: 'patient_family_profile', params: { id: selectedFamilyProfile.id }}" @click ="$mixpanel.track('CLICK BUTTON', { page: '相談者の選択', buttonName: 'こちら', userId: currentUser.id, source: currentUser.source })">こちら</router-link>へ
      </p>
      <p v-if="!isValid" class="p-healthCheckupSupportPatientInformation__link">
        ※予約前に<router-link class="c-link" :to="{name: 'patient_family_profile', params: { id: selectedFamilyProfile.id } }" @click.native="$mixpanel.track('CLICK BUTTON', { page: '相談者の選択', buttonName: 'こちら', userId: currentUser.id, source: currentUser.source })">こちら</router-link>からすべての情報を入力してください。
      </p>
    </div>

    <div class="p-healthCheckupSupportPatientInformation__footer">
      <button class="c-mainButton" @click="addConsultationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '病院の選択', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })" :disabled="!isValid">次へ</button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        patientFamilyProfiles: [],
        selectedFamilyProfile: {},
        isVisible:false
      }
    },
    async created() {
      const res = await axios.get(`/api/patient_family_profiles`)
      this.patientFamilyProfiles = res.data
      if (this.patientId){
        const result = res.data.filter( data => data.id == this.patientId)
        this.selectedFamilyProfile = result[0]
      } else {
        this.selectedFamilyProfile = res.data[0]
      }
      if(this.selectedFamilyProfile.pregnancy == null ){
        this.selectedFamilyProfile.pregnancy = 'no';
      }
      this.isVisible = true
    },
    computed: {
      ...mapGetters(['currentUser']),
      isValid() {
        const requiredInputFields = [
          this.selectedFamilyProfile.last_name,
          this.selectedFamilyProfile.first_name,
          this.selectedFamilyProfile.birthday,
          this.selectedFamilyProfile.sex,
        ]
        return !requiredInputFields.some(field => (field == null || field == ''))
      },
      currentUrl() {
        return location.href
      },
      patientId() {
        return this.$route.params.patientId
      }
    },
    methods: {
      addConsultationData() {
        const data = {
          selectedFamilyProfile: {...this.selectedFamilyProfile, ...{ email: this.currentUser.email }},
        }
        this.$emit('addConsultationData', data)
      },
    }
  }
</script>
