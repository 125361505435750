<template>
  <div key='medical_dashboard' id='cy-medical-dashboards'>
    <div class='panel panel-default consultation-panel'>
      <div class='panel-heading consultation-heading'>
        <div class='panel-title name'>
          <div class="row">
            <div class="col-xs-4">
              メディカルダッシュボード
            </div>
            <div class="col-xs-8">
              <base-loading-icon :target="patientFamilyProfiles">
                <a v-for="profile in patientFamilyProfiles" :key="profile.id" :href="`/family_profiles/${profile.id}`" class="btn btn-info btn-xs">
                  {{ `${profile.first_name} ${profile.last_name}` }}
                </a>
              </base-loading-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes from '../store/action-types'
  import * as PatientFamilyProfile from  '../store/modules/patientFamilyProfiles';
  import BaseLoadingIcon from "./base/BaseLoadingIcon";

  export default {
    name: 'MedicalDashboardLinks',
    components: {BaseLoadingIcon},
    created: function() {
      this.fetchPatientFamilyProfiles()
    },
    methods: {
      ...mapActions({ fetchPatientFamilyProfiles: `patientFamilyProfiles/${PatientFamilyProfile.actionTypes.FETCH_QUERY}` })
    },
    computed: {
      ...mapGetters(['currentUser']),
      ...mapGetters({patientFamilyProfiles: 'patientFamilyProfiles/query'})
    }
  }
</script>

<style scoped lang="scss">
</style>
