<template>
  <div class="operator-invitations">
    <h1>医師･CS 招待フォーム作成</h1>
    <p>
      医師･CS 用ユーザの招待フォームを作成します.
    </p>
    <div class="form-group row">
      <label for="role" class="col-md-4">ロール</label>
      <div class="col-md-4">
        <select v-model="form.role" class="form-control" id="role">
          <option value="doctor">医師アカウント</option>
          <option value="customer_service">CS アカウント</option>
        </select>
      </div>
      <div class="col-md-4">
        <button @click="handleCreate" class="btn btn-info">URL 作成</button>
      </div>
    </div>

    <div v-if="invitation" class="invitation-panel">
      <h2>このリンクを招待者に伝えてください</h2>
      <p>
        <i @click="clickCopy" :class="isCopied ? 'btn-success' : ' btn-info'" class="fa fa-clipboard btn btn-xs"/>
        <span class="cy-invitation-url">{{invitationUrl}}</span>
      </p>
      <p class="help-block">
        このリンクの有効期限は10日({{invitation.expires_in| moment('YYYY-MM-DD HH:mm')}})です.
        有効期限が切れた場合は再度発行してください.
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      form: {
        role: 'doctor'
      },
      invitation: null,
      isCopied: false
    }
  },

  computed: {
    invitationUrl() {
      if(!this.invitation) return '';

      return `${process.env.RAILS_HOST}/operators/sign_up/${this.invitation.token}`;
    },
  },

  methods: {
    async handleCreate() {
      const response = await axios.post('/api/invitations', { role: this.form.role });
      this.invitation = response.data;
    },
    async clickCopy() {
      if(!navigator.clipboard) return;

      await navigator.clipboard.writeText(this.invitationUrl);
      this.isCopied = true;
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  text-align: right;
  padding: 11px 8px 0 15px;
}

button {
  margin: 5px 0 0 0;
}

.invitation-panel {
  padding: 8px;
  background-color: white;
}
</style>
