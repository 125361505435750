import axios from 'axios'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import ChatLogEnum from '../../enums/ChatLogEnum'
import Chat from '../../enums/Chat'

const state = {
  chat: {
    chat_logs: []
  }
};

const getters = {
  chat: state => state.chat,
  subscriptionRequesting: state =>
    state.chat.chat_logs.filter(chatLog => chatLog.log_type === ChatLogEnum.logType.subscriptionRequest).length > 0
};

const actions = {
  async [actionTypes.FETCH_CHAT] ({ commit }, payload) {
    await axios.get(`/api/chats/${payload.id}`)
      .then(response => {
        commit(mutationTypes.SET_CHAT, response.data)
        payload.callback();
      })
      .catch(response => console.log(response))
  },

  [actionTypes.FETCH_OGP] ({ commit }, payload) {
    axios.get('/api/ogp', { params: { url: payload.url } })
      .then(response => commit(mutationTypes.SET_CHAT_LOG_OGP, { chatLog: payload.chatLog, ogp: response.data, url: payload.url }))
      .catch(response => console.log(response))
  },

  [actionTypes.POST_FINISH_REQUEST]({ commit }, { chatId, isSatisfactionRequest }) {
    return axios.post(`/api/chats/${chatId}/finish_requests`, { is_satisfaction_request: isSatisfactionRequest })
      .then(response => commit(mutationTypes.SET_CHAT_LOG, { chatLog: response.data }));
  },

  [actionTypes.POST_FINISH] ({ commit }, { chatId }) {
    return axios.post(`/api/chats/${chatId}/finishes`)
      .then(response => {
        commit(mutationTypes.SET_CHAT_LOG, { chatLog: response.data })
        commit(mutationTypes.FINISH_CHAT)
      });
  },

  [actionTypes.PATCH_FINISH_REQUEST] ({ commit }, payload) {
    return axios.patch(`/api/chats/${payload.chat.id}/finish_requests/${payload.chatLog.id}`, { is_satisfied: payload.isSatisfied })
      .then(response => commit(mutationTypes.SET_CHAT_LOG, { chatLog: response.data }));
  },

  [actionTypes.DELETE_FINISH_REQUEST] ({ commit }, payload) {
    return axios.delete(`/api/chats/${payload.chat.id}/finish_requests/${payload.chatLog.id}`)
      .then(response => commit(mutationTypes.SET_CHAT_LOG, { chatLog: response.data }));
  }
};

const mutations = {
  [mutationTypes.SET_CHAT] (state, chat) {
    state.chat = chat
  },
  [mutationTypes.SET_CHAT_LOG] (state, payload) {
    state.chat.chat_logs = state.chat.chat_logs.map((chatLog) => {
      if (chatLog.id === payload.chatLog.id) {
        return Object.assign({ ogps: chatLog.ogps}, payload.chatLog)
      } else {
        return chatLog
      }
    })
  },
  [mutationTypes.SET_CHAT_LOG_OGP] (state, payload) {
    state.chat.chat_logs = state.chat.chat_logs.map((chatLog) => {
      if (chatLog.id === payload.chatLog.id) {
        payload.chatLog.ogps.push(payload.ogp);
        return payload.chatLog
      } else {
        return chatLog
      }
    })
  },
  [mutationTypes.PUSH_CHAT_LOG] (state, payload) {
    if (state.chat.chat_logs.filter(chatLog => chatLog.id === payload.chatLog.id).length === 0) {
      state.chat.chat_logs = [...state.chat.chat_logs, payload.chatLog]
    }
  },
  [mutationTypes.DELETE_CHAT_LOG](state, payload) {
    state.chat.chat_logs = state.chat.chat_logs.filter(chat_log => chat_log.id != payload.chatLogId);
  },
  [mutationTypes.SET_CHAT_LOGS] (state, payload) {
    state.chat.chat_logs = payload.chatLogs.map((chatLog) => {
      const target = state.chat.chat_logs.find(cl => cl.id === chatLog.id)
      if(target) {
        chatLog.ogps = target.ogps;
        return chatLog
      } else {
        return chatLog
      }
    })
  },
  [mutationTypes.FINISH_CHAT](state, payload) {
    state.chat.status = Chat.Status.finished;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}

