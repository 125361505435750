var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-onlineClinicStatusBar"},[_c('span',{staticClass:"p-onlineClinicStatusBar__straightLine"}),_vm._v(" "),_c('div',{staticClass:"p-onlineClinicStatusBar__circle01",class:{
      active: this.examinationStatus === 0
    }},[_c('p',[_vm._v("診療機関の選択")])]),_vm._v(" "),_c('div',{staticClass:"p-onlineClinicStatusBar__circle02",class:{
      active: this.examinationStatus === 1
    }},[_c('p',[_vm._v("時間の選択")])]),_vm._v(" "),_c('div',{staticClass:"p-onlineClinicStatusBar__circle03",class:{
      active: this.examinationStatus === 2
    }},[_c('p',[_vm._v("問診の回答")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }