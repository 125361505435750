import axios from 'axios';
import * as rootMutationTypes from '../mutation-types';
import { LOADING_QUERY } from '../loading';
import moment from 'moment';

export const actionTypes = {
  FETCH_QUERY: 'FETCH_QUERY',
  FETCH_GET: 'FETCH_GET',
  FETCH_NEW_RESULTS: 'FETCH_NEW_RESULTS',
  INITIAL_GET: 'INITIAL_GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const mutationTypes = {
  SET_QUERY: 'SET_QUERY',
  SET_GET: 'SET_GET',
  SET_RESULTS: 'SET_RESULTS',
  SET_FIRST_NAME: 'SET_FIRST_NAME',
  SET_FIRST_NAME_KANA: 'SET_FIRST_NAME_KANA',
  SET_LAST_NAME: 'SET_LAST_NAME',
  SET_LAST_NAME_KANA: 'SET_LAST_NAME_KANA',
  SET_BIRTHDAY: 'SET_BIRTHDAY',
  SET_YEAR: 'SET_YEAR',
  SET_MONTH: 'SET_MONTH',
  SET_DAY: 'SET_DAY',
  SET_ZIP_CODE: 'SET_ZIP_CODE',
  SET_ADDRESS_PREFECTURE_ID: 'SET_ADDRESS_PREFECTURE_ID',
  SET_ADDRESS_PREFECTURES: 'SET_ADDRESS_PREFECTURES',
  SET_ADDRESS_CITY: 'SET_ADDRESS_CITY',
  SET_ADDRESS_STREET: 'SET_ADDRESS_STREET',
  SET_ADDRESS_OTHER: 'SET_ADDRESS_OTHER',
  SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
  SET_SEX: 'SET_SEX',
  SET_PREGNANCY: 'SET_PREGNANCY',
  SET_HEIGHT: 'SET_HEIGHT',
  SET_WEIGHT: 'SET_WEIGHT',
  SET_BASAL_BODY_TEMPERATURE: 'SET_BASAL_BODY_TEMPERATURE',
  SET_DEMECAL_ID: 'SET_DEMECAL_ID',
  SET_TRACKING_NUMBER: 'SET_TRACKING_NUMBER',
  PUSH_RESULT_ITEM: 'PUSH_RESULT_ITEM',
  DELETE_PATIENT_FAMILY_PROFILE: 'DELETE_PATIENT_FAMILY_PROFILE',
};

export const Sex = {
  female: 'female',
  male: 'male',
};

export const Pregnancy = {
  yes: 'yes',
  no: 'no',
};

const defaultPatientFamilyProfile = () => ({
  first_name: '',
  first_name_kana: '',
  last_name: '',
  last_name_kana: '',
  birthday: '',
  yaer: '',
  month: '',
  day: '',
  zip_code: '',
  address_prefecture_id: '',
  address_prefectures: '',
  address_city: '',
  address_street: '',
  address_other: '',
  phone_number: '',
  sex: Sex.female,
  pregnancy: Pregnancy.yes,
  height: null,
  weight: null,
  basal_body_temperature: null,
  demecal_id: '',
  tracking_number: '',
  interview_results: [],
});

const toParams = patientFamilyProfile => ({
  first_name: patientFamilyProfile.first_name,
  first_name_kana: patientFamilyProfile.first_name_kana,
  last_name: patientFamilyProfile.last_name,
  last_name_kana: patientFamilyProfile.last_name_kana,
  birthday: patientFamilyProfile.year + '-' + patientFamilyProfile.month + '-' + patientFamilyProfile.day,
  zip_code: patientFamilyProfile.zip_code,
  address_prefecture_id: patientFamilyProfile.address_prefecture_id,
  address_prefectures: patientFamilyProfile.address_prefectures,
  address_city: patientFamilyProfile.address_city,
  address_street: patientFamilyProfile.address_street,
  address_other: patientFamilyProfile.address_other,
  phone_number: patientFamilyProfile.phone_number,
  sex: patientFamilyProfile.sex,
  pregnancy: patientFamilyProfile.pregnancy,
  height: patientFamilyProfile.height,
  weight: patientFamilyProfile.weight,
  basal_body_temperature: patientFamilyProfile.basal_body_temperature,
  demecal_id: patientFamilyProfile.demecal_id,
  tracking_number: patientFamilyProfile.tracking_number,
  interview_results_attributes: patientFamilyProfile.interview_results.map(
    result => {
      return {
        id: result.id,
        master_id: result.master.id,
        way_type: result.way_type,
        content: result.content,
        result_items_attributes: result.result_items.map(result_item => {
          return {
            id: result_item.id,
            master_item_id: result_item.master_item_id,
            supplement: result_item.supplement,
            _destroy: result_item._destroy,
          };
        }),
      };
    }
  ),
});

const state = {
  patientFamilyProfiles: LOADING_QUERY,
  patientFamilyProfile: defaultPatientFamilyProfile(),
};

const getters = {
  query: state => state.patientFamilyProfiles,
  get: state => state.patientFamilyProfile,
  isRequireInterview: () => patientFamilyProfile =>
    patientFamilyProfile.interview_results.length === 0,
  errors: state => state.patientFamilyProfile.errors || {},
};

const actions = {
  [actionTypes.FETCH_QUERY]({ commit, dispatch }) {
    return axios
      .get('/api/patient_family_profiles')
      .then(response => {
        commit(mutationTypes.SET_QUERY, {
          patientFamilyProfiles: response.data,
        });
        if (response.data.length > 0) {
          commit(mutationTypes.SET_GET, {
            patientFamilyProfile: response.data[0],
          });
        }
        return response.data;
      })
      .catch(_response => {
        console.log(_response);
      });
  },
  [actionTypes.FETCH_GET]({ commit }, { id, then }) {
    axios
      .get(`/api/patient_family_profiles/${id}`)
      .then(response => {
        commit(mutationTypes.SET_GET, { patientFamilyProfile: response.data });
        then(response.data);
      })
      .catch(err => {
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: 'データの取得に失敗しました', type: 'danger' },
          { root: true }
        );
      });
  },
  [actionTypes.FETCH_NEW_RESULTS]({ commit }) {
    axios
      .get('/api/interview/results/new')
      .then(response => {
        commit(mutationTypes.SET_RESULTS, { results: response.data });
      })
      .catch(({ response }) => {
        'fuck';
      });
  },
  [actionTypes.INITIAL_GET]({ commit, dispatch }, { isFromOnlineClinicHome, isFromOnlineConsultationHome }) {
    commit(mutationTypes.SET_GET, {
      patientFamilyProfile: defaultPatientFamilyProfile(),
    });
    if (isFromOnlineClinicHome) {
      commit(
        rootMutationTypes.PUSH_NOTICE,
        { message: 'オンラインクリニックを利用するために会員情報を登録してください', type: 'success' },
        { root: true }
      );
    } else if (isFromOnlineConsultationHome) {
      commit(
        rootMutationTypes.PUSH_NOTICE,
        { message: '医療相談を利用するために会員情報を登録してください', type: 'success' },
        { root: true }
      );
    }
    dispatch(actionTypes.FETCH_NEW_RESULTS);
  },
  [actionTypes.POST]({ commit, dispatch }, { patientFamilyProfile }) {
    return axios
      .post('/api/patient_family_profiles', {
        patient_family_profile: toParams(patientFamilyProfile),
      })
      .then(response => {
        commit(mutationTypes.SET_GET, { patientFamilyProfile: response.data });
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: 'プロフィールを作成しました。', type: 'success' },
          { root: true }
        );
        return response
      })
      .catch(({ response }) => {
        commit(mutationTypes.SET_GET, { patientFamilyProfile: response.data });
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: '入力項目が正しくありません', type: 'danger' },
          { root: true }
        );
        return response
      });
  },
  [actionTypes.PATCH]({ commit, dispatch }, { patientFamilyProfile }) {
    return axios
      .patch(`/api/patient_family_profiles/${patientFamilyProfile.id}`, {
        patient_family_profile: toParams(patientFamilyProfile),
      })
      .then(response => {
        commit(mutationTypes.SET_GET, { patientFamilyProfile: response.data });
        dispatch(actionTypes.FETCH_QUERY);
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: 'プロフィールを更新しました', type: 'success' },
          { root: true }
        );
        return response
      })
      .catch(({ response }) => {
        let errorMessage = ''
        if (response.data.errors) {
          Object.keys(response.data.errors).forEach(function (key) {
            let message = '・' + response.data.errors[key] + ' '
            errorMessage = errorMessage + message
          });
        }
        commit(mutationTypes.SET_GET, { patientFamilyProfile: response.data });
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: errorMessage, type: 'danger' },
          { root: true }
        );
        return response
      });
  },
  [actionTypes.DELETE]({ commit, dispatch }, { id }) {
    return axios
      .delete(`/api/patient_family_profiles/${id}`)
      .then(response => {
        dispatch(actionTypes.FETCH_QUERY);
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: '家族プロフィールを削除しました', type: 'success' },
          { root: true }
        );
        return response
      })
      .catch(({ response }) => {
        let errorMessage = 'プロフィールが１つしかない場合または、過去に診療・相談にしたことがある場合は削除できません。';
        commit(
          rootMutationTypes.PUSH_NOTICE,
          { message: errorMessage, type: 'danger' },
          { root: true }
        );
        throw response
      });
  },
};

const mutations = {
  [mutationTypes.SET_QUERY](state, payload) {
    state.patientFamilyProfiles = payload.patientFamilyProfiles;
  },
  [mutationTypes.SET_GET](state, payload) {
    payload.patientFamilyProfile['year'] = String(moment(payload.patientFamilyProfile.birthday).get('year'));
    payload.patientFamilyProfile['month'] = String(moment(payload.patientFamilyProfile.birthday).get('month') + 1);
    payload.patientFamilyProfile['day'] = String(moment(payload.patientFamilyProfile.birthday).get('date'));
    state.patientFamilyProfile = payload.patientFamilyProfile;
  },
  [mutationTypes.SET_RESULTS](state, { results }) {
    state.patientFamilyProfile.interview_results = results;
  },
  [mutationTypes.SET_FIRST_NAME](state, { firstName }) {
    state.patientFamilyProfile.first_name = firstName;
  },
  [mutationTypes.SET_FIRST_NAME_KANA](state, { firstNameKana }) {
    state.patientFamilyProfile.first_name_kana = firstNameKana;
  },
  [mutationTypes.SET_LAST_NAME](state, { lastName }) {
    state.patientFamilyProfile.last_name = lastName;
  },
  [mutationTypes.SET_LAST_NAME_KANA](state, { lastNameKana }) {
    state.patientFamilyProfile.last_name_kana = lastNameKana;
  },
  [mutationTypes.SET_BIRTHDAY](state, { birthday }) {
    state.patientFamilyProfile.birthday = birthday;
  },
  [mutationTypes.SET_YEAR](state, { year }) {
    state.patientFamilyProfile.year = year;
  },
  [mutationTypes.SET_MONTH](state, { month }) {
    state.patientFamilyProfile.month = month;
  },
  [mutationTypes.SET_DAY](state, { day }) {
    state.patientFamilyProfile.day = day;
  },
  [mutationTypes.SET_ZIP_CODE](state, { zipCode }) {
    state.patientFamilyProfile.zip_code = zipCode;
  },
  [mutationTypes.SET_ADDRESS_PREFECTURE_ID](state, { addressPrefectureId }) {
    state.patientFamilyProfile.address_prefecture_id = addressPrefectureId;
  },
  [mutationTypes.SET_ADDRESS_PREFECTURES](state, { addressPrefectures }) {
    state.patientFamilyProfile.address_prefectures = addressPrefectures;
  },
  [mutationTypes.SET_ADDRESS_CITY](state, { addressCity }) {
    state.patientFamilyProfile.address_city = addressCity;
  },
  [mutationTypes.SET_ADDRESS_STREET](state, { addressStreet }) {
    state.patientFamilyProfile.address_street = addressStreet;
  },
  [mutationTypes.SET_ADDRESS_OTHER](state, { addressOther }) {
    state.patientFamilyProfile.address_other = addressOther;
  },
  [mutationTypes.SET_PHONE_NUMBER](state, { phoneNumber }) {
    state.patientFamilyProfile.phone_number = phoneNumber;
  },
  [mutationTypes.SET_SEX](state, { sex }) {
    state.patientFamilyProfile.sex = sex;
  },
  [mutationTypes.SET_PREGNANCY](state, { pregnancy }) {
    state.patientFamilyProfile.pregnancy = pregnancy;
  },
  [mutationTypes.SET_HEIGHT](state, { height }) {
    state.patientFamilyProfile.height = height;
  },
  [mutationTypes.SET_WEIGHT](state, { weight }) {
    state.patientFamilyProfile.weight = weight;
  },
  [mutationTypes.SET_BASAL_BODY_TEMPERATURE](state, { basalBodyTemperature }) {
    state.patientFamilyProfile.basal_body_temperature = basalBodyTemperature;
  },
  [mutationTypes.SET_DEMECAL_ID](state, { demecalId }) {
    state.patientFamilyProfile.demecal_id = demecalId;
  },
  [mutationTypes.SET_TRACKING_NUMBER](state, { trackingNumber }) {
    state.patientFamilyProfile.tracking_number = trackingNumber;
  },
  [mutationTypes.PUSH_RESULT_ITEM](state, { result, resultItem }) {
    state.patientFamilyProfile.interview_results
      .find(r => r.id === result.id)
      .result_items.push(resultItem);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
