<template>
  <div class="onlineConsultation-modal">
    <div class="onlineConsultation-modal__box">
      <div class="onlineConsultation-modal__header">
        医師情報
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>
      <div class="onlineConsultation-modal__content">
        <img :src="doctorProfile.imageUrl">
        <span>{{doctorProfile.firstName + ' ' + doctorProfile.lastName}}</span>
        <div class="onlineConsultation-modal__label">
          性別
        </div>
        <div class="onlineConsultation-modal__item">
          {{displaySex(doctorProfile.sex)}}
        </div>
        <div class="onlineConsultation-modal__label">
          専門
        </div>
        <div class="onlineConsultation-modal__item">
          {{doctorProfile.expert}}
        </div>
        <div class="onlineConsultation-modal__label">
          所属（出身）医局
        </div>
        <div class="onlineConsultation-modal__item">
          {{doctorProfile.graduateMedicalOffice}}
        </div>
        <div class="onlineConsultation-modal__label">
          自己紹介
        </div>
        <div class="onlineConsultation-modal__item">
          {{doctorProfile.selfIntroduction}}
        </div>
      </div>
    </div>
    <div class="onlineConsultation-modal__overlay">
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoctorProfileModal',
  created() {},
  data: () => {
    return {};
  },
  props: ['profile'],
  computed: {
    doctorProfile() {
      return this.$props.profile
    },
  },
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
    displaySex(sex){
      if (sex == 'male'){
        return '男性'
      } else if (sex == 'female')
        return '女性'
    }
  },
};
</script>

<style scoped lang="scss">
  .onlineConsultation-modal {

    &__box {
      background-color:#FFFFFF;
      width: 100%;
      max-width: 295px;
      height: auto;
      min-height: 493px;
      position: fixed;
      top: 95px;
      left: 0;
      right: 0;
      margin: auto;
      z-index:300;
      border-radius: 8px;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
    }

    &__overlay {
      background: #2a3039;
      opacity: 0.2;
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index:200;
    }

    &__header {
      width:auto;
      height:48px;
      border-bottom: #CFD8DC solid 1px;
      line-height: 48px;
      text-align: center;
      color: #2A3039;
      font-size:14px;
      position:relative;

      img {
        cursor: pointer;
        position:absolute;
        right: 10px;
        top: 13px;
        width:21px;
        height:21px;
      }
    }

    &__content {
      padding: 16px;

      img {
        width:52px;
        height:52px;
        border-radius: 50%;
      }

      span {
        display: inline-block;
        font-size: 16px;
        color: #2A3039;
        height:52px;
        line-height: 52px;
        vertical-align: top;
        margin: 0 0 0 13px;

      }
    }

    &__label {
      color:#2196F3;
      font-size:12px;
      text-align: left;
      margin: 16px 0 0 0;
    }

    &__item {
      color:#2A3039;
      font-size:14px;
      text-align: left;
      margin: 4px 0 0 0;
      word-wrap: break-word;
    }
  }


</style>
