<template>
  <div>
    <notice-alert v-for="notice in notices" :notice="notice" />
    <base-title :prev-path="'/patient_family_profiles'">
      <h1 slot>ユーザー管理</h1>
      <template slot="tools">
        <router-link to="/operator_invitations" class="btn btn-info">
          医師･CS 招待
        </router-link>
      </template>
    </base-title>
    <base-nav-tabs>
      <base-nav-tab-item :is-active="tabState === 'admin'">
        <router-link to="/management_users?role=admin">Admin</router-link>
      </base-nav-tab-item>
      <base-nav-tab-item :is-active="tabState === 'customer_service'">
        <router-link to="/management_users?role=customer_service">Customer Service</router-link>
      </base-nav-tab-item>
      <base-nav-tab-item :is-active="tabState === 'doctor'">
        <router-link to="/management_users?role=doctor">Doctor</router-link>
      </base-nav-tab-item>
    </base-nav-tabs>
    <base-loading-icon :target="users">
      <ul class="list-group">
        <li v-for="user in users" :key="user.id" class="list-group-item">
          {{user.email}}
          <a v-if="tabState !== 'patient'" :href="`/doctor/${user.id}/profile/edit`" class="btn btn-info">
            プロフィール編集
          </a>
          <router-link :to="`/management_users/${user.id}/role?role=${tabState}`"
                       :disabled="currentUser.id === user.id"
                       class="btn btn-warning">
            ロール変更
          </router-link>
        </li>
      </ul>
    </base-loading-icon>
    <router-view></router-view>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import {actionTypes} from '../store/modules/users'
  export default {
    created() {
      if(!this.isAdmin) {
        this.$router.history.push("/consultations")
      }
      this.fetchUsers()
    },
    computed: {
      tabState() {
        return this.$route.query.role || "admin"
      },
      users() {
        return {
          admin: this.admins,
          customer_service: this.customerServices,
          doctor: this.doctors,
        }[this.tabState]
      },
      ...mapGetters({
        currentUser: 'currentUser',
        notices: 'notices',
        isAdmin: 'isAdmin',
        admins: 'users/admins',
        customerServices: 'users/customerServices',
        doctors: 'users/doctors',
       })
    },
    methods: {
      fetchUsers() {
        ({
          admin: this.fetchAdmin,
          customer_service: this.fetchCustomerService,
          doctor: this.fetchDoctor,
        })[this.tabState]()
      },
      ...mapActions({
        fetchAdmin: `users/${actionTypes.FETCH_ADMIN}`,
        fetchCustomerService: `users/${actionTypes.FETCH_CUSTOMER_SERVICE}`,
        fetchDoctor: `users/${actionTypes.FETCH_DOCTOR}`,
      })
    },
    watch: {
      tabState() {
        this.fetchUsers()
      }
    }
  }
</script>
