<template>
  <div class="form-group">
    <div class="control-group">
      <label class="control-label">{{result.master.message}}</label>
      <div v-for="master_item in result.master.master_items" class="checkbox">
        <input :id="`interview-result-${master_item.id}`" v-model="value" type="checkbox"
                :value="master_item.id">
        <label :for="`interview-result-${master_item.id}`">
          <div class="checkbox-icon">
            {{master_item.label}}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapMutations} from 'vuex'
  import * as PatientFamilyProfiles from '../../store/modules/patientFamilyProfiles'

  export default {
    name: 'ResultCheckboxesField',
    props: ['result'],
    data() {
      return {
        checkedMasterItemIds: this.result.result_items.filter(ri => ri.id === null).length > 0 ? [] : this.result.result_items.map(ri => ri.master_item_id)
      }
    },
    computed: {
      value: {
        get() {
          return this.$data.checkedMasterItemIds
        },
        set(ids) {
          this.$data.checkedMasterItemIds = ids;
          this.result.result_items.map(ri => ri._destroy = true);
          this.$data.checkedMasterItemIds.map(id => {
            const resultItem = this.result.result_items.find(ri => ri.master_item_id  == id)
            if(resultItem) {
              resultItem._destroy = false
            } else {
              this.pushResultItem({ result: this.result, resultItem: { master_item_id: id, _destroy: false } })
            }
          });
        }
      }
    },
    methods: {
      ...mapMutations({
        pushResultItem: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.PUSH_RESULT_ITEM}`
      })
    }
  }
</script>

<style scoped lang="scss">
  input[type="checkbox"] {
    margin-left: -20px;
  }
</style>
