import axios from 'axios'
import qs from 'qs'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import {LOADING_QUERY} from '../loading'

import Consultation from '../../enums/Consultation'

const state = {
  consultations: [],
  totalConsultationsCount: LOADING_QUERY,
  respondingConsultationsCount: LOADING_QUERY,
  doctors_new_consultations: [],
  stillConsultation: null,
  noMoreConsultation: true,
  totalFilteredConsultationsCount: 0
};

const getters = {
  consultations: state => state.consultations,
  totalConsultationsCount: state => state.totalConsultationsCount,
  totalFilteredConsultationsCount: state => state.totalFilteredConsultationsCount,
  respondingConsultationsCount: state => state.respondingConsultationsCount,
  consultationsByStatus: state => status => state.consultations.filter(c => c.status === Consultation.status[status]),
  stillConsultation: state => state.stillConsultation,
  noMoreConsultation: state => state.noMoreConsultation,
  findConsultation: state => id => state.consultations.find(c => c.id === id),
  consultationToStatus: _status => consultation => {
    return (
      {
        0: 'new',
        1: 'now',
        2: 'request',
        3: 'end',
        4: 'deleted'
      }[consultation.status]
    )
  },
  doctors_new_consultations: state => state.doctors_new_consultations
};

const actions = {
  [actionTypes.FETCH_CONSULTATIONS] ({ commit }, payload) {
    return axios.get('/api/consultations', {
      params: { ...payload },
      paramsSerializer(params) {
        return qs.stringify(params)
      }
    }).then(response => {
      commit(mutationTypes.SET_CONSULTATIONS, response.data.consultations)
      commit(mutationTypes.SET_CONSULTATIONS_META, response.data.meta)
    })
      .catch(response => console.log(response))
  },
  [actionTypes.CLEAR_CONSULTATIONS] ({ commit }) {
    commit(mutationTypes.SET_CONSULTATIONS, [])
  },
  [actionTypes.FETCH_ADDITIONAL_CONSULTATIONS] ({ commit }, payload) {
    return axios.get('/api/consultations', {
      params: { ...payload },
      paramsSerializer(params) {
        return qs.stringify(params)
      }
    }).then(response => {
      commit(mutationTypes.SET_ADDITIONAL_CONSULTATIONS, response.data.consultations)
      commit(mutationTypes.SET_CONSULTATIONS_META, response.data.meta)
    })
      .catch(response => console.log(response))
  },
  [actionTypes.FETCH_TOTAL_CONSULTATIONS_COUNT] ({ commit }, payload) {
    return axios.get('/api/consultations/total_consultations_count', {
      params: { ...payload },
      paramsSerializer(params) {
        return qs.stringify(params)
      }
    }).then(response => commit(mutationTypes.SET_TOTAL_CONSULTATIONS_COUNT, response.data))
      .catch(response => console.log(response))
  },
  [actionTypes.FETCH_RESPONDING_CONSULTATIONS_COUNT] ({ commit }, payload) {
    return axios.get('/api/consultations/responding_consultations_count', {
      params: { ...payload },
      paramsSerializer(params) {
        return qs.stringify(params)
      }
    }).then(response => commit(mutationTypes.SET_RESPONDING_CONSULTATIONS_COUNT, response.data))
      .catch(response => console.log(response))
  },
  [actionTypes.FETCH_DOCTORS_NEW_CONSULTATIONS] ({ commit }) {
    return axios.get('/api/consultations/doctors_new_consultations')
      .then(response => commit(mutationTypes.SET_DOCTORS_NEW_CONSULTATIONS, response.data))
      .catch(response => console.log(response))
  },
  [actionTypes.CREATE_CHAT]({ commit }, payload) {
    axios.post('/api/chats/', { consultation_id: payload.id })
      .then(response => {
        location.href = `/chat/${response.data.id}`
      })
      .catch(response => console.log(response))
  },
  [actionTypes.UPDATE_CHAT]({ commit }, payload) {
    axios.patch(`/api/chats/${payload}`)
      .then(response => {
        location.href = `/chat/${response.data.id}`
      })
      .catch(response => console.log(response))
  }
};

const mutations = {
  [mutationTypes.SET_CONSULTATIONS] (state, consultations) {
    state.consultations = consultations;
    state.stillConsultation = consultations.length === 0;
  },
  [mutationTypes.SET_ADDITIONAL_CONSULTATIONS] (state, consultations) {
    state.consultations.push(...consultations)
    state.noMoreConsultation = consultations.length === 0
    state.stillConsultation = false
  },
  [mutationTypes.SET_CONSULTATIONS_META] (state, meta) {
    state.noMoreConsultation = meta.last_page
    state.totalFilteredConsultationsCount = meta.total_count
  },
  [mutationTypes.SET_TOTAL_CONSULTATIONS_COUNT] (state, totalConsultationsCount) {
    state.totalConsultationsCount = totalConsultationsCount;
  },
  [mutationTypes.SET_RESPONDING_CONSULTATIONS_COUNT] (state, respondingConsultationsCount) {
    console.log(respondingConsultationsCount)
    state.respondingConsultationsCount = respondingConsultationsCount;
  },
  [mutationTypes.SET_DOCTORS_NEW_CONSULTATIONS] (state, doctors_new_consultations) {
    state.doctors_new_consultations = doctors_new_consultations;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
