<template>
  <div class="p-onlineConsultationHome__root">
    <div class="p-onlineConsultationHome__rootInner">
      <div class="p-onlineConsultationHome__titleBox">
        <p class="p-onlineConsultationHome__mainText">
          <b>なんでも医師へ相談</b><br>
          <span>1日中365日、おうちから。</span>
        </p>
      </div>
      <div class="p-onlineConsultationHome__mainImage">
        <img src="@/../assets/images/online_consultation/top.png">
      </div>
      <div class="p-onlineConsultationHome__infoBox">
        <dl class="p-onlineConsultationHome__infoBoxInner p-onlineConsultationHome__infoBoxInner--medical">
          <dt>医療相談</dt>
          <dd>
            <span>月額</span>
            <b>980</b><span>円(税込)</span>
          </dd>
        </dl>
        <dl class="p-onlineConsultationHome__infoBoxInner p-onlineConsultationHome__infoBoxInner--supplement">
          <dt>サプリ相談</dt>
          <dd>
            <b>無料</b>
          </dd>
        </dl>
      </div>
      <button @click="startConsultation(); $mixpanel.track('CLICK BUTTON', { page: '相談トップ', buttonName: '相談をはじめる', userId: currentUser.id, source: currentUser.source, isCvButton: true })" class="c-mainButton p-onlineConsultationHome__mainButton" :class="{ disabled: !isCompletedLoading }">相談をはじめる</button>
      <div class="p-onlineConsultationHome__consultations__button__wrapper">
        <router-link :to=" { name: 'online_consultation_consultations' }" class="c-subButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '相談トップ', buttonName: 'ご相談一覧', userId: currentUser.id, source: currentUser.source })">ご相談一覧</router-link>
        <div v-if="unreadMessageCount > 0" class="ongoing-consultation-count">
          {{unreadMessageCount}}
        </div>
      </div>
      <footer-survey-link page="相談トップ"></footer-survey-link>
      <consultation-type-select v-if="isConsultationTypeSelectOpen" @close-modal="isConsultationTypeSelectOpen = false"></consultation-type-select>
    </div>
  </div>
</template>

<script>

import ConsultationNew from '@/pages/online_consultation/consultations/new'
import Consultations from '@/pages/online_consultation/consultations'
import ConsultationTypeSelect from "@/components/online_consultation/home/ConsultationTypeSelect";
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex';
import * as PatientFamilyProfile from  'store/modules/patientFamilyProfiles';
import FooterSurveyLink from "@/components/footer/FooterSurveyLink";

export default {
  components: { ConsultationNew, Consultations, ConsultationTypeSelect, FooterSurveyLink },
  data() {
    return {
      onGoingConsultations: [],
      isConsultationTypeSelectOpen: false
    };
  },
  async created() {
    this.fetchPatientFamilyProfiles()

    const res = await axios.get('/api/online_consultation/consultations', { params: { status: 'ongoing' }})
    this.onGoingConsultations = res.data
  },
  computed: {
    unreadMessageCount() {
      return this.onGoingConsultations.reduce((sum, i) => sum + i.chat.unread_message_count, 0);
    },
    currentUrl() {
      return location.href
    },
    isCompletedLoading() {
      return this.patientFamilyProfiles != 'LOADING_QUERY'
    },
    ...mapGetters({patientFamilyProfiles: 'patientFamilyProfiles/query', currentUser: 'currentUser'})
  },
  methods: {
    startConsultation() {
      if (this.isCompletedLoading) {
        if (this.patientFamilyProfiles.length > 0) {
          this.isConsultationTypeSelectOpen = true
        } else {
          this.$router.push({ name: 'patient_family_profile_new', params: {isFromOnlineConsultationHome: true}})
        }
      }
    },
    ...mapActions({ fetchPatientFamilyProfiles: `patientFamilyProfiles/${PatientFamilyProfile.actionTypes.FETCH_QUERY}` })
  },
};

</script>
