<template>
  <div class="p-patientFamilyProfile">
    <notice-alert v-for="notice in notices" :notice="notice"/>
    <base-title prev-path="/patient_family_profiles">
      <h1 class="p-patientFamilyProfile__title" slot>プロフィール登録</h1>
      <div class="p-patientFamilyProfile__guidance">
      サービスをご利用される際には、プロフィールを登録してください。
      <br>※オンラインクリニックをご利用される場合には、すべての項目の登録が必要です。
      </div>
    </base-title>
    <patient-family-profile-form :isNewForm="true" :isFromOnlineClinic="isFromOnlineClinic" @submit="submit"></patient-family-profile-form>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import PatientFamilyProfileForm from './PatientFamilyProfileForm'
  import * as PatientFamilyProfiles from '../store/modules/patientFamilyProfiles';
  import BaseTitle from "./base/BaseTitle";
  import NoticeAlert from './NoticeAlert';

  export default {
    name: 'PatientFamilyProfileNew',
    components: {BaseTitle, PatientFamilyProfileForm, NoticeAlert},
    created() {
      this.initialPatientFamilyProfile({isFromOnlineClinicHome: this.$route.params['isFromOnlineClinicHome'], isFromOnlineConsultationHome: this.$route.params['isFromOnlineConsultationHome']});
    },
    computed: {
      isFromOnlineClinic() {
        return this.$route.params['isFromOnlineClinicReservation'] || this.$route.params['isFromOnlineClinicHome']
      },
      ...mapGetters({
        notices: 'notices',
        patientFamilyProfile: 'patientFamilyProfiles/get',
        patientFamilyProfiles: 'patientFamilyProfiles/query'
      })
    },
    methods: {
      submit() {
        this.postPatientFamilyProfile({
          patientFamilyProfile: this.patientFamilyProfile,
        }).then((response) => {
          if (response.status === 200){
            if (this.$route.params.isFromOnlineClinicReservation) {
              this.$router.history.push({name: 'clinic_reservation_new', params: {patientId:response.data.id }})
            } else {
              this.$router.back()
            }
          }
        })
      },
      ...mapActions({
        fetchNewResults: `patientFamilyProfiles/${PatientFamilyProfiles.actionTypes.FETCH_NEW_RESULTS}`,
        initialPatientFamilyProfile: `patientFamilyProfiles/${PatientFamilyProfiles.actionTypes.INITIAL_GET}`,
        postPatientFamilyProfile: `patientFamilyProfiles/${PatientFamilyProfiles.actionTypes.POST}`,
      })
    },
  }
</script>
