<template>
  <div class="p-onlineClinicHistory">
    <p class="p-onlineClinicHistory__title">過去の診察</p>
    <p v-if="!existExaminations">まだ過去の診察はありません。</p>
    <ul v-if="existExaminations" class="p-onlineClinicHistory__list">
      <li class="p-onlineClinicHistory__item" v-for="examination in examinations" :value="examination" :key="examinations.id">
        <!--  診療済orキャンセル     -->
        <div class="p-onlineClinicHistory__box">
          <div class="p-onlineClinicHistory__icon">
            <img src="../../../../assets/images/online_clinic/status.png" alt="">
          </div>
          <div class="p-onlineClinicHistory__icon">
            <p class="p-onlineClinicHistory__status">{{getExaminationStatus(examination)}}</p>
            <!--  診療日時      -->
            <p class="p-onlineClinicHistory__date">
              診察日時：{{ formatDate(examination.doctor_schedule.start) }}
            </p>
          </div>
        </div>
        <!--  クリニック名      -->
        <p class="p-onlineClinicHistory__clinic">{{examination.clinic.name }}</p>
        <!--  Dr.の名前      -->
        <p class="p-onlineClinicHistory__doctor">{{ getDoctorName(examination)}}</p>
        <router-link :to="{ name: 'examination_completed', params: { id: examination.id }}" class="c-subButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '過去の診療', buttonName: '診療内容', userId: currentUser.id, source: currentUser.source })">診療内容</router-link>
      </li>
    </ul>
    <div class="p-onlineClinicHistory__footer">
      <a class="p-onlineClinicHistory__back" @click="$router.go(-1); $mixpanel.track('CLICK BUTTON', { page: '過去の診療', buttonName: 'もどる', userId: currentUser.id, source: currentUser.source })">もどる</a>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        examinations: [],
      }
    },
    //TODO いずれ件数増えて来る頃には非同期で件数しぼって読み込むよう修正
    async created() {
      const res = await axios.get(`/api/online_clinic/examinations`,
        {
          params:{
            completed: true
          }
        }
      )
      this.examinations = res.data
    },
    computed: {
      existExaminations(){
        if (this.examinations.length > 0){
          return true;
        }else{
          return false;
        }
      },
      ...mapGetters(['currentUser']),
    },
    methods: {
      formatDate(dateTime) {
        moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
        return moment(dateTime).format('YYYY/MM/DD(ddd)');
      },
      getExaminationStatus(examination) {
        if (examination.canceled_at === null) {
          return '診療済'
        } else {
          return 'キャンセル'
        }
      },
      getDoctorName(examination) {
        return examination.doctor.doctor_profile.first_name + ' ' + examination.doctor.doctor_profile.last_name + ' 医師'
      },
    }
  }
</script>
