<template>
  <div class="onlineClinic-interview">
    <button v-if="!isFirstInterview" class="onlineClinic-interview__button onlineClinic-interview__latest-interview" @click="setLatestInterview">前回の問診をコピーする</button>
    <div class="onlineClinic-interview__radio">
      <div class="onlineClinic-interview__label">血圧の高さについて、あてはまるものにチェックしてください
        <abbr title="required">必須</abbr>
      </div>
      <label><input type="radio" v-model="form.bloodPressure" value="普通">普通</label>
      <label><input type="radio" v-model="form.bloodPressure" value="高い">高い</label>
      <label><input type="radio" v-model="form.bloodPressure" value="低い">低い</label>
    </div>

    <div class="onlineClinic-interview__input">
      <label for="sleep_time" class="onlineClinic-interview__label">普段の睡眠時間について教えてください<br>(例：0時～6時まで)
        <abbr title="required">必須</abbr>
      </label>
      <textarea id="sleep_time" v-model="form.sleepTime"></textarea>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">睡眠の状態について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="夜に目が覚める" v-model="form.sleepQuality">
            夜に目が覚める
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="夢を多くみる" v-model="form.sleepQuality">
          夢を多くみる
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="いつもすっきりしない" v-model="form.sleepQuality">
          いつもすっきりしない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気にならない" v-model="form.sleepQuality">
          特に気にならない
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__radio">
      <div class="onlineClinic-interview__label">頭痛がありますか？あてはまるものにチェックしてください
        <abbr title="required">必須</abbr>
      </div>
      <label><input type="radio" v-model="form.isHeadache" value="特にない">特にない</label>
      <label><input type="radio" v-model="form.isHeadache" value="前頭部に感じる">前頭部に感じる</label>
      <label><input type="radio" v-model="form.isHeadache" value="側頭部に感じる">側頭部に感じる</label>
      <label><input type="radio" v-model="form.isHeadache" value="後頭部に感じる">後頭部に感じる</label>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">精神状態はいかがですか？あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="安定している" v-model="form.mentalConditions">
          安定している
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="イライラする" v-model="form.mentalConditions">
          イライラする
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="気が沈む" v-model="form.mentalConditions">
          気が沈む
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="不安を感じる" v-model="form.mentalConditions">
          不安を感じる
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">冷えの状態はいかがですか？あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特になし" v-model="form.coldSensitivities">
          特になし
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="手足に冷えがある" v-model="form.coldSensitivities">
          手足に冷えがある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="お腹に冷えがある" v-model="form.coldSensitivities">
          お腹に冷えがある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="下半身に冷えがある" v-model="form.coldSensitivities">
          下半身に冷えがある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="1年中冷えがある" v-model="form.coldSensitivities">
          1年中冷えがある
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">熱の有無について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特になし" v-model="form.hotSensitivities">
          特になし
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="のぼせ" v-model="form.hotSensitivities">
          のぼせ
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="ほてり" v-model="form.hotSensitivities">
          ほてり
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="口の渇き" v-model="form.hotSensitivities">
          口の渇き
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="多汗" v-model="form.hotSensitivities">
          多汗
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">汗の状態について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気にならない" v-model="form.sweatConditions">
          特に気にならない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="日中に多い" v-model="form.sweatConditions">
          日中に多い
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="夜間に多い" v-model="form.sweatConditions">
          夜間に多い
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="緊張時に多い" v-model="form.sweatConditions">
          緊張時に多い
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__radio">
      <div class="onlineClinic-interview__label">食欲はありますか？
        <abbr title="required">必須</abbr>
      </div>
      <label><input type="radio" v-model="form.isAppetite" value="あり">あり</label>
      <label><input type="radio" v-model="form.isAppetite" value="なし">なし</label>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">胃腸の状態について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気になることはない" v-model="form.gastrointestinalConditions">
          特に気になることはない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="胃痛がある" v-model="form.gastrointestinalConditions">
          胃痛がある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="腹痛がある" v-model="form.gastrointestinalConditions">
          腹痛がある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="胃もたれがある" v-model="form.gastrointestinalConditions">
          胃もたれがある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="胸やけがある" v-model="form.gastrointestinalConditions">
          胸やけがある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="吐き気がある" v-model="form.gastrointestinalConditions">
          吐き気がある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="お腹の張りがある" v-model="form.gastrointestinalConditions">
          お腹の張りがある
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__input">
      <label for="bowel_movement" class="onlineClinic-interview__label">便通について、1日の排便の回数と便の状態「普通・軟便・硬い」を記載してください
        <abbr title="required">必須</abbr>
      </label>
      <textarea id="bowel_movement" v-model="form.bowelMovement"></textarea>
    </div>

    <div class="onlineClinic-interview__input">
      <label for="urine_condition" class="onlineClinic-interview__label">お小水について、1日の回数と夜間尿の回数と出やすさ「出やすい・出にくい」を記載してください
        <abbr title="required">必須</abbr>
      </label>
      <textarea id="urine_condition" v-model="form.urineCondition"></textarea>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">皮膚の状態について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気になることはない" v-model="form.skinConditions">
          特に気になることはない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="乾燥している" v-model="form.skinConditions">
          乾燥している
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="蕁麻疹(じんましん)がある" v-model="form.skinConditions">
          蕁麻疹(じんましん)がある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="痒みがある" v-model="form.skinConditions">
          痒みがある
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="化膿している" v-model="form.skinConditions">
          化膿している
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">呼吸器について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気になることはない" v-model="form.respiratoryConditions">
          特に気になることはない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="鼻詰まり" v-model="form.respiratoryConditions">
          鼻詰まり
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="鼻水" v-model="form.respiratoryConditions">
          鼻水
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="咳" v-model="form.respiratoryConditions">
          咳
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="痰" v-model="form.respiratoryConditions">
          痰
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">痛みの有無について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気になることはない" v-model="form.isPains">
          特に気になることはない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="肩こり" v-model="form.isPains">
          肩こり
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="背部痛" v-model="form.isPains">
          背部痛
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="腰痛" v-model="form.isPains">
          腰痛
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="下肢痛" v-model="form.isPains">
          下肢痛
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="関節痛" v-model="form.isPains">
          関節痛
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__checkbox-section">
      <div class="onlineClinic-interview__label">全身症状について、あてはまるものにチェックしてください(複数選択可)
        <abbr title="required">必須</abbr>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="特に気になることはない" v-model="form.systemicSymptoms">
          特に気になることはない
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="疲れやすい" v-model="form.systemicSymptoms">
          疲れやすい
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="貧血" v-model="form.systemicSymptoms">
          貧血
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="アレルギー体質" v-model="form.systemicSymptoms">
          アレルギー体質
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="むくみ" v-model="form.systemicSymptoms">
          むくみ
        </label>
      </div>
      <div class="onlineClinic-interview__checkbox">
        <label>
          <input type="checkbox" value="めまい" v-model="form.systemicSymptoms">
          めまい
        </label>
      </div>
    </div>

    <div class="onlineClinic-interview__radio" v-if="(this.examination && this.examination.interview_for_department.patient_sex==='female') ||( this.$props.clinicReservationToCreate.selectedFamilyProfile && this.$props.clinicReservationToCreate.selectedFamilyProfile.sex === 'female')">
      <div class="onlineClinic-interview__label">生理痛はありますか？
        <abbr title="required">必須</abbr>
      </div>
      <label><input type="radio" v-model="form.isMenstrualPain" value="ない">ない</label>
      <label><input type="radio" v-model="form.isMenstrualPain" value="ある">ある</label>
    </div>

    <div class="onlineClinic-interview__radio" v-if="(this.examination && this.examination.interview_for_department.patient_sex === 'female') || ( this.$props.clinicReservationToCreate.selectedFamilyProfile && this.$props.clinicReservationToCreate.selectedFamilyProfile.sex === 'female')">
      <div class="onlineClinic-interview__label">生理時の塊はありますか？
        <abbr title="required">必須</abbr>
      </div>
      <label><input type="radio" v-model="form.isMenstrualMass" value="ない">ない</label>
      <label><input type="radio" v-model="form.isMenstrualMass" value="ある">ある</label>
    </div>

    <div class="onlineClinic-interview__input">
      <label for="current_medicine" class="onlineClinic-interview__label">その他服用しているお薬やサプリメントがあれば教えてください
      </label>
      <textarea id="current_medicine" v-model="form.currentMedicine"></textarea>
    </div>
    <div v-if="!isEdit">
      <div class="onlineClinic-interview__input" v-if="isFirstInterview">
        <label for="purpose" class="onlineClinic-interview__label">今回のご相談の目的を教えてください
          <abbr title="required">必須</abbr>
        </label>
        <textarea id="purpose" v-model="form.purpose"></textarea>
      </div>

      <div class="onlineClinic-interview__input" v-if="!isFirstInterview">
        <label for="changes_in_physical_condition" class="onlineClinic-interview__label">前回のご利用時と比べて体調面などの変化を教えてください
          <abbr title="required">必須</abbr>
        </label>
        <textarea id="changes_in_physical_condition" v-model="form.changesInPhysicalCondition"></textarea>
      </div>
    </div>
    <div v-else>
      <div class="onlineClinic-interview__input" v-if="examination.interview_for_department.purpose">
        <label for="purpose" class="onlineClinic-interview__label">今回のご相談の目的を教えてください
          <abbr title="required">必須</abbr>
        </label>
        <textarea id="purpose" v-model="form.purpose"></textarea>
      </div>

      <div class="onlineClinic-interview__input" v-if="examination.interview_for_department.changes_in_physical_condition">
        <label for="changes_in_physical_condition" class="onlineClinic-interview__label">前回のご利用時と比べて体調面などの変化を教えてください
          <abbr title="required">必須</abbr>
        </label>
        <textarea id="changes_in_physical_condition" v-model="form.changesInPhysicalCondition"></textarea>
      </div>
    </div>

    <div class="onlineClinic-interview__file">
      <div class="onlineClinic-interview__label">よりよい漢方アドバイスのために舌の写真を撮影して添付してください<abbr title="required">必須</abbr>
      </div>
      <input ref="tongue_image" id="tongue_image" type="file" @change="selectedTongueImage" accept="image/*">
      <label for="tongue_image">写真を撮ってアップロード</label>
      <span>{{tongueImageName}}</span>
    </div>

    <div class="onlineClinic-interview__file">
      <div class="onlineClinic-interview__label">よりよい漢方アドバイスのために基礎体温表があれば写真を撮影して添付してください
      </div>
      <input ref="basal_body_temperature_table" id="basal_body_temperature_table" type="file" @change="selectedBasalBodyTemperatureTable" accept="image/*">
      <label for="basal_body_temperature_table">写真を撮ってアップロード</label>
      <span>{{basalBodyTemperatureTableName}}</span>
    </div>

    <div v-if="!isEdit">
      <div class="onlineClinic-interview__footer">
        <button class="onlineClinic-interview__button" @click="addExaminationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })"
                :disabled="!isValid">次へ
        </button>
        <button class="onlineClinic-interview__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
      </div>
    </div>
    <div v-if="isEdit">
      <div class="onlineClinic-interview__footer">
        <button class="onlineClinic-interview__button" @click="emitUpdateExamination(); $mixpanel.track('CLICK BUTTON', { page: '問診修正', buttonName: '保存', userId: currentUser.id, source: currentUser.source })"
                :disabled="!isValid">保存
        </button>
        <button class="onlineClinic-interview__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診修正', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
      </div>
   </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
    examination: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    }
  },
  data() {
    return {
      tongueImageName: '',
      basalBodyTemperatureTableName: '',
      form: {
        tongueImage: null,
        basalBodyTemperatureTable: null,
        bloodPressure: '普通',
        sleepTime: null,
        sleepQuality: [],
        isHeadache: '特にない',
        mentalConditions: [],
        coldSensitivities: [],
        hotSensitivities: [],
        sweatConditions: [],
        isAppetite: 'あり',
        gastrointestinalConditions: [],
        bowelMovement: null,
        urineCondition: null,
        skinConditions: [],
        respiratoryConditions: [],
        isPains: [],
        systemicSymptoms: [],
        isMenstrualPain: 'ない',
        isMenstrualMass: 'ない',
        currentMedicine: null,
        purpose: null,
        changesInPhysicalCondition: null,
      },
    };
  },
  created() {
    if (this.isEdit) {
      this.setExamination()
    }
  },
  computed: {
    isValid() {
      let element;
      let tongueImageValidataion;
      this.isFirstInterview ? element = this.form.purpose : element = this.form.changesInPhysicalCondition
      if (!this.isEdit){
        if (this.isFirstInterview){
          element = this.form.purpose
        } else {
          element = this.form.changesInPhysicalCondition
        }
      } else {
        if (this.examination.interview_for_department.purpose) {
          element = this.form.purpose
        } else if (this.examination.interview_for_department.changes_in_physical_condition) {
          element = this.form.changesInPhysicalCondition
        }
      }
      this.isEdit ? tongueImageValidataion = true : tongueImageValidataion = this.form.tongueImage
      return (
        this.form.bloodPressure &&
        this.form.sleepTime &&
        this.form.sleepQuality.length > 0 &&
        this.form.isHeadache &&
        this.form.mentalConditions.length > 0 &&
        this.form.coldSensitivities.length > 0 &&
        this.form.hotSensitivities.length > 0 &&
        this.form.sweatConditions.length > 0 &&
        this.form.isAppetite &&
        this.form.gastrointestinalConditions.length > 0 &&
        this.form.bowelMovement &&
        this.form.urineCondition &&
        this.form.skinConditions.length > 0 &&
        this.form.respiratoryConditions.length > 0 &&
        this.form.isPains.length > 0 &&
        this.form.systemicSymptoms.length > 0 &&
        this.form.isMenstrualPain &&
        this.form.isMenstrualMass &&
        element &&
        tongueImageValidataion
      );
    },
    isFirstInterview() {
      return !this.clinicReservationToCreate.latestInterview
    },
    ...mapGetters(['currentUser'])
  },
  methods: {
    async selectedTongueImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const image = await this.getBase64(files[0]);
      this.form.tongueImage = image;
      this.tongueImageName = files[0].name
    },
    async selectedBasalBodyTemperatureTable(e) {
      e.preventDefault();
      const files = e.target.files;
      const image = await this.getBase64(files[0]);
      this.form.basalBodyTemperatureTable = image;
      this.basalBodyTemperatureTableName = files[0].name
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    addExaminationData() {
      const data = {
        interview: this.form,
      };
      this.$emit('addExaminationData', data);
    },
    setLatestInterview() {
      this.form.bloodPressure = this.clinicReservationToCreate.latestInterview.blood_pressure
      this.form.sleepTime = this.clinicReservationToCreate.latestInterview.sleep_time
      this.form.sleepQuality = this.clinicReservationToCreate.latestInterview.sleep_quality.split(',')
      this.form.isHeadache = this.clinicReservationToCreate.latestInterview.is_headache
      this.form.mentalConditions = this.clinicReservationToCreate.latestInterview.mental_conditions.split(',')
      this.form.coldSensitivities = this.clinicReservationToCreate.latestInterview.cold_sensitivities.split(',')
      this.form.hotSensitivities = this.clinicReservationToCreate.latestInterview.hot_sensitivities.split(',')
      this.form.sweatConditions = this.clinicReservationToCreate.latestInterview.sweat_conditions.split(',')
      this.form.isAppetite = this.clinicReservationToCreate.latestInterview.is_appetite
      this.form.gastrointestinalConditions = this.clinicReservationToCreate.latestInterview.gastrointestinal_conditions.split(',')
      this.form.bowelMovement = this.clinicReservationToCreate.latestInterview.bowel_movement
      this.form.urineCondition = this.clinicReservationToCreate.latestInterview.urine_condition
      this.form.skinConditions = this.clinicReservationToCreate.latestInterview.skin_conditions.split(',')
      this.form.respiratoryConditions = this.clinicReservationToCreate.latestInterview.respiratory_conditions.split(',')
      this.form.isPains = this.clinicReservationToCreate.latestInterview.is_pains.split(',')
      this.form.systemicSymptoms = this.clinicReservationToCreate.latestInterview.systemic_symptoms.split(',')
      this.form.currentMedicine = this.clinicReservationToCreate.latestInterview.current_medicine
      this.form.changesInPhysicalCondition = this.clinicReservationToCreate.latestInterview.changes_in_physical_condition



      alert('前回の問診内容をコピーしました');
    },
    setCheckbox(str, ary) {
      return ary.concat(str.split(','));
    },
    setExamination() {
      this.form.bloodPressure = this.examination.interview_for_department.blood_pressure
      this.form.sleepTime = this.examination.interview_for_department.sleep_time
      this.form.sleepQuality = this.setCheckbox(this.examination.interview_for_department.sleep_quality, this.form.sleepQuality)
      this.form.isHeadache = this.examination.interview_for_department.is_headache
      this.form.mentalConditions = this.setCheckbox(this.examination.interview_for_department.mental_conditions, this.form.mentalConditions)
      this.form.coldSensitivities = this.setCheckbox(this.examination.interview_for_department.cold_sensitivities, this.form.coldSensitivities)
      this.form.hotSensitivities = this.setCheckbox(this.examination.interview_for_department.hot_sensitivities, this.form.hotSensitivities)
      this.form.sweatConditions = this.setCheckbox(this.examination.interview_for_department.sweat_conditions, this.form.sweatConditions)
      this.form.isAppetite = this.examination.interview_for_department.is_appetite
      this.form.gastrointestinalConditions = this.setCheckbox(this.examination.interview_for_department.gastrointestinal_conditions, this.form.gastrointestinalConditions)
      this.form.bowelMovement = this.examination.interview_for_department.bowel_movement
      this.form.urineCondition = this.examination.interview_for_department.urine_condition
      this.form.skinConditions = this.setCheckbox(this.examination.interview_for_department.skin_conditions, this.form.skinConditions)
      this.form.respiratoryConditions = this.setCheckbox(this.examination.interview_for_department.respiratory_conditions, this.form.respiratoryConditions)
      this.form.isPains = this.setCheckbox(this.examination.interview_for_department.is_pains, this.form.isPains)
      this.form.systemicSymptoms = this.setCheckbox(this.examination.interview_for_department.systemic_symptoms, this.form.systemicSymptoms)
      this.form.isMenstrualPain = this.examination.interview_for_department.is_menstrual_pain
      this.form.isMenstrualMass = this.examination.interview_for_department.is_menstrual_mass
      this.form.currentMedicine = this.examination.interview_for_department.current_medicine
      this.form.purpose = this.examination.interview_for_department.purpose
      this.form.changesInPhysicalCondition = this.examination.interview_for_department.changes_in_physical_condition

    },
    emitUpdateExamination() {
      const examinationParams = {
        interview_attributes: {
          blood_pressure: this.form.bloodPressure,
          sleep_time: this.form.sleepTime,
          sleep_quality: this.form.sleepQuality.join(','),
          is_headache: this.form.isHeadache,
          mental_conditions: this.form.mentalConditions.join(','),
          cold_sensitivities: this.form.coldSensitivities.join(','),
          hot_sensitivities: this.form.hotSensitivities.join(','),
          sweat_conditions: this.form.sweatConditions.join(','),
          is_appetite: this.form.isAppetite,
          gastrointestinal_conditions: this.form.gastrointestinalConditions.join(','),
          bowel_movement: this.form.bowelMovement,
          urine_condition: this.form.urineCondition,
          skin_conditions: this.form.skinConditions.join(','),
          respiratory_conditions: this.form.respiratoryConditions.join(','),
          is_pains: this.form.isPains.join(','),
          systemic_symptoms: this.form.systemicSymptoms.join(','),
          is_menstrual_pain: this.form.isMenstrualPain,
          is_menstrual_mass: this.form.isMenstrualMass,
          current_medicine: this.form.currentMedicine,
          purpose: this.form.purpose,
          changes_in_physical_condition: this.form.changesInPhysicalCondition,
          tongue_image: this.form.tongueImage,
          basal_body_temperature_table: this.form.basalBodyTemperatureTable,
        }
      }
      this.$emit('updateExamination', examinationParams)
    }
  },
};
</script>

<style scoped lang="scss">
.onlineClinic-interview {
  max-width: 312px;
  margin: auto;
  padding: 100px 15px 25px;

  &__radio {
    margin: 18px 0;

    label {
      font-weight: 200;
      display: block;
    }
  }

  &__checkbox-section {
    margin: 18px 0;

  }

  &__checkbox {
    display: block;

    label {
      font-weight: 200;
    }
  }

  &__input {
    margin: 18px 0;

    input {
      width: 100%;
      box-sizing: border-box;
      height: 46px;
      border-radius: 8px;
      padding: 0 15px;
      background: #fff;
      border: 1px solid #e4e4e9;
      border-radius: 8px;
      color: #97979a;
    }

    textarea {
      width: 100%;
      box-sizing: border-box;
      height: 46px;
      border-radius: 8px;
      padding: 0 15px;
      background: #fff;
      border: 1px solid #e4e4e9;
      border-radius: 8px;
      color: #97979a;
    }
  }

  &__file {
    margin: 18px 0;
    word-wrap: break-word;

    input {
      display: none;
      margin: 10px 0;
    }

    label {
      color: transparent;
      text-shadow: 0 0 0 #2A3039;
      background: #EEEEEE;
      height: 18px;
      border: solid 1px;
      border-color: #000000;
      font-size: 0.8rem;
      padding: 3px 8px;
      border-radius: 4px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      margin-right: 8px;
      &:hover {
        opacity: 0.7;
        transition: 0.3s ease-out;
      }
    }

    span {
      display: block;
      margin: 5px 5px;
      font-size: 11px;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__select {
    width: 100%;
    box-sizing: border-box;
    background-color: #f3f4f5;
    border: none;
    margin-top: 15px;
    padding: 14px 14px 14px 28px;
    border-radius: 50px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="x,y"><path fill="%234f4f4f" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>');
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position: right 22px center;
  }

  &__footer {
    text-align: center;
  }

  &__button {
    display: block;
    width: 100%;
    height: 56px;
    background: #04aae4;
    border-radius: 60px;
    color: #fff;
    text-align: center;
    padding-top: 6px;
    text-decoration: none;
    margin: 20px auto;
    border: none;

    &:disabled {
      background: #e4e4e9;
    }
  }

  &__nobutton {
    color: #97979a;
    font-size: 12px;
    background: none;
    border: none;
    text-decoration: underline;
    margin-bottom: 30px;
  }

  abbr {
    background-color: #f7526c;
    color: #fff;
    font-size: 11px;
    border: none;
    border-radius: 14px;
    cursor: default;
    margin-left: 10px;
    padding: 3px 6px;
    text-decoration: none;
  }

  .supplement {
    font-size: 0.75em;
    color: gray;
  }
}
</style>
