<template>
  <base-tooltip component="div" :message="fullName" class="baseUserIcon">
    <img v-if="iconUrl" :src="iconUrl" alt="ユーザアイコン" class='consultation-list-icon'/>
    <i v-else class='fa fa-user fa-3x' aria-hidden='true'></i>
  </base-tooltip>
</template>

<script>
  export default {
    props: {
      iconUrl: {
        type: String
      },
      fullName: {
        type: String,
        require: true
      }
    }
  }
</script>

<style scoped>
  .baseUserIcon {
    display: inline-block;
  }
  .consultation-list-icon {
    max-height: 39px;
    vertical-align: bottom;
    border: 2px solid #fff;
    border-radius: 50%;
  }

</style>
