<template>
  <div class="p-onlineClinicInterviewConfirmation">
    <div class="p-onlineClinicInterviewConfirmation__box">

      <div class="p-onlineClinicInterviewConfirmation__label">問診内容</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">どのような症状でご来院されましたか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.symptom}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">それはいつ頃からですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.from_when}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">保険証<br>※クリックすると拡大されます</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">
        <img :src="clinicReservationToCreate.interview_for_department.patient_insurance_card_image.url" @click="openImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
      </div>

      <div v-if="clinicReservationToCreate.interview_for_department.recipient_certificate_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview_for_department.recipient_certificate_image.url" @click="openRecipientCertificateImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview_for_department.recipient_certificate_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">最後に月経があったのはいつですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.latest_menstruation_date}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在妊娠している、またはその可能性はありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.is_possibility_of_pregnancy}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">治療中、あるいは今までに病気や手術したことはありますか？</div>
      <div v-if="clinicReservationToCreate.interview_for_department.medical_history_select">
        <div v-for="(item, index) in clinicReservationToCreate.interview_for_department.medical_history_select.split(',')" :key="`first-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
          <p v-if="item != 'その他'">{{item}}</p>
          <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview_for_department.medical_history}}）</p>
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.medical_history}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">以下の疾患に該当するものはありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__example">
肥満・過敏性素因・乳癌・子宮癌・子宮筋腫・子宮腺筋症・子宮内膜症・チョコレート嚢腫・奇形腫・深部静脈血栓症・血栓性静脈炎・肺塞栓症・脳血管障害・冠動脈疾患・片頭痛・高血圧症・心疾患・糖尿病・血栓性素因・自己免疫疾患・肝障害・肝腫瘍・高脂血症・妊娠時黄疸・妊娠時持続性そう痒症・妊娠ヘルペス・ポルフィリン症・てんかん・テタニー・クローン病・腎疾患・潰瘍性大腸炎</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.is_disease}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">血圧を教えてください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.blood_pressure}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">ご家族に血栓症・乳がんにかかったことのある方はいますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.is_serious_disease_before}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">アレルギーはありますか？</div>
      <div v-if="clinicReservationToCreate.interview_for_department.allergy_select">
        <div v-for="(item, index) in clinicReservationToCreate.interview_for_department.allergy_select.split(',')" :key="`second-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
          <p v-if="item != 'その他'">{{item}}</p>
          <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview_for_department.allergy}}）</p>
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.allergy}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">タバコは吸いますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{smokingText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるお薬はありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="!clinicReservationToCreate.interview_for_department.is_current_medicine">{{clinicReservationToCreate.interview_for_department.current_medicine}}</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview_for_department.is_current_medicine == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview_for_department.is_current_medicine == 'あり'">あり<br>{{clinicReservationToCreate.interview_for_department.current_medicine}}</p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">今までに低用量ピルまたはアフターピルを服用したことはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview_for_department.is_pills_before == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview_for_department.is_pills_before == 'あり'">あり<br>{{clinicReservationToCreate.interview_for_department.pills}}</p>

      <div v-if="clinicReservationToCreate.interview_for_department.pills_prescription_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">処方されたお薬手帳画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview_for_department.pills_prescription_image.url" @click="openPillsPrescriptionImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview_for_department.pills_prescription_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">処方されたお薬手帳画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div v-if="clinicReservationToCreate.interview_for_department.medicine_notebook_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬手帳画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview_for_department.medicine_notebook_image.url" @click="openMedicineNotebookImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview_for_department.medicine_notebook_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬手帳画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬の副作用が起きたことはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="!clinicReservationToCreate.interview_for_department.is_side_effect">{{clinicReservationToCreate.interview_for_department.side_effect}}</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview_for_department.is_side_effect == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview_for_department.is_side_effect == 'あり'">あり<br>{{clinicReservationToCreate.interview_for_department.side_effect}}</p>
      
      <div class="p-onlineClinicInterviewConfirmation__labelInterview">ジェネリック医薬品がある場合、ジェネリック医薬品をご希望ですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.generic | convertGenericValueToText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるサプリメントはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview">
        {{clinicReservationToCreate.interview_for_department.is_current_supplement}}<br>{{clinicReservationToCreate.interview_for_department.current_supplement}}
      </p>
      
      <div class="p-onlineClinicInterviewConfirmation__labelInterview">医師に伝えたいことや質問がありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview_for_department.other_information}}</div>

      <div v-if="clinicReservationToCreate.interview_for_department.other_useful_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview_for_department.other_useful_image.url" @click="openOtherUsefulImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview_for_department.other_useful_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

    </div>
    <div>
      <ImageView :image="clinicReservationToCreate.interview_for_department.patient_insurance_card_image.url" v-if="isImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview_for_department.recipient_certificate_image.url" v-if="isRecipientCertificateImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview_for_department.pills_prescription_image.url" v-if="isPillsPrescriptionImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview_for_department.medicine_notebook_image.url" v-if="isMedicineNotebookImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview_for_department.other_useful_image.url" v-if="isOtherUsefulImageViewModal"></ImageView>
    </div>
  </div>
</template>
<script>
import Interview from '@/enums/online_clinic/Interview';
import EventBus from '@/event-bus';
import ImageView from '@/components/Common/ImageView';
const genericHash = {
  [Interview.generic.yes]: {
    labelText: 'はい',
  },
  [Interview.generic.no]: {
    labelText: 'いいえ',
  },
};
export default {
  components: {
    ImageView,
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isImageViewModal: false,
      isRecipientCertificateImageViewModal: false,
      isPillsPrescriptionImageViewModal: false,
      isMedicineNotebookImageViewModal: false,
      isOtherUsefulImageViewModal: false,
    };
  },
  created() {
    EventBus.$on('close-image-modal', () => {
      this.closeImageModal();
    });
  },
  methods: {
    openImageModal() {
      this.isImageViewModal = true;
    },
    closeImageModal() {
      this.isImageViewModal = false;
      this.isRecipientCertificateImageViewModal = false;
      this.isPillsPrescriptionImageViewModal = false;
      this.isMedicineNotebookImageViewModal = false;
      this.isOtherUsefulImageViewModal = false;
    },
    openRecipientCertificateImageModal() {
      this.isRecipientCertificateImageViewModal = true;
    },
    openPillsPrescriptionImageModal() {
      this.isPillsPrescriptionImageViewModal = true;
    },
    openMedicineNotebookImageModal() {
      this.isMedicineNotebookImageViewModal = true;
    },
    openOtherUsefulImageModal() {
      this.isOtherUsefulImageViewModal = true;
    },
  },
  computed: {
    smokingText() {
      if (this.clinicReservationToCreate.interview_for_department.smoking == '過去に吸っていた'){
        if (!this.clinicReservationToCreate.interview_for_department.smoking_past_age_from)  this.clinicReservationToCreate.interview_for_department.smoking_past_age_from = ''
        if (!this.clinicReservationToCreate.interview_for_department.smoking_past_age_to)  this.clinicReservationToCreate.interview_for_department.smoking_past_age_to = ''
        if (!this.clinicReservationToCreate.interview_for_department.smoking_past_number)  this.clinicReservationToCreate.interview_for_department.smoking_past_number = ''
        return this.clinicReservationToCreate.interview_for_department.smoking + '（' + this.clinicReservationToCreate.interview_for_department.smoking_past_age_from + '〜' + this.clinicReservationToCreate.interview_for_department.smoking_past_age_to + '歳まで、1日' + this.clinicReservationToCreate.interview_for_department.smoking_past_number + '本）'
      } else if (this.clinicReservationToCreate.interview_for_department.smoking == '吸っている') {
        if (!this.clinicReservationToCreate.interview_for_department.smoking_present_age_from)  this.clinicReservationToCreate.interview_for_department.smoking_present_age_from = ''
        if (!this.clinicReservationToCreate.interview_for_department.smoking_present_number)  this.clinicReservationToCreate.interview_for_department.smoking_present_number = ''
      
        return this.clinicReservationToCreate.interview_for_department.smoking + '（' + this.clinicReservationToCreate.interview_for_department.smoking_present_age_from + '歳から、1日' + this.clinicReservationToCreate.interview_for_department.smoking_present_number + '本）'
      } else {
        return this.clinicReservationToCreate.interview_for_department.smoking
      }
    },
  },
  filters: {
    convertGenericValueToText(value) {
      return genericHash[value].labelText;
    },
  },
};
</script>