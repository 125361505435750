<template>
  <base-detail-item :label="interviewResult.master.message">
    <span v-if="interviewType === 'text'">
      <base-nl2br :text="interviewResult.content" tag="span" :text-filter="(_) => false"></base-nl2br>
    </span>
    <span v-else-if="interviewType === 'radio'">
      {{interviewResult.master.master_items.find(mi => mi.id === interviewResult.result_items[0].master_item_id).label}}
    </span>
    <span v-else-if="interviewType === 'checkboxes'">
      <span v-for="resultItem in interviewResult.result_items" :key="resultItem.id">
        {{interviewResult.master.master_items.find(mi => mi.id === resultItem.master_item_id).label}}
      </span>
    </span>
  </base-detail-item>
</template>

<script>
  import BaseDetailItem from "../../base/BaseDetailItem";
  export default {
    name: 'InterviewResult',
    components: {BaseDetailItem},
    props: ['interviewResult'],
    computed: {
      interviewType() {
        return this.interviewResult.master.interview_type
      }
    }
  }
</script>
