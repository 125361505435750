import CableApp from './CableApp'

const consultationChannel = (component) => {
  CableApp.init();
  CableApp.ConsultationsChannel = CableApp.cable.subscriptions.create({channel: 'ConsultationsChannel'}, {
    connected: function () {
      component.$data.cableStatus = 'CONNECTED'
    },
    disconnected: function () {
      component.$data.cableStatus = 'DISCONNECTED'
    },
    received: function (data) {
      switch (data.type) {
        case 'GET_CONSULTATION':
          component.setConsultations(component.consultations.map(consultation =>
            consultation.id === data.payload.id ? data.payload : consultation
          ));
        default:
          return;
      }
    },
    getConsultation: function (id) {
      this.perform('get_consultation', {id: id});
    }
  });
};

export default consultationChannel;
