<template>
  <div class="p-onlineClinicInterviewConfirmation">
    <div class="p-onlineClinicInterviewConfirmation__box">

      <div class="p-onlineClinicInterviewConfirmation__label">問診内容</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">どのような症状でご来院されましたか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.symptom}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">それはいつ頃からですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.from_when}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">保険証<br>※クリックすると拡大されます</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">
        <img :src="clinicReservationToCreate.interview.patient_insurance_card_image.url" @click="openImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
      </div>

      <div v-if="clinicReservationToCreate.interview.recipient_certificate_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview.recipient_certificate_image.url" @click="openRecipientCertificateImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview.recipient_certificate_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <!-- <div v-if="clinicReservationToCreate.interview.health_diagnosis_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">健康診断画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview.health_diagnosis_image.url" @click="openHealthDiagnosisImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview.health_diagnosis_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">健康診断画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div> -->

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">治療中、あるいは今までに病気や手術したことはありますか？</div>
      <div v-if="clinicReservationToCreate.interview.medical_history_select">
        <div v-for="(item, index) in clinicReservationToCreate.interview.medical_history_select.split(',')" :key="`first-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
          <p v-if="item != 'その他'">{{item}}</p>
          <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview.medical_history}}）</p>
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.medical_history}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">アレルギーはありますか？</div>
      <div v-if="clinicReservationToCreate.interview.allergy_select">
        <div v-for="(item, index) in clinicReservationToCreate.interview.allergy_select.split(',')" :key="`second-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
          <p v-if="item != 'その他'">{{item}}</p>
          <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview.allergy}}）</p>
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.allergy}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">タバコは吸いますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{smokingText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるお薬はありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="!clinicReservationToCreate.interview.is_current_medicine">{{clinicReservationToCreate.interview.current_medicine}}</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview.is_current_medicine == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview.is_current_medicine == 'あり'">あり<br>{{clinicReservationToCreate.interview.current_medicine}}</p>

      <div v-if="clinicReservationToCreate.interview.medicine_notebook_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬手帳画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview.medicine_notebook_image.url" @click="openMedicineNotebookImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview.medicine_notebook_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬手帳画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬の副作用が起きたことはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="!clinicReservationToCreate.interview.is_side_effect">{{clinicReservationToCreate.interview.side_effect}}</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview.is_side_effect == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-else-if="clinicReservationToCreate.interview.is_side_effect == 'あり'">あり<br>{{clinicReservationToCreate.interview.side_effect}}</p>
      

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">ジェネリック医薬品がある場合、ジェネリック医薬品をご希望ですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.generic | convertGenericValueToText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるサプリメントはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview">
        {{clinicReservationToCreate.interview.is_current_supplement}}<br>{{clinicReservationToCreate.interview.current_supplement}}
      </p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">医師に伝えたいことや質問がありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.other_information}}</div>

      <div v-if="clinicReservationToCreate.interview.other_useful_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview.other_useful_image.url" @click="openOtherUsefulImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-if="!clinicReservationToCreate.interview.other_useful_image.url">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

    </div>
    <div>
      <ImageView :image="clinicReservationToCreate.interview.patient_insurance_card_image.url" v-if="isImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview.recipient_certificate_image.url" v-if="isRecipientCertificateImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview.medicine_notebook_image.url" v-if="isMedicineNotebookImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview.other_useful_image.url" v-if="isOtherUsefulImageViewModal"></ImageView>
      <ImageView :image="clinicReservationToCreate.interview.health_diagnosis_image.url" v-if="isHealthDiagnosisImageViewModal"></ImageView>
    </div>
  </div>
</template>
<script>
// TODO yes noの変換の仕組みはどこかにまとめたい
import Interview from '@/enums/online_clinic/Interview';
import EventBus from '@/event-bus';
import ImageView from '@/components/Common/ImageView';

const genericHash = {
  [Interview.generic.yes]: {
    labelText: 'はい',
  },
  [Interview.generic.no]: {
    labelText: 'いいえ',
  },
};
export default {
  components: {
    ImageView,
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isImageViewModal: false,
      isRecipientCertificateImageViewModal: false,
      isMedicineNotebookImageViewModal: false,
      isOtherUsefulImageViewModal: false,
      isHealthDiagnosisImageViewModal: false,
    };
  },
  filters: {
    convertGenericValueToText(value) {
      return genericHash[value].labelText;
    },
  },
  created() {
    EventBus.$on('close-image-modal', () => {
      this.closeImageModal();
    });
  },
  methods: {
    openImageModal() {
      this.isImageViewModal = true;
    },
    closeImageModal() {
      this.isImageViewModal = false;
      this.isRecipientCertificateImageViewModal = false;
      this.isMedicineNotebookImageViewModal = false;
      this.isOtherUsefulImageViewModal = false;
      this.isHealthDiagnosisImageViewModal = false;
    },
    openRecipientCertificateImageModal() {
      this.isRecipientCertificateImageViewModal = true;
    },
    openMedicineNotebookImageModal() {
      this.isMedicineNotebookImageViewModal = true;
    },
    openOtherUsefulImageModal() {
      this.isOtherUsefulImageViewModal = true;
    },
    openHealthDiagnosisImageModal() {
      this.isHealthDiagnosisImageViewModal = true;
    },
  },
  computed: {
    smokingText() {
      if (this.clinicReservationToCreate.interview.smoking == '過去に吸っていた'){
        if (!this.clinicReservationToCreate.interview.smoking_past_age_from)  this.clinicReservationToCreate.interview.smoking_past_age_from = ''
        if (!this.clinicReservationToCreate.interview.smoking_past_age_to)  this.clinicReservationToCreate.interview.smoking_past_age_to = ''
        if (!this.clinicReservationToCreate.interview.smoking_past_number)  this.clinicReservationToCreate.interview.smoking_past_number = ''
        return this.clinicReservationToCreate.interview.smoking + '（' + this.clinicReservationToCreate.interview.smoking_past_age_from + '〜' + this.clinicReservationToCreate.interview.smoking_past_age_to + '歳まで、1日' + this.clinicReservationToCreate.interview.smoking_past_number + '本）'
      } else if (this.clinicReservationToCreate.interview.smoking == '吸っている') {
        if (!this.clinicReservationToCreate.interview.smoking_present_age_from)  this.clinicReservationToCreate.interview.smoking_present_age_from = ''
        if (!this.clinicReservationToCreate.interview.smoking_present_number)  this.clinicReservationToCreate.interview.smoking_present_number = ''
      
        return this.clinicReservationToCreate.interview.smoking + '（' + this.clinicReservationToCreate.interview.smoking_present_age_from + '歳から、1日' + this.clinicReservationToCreate.interview.smoking_present_number + '本）'
      } else {
        return this.clinicReservationToCreate.interview.smoking
      }
    },
  }
};
</script>