<template>
  <div class="base-detail-item">
    <span class="base-label">{{label}}</span>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'BaseDetailItem',
    props: ['label']
  }
</script>

<style scoped lang="scss">
  .base-detail-item {
    font-size: 1.2em;

    .base-label {
      font-weight: bold;
      &:after {
        content: ':';
        padding: 0 2px;
      }
    }
  }
</style>
