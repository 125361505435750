<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button btn btn-default" @click="closeModal">
                閉じる
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from '../../event-bus';
export default {
  methods: {
    closeModal() {
      EventBus.$emit('close-image-modal');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      overflow-y: scroll;
      width: 700px;
      max-height: 95%;
      margin: auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;

      @media screen and (max-width: 520px) {
        width: 360px;
        padding: 10px;
      }

      @media screen and (max-width: 360px) {
        width: 300px;
        padding: 10px;
      }

      .modal-body {
        margin: 5px 0;
      }

      .modal-default-button {
        float: right;
      }

      .modal-header {
        border-bottom: none;
      }
    }
  }
}
</style>
