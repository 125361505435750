<template>
  <div class="form-group">
    <div class="checkbox">
      <label :for="thisName">
        <input :id="thisName" :checked="value" @change="onChange" type="checkbox" value="true">
        <span class="checkbox-icon">
          {{labelText}}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseCheckBoxField',
    props: ['name', 'labelText', 'value'],

    data () {
      return { checked: this.value }
    },

    computed: {
      thisName() {
        return `${name}_${parseInt(Math.random() * 1000)}`
      }
    },

    methods: {
      onChange(e) {
        this.checked = !this.value;
        this.$emit('input', this.checked)
      }
    }
  }
</script>

<style scoped lang="scss">
  $main: #6cbeec;
  $white: #fff;

  input[type="checkbox"] {
    margin-left: -20px;
    &:checked {
      & + .checkbox-icon:before {
        content: '';
        display: inline-block;
        height: 15px;
        width: 15px;
        background-color: $main;
        border: solid 1px $main;
        border-radius: 3px;
      }
    }
  }
  .checkbox-icon {
    position: relative;
    vertical-align: middle;
    margin-left: -21px;
    padding-right: 6px;

    &:before {
      content: '';
      display: inline-block;
      height: 15px;
      width: 15px;
      font-family: "Ionicons";
      background-color: $white;
      border: solid 1px #d3d3d3;
      border-radius: 3px;
      font-size: 22px;
    }
    :after {
      content: '';
      position: absolute;
      top: 2px;
      left: 3px;
      height: 4px;
      width: 8px;
      border-bottom: solid 2px $white;
      border-left: solid 2px $white;
      transform: rotate(-47deg);
    }
  }
</style>
