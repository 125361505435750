<template>
  <li class="step" :class="modifyClass">
    <div>
      <span :class="{'current-step-point':isActive}">
      </span>
      <div class="step-no">
      {{step}}
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    isContinuous: {
      type: Boolean,
      require: true
    }
  },
  computed: {
    modifyClass() {
      return (this.currentStep === this.step ? 'step-current' : '') + (this.isContinuous ? '' : ' step-dotted')
    }
  },
  mounted() {
    this.isCurrentStepPoint();
  },
  methods: {
    isCurrentStepPoint() {
      if (this.step === this.currentStep){
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
  },
  data() {
    return { isActive: false };
  },
  watch: {
    currentStep() {
      this.isCurrentStepPoint()
    }
  }
}
</script>

<style scoped lang="scss">
$color: #6cbeec;
$radius: 16px;

.current-step-point {
  background-color: #04AAE4 !important;
}

.step {
  list-style-type: none;
  position: relative;
  flex-grow: 1;

  &.step-dotted {
    &:after {
      background-image: -webkit-gradient(linear, 0 0, 100% 0, color-stop(.7, transparent), color-stop(.7, white), to(white));
      -webkit-background-size: 10px;
    }
  }

  &:after {
    display: block;
    z-index: 1;
    content: '';
    position: absolute;
    background-color: $color;
    height: 2px;
    width: 100%;
    top: 6px;
  }

  &:last-child {
    flex-grow: 0;
    &:after {
      width: 0;
      height: 0;
    }
  }

  span {
    position: relative;
    z-index: 2;
    display: block;
    border-radius: $radius;
    color: $color;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: $radius * 2  - 3;
    font-size: 0.9em;
    box-shadow: 0 0 0 1px $color;
    background: #FFF;
    border: 2px solid #04AAE4;
    box-sizing: border-box;
  }

  &.step-current {
    &:after {
      margin-bottom: 3px;
      background-color: #04AAE4;
    }

    &~.step {
      &:after {
        background-color: #04AAE4;
      }

      span {
        background-color: #FFF;
        box-shadow: 0 0 0 1px #04AAE4;
      }
    }
  }

  .step-no {
    color: #04AAE4;
    margin: 4px 0 0 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
  }
}
</style>
