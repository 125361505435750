<template>
  <div>
    <notice-alert v-for="notice in notices" :notice="notice" />
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'NoticeAlerts',
    computed: {
      ...mapGetters({notices: 'notices'})
    },
  }
</script>
