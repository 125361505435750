const getters = {
  categorizeItem: () => (category) => (item) => {
    return {
      item: item,
      categoryItemMaster: item.category_item_masters.find(cim => cim.category_id === category.id)
    }
  },

  itemValue: () => (item) => {
    switch(item.data_type) {
      case 'float':
        return item.value_float;
      case 'integer':
        return item.value_integer;
      case 'boolean':
        return item.value_boolean;
    }
  },

  itemStatus: (_, getters) => (item) => {
    switch(item.data_type) {
      case 'float':
      case 'integer':
        return getters.numericItemValueStatus(item);
      case 'boolean':
        return getters.booleanItemValueStatus(item);
    }
  },

  numericItemValueStatus: (_, getters) => (item) => {
    const value = getters.itemValue(item);
    if (item.red_upper_range && value > item.red_upper_range) {
      return 'danger'
    }
    else if (item.red_lower_range && value < item.red_lower_range) {
      return 'danger'
    }
    else if (item.yellow_upper_range && value > item.yellow_upper_range) {
      return 'warning'
    }
    else if (item.yellow_lower_range && value < item.yellow_lower_range) {
      return 'warning'
    } else {
      return 'success'
    }
  },
  booleanItemValueStatus: () => (item) => {
    if (item.value_boolean) {
     if(item.red_target === 'red_yes') {
        return 'danger';
      } else if (item.yellow_target === 'yellow_yes') {
        return 'warning';
      }
    }

    if (item.red_target === 'red_no') {
      return 'danger';
    } else if (item.yellow_target === 'yellow_no')  {
      return 'warning';
    }
    return 'success';
  }
};

export default {
  getters,
}

