<template>
    <div class="loading" :class="{ 'translucent': translucent }">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    translucent: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.loading {
  width: 100%;
  height: 100%;
  background: #fefefe;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: grid;
  place-content: center;

  .fa-spinner {
    color: #00acff;
  }
}

.loading.translucent {
  background: #666666;
  opacity: 0.6;
}
</style>
