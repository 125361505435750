<template>
  <div>
    <loading :translucent="true" v-show="loading"></loading>
    <progress-circle :currentStep = currentStep></progress-circle>
    <div>
      <keep-alive>
        <component :is="currentComponent"
                    ref="currentComponent"
                    :consultationReservationToCreate="consultation"
                    @moveToNextStep="moveToNextStep"
                    @onCancel="onCancel"
                    @moveToPreviousStep="moveToPreviousStep"
                    @addConsultationData="addConsultationData"
                    @create-reservation="createReservation"
                    :apiError="apiError"
                    :apiCreditError="apiCreditError"
                    :apiDoctorScheduleConflictError="apiDoctorScheduleConflictError"
                    :apiDoubleReservationError="apiDoubleReservationError"
                    :apiInvalidCouponError="apiInvalidCouponError"
                    />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PatientInformation from '@/components/health_checkup_support/consultations/PatientInformation';
import SelectType from '@/components/health_checkup_support/consultations/SelectType';
import SelectSchedule from '@/components/health_checkup_support/consultations/SelectSchedule';
import InterviewForm from '@/components/health_checkup_support/consultations/InterviewForm';
import Confirmation from '@/components/health_checkup_support/consultations/Confirmation';
import ProgressCircle from '@/components/health_checkup_support/consultations/ProgressCircle';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import EventBus from '@/event-bus';
import Loading from '@/components/Common/Loading';
import * as mutationTypes from "@/store/mutation-types";

export default {
  components: {
    PatientInformation,
    SelectType,
    SelectSchedule,
    InterviewForm,
    Confirmation,
    Loading,
    ProgressCircle
  },
  props: {
    apiError: {
      type: Boolean
    },
    apiCreditError: {
      type: Object
    },
    apiDoctorScheduleConflictError: {
      type: Boolean
    },
    apiDoubleReservationError: {
      type: Boolean
    },
    apiInvalidCouponError: {
      type: Boolean
    }
    
  },
  created() {
    EventBus.$on('back-page-event', () => {
      if (this.currentStep > 1) {
        this.moveToPreviousStep();
      } else if (this.currentStep == 1) {
        this.onCancel();
      }
    });

    //選択されたスケジュールをクリアする
    EventBus.$on('clear-examination-doctor-schedule', () => {
      this.examination.doctorSchedule = null;
    });
  },
  data() {
    return {
      currentStep: 1,
      formSteps: [
        'PatientInformation',
        'SelectType',
        'SelectSchedule',
        'InterviewForm',
        'Confirmation',
      ],
      consultation: {},
      loading: false
    };
  },
  computed: {
    allStepsCount() {
      return this.formSteps.length;
    },

    currentComponent() {
        return this.formSteps[this.currentStep - 1];
    },
  },
  watch: {
    apiDoctorScheduleConflictError: function(val) {
      if (this.apiDoctorScheduleConflictError) {
        //スケジュールを再選択させる
        this.currentStep = 3
      }
    },
    apiDoubleReservationError: function(val) {
      if (this.apiDoubleReservationError) {
        this.$store.commit(mutationTypes.PUSH_NOTICE, { message: 'すでに予約されている診療があります', type: 'danger' }, { root: true })
        this.$router.push(`/health_checkup_support/home`);
      }
    },
  },
  methods: {
    moveToNextStep() {
      this.currentStep++;
    },
    moveToPreviousStep() {
      this.currentStep--;
    },
    addConsultationData(data) {
      this.consultation = { ...this.consultation, ...data };

    },
    onCancel() {
      this.$dialog
        .confirm(
          {
            title: 'キャンセル',
            body:
              '今までの保存内容が失われる可能性があります。キャンセルしてもよろしいですか？',
          },
          {
            okText: 'はい',
            cancelText: 'いいえ',
          }
        )
        .then(() => {
          this.loading = true
          window.location.href = '/health_checkup_support/home';
        });
    },
    createReservation(callback) {
      this.loading = true

      const reservationFormattedParams = {
        patient_family_profile_id: this.consultation.selectedFamilyProfile.id,
        type_id: this.consultation.selectedType.id,
        doctor_schedule_id: this.consultation.selectedDoctorSchedule.id,
        patient_comment: this.consultation.interview.patientComment,
        image_tokens: this.consultation.imageTokens,
        coupon_code: this.consultation.couponCode
      }

      this.$emit('create-reservation', reservationFormattedParams, () => {
        this.loading = false
        callback()
      });
    },
  },
};
</script>