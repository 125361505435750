<template>
  <base-modal>
    <template slot="header">
      <h1>
        プラン登録
      </h1>
    </template>
    <template slot="body">
      <div class="stripeCardForm" v-if="isShowCreditcardForm">
        <div>カード番号</div>
        <card-number class=""
                     ref="cardNumber"
                     :stripe="stripePublishableKey"
                     :options="options"
                     @change="number = $event.complete"
        />
        <div>有効期限 月/年</div>
        <card-expiry class=""
                     ref="cardExpiry"
                     :stripe="stripePublishableKey"
                     :options="options"
                     @change="expiry = $event.complete"
        />
        <div>セキュリティコード</div>
        <card-cvc class=''
                  ref='cardCvc'
                  :stripe='stripePublishableKey'
                  :options='options'
                  @change="cvc = $event.complete"
        />
        <p v-if="errorMessage" class="error-text">{{errorMessage}}</p>
        <p>
          <img :src="visaLogo" alt="visa" style="height: 20px"/>
          <img :src="masterCardLogo" alt="masterCard" style="height: 34px"/>
          <img :src="amexLogo" alt="amex" style="height: 24px"/>
          <img :src="jcbLogo" alt="jcb" style="height: 24px"/>
          がご利用いただけます
        </p>
        <div class="subscription-plan">
          <span class="subscription-period">{{this.targetPlan.name}}</span>
          <span class="subscription-amount">{{this.targetPlan.price}}</span>
          <button class="btn subscription-submit" @click="submit" :disabled="submitDisabled">プラン登録</button>
        </div>
      </div>
      <div v-else>
        <div class="subscription-plan">
          <span class="subscription-period">{{this.targetPlan.name}}</span>
          <span class="subscription-amount">{{this.targetPlan.price}}</span>
          <button class="btn subscription-submit" @click="subscribe" :disabled="submitDisabled">プラン登録</button>
        </div>
      </div>
      <section class="creditCardPolicy">
        <p>【クレジットカード購入の注意事項】</p>
        <h4> 【クレジットカード決済について】 </h4>
        <ul>
          <li>
            本クレジットカード決済は決済代行サービス提供会社(Stripe社)による機能を使用して提供されます。
          </li>
          <li>
            お客様のクレジットカード番号は、SSLというセキュリティシステムを利用し暗号化されているため、漏洩等のご心配はございません。安心してサービスをご利用ください。
          </li>
          <li>
            お客様のクレジットカード情報は弊社データベースには格納されません。
          </li>
          <li>
            決済手数料は弊社負担となります。
          </li>
        </ul>
      </section>
    </template>
  </base-modal>
</template>

<script>
  import amexLogo from '@/../assets/images/cards/amex.gif'
  import visaLogo from '@/../assets/images/cards/visa.png'
  import masterCardLogo from '@/../assets/images/cards/master_card.svg'
  import jcbLogo from '@/../assets/images/cards/jcb.gif'
  import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus'
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes  from '../store/action-types'
  import PlanTypes from '@/const/plan_types'

  export default {
    name: 'StripeCardForm',
    components: { CardNumber, CardExpiry, CardCvc },
    computed: {
      amexLogo: () => amexLogo,
      visaLogo: () => visaLogo,
      masterCardLogo: () => masterCardLogo,
      jcbLogo: () => jcbLogo,
      ...mapGetters(['currentUser']),
      isCreditCardRegistered() {
        return this.currentUser.is_creditcard_registered
      },
      isShowCreditcardForm() {
        if (this.currentUser.is_creditcard_registered) {
          return false
        }
        return true
      },
      targetPlan() {
        if (this.selectedPlanType == PlanTypes.BASIC_PLAN) {
          return { name: "相談し放題", price: "980円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.TRIAL_PLAN) {
          return { name: "相談し放題", price: "980円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.KANPO_PLAN) {
          return { name: "相談し放題", price: "980円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.STANDARD_PLAN) {
          return { name: "スタンダードプラン", price: "7,700円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.PREMIUM_PLAN) {
          return { name: "プレミアムプラン", price: "16,500円（税込）"}
        }
      },
      selectedPlanType() {
        return this.$route.query.plan_type
      }
    },
    data () {
      const token = document.getElementsByName('stripe-public-key')[0].getAttribute('content');
      return {
        submitDisabled: false,
        complete: false,
        number: false,
        expiry: false,
        cvc: false,
        token: null,
        stripePublishableKey: token,
        options: {},
        errorMessage: null,
        answeredMsg: {
          content: '有料会員に登録しました'
        }
      }
    },
    watch: {
      number() {
        this.update();
      },
      expiry() {
        this.update();
      },
      cvc() {
        this.update();
      }
    },
    methods: {
      confirmCancel() {
        this.token = null;
      },
      submit() {
        this.submitDisabled = true;
        createToken().then(data => {
          if (!!data.error) {
            this.errorMessage = data.error.message;
            this.submitDisabled = false;
            return
          }

          this.postSubscription({ sourceToken: data.token.id, selectedPlanType: this.selectedPlanType }).then(response => {
            this.submitDisabled = false;
            if (response.statusText !== 'OK') {
              if (response.status === 409 && response.data.type === 'PlanDoubleRegistrationError') {
                const answeredErrorMsg = { content: response.data.message }
                this.postChatLog({ chatLog: answeredErrorMsg, chatId: this.$route.params.chat_id });
              } else {
                const answeredErrorMsg = { content: 'プラン登録に失敗しました。' + response.data.messages }
                this.postChatLog({ chatLog: answeredErrorMsg, chatId: this.$route.params.chat_id });
              }
            } else {
              this.postChatLog({ chatLog: this.$data.answeredMsg, chatId: this.$route.params.chat_id });
            }
            this.backChat();
          })
        })
      },
      subscribe() {
        this.submitDisabled = true;
        this.postSubscription({ selectedPlanType: this.selectedPlanType }).then(response => {
          this.submitDisabled = false;
          if (response.statusText !== 'OK') {
            if (response.status === 422 ) {
              const answeredErrorMsg = { content: 'プラン登録に失敗しました。会員情報ページよりクレジットカード情報を登録してから再度プラン登録をお試しください。' }
              this.postChatLog({ chatLog: answeredErrorMsg, chatId: this.$route.params.chat_id });
            } else if (response.status === 409 && response.data.type === 'PlanDoubleRegistrationError') {
              const answeredErrorMsg = { content: response.data.message }
              this.postChatLog({ chatLog: answeredErrorMsg, chatId: this.$route.params.chat_id });
            } else {
              const answeredErrorMsg = { content: 'プラン登録に失敗しました。' + response.data.messages }
              this.postChatLog({ chatLog: answeredErrorMsg, chatId: this.$route.params.chat_id });
            }
          } else {
            this.postChatLog({ chatLog: this.$data.answeredMsg, chatId: this.$route.params.chat_id });
          }
          this.backChat();
        })
      },
      backChat() {
        this.$router.push(`/chat/${this.$route.params.chat_id}`)
      },
      update() {
        this.complete = this.number && this.expiry && this.cvc;
        if (this.number) {
          if (!this.expiry) {
            this.$refs.cardExpiry.focus();
          } else if (!this.cvc) {
            this.$refs.cardCvc.focus();
          }
        } else if (this.expiry) {
          if (!this.cvc) {
            this.$refs.cardCvc.focus();
          } else if (!this.number) {
            this.$refs.cardNumber.focus();
          }
        }
      },
      ...mapActions({ postSubscription: actionTypes.POST_SUBSCRIPTION,
      postChatLog: "POST_CHAT_LOG" })
    },
  }
</script>

<style scoped lang="scss">
  .subscription-form {
    .source-radio {
      border-bottom: 1px solid #aaa;
      &:last-child {
        border-bottom: 0px solid #aaa;
      }

      input[type=radio] {
        display: none;
      }

      &-label {
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        -webkit-transition: background-color 0.2s linear;
        transition: background-color 0.2s linear;
        position: relative;
        display: inline-block;
        margin: 0 20px 0px 0;
        padding: 12px 12px 12px 42px;
        background-color: #f6f7f8;
        vertical-align: middle;
        cursor: pointer;
        font-weight: normal;

        &:hover {
          background-color: #e2edd7;
          &:after {
            border-color: #53b300; }
        }

        &:before {
          -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
          position: absolute;
          //top: 50%;
          left: 20px;
          display: block;
          //margin-top: -5px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          color: #53b300;
          content: "\f00c";
          font-family: FontAwesome;
          opacity: 0;
        }
      }

      input[type=radio]:checked + .source-radio-label:before {
        opacity: 1;
      }
    }
  }

  .subscription-plan {
    margin-top: 20px;
    position: relative;
    height : 50px;
    background-color: white;
    .subscription-period {
      font-size: 1.2em;
      height: 36px;
      line-height: 36px;

      &:after {
        content: ':';
        padding: 0 2px;
      }
    }
    .subscription-amount {
      font-size: 1.2em;
      height: 36px;
      line-height: 36px;
    }
    .subscription-submit {
      color: #fff;
      display: inline;
      background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 11px 30px;
      margin: 10px 0;
      border-radius: 50px;
      text-decoration: none;
      position: absolute;
      right: 8px;
      bottom: 0px;
    }

    @media screen and (max-width: 520px) {
      height: 96px;
      width: 100%;
      .subscription-submit {
        margin: auto;
        position: static;
        width: 100%;
        display: block;
      }
    }
  }

  .creditCardPolicy {
    border-top: 1px solid #eee;
    padding: 16px 0 0 0;
  }
</style>
