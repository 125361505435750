<template>
  <div class="files-field-group">
    <label
      :for="`interview-result-item-${groupMaster.master_id}`"
      class="file-label"
    >
      画像を選択
    </label>
    <input
      :id="`interview-result-item-${groupMaster.master_id}`"
      multiple
      type="file"
      accept="image/*"
      class="file-input"
      @change="handleInput"
    />
    <base-loading-icon :target="previews">
      <div
        v-for="(preview, i) in previews"
        :key="i"
        class="preview-wrapper"
      >
        <p>{{preview.name}}</p>
        <img
          :src="preview.url"
          class="preview"
          :alt="preview.name"
        />
      </div>
    </base-loading-icon>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { LOADING_QUERY } from '../../../store/loading'
  export default {
    name: 'ChatLogInterviewResultFilesField',
    props: {
      groupMaster: {
        type: Object,
        required: true
      },
      resultForm: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        previews: []
      }
    },
    methods: {
      handleInput(e) {
        e.preventDefault();
        this.previews = LOADING_QUERY;
        this.$emit('input', Array.prototype.map.call(e.target.files, file => ({ file })));
        Array.prototype.map.call(e.target.files, this.attachPreview)
      },
      attachPreview(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          if (e.target.result) {
            if (this.previews.map(i => i).length === 0) {
              this.previews = [
                {
                  name: file.name,
                  url: e.target.result
                }
              ];
            } else {
              this.previews = [
                ...this.previews,
                {
                  name: file.name,
                  url: e.target.result
                }
              ];
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .file-label {
    background-color: #6cbeec;
    color: white;
    padding: 8px 16px;
    border-radius: 25px;
    cursor: pointer;
  }
  .file-input {
    display: none;
  }
  .preview-wrapper {
    padding-left: 80px;
    .preview {
      width: 280px;
      max-height: 280px;
      object-fit: scale-down;
    }
  }
  .files-field-group {
    margin: 16px 16px 32px 5px;
  }

  .file-label {
    color: #FFFFFF;
    background: linear-gradient(41.64deg, #00BCD4 0.06%, #2196F3 100.01%);
  }

  .preview-wrapper {
    padding: 0;
  }

  .preview-wrapper .preview {
    width: 180px;
    height: auto;
  }

  .preview-wrapper p {
    font-size: 12px;
    font-weight: 400;
  }
</style>
