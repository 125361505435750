<template>
  <span v-if="hasPregnancy" class="fa-stack">
    <i class="fa fa-stack-1x fa-user-o"></i>
    <i class="fa fa-stack-1x fa-user"></i>
  </span>
</template>
<script>
  import PatientFamilyProfile from '../../enums/PatientFamilyProfile'

  export default {
    props: ['patientFamilyProfile'],
    computed: {
      hasPregnancy() {
        return this.patientFamilyProfile.pregnancy === PatientFamilyProfile.pregnancy.yes
      },
      textDanger() {
        return this.hasPregnancy
      }
    }
  };
</script>

<style scoped lang="scss">
  span {
    color: #f7526c;

    .fa-user-o {
      font-size: 24px;
    }

    .fa-user {
      font-size: 18px;
      left: 9px;
      top: 5px;
    }
  }
</style>
