<template>

  <div>
    <loading :translucent="true" v-show="loading"></loading>
    <div v-if="isAdmin || isCustomerService">
      <div class='row'>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <consultation-summary-card
            title='登録患者数' :text="totalUsersCount" bgClass='bg-aqua' iconClass='fa-user-o' />
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <consultation-summary-card
            title='相談件数(削除済、終了済含)' :text="totalConsultationsCount" bgClass='bg-green' iconClass='fa-comment-o' />
        </div>
      </div>
    </div>

    <div id="create-consultation">
      <a v-if="isAdmin" href="/notifications/new" class="btn btn-success">
        <i class="fa fa-bell fa-fw" aria-hidden="true"></i>
        プッシュ通知送信
      </a>

      <a v-if="isAdmin" href="/medical_dashboard/categories" class="btn btn-warning">
        <i class="fa fa-dashboard" aria-hidden="true"></i>
        メディカルダッシュボード設定
      </a>

      <router-link v-if="isAdmin" to="/interview/masters" class="btn btn-info">
        <i class="fa fa-bell fa-fw" aria-hidden="true"></i>
        問診票設定
      </router-link>

      <router-link v-if="isAdmin" to="/chat_interview/groups" class="btn btn-info">
        <i class="fa fa-bell fa-fw" aria-hidden="true"></i>
        チャット問診設定
      </router-link>

      <a v-if="isPatient" href="/consultations/new" class="btn">
        <i class="fa fa-comments fa-fw" aria-hidden="true"></i>
        相談する
      </a>
    </div>
    <div class='text-right world-clock' v-show='isAdmin || isCustomerService'>
      <i class="fa fa-clock-o fa-fw"
         @click="showWorldTimeModal = true"
         aria-hidden="true" />
    </div>
    <world-time-modal v-show='showWorldTimeModal' @close="showWorldTimeModal = false">
    </world-time-modal>

    <consultation-search-form v-if="isNotPatient" @search="search"></consultation-search-form>

    <base-nav-tabs v-if="isNotPatient" >
      <consultations-nav-list-item :consultationStatus="$data.consultationStatus" :type="'new'" />
      <consultations-nav-list-item :consultationStatus="$data.consultationStatus" :type="'now'" />
      <consultations-nav-list-item :consultationStatus="$data.consultationStatus" :type="'request'" />
      <consultations-nav-list-item :consultationStatus="$data.consultationStatus" :type="'end'" />
      <consultations-nav-list-item :consultationStatus="$data.consultationStatus" :type="'deleted'" />
    </base-nav-tabs>

    <base-loading-icon :target="consultations" id="timeline">
      <medical-dashboard-links v-if="hasMedicalDashboard" />

      <h3 class="text-center" v-if="stillConsultation && !isPatient">該当する相談がありません</h3>

      <div>
        <h4 v-if="isAdmin || isCustomerService">合計件数: {{totalFilteredConsultationsCount}}件</h4>
        <div v-for="consultation in listedConsultations">
          <ConsultationListItem :key="consultation.id" :consultation="consultation" />
        </div>
      </div>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
        <div slot="no-more">No More</div>
        <div slot="no-results">No Result</div>
      </infinite-loading>

      <base-reload-link v-if="cableStatus === 'DISCONNECTED'">
        <div class='alert alert-danger' role="alert">
          <span>
            <i class="fa fa-refresh"></i>
            画面を更新してください
          </span>
        </div>
      </base-reload-link>
    </base-loading-icon>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import * as actionTypes from '../store/action-types';
import * as mutationTypes from '../store/mutation-types';
import * as PatientFamilyProfiles from '../store/modules/patientFamilyProfiles';
import BaseReloadLink from './base/BaseReloadLink';
import ConsultationListItem from './ConsultationListItem';
import MedicalDashboardLinks from './MedicalDashboardLinks';
import ConsultationsNavListItem from './ConsultationsNavListItem';
import consultationsChannel from '../channels/consultationsChannel';

import User from '../enums/User';
import BaseLoadingIcon from './base/BaseLoadingIcon';
import ConsultationSearchForm from './ConsultationSearchForm';
import WorldTimeModal from './WorldTimeModal';
import ConsultationSummaryCard from './ConsultationSummaryCard';
import Loading from '@/components/Common/Loading';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Consultations',
  components: {
    ConsultationSearchForm,
    BaseLoadingIcon,
    ConsultationListItem,
    MedicalDashboardLinks,
    BaseReloadLink,
    ConsultationsNavListItem,
    WorldTimeModal,
    ConsultationSummaryCard,
    Loading,
    InfiniteLoading,
  },
  data: () => {
    return {
      cableStatus: 'READY_CONNECT',
      consultationStatus: null,
      showModal: false,
      showWorldTimeModal: false,
      loading: false,
      page: 1, // ページング初期値
      perPage: 20,
      infiniteId: 1, // vue-infinite-loadingでタブ切り替えに対応するために必要
      query: {}
    };
  },
  async created() {
    this.$data.consultationStatus = this.$route.query.status || 'new';
    this.fetchTotalConsultationsCount() // 画面右上のトータル件数
    consultationsChannel(this);
    const patientFamilyProfiles = await this.fetchPatientFamilyProfiles();
    this.fetchPatientCount();
    this.modalCheck(patientFamilyProfiles);
  },

  computed: {
    hasMedicalDashboard() {
      return (
        this.$store.getters.currentUser.role === User.Role.patient &&
        this.$store.getters.currentUser.is_dashboards_exist
      );
    },
    listedConsultations() {
      return this.consultationsByStatus(this.$data.consultationStatus);
    },
    totalUsersCount() {
      return this.patientCount + '人';
    },
    totalConsultationsCount() {
      return this.totalConsultationsCount + '件';
    },

    ...mapGetters([
      'currentUser',
      'consultations',
      'consultationsByStatus',
      'stillConsultation',
      'isAdmin',
      'isPatient',
      'isCustomerService',
      'isNotPatient',
      'noMoreConsultation',
      'totalFilteredConsultationsCount',
      'totalConsultationsCount'
    ]),
    ...mapGetters({
      isRequireInterview: 'patientFamilyProfiles/isRequireInterview',
      patientCount: 'users/patientCount'
    }),
  },
  methods: {
    modalCheck(patientFamilyProfiles) {
      if (this.isPatient && this.consultations.length === 0) {
        if (patientFamilyProfiles.length === 0) {
          this.$router.history.push('/patient_family_profiles/new');
        }
      }
    },
    // 無限スクロール用のハンドラ
    infiniteHandler($state) {
      // フェッチ
      this.fetchAdditionalConsultations({
        ...this.query,
        status: this.$data.consultationStatus, // どのタブか
        limit:  this.perPage, // 最大件数
        page: this.page // 現在のページ数
      }).then(_ => {
        if (this.noMoreConsultation) {
          // もうない場合
          $state.complete();
        } else {
          // まだある場合
          this.page += 1;
          $state.loaded();
        }
      })
    },
    resetInfiniteScrollInformation() {
      this.clearConsultations()
      this.infiniteId += 1;
      this.page = 1
    },
    search(params) {
      this.query = params
      this.fetchConsultations({
        ...this.query,
        status: this.$data.consultationStatus, // どのタブか
        limit:  this.perPage, // 最大件数
        page: 1 // 現在のページ数
      })
    },
    ...mapActions({
      fetchConsultations: actionTypes.FETCH_CONSULTATIONS,
      fetchAdditionalConsultations: actionTypes.FETCH_ADDITIONAL_CONSULTATIONS,
      clearConsultations: actionTypes.CLEAR_CONSULTATIONS,
      fetchTotalConsultationsCount: actionTypes.FETCH_TOTAL_CONSULTATIONS_COUNT,
      fetchPatientFamilyProfiles: `patientFamilyProfiles/${
        PatientFamilyProfiles.actionTypes.FETCH_QUERY
      }`,
      fetchPatientCount: `users/${actionTypes.FETCH_PATIENT_COUNT}`,
    }),
    ...mapMutations({
      setConsultations: mutationTypes.SET_CONSULTATIONS,
    }),
  },
  watch: {
    $route(to, from) {
      //ローディング開始
      if (from.fullPath.indexOf('patient_family_profile') == -1  && (to.fullPath == '/consultations?status=new' || to.fullPath == '/consultations?status=now' || to.fullPath == '/consultations?status=request'
      || to.fullPath == '/consultations?status=end' || to.fullPath == '/consultations?status=end')){
        this.loading = true;
      }
      this.$data.consultationStatus = this.$route.query.status || 'new';
    },
    listedConsultations() {
      this.$nextTick(function() {
        //リストが再描画されたタイミングでローディングの停止
        this.loading = false;
      });
    },
    // タブ変更を検知
    consultationStatus() {
      // vue-infinite-scrollのリセット
      this.resetInfiniteScrollInformation()
    }
  },
};
</script>

<style scoped>
.world-clock {
  font-size: 1.5em;
}
</style>
