<template>
  <div :class="invalidClass" class="form-group">
    <label :for="name" class="control-label">
      {{labelText}}
      <abbr title="required">必須</abbr>
    </label>
    <input :id="name" :value="value" @input="onInput" class="form-control" />
    <span v-if="errors" v-for="error in errors" class="help-block">{{error}}</span>
  </div>
</template>

<script>
export default {
  name: 'BaseTextField',
  props: ['name', 'labelText', 'value', 'errors'],
  data() {
    return { text: this.value };
  },
  computed: {
    invalidClass() {
      return !!this.errors ? ' has-error' : '';
    },
  },
  methods: {
    onInput(e) {
      this.text = e.target.value;
      this.$emit('input', this.text);
    },
  },
};
</script>

<style scoped lang="scss">
</style>