<template>
  <base-modal>
    <template slot="header">
      <h3>ロール変更</h3>
    </template>
    <template slot="body">
      {{user.email}}
      <base-select-field name="role"
                         label-text="Role"
                         v-model="$data.form.role">
        <option v-for="(value, name) in roles" :key="value" :value="value">
          {{name}}
        </option>
      </base-select-field>
      <button type="button" @click="handleSubmit" class="btn btn-primary">更新</button>
    </template>
  </base-modal>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import {actionTypes} from '../store/modules/users'
  import UserEnum from '../enums/User'

  export default {
    async created() {
      if(this.$route.params.id === this.currentUser.id) {
        this.$router.history.push("/consultations")
      }
      const user = await this.fetchUser({id: this.$route.params.id});
      this.$data.form.role = user.role
    },
    data() {
      return {
        form: {
          role: null
        }
      }
    },
    computed: {
      roles() {
        return  UserEnum.Role
      },
      ...mapGetters({
        currentUser: 'currentUser',
        user: 'users/user'
      })
    },
    methods: {
      async handleSubmit() {
        await this.patchUserRole({id: this.$route.params.id, role: parseInt(this.$data.form.role)});
        ({
          admin: this.fetchAdmin,
          customer_service: this.fetchCustomerService,
          doctor: this.fetchDoctor,
          patient: this.fetchPatient
        })[this.$route.query.role]();
        this.$router.history.push(`/management_users?role=${this.$route.query.role}`);
      },
      ...mapActions({
        fetchUser: `users/${actionTypes.FETCH_USER}`,
        fetchAdmin: `users/${actionTypes.FETCH_ADMIN}`,
        fetchCustomerService: `users/${actionTypes.FETCH_CUSTOMER_SERVICE}`,
        fetchDoctor: `users/${actionTypes.FETCH_DOCTOR}`,
        fetchPatient: `users/${actionTypes.FETCH_PATIENT}`,
        patchUserRole: `users/${actionTypes.PATCH_USER_ROLE}`
      })
    }
  }
</script>
