import * as mutationTypes from '../../mutation-types'
import * as actionTypes from '../../action-types'

const getters = {
  medicalDashboardPatientFamilyProfileCategories: (_, getters) => getters
    .medicalDashboardPatientFamilyProfile
    .medical_dashboard_patient_family_profile_categories
};

export default {
  getters
}

