<template>
  <base-modal>
    <template slot="header">
      <h1>
        クレジットカード情報登録
      </h1>
    </template>
    <template slot="body">
      <div class="stripeCardForm">
        <div class="form-group">
          <label class="control-label">カード番号</label>
          <card-number class="form-control"
                       ref="cardNumber"
                       :stripe="stripePublishableKey"
                       :options="options"
                       @change="number = $event.complete"
          />
        </div>

        <div class="form-group">
          <label class="control-label">有効期限 月/年</label>
          <card-expiry class="form-control"
                       ref="cardExpiry"
                       :stripe="stripePublishableKey"
                       :options="options"
                       @change="expiry = $event.complete"
          />
        </div>

        <div class="form-group">
          <label class="control-label">セキュリティコード</label>
          <card-cvc class='form-control'
                    ref='cardCvc'
                    :stripe='stripePublishableKey'
                    :options='options'
                    @change="cvc = $event.complete"
          />
        </div>

        <p v-if="errorMessage" class="error-text">{{errorMessage}}</p>

        <p>
          <img :src="visaLogo" alt="visa" style="height: 20px"/>
          <img :src="masterCardLogo" alt="masterCard" style="height: 34px"/>
          <img :src="amexLogo" alt="amex" style="height: 24px"/>
          <img :src="jcbLogo" alt="jcb" style="height: 24px"/>
          がご利用いただけます
        </p>
        <div class="subscription-plan">
          <button class="submit" @click="submit" :disabled="submitDisabled">カードを登録</button>
        </div>
      </div>
    </template>
  </base-modal>

</template>

<script>

import amexLogo from '@/../assets/images/cards/amex.gif';
import visaLogo from '@/../assets/images/cards/visa.png';
import masterCardLogo from '@/../assets/images/cards/master_card.svg';
import jcbLogo from '@/../assets/images/cards/jcb.gif';

import {
  CardNumber,
  CardExpiry,
  CardCvc,
  createToken,
} from 'vue-stripe-elements-plus';
import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '../store/action-types';

export default {
  name: 'StripeCardGetAuthorizationForm',
  components: { CardNumber, CardExpiry, CardCvc },
  computed: {
    amexLogo: () => amexLogo,
    visaLogo: () => visaLogo,
    masterCardLogo: () => masterCardLogo,
    jcbLogo: () => jcbLogo,
    ...mapGetters(['currentUser']),
    isCreditCardRegistered() {
      return this.currentUser.is_creditcard_registered
    },
  },
  data() {
    const token = document
      .getElementsByName('stripe-public-key')[0]
      .getAttribute('content');
    return {
      submitDisabled: false,
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      token: null,
      stripePublishableKey: token,
      options: {},
      errorMessage: null,
    };
  },
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
  },
  methods: {
    confirmCancel() {
      this.token = null;
    },
    submit() {
      this.submitDisabled = true;
      createToken().then(data => {
        if (!!data.error) {
          this.errorMessage = data.error.message;
          this.submitDisabled = false;
          return;
        }
        let submitAction = null;
        if(this.isCreditCardRegistered) {
          submitAction = this.updateAuthorization
        } else {
          submitAction = this.postSAuthorization
        }
        submitAction(data.token.id).then(response => {
          this.submitDisabled = false;
          if (this.$route.path == '/online_clinic/clinic/reservation/new') {
            this.$emit('close-credit-modal', { cardRegistered: true });
          } else {
            this.backPatientFamilyProfiles();
          }
        });
      });
    },
    backPatientFamilyProfiles() {
      this.$router.push(`/patient_family_profiles`);
    },
    update() {
      this.complete = this.number && this.expiry && this.cvc;
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
    },
    ...mapActions({ postSAuthorization: actionTypes.POST_AUTHORIZATION, updateAuthorization: actionTypes.UPDATE_AUTHORIZATION }),
  },
};
</script>

<style>
  .form-control {
    padding: 12px 16px;
     height: auto;
    box-shadow: none;
    border-radius: 8px;
    border: solid 1px #d3d3d3;
  }
  .submit {
    display: block;
    color: #fff;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 22px 0;
    margin: 20px 0;
    border-radius: 50px;
    text-decoration: none;
    width: 100%;
    border: none;
    cursor: pointer;
  }
  .error-text {
    color:#FF4545;
    background: #FFF0F0;
    height: 35px;
    padding: 0 10px;
    line-height: 35px;
    text-align: left;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
  }
</style>
