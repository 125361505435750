<template>
  <div class="onlineClinic-modal">
    <div class="onlineClinic-modal__box">
      <div class="onlineClinic-modal__header">
        前回の問診回答の自動入力
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>

      <div class="onlineClinic-modal__content">
        <h3>
          前回の問診回答を自動入力しますか？
        </h3>
        <p>
          ※「初診ですか？」は「いいえ」が選択されます<br>
          ※「薬局」「保険証」「乳幼児医療など、医療費の助成資格」は、再選択・再アップロードが必要です<br>
          ※既に問診に回答している項目がある場合、前回の問診回答内容に上書きされます
        </p>
      </div>
      <div class="onlineClinic-modal__footer">
        <button @click="closeModal()" class="onlineClinic-modal__button-white">いいえ</button>
        <button @click="setLatestInterview()" class="onlineClinic-modal__button-blue">はい</button>
      </div>
    </div>
    <div class="onlineClinic-modal__overlay">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
    setLatestInterview(){
      this.$emit('set-latest-interview')
    }
  },
};
</script>

<style scoped lang="scss">
.onlineClinic-modal {

  &__box {
    background-color:#FFFFFF;
    width: 100%;
    max-width: 395px;
    height: auto;
    position: fixed;
    top: 95px;
    left: 0;
    right: 0;
    margin: auto;
    z-index:300;
    border-radius: 8px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow: scroll;
    -ms-overflow-style: none;    /* IE, Edge 対応 */
    scrollbar-width: none;
    &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
      display:none;
    }
  }

  &__content {
    margin: 0 17px 0 16px;
    padding: 0 32px 8px 32px;
    line-height: 25px;

    h3 {
      color: #2A3039;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      line-height: 21px;
    }
    p {
      color: rgba(42, 48, 57, 0.6);
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__bottom {
    height: 16px;
  }


  &__overlay {
    background: #2a3039;
    opacity: 0.2;
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index:200;
  }

  &__header {
    border-bottom: #CFD8DC solid 1px;
    text-align: center;
    color: #2A3039;
    font-size:16px;
    position:relative;
    padding: 20px;
    font-weight: 500;

    img {
      cursor: pointer;
      position:absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      width:21px;
      height:21px;
    }
  }
  &__footer {
    width: 100%;
    text-align: center;
    display: inline-block;
  }

  &__button-blue {
    cursor:pointer;
    width: 122px;
    height: 40px;
    color: #fff;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 20px 6px;
    border: 1px solid #2196F3;
    border-radius: 100px;
    text-decoration: none;
  }

  &__button-white {
    cursor:pointer;
    width: 122px;
    height: 40px;
    color: #2196F3;
    border: 1px solid #2196F3;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 20px 6px;
    border-radius: 100px;
    text-decoration: none;
    background: #FFFFFF;
  }

}

</style>
