<template>
  <div class='row'>
    <div class="panel-title col-xs-12">
      <still-reply-label v-show="isStillReply"></still-reply-label>
    </div>

    <div class='panel-title col-xs-2'>
      <base-user-icon :icon-url="patientFamilyProfile.image_icon_url"
                      :full-name="`${patientFamilyProfile.first_name} ${patientFamilyProfile.last_name}`" />
    </div>
    <div class='panel-title name col-xs-2'>
      {{ `${patientFamilyProfile.first_name} ${patientFamilyProfile.last_name}` }}
    </div>
    <div class='panel-title name col-xs-3' v-if="kireidoId">
      キレイドユーザー
    </div>
    <div class='panel-title name col-xs-1'>
      {{ patientFamilyProfile.sex_text }}
    </div>
    <div class='panel-title name col-xs-1'>
      {{ patientFamilyProfile.age }}歳
    </div>
    <div class='panel-link col-xs-2'>
      <router-link :to="patientFamilyProfilePath" class="btn btn-info">
        患者情報
      </router-link>
    </div>
    <div class="panel-title col-xs-3">
      <base-user-icon v-for="doctorProfile in consultation.doctor_profiles"
                      :key="doctorProfile.id"
                      :full-name="`${doctorProfile.first_name} ${doctorProfile.last_name}`"
                      :icon-url="doctorProfile.image.icon.url" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import StillReplyLabel from './StillReplyLabel'

  export default {
    name: 'DoctorHeader',
    props: ['consultation'],
    components: { StillReplyLabel },
    created: function() {
    },
    computed: {
      patientFamilyProfilePath() {
        return `/consultations/${this.consultation.id}/patient_family_profile?status=${this.consultationToStatus(this.consultation)}`
      },
      patientFamilyProfile () {
        return this.consultation.patient_family_profile
      },
      isStillReply () {
        if (!this.consultation.user) {
          return false
        }
        return this.consultation.last_chat_log_user_id === this.consultation.user.id
      },
      kireidoId () {
        return this.consultation.user ? this.consultation.user.kireido_id : null
      },
      ...mapGetters(['currentUser', 'consultationToStatus'])
    }
  }
</script>

<style scoped>
  .panel-link {
    margin-top: 4px;
  }

  .panel-title.name {
    margin-top: 10px;
  }

  .panel-title {
    font-size: 14px;
  }
</style>
