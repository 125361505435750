<template>
  <div class="p-onlineClinicHome">
    <div class="p-onlineClinicHome__info">
      <div class="p-onlineClinicHome__header">
        <p class="p-onlineClinicHome__status">現在のステータス</p>
      </div>

      <div class="p-onlineClinicHome__body">
        <p class="p-onlineClinicHome__title">キャンセルされました</p>
        <p class="p-onlineClinicHome__comment">予約時間に診察ができなかったため、キャンセルさせていただきました。<br>以下のボタンより再度予約をお取りください。</p>
      </div>
      <p class="p-onlineClinicHome__contact-support">ご不明点がございましたら<a href="/contacts/new">お問い合わせ</a>よりご連絡ください。</p>
    </div>

    <router-link to="/online_clinic/clinic/reservation/new" class="c-mainButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '診察を予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })">
      診察を予約する
    </router-link>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="this.$props.chat == null ? null : this.$props.chat.id"
    ></notification-button>

    <a href="/online_clinic/examinations" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '過去の診療', userId: currentUser.id, source: currentUser.source })">
      過去の診察
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">
      会員情報
    </a>
  </div>
</template>


<script>
import NotificationButton from "./buttons/NotificationButton";
import {mapGetters} from "vuex";
  export default {
    name: 'CancelByAdmin',
    props: ['chat'],
    components: {
      NotificationButton
    },
    computed: {
      ...mapGetters(['currentUser'])
    },
    methods: {
      mixpanelTrackContact() {
        this.$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: 'お問い合わせ', userId: this.currentUser.id });
      }
    }
  };
</script>
