<template>
  <div class="row">
    <h2>{{category.name}}</h2>
    <patient-category-field :patientFamilyProfileCategory="patientFamilyProfileCategory" v-if="patientFamilyProfileCategory"/>
    <div v-for="{ item } in categorizeItems" v-if="!patientFamilyProfileCategory._destroy" class="form-group">
      <item-input :item="item" />
    </div>
  </div>
</template>

<script>
  import ItemInput from './ItemInput'
  import PatientCategoryField from './PatientCategoryField'

  export default {
    name: 'CategoryField',
    components: { ItemInput, PatientCategoryField },
    props: ['category', 'patientFamilyProfileCategory', 'categorizeItems']
  }
</script>

<style scoped>
</style>
