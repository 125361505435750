<template>
  <div class="form-group">
    <div class="control-group">
      <label class="control-label">{{result.master.message}}</label>
      <base-radio-field v-for="master_item in result.master.master_items"
                        :key="master_item.id"
                        :label-text="master_item.label"
                        v-model="result_item.master_item_id"
                        :name="`interview-result-${master_item.id}`" :checked-value="master_item.id">
      </base-radio-field>
    </div>
  </div>
</template>
<script>
  import BaseRadioField from "../base/Form/BaseRadioField";
  export default {
    name: 'ResultRadioField',
    components: {BaseRadioField},
    props: ['result'],
    computed: {
      result_item() {
        return this.result.result_items[0]
      }
    }
  }
</script>
