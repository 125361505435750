<template>
  <ul class="step-bar">
    <step :current-step="currentStep"
          :step="1"
          :is-continuous="aroundSteps[0] === 2 || fieldCount === 2">
    </step>
    <step v-for="step in aroundSteps"
          :key="step"
          :step="step"
          :current-step="currentStep"
          :is-continuous="step != aroundSteps.slice(-1)[0] || step === currentStep || currentStep >= fieldCount">
    </step>
    <step :current-step="currentStep"
          :step="fieldCount">
    </step>
  </ul>
</template>

<script>

import Step from "./Step";

const filterOver = lastStep => xs => xs.filter(x => x > 1 && x < lastStep);

const aroundSteps = (currentStep, lastStep) => {
  if(currentStep === 1) {
    return [2]
  } else if(currentStep === 2) {
    return [currentStep, currentStep +  1, currentStep + 2]
  } else if(currentStep > 2 && currentStep < lastStep - 1) {
    return [currentStep - 1, currentStep, currentStep + 1]
  } else {
    return [lastStep - 2, lastStep - 1]
  }
};

export default {
  components: {Step},
  props: { fieldCount: {
      type: Number,
      required: true
    },
    answeredCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    aroundSteps() {
      return filterOver(this.fieldCount)(aroundSteps(this.currentStep, this.fieldCount))
    },
    currentStep() {
      return this.answeredCount + 1;
    }
  }
}
</script>

<style scoped lang="scss">
.step-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  padding: 0;
}
</style>
