<template>
  <div class="p-onlineClinicInterviewConfirmation">
    <flash-message />

    <div class="p-onlineClinicInterviewConfirmation__container l-ignoreParentContainer">
      <div class="p-onlineClinicInterviewConfirmation__box">
        <div class="p-onlineClinicInterviewConfirmation__label">診療機関</div>
        <div class="p-onlineClinicInterviewConfirmation__item">{{clinicReservationToCreate.clinic.name}}</div>
      </div>
      <div class="p-onlineClinicInterviewConfirmation__box" v-if="clinicReservationToCreate.interview.selectedPharmacy">
        <div class="p-onlineClinicInterviewConfirmation__label">薬局名</div>
        <div class="p-onlineClinicInterviewConfirmation__item">{{clinicReservationToCreate.interview.selectedPharmacy.name}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__box">
        <div class="p-onlineClinicInterviewConfirmation__label">診療科目</div>
        <div class="p-onlineClinicInterviewConfirmation__item">{{clinicReservationToCreate.medicalDepartment.name}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__box">
        <div class="p-onlineClinicInterviewConfirmation__label">予約日時</div>
        <div class="p-onlineClinicInterviewConfirmation__item">{{examinationDatetime}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__box">
        <div class="p-onlineClinicInterviewConfirmation__label">担当医師</div>
        <img :src="clinicReservationToCreate.doctorSchedule.user.doctor_profile.image.url" class="p-onlineClinicInterviewConfirmation__image">
        <div class="p-onlineClinicInterviewConfirmation__item">{{clinicReservationToCreate.doctorSchedule.user.doctor_profile.first_name}} {{clinicReservationToCreate.doctorSchedule.user.doctor_profile.last_name}}先生</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__box">
        <div class="p-onlineClinicInterviewConfirmation__label">来院される方</div>
        <div class="p-onlineClinicInterviewConfirmation__item">{{clinicReservationToCreate.selectedFamilyProfile.first_name}} {{clinicReservationToCreate.selectedFamilyProfile.last_name}}さま</div>
      </div>

      <component :is="currentComponent"
                 ref="currentComponent"
                 :clinicReservationToCreate="clinicReservationToCreate"
                />

    </div>

    <stripe-card-get-authorization-form v-if="isCreditModal" v-on:close-credit-modal="closeCreditModal" class="creditModal"></stripe-card-get-authorization-form>
    <div class="p-onlineClinicInterviewConfirmation__footer"></div>
    <div v-if="apiError">
      <p class="p-onlineClinicInterviewConfirmation__title p-onlineClinicInterviewConfirmation__textDanger">予約処理の際にエラーが発生しました。<br>ご迷惑をおかけして恐縮ですが、<a href="/contacts/new" target="_blank" class="p-onlineClinicInterviewConfirmation__contactsLink">こちら</a>よりお問い合わせください。</p>
    </div>
    <div v-if="apiCreditError">
      <p class="p-onlineClinicInterviewConfirmation__title p-onlineClinicInterviewConfirmation__textDanger">クレジットカードの有効性を確認できませんでした。<br>クレジットカードを変更してください。</p>
      <button class="c-mainButton" @click="openCreditModal">
        クレジットカードを変更する
      </button>
    </div>
    <div class="p-onlineClinicInterviewConfirmation__footer">
      <div v-if="!isCreditCardRegistered">
        <p class="p-onlineClinicInterviewConfirmation__title">※予約にはクレジットカードの登録が必要です</p>
        <button class="c-mainButton" @click="openCreditModal(); $mixpanel.track('CLICK BUTTON', { page: '問診の回答（2）', buttonName: 'クレジットカードを登録する', userId: currentUser.id, source: currentUser.source, isCvButton: true })">
          クレジットカードを登録する
        </button>
      </div>
      <button class="c-mainButton" @click="createReservation(); $mixpanel.track('CLICK BUTTON', { page: '問診の回答（2）', buttonName: '予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })" :disabled="!isCreditCardRegistered || isRequesting || apiError || apiCreditError">予約する</button>
      <button class="p-onlineClinicInterviewConfirmation__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答（2）', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
    </div>
  </div>


</template>
<script>
import { mapGetters } from 'vuex';
import StripeCardGetAuthorizationForm from '@/components/StripeCardGetAuthorizationForm';
import EventBus from '@/event-bus';
import moment from 'moment';
import VueLocalStorage from 'vue-localstorage';
import FlashMessage from '@/components/online_clinic/FlashMessage';
import DefaultConfirmation from '@/components/online_clinic/clinics/reservations/reservationConfirmation/Default';
import ChineseMedicineConfirmation from '@/components/online_clinic/clinics/reservations/reservationConfirmation/ChineseMedicine';
import EmergencyContraceptionConfirmation from '@/components/online_clinic/clinics/reservations/reservationConfirmation/EmergencyContraception';
import PmsConfirmation from '@/components/online_clinic/clinics/reservations/reservationConfirmation/Pms';
import InterviewFormComponent from '@/enums/online_clinic/InterviewFormComponent';

export default {
  components: {
    StripeCardGetAuthorizationForm,
    FlashMessage,
    DefaultConfirmation,
    ChineseMedicineConfirmation,
    EmergencyContraceptionConfirmation,
    PmsConfirmation
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
    apiError: {
      type: Object
    },
    apiCreditError: {
      type: Object
    },
  },
  data: () => {
    return {
      isCreditModal: false,
      isRequesting: false,
    };
  },
  created() {

    EventBus.$on('close-credit-modal', () => {
      this.closeCreditModal();
    });
    this.$localStorage.set('flashType', 'info');
    this.$localStorage.set('flashMessage', '予約の最終確認をしてください');
  },
  computed: {
    currentComponent() {
      return InterviewFormComponent.name[this.$props.clinicReservationToCreate.medicalDepartment.interview_type.interview_table_name] + 'Confirmation'
    },
    isCreditCardRegistered() {
      return this.currentUser.is_creditcard_registered;
    },
    examinationDatetime() {
      if (this.clinicReservationToCreate.doctorSchedule.start == null) {
        return '読込中...';
      } else {
        moment.locale('ja', {
          weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
        });
        let startTime = moment(
          this.clinicReservationToCreate.doctorSchedule.start
        ).format('HH:mm');
        let endTime = moment(
          this.clinicReservationToCreate.doctorSchedule.end
        ).format('HH:mm');
        let day = moment(
          this.clinicReservationToCreate.doctorSchedule.start
        ).format('YYYY/MM/DD(ddd)');
        return `${day} ${startTime} ~ ${endTime}`;
      }
    },
    ...mapGetters(['currentUser']),
  },
  methods: {
    openCreditModal() {
      this.isCreditModal = true;
    },
    closeCreditModal(args = {}) {
      this.isCreditModal = false;
      if(args.cardRegistered) {
        this.$parent.$parent.apiCreditError = null
      }
    },
    createReservation() {
      this.isRequesting = true;
      this.$emit('emitClinicReservationConfirmed', () => {
        this.isRequesting = false
      });
    },
  },
};
</script>
