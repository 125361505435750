<template>
  <div class="tab-group">
    <router-link to="/interview/masters?way_type=patient_question">
        プロフィール問診
    </router-link>
    <router-link to="/interview/masters?way_type=chat_log">
      チャット問診
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'WayTypeTabs',
  }
</script>

<style scoped lang="scss">
  .tab-group {
    display: inline-block;
    margin-left: 8px;
    margin-top: 8px;

    a {
      color: #444;
      border: 1px solid #ddd;
      border-right: 0;
      border-bottom: 0;
      padding: 10px;
      display: inline-block;
      background: #eee;
      margin-left: -4px;

      &.router-link-exact-active {
        background: white;
      }

      &:hover, &:active, &:focus{
        text-decoration: none;
      }

      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-right: 1px solid #ddd;
        border-top-right-radius: 4px;
      }
    }
  }
</style>
