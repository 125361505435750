<template>
  <component :is="layoutComponent" :chat-log="chatLog" :patient-image="patientImage" :is-interview-finished="isInterviewFinished">
    <template slot="chat-content">
      <div v-show="!isSubscriptionRequestMsg">
        <chat-log-message :chat-log="chatLog" :item-type="itemType" />
        <chat-log-image :chat-log="chatLog" :item-type="itemType" />
        <chat-log-choice-board :chat-log="chatLog" :item-type="itemType" />
        <chat-log-interview :chat-log="chatLog" :item-type="itemType" />
      </div>
      <chat-log-subscription-request :chat-log="chatLog" :item-type="itemType" />
    </template>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'
import MyselfLayout from './ChatLogListItem/MyselfLayout'
import OthersLayout from './ChatLogListItem/OthersLayout'
import ChatLogMessage from './ChatLogListItem/ChatLogMessage'
import ChatLogImage from './ChatLogListItem/ChatLogImage'
import ChatLogChoiceBoard from './ChatLogListItem/ChatLogChoiceBoard'
import ChatLogEnum from '../enums/ChatLogEnum'
import ChatLogInterview from "./ChatLogListItem/ChatLogInterview";
import ChatLogSubscriptionRequest from "./ChatLogListItem/SubscriptionRequest";

export default {
  name: 'ChatLogListItem',
  props: ['chatLog', 'patientImage'],
  components: {ChatLogSubscriptionRequest, ChatLogInterview, ChatLogMessage, ChatLogImage, ChatLogChoiceBoard },
  computed: {
    mySelf() {
      return this.chatLog.user_id === this.currentUser.id
    },
    isSubscriptionRequestMsg(){
      return this.chatLog.log_type == ChatLogEnum.logType.subscriptionRequest
    },
    layoutComponent() {
      if (this.isInterviewFinished){
          if (this.isPatient) {
            return MyselfLayout
          } else {
            return OthersLayout
          }
      } else {
        return this.mySelf ? MyselfLayout : OthersLayout
      }
    },
    itemType() {
      return ChatLogEnum.chatItemType(this.chatLog)
    },
    interview() {
      return this.interviewItem(this.chatLog.id)
    },
    groupMasters() {
      return this.interview.chat_interview_public_group_masters || []
    },
    results() {
      return this.interview.chat_interview_results || []
    },
    isInterviewFinished() {
      return this.itemType == 'INTERVIEW' && (this.results.length === this.groupMasters.length)
    },
    ...mapGetters(['currentUser']),
    ...mapGetters({interviewItem: 'chatLogInterview/item', isPatient: 'isPatient' }),
  },
}
</script>

<style scoped>
  .chat-log-list-item {
    overflow: hidden;
    margin: 0 0 1em 0;
    padding: 0;
    color: #2A3039;
  }

  .chat-log-content {
    word-wrap:break-word;
    overflow-wrap:break-word;
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    min-height: 50px;
    border-radius: 20px;
    background-color: #eaeaea;
    padding: 1em;
  }
</style>
