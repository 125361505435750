<template>
  <base-modal>
    <template slot="header">
      <h3>プラン選択</h3>
    </template>
    <template slot="body">

          <label class="c-baseModal__controlLabel">プラン</label>
          <div v-for="plan in plans" :key="plan.plan_type">
            <input type="radio" :id="plan.plan_type" :value="plan.plan_type" v-model="selectedPlanType">
            <label class="c-baseModal__optionLabel" :for="plan.plan_type">{{plan.label}}</label>
          </div>

    </template>
    <template slot="footer">
      <button type="button" @click="handleSubmit" class="btn btn-primary" id="js-require-payment">課金してもらう</button>
      <button type="button" @click="$emit('subscriptionRequestCancel')" class="btn btn-link">キャンセル</button>
    </template>
  </base-modal>
</template>

<script>
  import PlanTypes from "@/const/plan_types"
  export default {
    data() {
      return {
        selectedPlanType: PlanTypes.BASIC_PLAN,
        plans: [
          { plan_type: PlanTypes.BASIC_PLAN, label: '相談し放題[医療相談] 980円（税込）' },
          { plan_type: PlanTypes.TRIAL_PLAN, label: '相談し放題[医療相談(初月無料)] 980円（税込）' },
          { plan_type: PlanTypes.KANPO_PLAN, label: '相談し放題[漢方相談] 980円（税込）' },
          { plan_type: PlanTypes.STANDARD_PLAN, label: 'スタンダードプラン 月額7,700円（税込）' },
          { plan_type: PlanTypes.PREMIUM_PLAN, label: 'プレミアムプラン 月額16,500円（税込）' },
        ]
      }
    },
    methods: {
      handleSubmit() {
        this.$emit("onSubscriptionRequest", this.selectedPlanType)

      }
    }
  }
  // import {mapGetters, mapMutations, mapActions} from 'vuex'
  // import {actionTypes} from '../store/modules/users'
  // import UserEnum from '../enums/User'
  //
  // export default {
  //   async created() {
  //     if(this.$route.params.id === this.currentUser.id) {
  //       this.$router.history.push("/consultations")
  //     }
  //     const user = await this.fetchUser({id: this.$route.params.id});
  //     this.$data.form.role = user.role
  //   },
  //   data() {
  //     return {
  //       form: {
  //         role: null
  //       }
  //     }
  //   },
  //   computed: {
  //     roles() {
  //       return  UserEnum.Role
  //     },
  //     ...mapGetters({
  //       currentUser: 'currentUser',
  //       user: 'users/user'
  //     })
  //   },
  //   methods: {
  //     async handleSubmit() {
  //       await this.patchUserRole({id: this.$route.params.id, role: parseInt(this.$data.form.role)});
  //       ({
  //         admin: this.fetchAdmin,
  //         customer_service: this.fetchCustomerService,
  //         doctor: this.fetchDoctor,
  //         patient: this.fetchPatient
  //       })[this.$route.query.role]();
  //       this.$router.history.push(`/management_users?role=${this.$route.query.role}`);
  //     },
  //     ...mapActions({
  //       fetchUser: `users/${actionTypes.FETCH_USER}`,
  //       fetchAdmin: `users/${actionTypes.FETCH_ADMIN}`,
  //       fetchCustomerService: `users/${actionTypes.FETCH_CUSTOMER_SERVICE}`,
  //       fetchDoctor: `users/${actionTypes.FETCH_DOCTOR}`,
  //       fetchPatient: `users/${actionTypes.FETCH_PATIENT}`,
  //       patchUserRole: `users/${actionTypes.PATCH_USER_ROLE}`
  //     })
  //   }
  // }
</script>

