<template>
  <div class="p-healthCheckupSupportSelectType">
    <loading :translucent="true" v-show="loading"></loading>
    <p class="p-healthCheckupSupportSelectType__label">サポートの種類</p>
    <div>
      <select class="p-healthCheckupSupportSelectType__select" id="p-healthCheckupSupportSelectType__medical-departments" v-model="selectedType" @change="fetchAvailableDate()">
        <option disabled value="">サポートの種類を選択してください</option>
        <option v-for="type in types" :value="type" :key="type.id">
          {{ type.name }}
        </option>
      </select>
    </div>
    <p class="p-healthCheckupSupportSelectType__content">健康診断の結果で気になることがあったり、再検査の項目があった時、その結果をご共有いただき、医師とのビデオ通話によって、健康的なライフスタイルをサポートします。</p>

    
    <div class="p-healthCheckupSupportSelectType__footer">
      <button class="c-backButton" @click="$emit('moveToPreviousStep'); $mixpanel.track('CLICK BUTTON', { page: 'サポート種類の選択', buttonName: '戻る', userId: currentUser.id, source: currentUser.source })"
              >戻る
      </button>
      <button class="c-mainButton" @click="addConsultationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: 'サポート種類の選択', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })"
              :disabled="!isFullyInputed">次へ
      </button>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Common/Loading';
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  components: { Loading },
  props: {
    consultationReservationToCreate: {
      required: true,
      type: Object,
    },
  },
  created() {
    this.fetchTypes();
  },
  data: () => {
    return {
      types: [],
      selectedType: {},
      availableDates: [],
      isAvailableDates: false,
      loading: false,
    };
  },
  computed: {
    isTypeSelected() {
      return this.selectedType.id != undefined;
    },
    isFullyInputed() {
      return this.isTypeSelected;
    },
    ...mapGetters(['currentUser'])
  },
  methods: {
    async fetchTypes() {
      this.loading = true
      try {
        let res = await axios.get('/api/health_checkup_support/types');
        this.types = res.data;
      } catch {
        alert('ERROR');
      }
      this.loading = false
    },
    async fetchAvailableDate() {
      this.loading = true
      try {
        const res = await axios.get('/api/health_checkup_support/doctor_schedules', {
          params: {
            available_all_date: true,
            type_id: this.selectedType.id,
          },
        });
        this.setAvailableDates(res.data)
        this.isAvailableDates = true;
      } catch {
        alert('ERROR');
      }
      this.loading = false
    },
    addConsultationData() {
      const data = {
        selectedType: this.selectedType,
        availableDates: this.availableDates,
      };

      this.$emit('addConsultationData', data);
    },
    setAvailableDates(schedules) {
      const availableDates = schedules.map((schedule) => moment(schedule.start).format("YYYY-MM-DD"));
      const uniqueAvailableDates = Array.from(new Set(availableDates))
      this.availableDates = uniqueAvailableDates;
    },
  },
};
</script>
