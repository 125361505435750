import groups from './groups'
import masters from './masters'

export default {
  namespaced: true,
  modules: {
    groups: {
      namespaced: true,
      ...groups
    },
    masters
  }
}
