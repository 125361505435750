import axios from 'axios'
import * as mutationTypes from '../../mutation-types'
import * as actionTypes from '../../action-types'

const state = {
  patientFamilyProfile: {
    medical_dashboard_items: [],
    medical_dashboard_patient_family_profile_categories: [],
  }
};

const getters = {
  medicalDashboardPatientFamilyProfile: state => state.patientFamilyProfile
};

const mutations = {
  [mutationTypes.SET_MEDICAL_DASHBOARD_PATIENT] (state, patientFamilyProfile) {
    state.patientFamilyProfile = patientFamilyProfile
  }
};


const actions = {
  [actionTypes.FETCH_MEDICAL_DASHBOARD_PATIENT] ({ commit }, payload) {
    axios.get(`/api/medical_dashboard/patient_family_profiles/${payload.patientFamilyProfileId}/edit`)
      .then(response => {
        commit(mutationTypes.SET_MEDICAL_DASHBOARD_PATIENT, response.data)
      })
      .catch(response => console.log(response))
  }, [actionTypes.PATCH_MEDICAL_DASHBOARD_PATIENT] ({ commit }, { patientFamilyProfile }) {
    const patientCategories = patientFamilyProfile.medical_dashboard_patient_family_profile_categories;
    axios.patch(`/api/medical_dashboard/patient_family_profiles/${patientFamilyProfile.id}`, {
      patient_family_profile: {
        medical_dashboard_patient_family_profile_categories_attributes: patientCategories.map(pc => {
          return {
            id: pc.id,
            category_id: pc.category_id,
            _destroy: pc._destroy
          }
        }),
        medical_dashboard_items_attributes: patientFamilyProfile.medical_dashboard_items.map(item => {
          return {
            id: item.id,
            item_master_id: item.item_master_id,
            value_float: item.value_float,
            value_integer: item.value_integer,
            value_boolean: item.value_boolean
          }
        })
      }
    }).then(() => {
        commit(mutationTypes.PUSH_NOTICE, { message: '更新されました', type: 'success' });
      })
      .catch(() => {
         commit(mutationTypes.PUSH_NOTICE, { message: '更新に失敗しました', type: 'danger' });
      })
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}

