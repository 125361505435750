<template>
  <base-modal>
    <template slot="header">
      <h3>定型文選択</h3>
    </template>
    <template slot="body">

      <label class="c-baseModal__controlLabel">定型文</label>
      <div v-for="routineMessage in routineMessages" :key="routineMessage.routine_message_type">
        <input type="radio" :id="routineMessage.routine_message_type" :value="routineMessage.routine_message_type" v-model="selectedRoutineMessageType">
        <label class="c-baseModal__optionLabel" :for="routineMessage.routine_message_type">{{routineMessage.label}}</label>
      </div>

    </template>
    <template slot="footer">
      <button type="button" @click="handleSubmit" class="btn btn-primary" id="js-require-payment">投稿</button>
      <button type="button" @click="$emit('routineMessageRequestCancel')" class="btn btn-link">キャンセル</button>
    </template>
  </base-modal>
</template>

<script>
  import RoutineMessageTypes from "@/const/routine_message_types"
  export default {
    data() {
      return {
        selectedRoutineMessageType: RoutineMessageTypes.FIRST_REPLY,
        routineMessages: [
          { routine_message_type: RoutineMessageTypes.FIRST_REPLY, label: '通常医療相談1回目' },
          { routine_message_type: RoutineMessageTypes.FIRST_REPLY_TO_KIREIDO_USER, label: 'キレイドナビ用1回目' },
          { routine_message_type: RoutineMessageTypes.PLAN_REGISTRATION_COMPLETED, label: '有料プラン登録' },
          { routine_message_type: RoutineMessageTypes.HOW_TO_PURCHASE_SUPPLEMENTS, label: 'サプリ購入方法のご案内' },
          { routine_message_type: RoutineMessageTypes.FINISH_REQUEST, label: '終了リクエストを送る（追加相談）' },
          { routine_message_type: RoutineMessageTypes.FINISH_REQUEST_WITH_SATISFACTION_QUESTIONNAIRE, label: '終了リクエストを送る（満足度）' },
        ]
      }
    },
    methods: {
      handleSubmit() {
        this.$emit("onRoutineMessageRequest", this.selectedRoutineMessageType)
      }
    }
  }
</script>
<style scope="scss">
  .modal-body {
    margin: -30px 0;
  }
  .option-label {
    font-weight: normal;
  }
</style>
