<template>
  <base-modal>
    <template slot="header">
      <h3>退会</h3>
    </template>
    <template slot="body">
      <form id="cancel-form" ref="cancelForm" action="/users" method="post">
        <input type="hidden" name="_method" value="delete">
        <div v-for="cancelReason in cancelReasons" :key="cancelReason.id">
          <input type="checkbox" :id="`cancel_reason_${cancelReason.id}`" name="cancel_reason_ids[]" :value="cancelReason.id" v-model="selectedReasons">
          <label :for="`cancel_reason_${cancelReason.id}`">{{cancelReason.reason}}</label>
        </div>
        <textarea v-if="isOtherReasonSelected" name="reason_text" rows="5" v-model="reasonText" placeholder="その他の理由"></textarea>
      </form>
    </template>
    <template slot="footer">
      <button type="button" :disabled="!isSubmitButtonEnabled" @click="handleSubmit" class="execute-button">退会する</button>
      <button type="button" :disabled="isLoading" @click="$emit('closeModal')" class="cancel-button">キャンセル</button>
    </template>
  </base-modal>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        cancelReasons: [],
        selectedReasons: [],
        reasonText: '',
        isLoading: true
      }
    },
    async created() {
      const res = await axios.get('/api/cancel_reasons')
      this.cancelReasons = res.data
      const token = document
        .getElementsByName('csrf-token')[0]
        .getAttribute('content');
      const element = document.createElement('input')
      element.setAttribute('type', 'hidden')
      element.setAttribute('name', 'authenticity_token')
      element.setAttribute('value', token)
      this.$refs.cancelForm.appendChild(element)
      this.isLoading = false
    },
    methods: {
      async handleSubmit() {
        this.isLoading = true
        this.$refs.cancelForm.submit()
      }
    },
    computed: {
      isOtherReasonSelected() {
        const otherReason = this.cancelReasons.find(cancel_reason => cancel_reason.other == true )

        return this.selectedReasons.find(reason_id => reason_id == otherReason.id) != null
      },
      isSubmitButtonEnabled() {
        if (this.isLoading) return false

        if (this.selectedReasons.length == 0) return false

        return  !(this.isOtherReasonSelected && this.reasonText == '')
      }
    }
  }
</script>
<style scoped lang="scss">
  #cancel-form {
    max-width: 250px;
    margin: auto;
    text-align: left;
    margin-bottom: 30px;

    textarea {
      width: 100%;
    }
  }

  .execute-button {
    outline: none;
    border: 1px solid red;
    border-radius: 9999px;
    padding: 8px 16px;
    background: red;
    color: white;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .cancel-button {
    outline: none;
    border: 1px solid #ccc;
    border-radius: 9999px;
    padding: 8px 16px;
    background: none;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

</style>
