<template>
  <div class="upload-box">
    <label for="file">
      <slot></slot>
    </label>
    <input type="file" name="chat_log[image]" id="file" multiple :disabled="$attrs.disabled" accept="image/*" @change="selectedFile">
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  import * as actionTypes from '../../store/action-types'
  import * as mutationTypes from '../../store/mutation-types'

  export default {
    name: 'ChatImageInput',
    computed: {
      ...mapGetters(['chat'])
    },

    methods: {
      selectedFile(e) {
        e.preventDefault();
        Array.prototype.map.call(e.target.files, (image) => {
          this.postChatLogImage({ image, chatId: this.chat.id });
        });
        this.$emit('close')
      },
      ...mapActions({ postChatLogImage: actionTypes.POST_CHAT_LOG_IMAGE }),
    }
  }
</script>

<style scoped lang="scss">
  .upload-box {
    position: relative;

    label {
      display: block;
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  input[type=file] {
    height: 0;
    visibility: hidden;
    position: absolute;
  }

  .chat-camera-btn {
    margin-top: 4px;
  }
</style>
