<template>
  <div class="p-onlineClinicHome">
    <div class="p-onlineClinicHome__info">
      <div class="p-onlineClinicHome__header">
        <p class="p-onlineClinicHome__status">現在のステータス</p>
      </div>

      <div class="p-onlineClinicHome__body">
        <p class="p-onlineClinicHome__title">{{title}}</p>
        <p class="p-onlineClinicHome__comment">しばらくお待ちください </p>
      </div>
      <p class="p-onlineClinicHome__contact-support">診察を受けることができなかった場合など、何か不備がございましたら<a href="/contacts/new">お問い合わせ</a>よりご連絡ください。</p>
    </div>
    <div class="p-onlineClinicHome__inactiveButton">
      診察を予約する
    </div>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="this.$props.chat == null ? null : this.$props.chat.id"
    ></notification-button>

    <a href="/online_clinic/examinations" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '過去の診療', userId: currentUser.id, source: currentUser.source })">
      過去の診察
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">
      会員情報
    </a>
  </div>
</template>


<script>
import NotificationButton from "./buttons/NotificationButton";
import {mapGetters} from "vuex";
export default {
  name: 'BeforeIssuingPrescription',
  components: {
    NotificationButton
  },
  props: ['examination','consultation', 'chat'],
  computed: {
    title() {
      if (this.examination.no_prescription) {
        return 'クリニック処理中です'
      } else {
        return '処方箋を発行中です'
      }
    },
    ...mapGetters(['currentUser'])
  }
};
</script>
