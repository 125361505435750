<script>
  import ResultTextField from './ResultTextField'
  import ResultCheckboxesField from './ResultCheckboxesField'
  import ResultRadioField from './ResultRadioField'
  export default {
    name: 'ResultField',
    props: ['result'],
    computed: {
      component() {
        switch (this.result.interview_type) {
          case 'text':
            return ResultTextField;
          case 'checkboxes':
            return ResultCheckboxesField;
          case 'radio':
            return ResultRadioField;
        }
      }
    },
    render(h) {
      return h(this.component, {props: {result: this.result}})
    }
  }
</script>
