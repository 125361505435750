<template>
    <div class="p-healthCheckupSupportProgressCircle">
    <div class="p-healthCheckupSupportProgressCircle__container">
        <svg class="p-healthCheckupSupportProgressCircle__progressCircle" width="90" height="90">
            <circle class="p-healthCheckupSupportProgressCircle__circle" stroke="lightgrey" stroke-width="4" fill="transparent" r="25" cx="55" cy="30" />
            <circle class="p-healthCheckupSupportProgressCircle__circle" stroke="#00BCD4" stroke-width="4" fill="transparent" r="25" cx="55" cy="30" 
                :stroke-dasharray="circumference"
                :stroke-dashoffset="offset" />

            <svg x="17" y="20" width="30" height="30">
                <line x1="5" y1="25" x2="25" y2="5" stroke="black" stroke-width="0.5" />
                <text x="4" y="15" font-size="14" font-weight="500" fill="#00BCD4">{{ currentStep }}</text>
                <text x="18" y="24" font-size="10">6</text>
            </svg>
        </svg>
    </div>
    <p class="p-healthCheckupSupportProgressCircle__title">{{titleTexts[currentStep -1]}}</p>
</div>

</template>

<script>
export default {
    props: ['currentStep'],
    data() {
        return {
            radius: 25,
            titleTexts: [
                '相談者の選択',
                'サポート種類の選択',
                '予約日時の選択',
                '問診の回答',
                '予約の確認',
                '予約完了',
            ],
        };
    },
    computed: {
        circumference() {
            return 2 * Math.PI * this.radius;
        },
        offset() {
            return this.circumference * (1 - this.currentStep / 6);
        },

    }
};
</script>
