<template>
  <div>
    <div v-if="isLoading" class="v-centering">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
  import { LOADING, LOADING_QUERY } from '../../store/loading'

  export default {
    name: 'BaseLoadingIcon',
    props: ['target'],
    computed: {
      isLoading() {
        return LOADING === this.target || LOADING_QUERY === this.target
      }
    }
  }
</script>

<style scoped lang="scss">
  .v-centering {
    text-align: center;

    .fa-spinner {
      color: #00acff;
    }
  }
</style>
