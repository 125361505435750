<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header">
        お支払い方法の確認
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>

      <div class="c-mainModal__content">
        <p class="p-healthCheckupSupportReservationModal__mainText">まだご予約は確定していません</p>
        <p class="p-healthCheckupSupportReservationModal__subText">内容をご確認の上、確定ボタンを押してください</p>
        <p>
        <div class="p-healthCheckupSupportReservationModal__box">
        <p>お支払い方法</p>
        <p>クレジットカード決済</p>
        <p>登録済みカード暗号<img :src="visaLogo" alt="visa" style="height: 15px"/>
          <img :src="masterCardLogo" alt="masterCard" style="height: 29px"/>
          <img :src="amexLogo" alt="amex" style="height: 19px"/>
        <p>XXXX XXXX XXXX {{creditCardLast4}}
        </p>
        <p class="p-healthCheckupSupportReservationModal__msg">クレジットカードを変更の場合、<router-link class="c-link p-healthCheckupSupportReservationModal__msg" to="/patient_family_profiles" @click.native="$mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答（2）', buttonName: 'こちら', userId: currentUser.id, source: currentUser.source })">会員情報</router-link>から変更ください</p>
        <div class="p-healthCheckupSupportReservationModal__serviceCharge">
          <span class="p-healthCheckupSupportReservationModal__serviceChargeTag">健診サポート1回</span>
          <p>7,700<span>円（税込）</span></p>
        </div>
        </div>
      <p class="p-healthCheckupSupportReservationModal__noticeTitle">キャンセルポリシー</p>
      <div class="p-healthCheckupSupportReservationModal__notice">
        <p>・キャンセルは予約開始の24時間前までとなります。24時間を切りますとキャンセルできません。<br>
・日程変更につきましては、予約キャンセル完了後に再度ご予約ください。<br>
・予約キャンセル時のキャンセル料は頂きませんが、必ずキャンセルのお手続きをお願いします。</p>
      </div>
      <button class="c-mainButton" @click="submit" :disabled="submitDisabled">予約の確定</button>
      </div>
    </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>

</template>

<script>

import amexLogo from '@/../assets/images/cards/amex.gif';
import visaLogo from '@/../assets/images/cards/visa.png';
import masterCardLogo from '@/../assets/images/cards/master_card.svg';
import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '@/store/action-types';

export default {
  computed: {
    amexLogo: () => amexLogo,
    visaLogo: () => visaLogo,
    masterCardLogo: () => masterCardLogo,
    jcbLogo: () => jcbLogo,
    ...mapGetters(['currentUser']),
    creditCardLast4() {
      return this.currentUser.default_card.last4
    },
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      submitDisabled: false,
    };
  },
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
    submit() {
      this.submitDisabled = true;
      this.$emit('create-reservation')
      this.$mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答（2）', buttonName: '予約を確定する', userId: this.currentUser.id, source: this.currentUser.source, isCvButton: true });
      this.submitDisabled = false;
    },
    backPatientFamilyProfiles() {
      this.$router.push(`/patient_family_profiles`);
    },
    ...mapActions({ postSAuthorization: actionTypes.POST_AUTHORIZATION, updateAuthorization: actionTypes.UPDATE_AUTHORIZATION }),
  },
};
</script>

