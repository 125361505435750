<template>
  <span class="send-at">
    {{isReadedText}} {{sendAt| moment('YYYY-MM-DD HH:mm')}}
    <i class="fa fa-trash-o fa-lg" aria-hidden="true" @click="performDelete()" v-if="this.isAdmin || this.isCustomerService"></i>
  </span>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as actionTypes from '../../store/action-types'
  import * as mutationTypes from '../../store/mutation-types'

  export default {
    name: 'ChatSendAt',
    props: ['sendAt', 'chatLog', 'isReadedTextDisplay'],
    computed: {
      ...mapGetters(['isAdmin', 'isCustomerService', 'patientReadMessage', 'isNotPatient']),
      isReadedText () {
        if (this.patientReadMessage) {
          if (this.isNotPatient && this.isReadedTextDisplay && this.chatLog.created_at < this.patientReadMessage.read_at) {
            return '既読'
          } else {
            return ''
          }
        }
      },
    },
    methods: {
      performDelete() {
        if( window.confirm('本当に削除していいですか？') ) {
          this.deleteChatLog({ chatLogId: this.chatLog.id, chatId: this.chatLog.chat_id});
        }
      },
      ...mapActions({ deleteChatLog: actionTypes.DELETE_CHAT_LOG }),
    },
  }
</script>

<style scoped="scss">
  .send-at {
    color: darkgrey;
  }
</style>
