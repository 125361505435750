<template>
  <div v-if="isShow" class="chat-log-content">
    <base-nl2-br tag="p" :text="chatLogContent" :textFilter="spanWrap" v-if="!isFinishRequest && !isSatisfactionRequest"/>
    <chat-log-finish-buttons :chat-log="chatLog" v-if="isFinishRequest"/>
    <chat-log-satisfaction-buttons :chat-log="chatLog" v-if="isSatisfactionRequest"/>
  </div>
</template>

<script>
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import UserEnum from '../../enums/User'
  import BaseNl2Br from '../base/BaseNl2Br'
  import ChatLogFinishButtons from './ChatLogFinishButtons'
  import ChatLogSatisfactionButtons from './ChatLogSatisfactionButtons'
  import { urlWithDeviceOption } from '../../shared/Helper'
  import {mapGetters} from "vuex";
  import ChatLogMessageBlurred from './ChatLogMessageBlurred'

  const urlRegex = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

  export default {
    name: 'ChatLogMessage',
    props: ['chatLog', 'itemType'],
    components: { BaseNl2Br, ChatLogFinishButtons, ChatLogMessageBlurred, ChatLogSatisfactionButtons },
    methods: {
      spanWrap(createElement, _) {
        return function(text) {
          const stringArray = text.split(/( )/g);
          const elements = stringArray.map((string) => {
            if(string.slice(0,15) == '<button-create>') {
              try {
                const obj = JSON.parse(string.replace(/<button-create>/g, ''))
                const name = obj.name
                const url = obj.url
                return createElement('a', { domProps: urlWithDeviceOption(url), class: 'custom-btn' }, name) ;
              } catch (e) {
                console.log(e)
                return string
              }
            } else if (urlRegex.test(string)){
              return createElement('a', { domProps: urlWithDeviceOption(string) }, string) ;
            } else {
              return string
            }
          });
          return elements
        }.bind(this)
      }
    },
    computed: {
      isShow () {
        return this.itemType === ChatLogEnum.ChatItemTypes.MESSAGE
      },
      isShowLeadToSubscription() {
        return this.itemType === ChatLogEnum.ChatItemTypes.MESSAGE &&
          this.isPatient &&
          this.isFromDoctor &&
          !this.isSubscribed
      },
      isFromDoctor () {
        return this.chatLog.sender_role == UserEnum.Role.doctor
      },
      isSubscribed() {
        return this.currentUser.is_subscribe
      },
      isFinishRequest() {
        return this.chatLog.log_type === ChatLogEnum.logType.finish_request && this.isPatient && !this.chatLog.is_satisfaction_request
      },
      isSatisfactionRequest() {
        return this.chatLog.is_satisfaction_request
      },
      ...mapGetters(['currentUser', 'isPatient']),
      chatLogContent () {
        if (this.chatLog.content) {
          return this.chatLog.content.replace(/\u2028/g, "")
        } else {
          return null
        }
      }
    }
  }

</script>

<style scoped>
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .custom-btn {
    display: block;
    color: #fff;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 11px 10px;
    margin: 10px 0;
    border-radius: 50px;
    text-decoration: none;
  }
</style>
