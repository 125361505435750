<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header">
        相談をはじめる
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>

      <div class="c-mainModal__content">
        <h3>
          医療相談
        </h3>
        <p>あなたの気になる症状や悩みに合わせて24時間365日いつでも女性医師にチャットで相談</p>
        <p><strong>月額980円（税込）<br>連携企業経由のご利用の場合、初月無料</strong></P>
        <router-link :to=" { name: 'online_consultation_consultations_new', query: { consultationType: 1 } }" class="c-mainButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '相談をはじめる', buttonName: '医療相談', userId: currentUser.id, source: currentUser.source, isCvButton: true })">医療相談</router-link>
      </div>
      <div class="c-mainModal__content">
        <h3>
          サプリ相談
        </h3>
        <p>あなたの体の状態を5段階で回答、結果からお悩みに合わせた最適なサプリを提案</p>
        <p><strong>無料、相談後にサプリの購入ができます</strong></p>
        <a href="/supplement_outpatient/interview" class="c-mainButton" @click="$mixpanel.track('CLICK BUTTON', { page: '相談をはじめる', buttonName: 'サプリ相談', userId: currentUser.id, source: currentUser.source, isCvButton: true })">サプリ相談</a>
      </div>
      <div class="c-mainModal__bottom">
      </div>
    </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
  },
};
</script>
