<template>
  <span>
    <button class="btn finish-request-btn" :class="[isGood, isInactive]" :disabled="isDisabled" @click="handlePatchFinishRequest({ isSatisfied: true })">👍</button>
    <button class="btn finish-request-btn" :class="[isBad, isInactive]" :disabled="isDisabled" @click="handlePatchFinishRequest({ isSatisfied: false })">👎</button>
  </span>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import * as actionTypes from '../../store/action-types'

  export default {
    name: 'ChatLogFinishButtons',
    props: ['chatLog'],
    data() {
      return {
        isPatchRequestInProgress: false,
      };
    },
    methods: {
      ...mapActions({ patchFinishRequest: actionTypes.PATCH_FINISH_REQUEST, deleteFinishRequest: actionTypes.DELETE_FINISH_REQUEST }),
      async handlePatchFinishRequest({ isSatisfied }) {
        this.isPatchRequestInProgress = true;

        try {
          await this.patchFinishRequest({ chat: this.chat, chatLog: this.chatLog, isSatisfied });
        } catch {
          alert('ERROR');
        } finally {
          this.isPatchRequestInProgress = false;
        }
      }
    },
    computed: {
      isPatient() {
        return this.chat.patient_id === this.currentUser.id
      },
      isGood () {
        return this.chat.consultation.is_satisfied === true ? 'selected' : ''
      },
      isBad () {
        return this.chat.consultation.is_satisfied === false ? 'selected' : ''
      },
      isInactive () {
        return this.isDisabled ? 'inactive' : ''
      },
      isDisabled () {
        return this.isPatchRequestInProgress || !(this.chat.consultation.is_satisfied === null && this.isPatient);
      },
      ...mapGetters(['currentUser', 'chat'])
    }
  }
</script>

<style scoped lang="scss">
  span {
    display: block;
  }
  .finish-request-btn{
    width: 80px;
    color: #2196f3;
    background: #fff;
    border: 1px solid #2196f3;
    font-size: 20px;
    text-align: center;
    padding: 4px 0;
    margin: 5px auto;
    border-radius: 30px;
    text-decoration: none;
    cursor : pointer;
  }

  .selected {
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    border: none;
  }

  .inactive {
    cursor : default;
    opacity: 0.8;
  }

</style>
