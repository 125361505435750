<template>
  <div>
    <multiselect v-model="selectedGroups"
                 tag-placeholder="Add this as new tag"
                 placeholder="Search or add a tag"
                 label="name"
                 track-by="id"
                 :options="groups.map(g => g)"
                 :multiple="true"
                 :taggable="true">

    </multiselect>
    <base-select-field name="interview_type" v-model="masterForm.interview_type" label-text="質問形式">
      <option value="text">テキスト</option>
      <option value="checkboxes">チェックボックス</option>
      <option value="radio">ラジオボタン</option>
      <option value="file">ファイルアップロード</option>
    </base-select-field>
    <base-text-area
      v-model="masterForm.message"
      label-text="メッセージ"
      :errors="errors.message"
      name="master_message"
    />
    <base-check-box-field
      v-model="masterForm.is_publishing"
      label-text="公開中"
      name="master_is_publishing"
    />
    <div v-show="haveItems" class="panel panel-default">
      <draggable element="div" class="panel-body" v-model="masterItemsOrder">
        <chat-interview-master-item-field v-for="(masterItem, idx) in masterForm.master_items"
                                          :key="masterItem.id || masterItem.vueKey"
                                          :master-item="masterItem"
                                          :errors="(errorsChildren['master_items'] || [])[idx]">
        </chat-interview-master-item-field>
      </draggable>
      <div class="panel-footer">
        <button @click="pushMasterItem" type="button" class="btn btn-info">選択肢の追加</button>
      </div>
    </div>
    <slot name="submit"></slot>
  </div>
</template>

<script>
  import { v1 as uuidv1 } from 'uuid';
  import {mapActions, mapGetters} from 'vuex';
  import * as Groups from '../../store/modules/chatInterview/groups';
  import ChatInterviewMasterItemField from "./MasterItemField";
  export default {
    name: 'ChatInterviewMasterForm',
    components: {ChatInterviewMasterItemField},
    props: ['masterForm', 'errors', 'errorsChildren'],
    created() {
      this.fetchGroups();
    },
    computed: {
      haveItems() {
        return this.masterForm.interview_type === 'checkboxes' ||
          this.masterForm.interview_type === 'radio'
      },
      masterItemsOrder: {
        get() {
          return this.$props.masterForm.master_items;
        },
        set(value) {
          this.$props.masterForm.master_items = value.map((v, i) => { return { ...v, position: i }});
        }
      },
      selectedGroups: {
        get() {
          return this.$props.masterForm.group_ids.map(groupId => this.groups.find(g => g.id === Number(groupId)))
        },
        set(values) {
          this.$props.masterForm.group_ids = values.map(v => v.id)
        }
      },
      ...mapGetters({groups: `chatInterview/groups/query`}),
    },
    methods: {
      pushMasterItem() {
        this.$props.masterForm.master_items = [
          ...this.$props.masterForm.master_items,
          {
            label: '',
            is_supplement_text: false,
            is_publishing: true,
            position: this.$props.masterForm.master_items.length,
            vueKey: uuidv1()
          }
        ]
      },
      ...mapActions({fetchGroups: `chatInterview/groups/${Groups.actionTypes.FETCH_QUERY}`})
    }
  }
</script>
