<template>
  <div :class="className"  role="alert">
     {{notice.message}}
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import * as mutationTypes from '../store/mutation-types'
  export default {
    name: 'NoticeAlert',
    props: ['notice'],
    created () {
      const removeNotice = this.removeNotice;
      const notice = this.notice;
      setTimeout(function() {
        removeNotice(notice)
      }, 5000);
    },
    computed: {
      className () {
        return `alert alert-${this.notice.type} notice-alert`
      }
    },
    methods: {
      ...mapMutations({
        removeNotice: mutationTypes.REMOVE_NOTICE
      })
    }
  }
</script>

<style scoped>
  .notice-alert {
    position: sticky;
    top: 70px;
    left: 0;
    right: 0;
    transform: none;
    width: 100%;
  }
</style>
