<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h1>
              患者情報
            </h1>
          </div>
          <base-loading-icon :target="patientFamilyProfile" class="modal-body">
            <base-detail-item label="ID">
              {{patientFamilyProfile.user_id}}
            </base-detail-item>
            <base-detail-item label="性別">
              {{patientFamilyProfile.sex === 'female' ? '女性' : '男性'}}
            </base-detail-item>
            <base-detail-item label="年齢">
              {{patientFamilyProfile.age}}
            </base-detail-item>
            <base-detail-item label="名前">
              {{patientFamilyProfile.first_name}}
              {{patientFamilyProfile.last_name}}
            </base-detail-item>
            <base-detail-item label="誕生日">
              {{patientFamilyProfile.birthday | moment}}
            </base-detail-item>
            <base-detail-item v-if="patientFamilyProfile.sex === 'female'" label="妊娠">
              {{isPregnancy }}
            </base-detail-item>
            <base-detail-item label="相談件数">
              {{ chat.consultation.total_consultation_count }}件
            </base-detail-item>
            <br>
            <base-detail-item label="SR">
              {{ chat.patient.source || '情報なし' }}
            </base-detail-item>
            <base-detail-item label="プラン">
              {{ chat.patient.subscripting_plan || '未登録' }}
            </base-detail-item>
            <base-detail-item label="課金状況">
              {{ chat.patient.is_subscribe ? '課金中' : '非課金' }}
            </base-detail-item>
            <base-detail-item label="クレジット登録">
              {{ chat.patient.is_creditcard_registered ? '登録済' : '未登録' }}
            </base-detail-item>
            <base-detail-item label="メールアドレス">
              {{ chat.patient.email }}
            </base-detail-item>
            <base-detail-item v-if="!!patientFamilyProfile.demecal_id" label="DEMECAL_ID">
              {{patientFamilyProfile.demecal_id}}
            </base-detail-item>
            <base-detail-item v-if="!!patientFamilyProfile.tracking_number" label="お問い合わせ番号">
              {{patientFamilyProfile.tracking_number}}
            </base-detail-item>
            <interview-result v-for="interviewResult in patientFamilyProfile.interview_results"
                              :interview-result="interviewResult"
                              :key="interviewResult.id">
            </interview-result>
            <a v-if="!!patientFamilyProfile.tracking_number"
               :href="`/medical_dashboard/patient_family_profiles/${patientFamilyProfile.id}`"
               class='btn btn-info btn-xs'>
              メディカルダッシュボード
            </a>
          </base-loading-icon>
          <div class="modal-footer">
            <button class="modal-default-button btn btn-default" @click="historyBack">
              戻る
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import * as patientFamilyProfiles from '../store/modules/doctor/patientFamilyProfiles'
  import BaseLoadingIcon from './base/BaseLoadingIcon'
  import moment from 'moment'
  import InterviewResult from "./doctor/PatientFamilyProfileModal/InterviewResult";
  import BaseDetailItem from "./base/BaseDetailItem";

  export default {
    name: 'PatientFamilyProfileModal',
    components: {BaseDetailItem, InterviewResult, BaseLoadingIcon },
    created() {
      if (this.patientFamilyProfileId) {
        this.fetchPatientFamilyProfile({ id: this.patientFamilyProfileId })
      }
    },
    destroyed() {
      this.refreshPatientFamilyProfile()
    },
    computed: {
      patientFamilyProfileId() {
        if(this.$route.params.consultation_id) {
          return this.consultation ? this.consultation.patient_family_profile.id : null
        } else {
          return this.chat ? this.chat.patient_family_profile.id : null
        }
      },
      consultation() {
        return this.findConsultation(Number(this.$route.params.consultation_id))
      },
      isPregnancy() {
        if (this.patientFamilyProfile.pregnancy === 'yes'){
          return 'している';
        } else if (this.patientFamilyProfile.pregnancy === 'no'){
          return 'していない';
        } else {
          return '';
        }
      },
      ...mapGetters(['findConsultation', 'chat']),
      ...mapGetters({
        patientFamilyProfile: 'doctor/patientFamilyProfiles/get'
      })
    },
    methods: {
      historyBack() {
        this.$router.go(-1)
      },
      ...mapActions({
        fetchPatientFamilyProfile: `doctor/patientFamilyProfiles/${patientFamilyProfiles.actionTypes.FETCH_GET}`
      }),
      ...mapMutations({
        refreshPatientFamilyProfile: `doctor/patientFamilyProfiles/${patientFamilyProfiles.mutationTypes.REFRESH_GET}`
      })
    },
    filters: {
      moment (date) {
        return moment(date).format('YYYY/MM/DD');
      }
    },
    watch: {
      chat() {
        this.fetchPatientFamilyProfile({ id: this.chat.patient_family_profile.id })
      },
      consultation () {
        this.fetchPatientFamilyProfile({ id: this.consultation.patient_family_profile.id })
      }
    }
  }
</script>

<style scoped lang="scss">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;

      .modal-container {
        width: 300px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;

        @media screen and (min-width:520px)  {
          width: 700px;
        }

        .modal-header h3 {
          margin-top: 0;
          color: #42b983;
        }

        .modal-body {
          margin: 20px 0;
        }

        .modal-default-button {
          float: right;
        }

        .modal-enter {
          opacity: 0;
        }

        .modal-leave-active {
          opacity: 0;
        }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  }
</style>
