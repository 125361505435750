<template>
  <div>
    <div class="map" ref="googleMap" />
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
import anamnePin from '@/../assets/images/google_map/anamne.svg';
import otherPin from '@/../assets/images/google_map/other.svg';

export default {
  name: 'GoogleMap',
  props: {
    lat: Number,
    lng: Number,
    pharmacies: Array,
    isDefaultPin: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      map: null,
      google: null,
      mapConfig: {
        center: {
          lat: this.lat,
          lng: this.lng
        },
        zoom: 12,
        styles: [
          {
            featureType: "poi",
            stylers: [
              { visibility: "off" }
            ]
          },
        ]
      }
    }
  },
  async mounted() {
    this.google = await GoogleMapsApiLoader({
      apiKey: 'AIzaSyCz4oJ8UcC7BwnuaYgk0wBIPCQvvGpbrHI'
    });
    this.initializeMap();
    this.addMarker();
  },
  methods: {
    initializeMap() {
      this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);
    },
    addMarker() {
      this.pharmacies.forEach(v => {
        const defaultMapOption = {
          position: {
            lat: parseFloat(v.lat),
            lng: parseFloat(v.lng)
          },
          map: this.map
        }
        const additionalMapOption = (() => {
          if (this.isDefaultPin) return {}
          const pin = v.is_alliance ? anamnePin : otherPin
          return {
            icon: {
              url: pin,
              scaledSize: new this.google.maps.Size(22, 30)
            }
          }
        })()
        const marker = new this.google.maps.Marker({ ...defaultMapOption, ...additionalMapOption })

        let infoWindow = new this.google.maps.InfoWindow({
          content: `<div><p>${v.name}</p><p>${v.address}</p></div>`
        })
        marker.addListener('click', function(){
          infoWindow.open(this.map, marker)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 300px;
}
</style>
