<template>
  <div v-if="isShow" class="chat-log-content">
    <p>
      <base-nl2-br tag="p" :text="chatLog.content" :textFilter="() => false"/>
    </p>
    <router-link :to="`/chat/${chatLog.chat_id}/subscription?plan_type=${chatLog.plan_type}`" class="subscription-btn" v-if="!isComplete" :disabled="!isPatient">
      プラン登録し相談を続ける
    </router-link>
    <p v-else class="completedText">
      プラン登録済みです
    </p>
  </div>
</template>

<script>
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import {mapGetters} from 'vuex'
  import BaseNl2Br from '../base/BaseNl2Br'

  export default {
    name: 'ChatLogSubscriptionRequest',
    props: ['chatLog', 'itemType'],
    components: {BaseNl2Br},
    computed: {
      isShow () {
        return this.itemType === ChatLogEnum.ChatItemTypes.SUBSCRIPTION_REQUEST
      },
      isComplete() {
        return this.isPatient ? this.currentUser.is_subscribe : this.chat.patient.is_subscribe
      },
      ...mapGetters(['currentUser', 'chat', 'isPatient'])
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0;
  }

  a[disabled]{
    pointer-events: none;
  }

  .completedText {
    margin: 16px 0 0 0;
    font-weight: bold;
  }

  .subscription-btn {
    display: block;
    color: #fff;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 11px 10px;
    margin: 10px 0;
    border-radius: 50px;
    text-decoration: none;
  }
</style>

