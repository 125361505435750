<template>
  <router-view v-if="Object.keys(currentUser).length"></router-view>
</template>

<script>
import * as actionTypes from './store/action-types';
import { mapGetters } from 'vuex';
export default {
  created: function() {
    this.$store.dispatch(actionTypes.FETCH_CURRENT_USER);
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>
