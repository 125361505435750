<template>
  <span v-if="isShow">
    <button class="btn delete-finish-request-btn" @click="deleteFinishRequest({ chat: chat, chatLog: chatLog })">相談する</button>
    <button class="btn patch-finish-request-btn" @click="patchFinishRequest({ chat: chat, chatLog: chatLog })">終了する</button>
  </span>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import * as actionTypes from '../../store/action-types'

  export default {
    name: 'ChatLogFinishButtons',
    props: ['chatLog'],
    methods: {
      ...mapActions({ patchFinishRequest: actionTypes.PATCH_FINISH_REQUEST, deleteFinishRequest: actionTypes.DELETE_FINISH_REQUEST })
    },
    computed: {
      isPatient() {
        return this.chat.patient_id === this.currentUser.id
      },
      isShow () {
        return this.chatLog.log_type === ChatLogEnum.logType.finish_request && this.isPatient
      },
      ...mapGetters(['currentUser', 'chat'])
    }
  }
</script>

<style scoped lang="scss">
  span {
    display: block;
  }
  .delete-finish-request-btn{
    width: 100px;
    color: #2196f3;
    background: #fff;
    border: 1px solid #2196f3;
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
    margin: 5px auto;
    border-radius: 30px;
    text-decoration: none;
  }
  .patch-finish-request-btn{
    width: 100px;
    color: #fff;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
    margin: 5px auto;
    border: none;
    border-radius: 50px;
    text-decoration: none;
  }

</style>
