<template>
  <div :id="`cy-chat-log-${chatLog.id}`" class="chat-talk chat-log-list-item">
    <div class="content-line">
      <span class="chat-log-user-icon">
        <img :src="iconUrl" alt="user icon" />
      </span>
      <div class="vue-clearfix">
        <slot name="chat-content" />
      </div>
    </div>
    <chat-send-at :send-at="chatLog.created_at" :chat-log="chatLog" :isReadedTextDisplay="true"></chat-send-at>
    <div v-for="url in chatLog.urls" class="vue-clearfix ogp-box-wrap">
      <chat-log-ogp :url="url" :chat-log="chatLog" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ChatLogOgp from './ChatLogOgp'
  import ChatSendAt from './ChatSendAt'

  export default {
    name: 'OthersLayout',
    props: ['chatLog', 'patientImage', 'isInterviewFinished'],
    components: { ChatLogOgp, ChatSendAt },
    computed: {
      iconUrl () {
        if (this.isInterviewFinished) {
          if (this.patientImage) {
            return this.patientImage
          } else {
            return document.getElementById('default-chat-icon').getAttribute('data-url')
          }
        } else {
          if (this.chatLog.icon_url) {
            return this.chatLog.icon_url
          } else {
            return document.getElementById('default-chat-icon').getAttribute('data-url')
          }
        }
      },
      ...mapGetters(['currentUser'])
    }
  }
</script>

<style scoped>
  .content-line {
    display:flex;
    width:100%;
  }

  .vue-clearfix {
    width:auto;
    margin: 0 12% 0 0;
  }

  .vue-clearfix:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }

  .chat-log-list-item >>> .chat-log-content {
    margin-left: 10px;
    margin-bottom: 10px;
    border-bottom-left-radius: 0;
    word-break: break-word;
  }

  .chat-log-list-item >>> .chat-log-interview {
    border-bottom-left-radius: 0;
  }

  .chat-log-user-icon {
    flex-shrink: 0;
    align-items: flex-end;
    display: flex;
    margin: 0 10px 10px 0;
    width: 50px;
  }

  .chat-log-user-icon img {
    object-fit: cover;
    max-width: 100%;
    width: 50px;
    height: 50px;
    vertical-align: bottom;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  .ogp-box-wrap {
    margin-left: 55px;
  }

  span.send-at {
    margin: 0 0 0 70px;
  }
</style>
