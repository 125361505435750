<template>
  <div class="radio">
    <input :id="thisName" :checked="value === checkedValue" @change="onChange" type="radio" :value="checkedValue">
    <label :for="thisName">
      {{labelText}}
    </label>
  </div>
</template>

<script>
  export default {
    name: 'BaseRadioField',
    props: ['name', 'labelText', 'value', 'checkedValue'],

    data () {
      return { checked: this.value }
    },

    computed: {
      thisName() {
        return `${this.name}_${parseInt(Math.random() * 1000)}`
      }
    },

    methods: {
      onChange(_) {
        this.checked = !this.value;
        this.$emit('input', this.checkedValue)
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
