<template>
  <ul class="nav nav-tabs nav-justified sub-nav">
    <slot></slot>
  </ul>
</template>

<script>
  export default {

  }
</script>
