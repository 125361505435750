<template>
  <li :class="{ active: isActive }" role="presentation">
    <slot></slot>
  </li>
</template>

<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        require: true
      }
    }
  }
</script>
