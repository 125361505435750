<template>
  <a v-if="ogp" class="ogp-box" v-bind:href="href" target="_blank">
    <div class="ogp-thumb" :style="thumbStyle">
      <img v-show="ogp.image_url === undefined" src="../../../assets/images/online_consultation/ogp_default.png"/>
    </div>
    <div class="ogp-body">
      <div class="ogp-title">{{ogp.title}}</div>
      <div class="ogp-description">{{ogp.description}}</div>
      <div class="ogp-link">{{this.url}}</div>
    </div>
  </a>
  <div v-else />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes from '../../store/action-types'
  import { urlWithDeviceOption } from '../../shared/Helper'

  export default {
    name: 'ChatLogOgp',
    props: ['chatLog', 'url'],
    created: function() {
      this.fetchOgp({ chatLog: this.chatLog, url: this.url })
    },
    methods: {
      ...mapActions({ fetchOgp: actionTypes.FETCH_OGP })
    },
    computed: {
      ogp () {
        return this.chatLog.ogps.find(ogp => ogp.url === this.url)
      },
      thumbStyle () {
        if (this.ogp.image_url !== undefined) {
          return `background-image: url('${this.ogp.image_url}')`
        } else {
          return `display: flex; justify-content: center; align-items: center; padding-bottom: 0`
        }
      },
      href () {
       return urlWithDeviceOption(this.url).href
      },
      ...mapGetters(['currentUser'])
    }
  }
</script>

<style scoped lang="scss">
  .ogp-box {
    max-width: 85%;
    min-height: 100px;
    max-height: 160px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #eee;
    .ogp-thumb {
      background-color: #888;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      box-sizing: border-box;
      margin-right: -1px;
      min-height: 112px;
      min-width: 100px;
      padding-bottom: 20%;
      color: white;
      text-align: center;
    }
    img {
      width: 80px;
      height: auto;
    }
  }

  .ogp-body {
    padding: 10px 12px;
    color: #111;
    word-break: break-word;
  }

  .ogp-title {
    line-height: 18px;
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
  }

  .ogp-description {
    line-height: 16px;
    max-height: 80px;
    overflow: hidden;
  }

  .ogp-link {
    font-size: 12px;
    color: #aaa;
  }
</style>
