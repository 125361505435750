import { render, staticRenderFns } from "./InterviewTypeIcon.vue?vue&type=template&id=5354c686&scoped=true&"
import script from "./InterviewTypeIcon.vue?vue&type=script&lang=js&"
export * from "./InterviewTypeIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5354c686",
  null
  
)

export default component.exports