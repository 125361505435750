import axios from 'axios'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'
import User from '../../enums/User'

const state = {
  currentUser: {},
  patientFamilyProfiles: [],
  isFromOuchiByouinApp: false
};

const getters = {
  currentUser: state => state.currentUser,
  patientFamilyProfiles: state => state.patientFamilyProfiles,
  isPatient: state => state.currentUser.role === User.Role.patient,
  isAdmin: state => state.currentUser.role === User.Role.admin,
  isCustomerService: state => state.currentUser.role === User.Role.customerService,
  isNotPatient: state => state.currentUser.role === User.Role.admin || state.currentUser.role === User.Role.doctor || state.currentUser.role === User.Role.customerService,
  isFromOuchiByouinApp: state => state.isFromOuchiByouinApp
};

const actions = {
  [actionTypes.FETCH_CURRENT_USER] ({ commit }) {
    axios.get('/api/current_user')
      .then(response => commit(mutationTypes.SET_CURRENT_USER, response))
      .catch(_response => alert("ERROR"))
  },
  [actionTypes.POST_SUBSCRIPTION] ({state, commit}, {sourceToken, selectedPlanType}) {
    return axios.post('/api/subscriptions', { subscription: { source_token: sourceToken, selected_plan_type: selectedPlanType } })
      .then(response => {
        commit(mutationTypes.SET_CURRENT_USER, response)
        return response
      })
      .catch(({ response }) => {
        commit(mutationTypes.PUSH_NOTICE, { message: response.message, type: 'danger' }, { root: true })
        return response
      })
  },
  [actionTypes.POST_AUTHORIZATION] ({state, commit}, sourceToken) {
    return axios.post('/api/creditcard', { authorization: { source_token: sourceToken } })
      .then(response => commit(mutationTypes.SET_CURRENT_USER, response))
      .catch(({response}) =>
        commit(mutationTypes.PUSH_NOTICE, { message: response.message, type: 'danger' }, {root: true})
      )
  },
  [actionTypes.UPDATE_AUTHORIZATION] ({state, commit}, sourceToken) {
    return axios.patch('/api/creditcard', { authorization: { source_token: sourceToken } })
      .then(response => commit(mutationTypes.SET_CURRENT_USER, response))
      .catch(({response}) =>
        commit(mutationTypes.PUSH_NOTICE, { message: response.message, type: 'danger' }, {root: true})
      )
  },
  [actionTypes.PATCH_CURRENT_USER]({ state, commit }, currentUserParams) {
    let message = ''

    return axios.patch('/users', currentUserParams)
      .then(response => {
        if (response.data.unconfirmed_email) {
          message = '本人確認用のメールが' + response.data.unconfirmed_email + 'に送られました。メール内のリンクからアドレスの変更を完了してください'
        } else {
          message = 'ユーザー情報を更新しました。'
        }
        commit(
          mutationTypes.PUSH_NOTICE,
          { message: message, type: 'success' },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response.data.errors) {
          let errorMessage = error.response.data.errors
          errorMessage.forEach(function (element) {
            message = message + '・' + element + ' '
          });
        } else {
          message = '入力情報が正しくありません。'
        }
        commit(mutationTypes.PUSH_NOTICE, { message: message, type: 'danger' }, { root: true })
      })
  },
  [actionTypes.CHECK_USER_AGENT] ({state, commit}) {
    commit(mutationTypes.SET_IS_FROM_OUCHI_BYOUIN_APP, navigator.userAgent.includes('ouchi-byouin-app'));
  },
};

const mutations = {
  [mutationTypes.SET_CURRENT_USER] (state, response) {
    state.currentUser = response.data
  },
  [mutationTypes.SET_IS_FROM_OUCHI_BYOUIN_APP] (state, value) {
    state.isFromOuchiByouinApp = value
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
