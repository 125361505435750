<template>
  <div id="timeline" class="p-chat">
    <base-reload-link v-if="cableStatus === 'DISCONNECTED'">
      <div class='alert alert-danger' role="alert">
          <span>
            <i class="fa fa-refresh" />
            画面を更新してください
          </span>
      </div>
    </base-reload-link>
    <div class="p-chat__header">
      <div class="p-chat__idDisplay" v-if="!this.isPatient">ID：{{chat.consultation_id}}</div>
      <div v-if="!this.isPatient">診療科目：
        <select v-model="selectedConsultationMedicalDepartment" @change="updateConsultation()">
          <option v-for="cmd in consultationMedicalDepartments" :value="cmd" :key="cmd.id">
            {{ cmd.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-for="chatLog in chat.chat_logs.filter(cl => cl.chat_id === parseInt($route.params.chat_id))">
      <div class="p-chat__unreadLabel" v-if="unreadChatLogHead.id === chatLog.id">ここから未読メッセージ</div>
      <chat-log-list-item v-bind:key="chatLog.id" v-bind:chat-log="chatLog" v-bind:patient-image="patientImage"/>
    </div>
    <chat-footer-layout ref="chatFooterLayout" @clickSubscriptionRequest="isPlanSelectOpen = true" @clickButtonRequest="isButtonCreateOpen = true" @clickRoutineMessageRequest="isRoutineMessageOpen = true" @clickFinishRequest="isFinishConsultationOpen = true"/>
    <chat-plan-select v-if="isPlanSelectOpen" @subscriptionRequestCancel="isPlanSelectOpen = false" @onSubscriptionRequest="onSubscriptionRequest"></chat-plan-select>
    <chat-button-create v-if="isButtonCreateOpen" @buttonRequestCancel="isButtonCreateOpen = false"></chat-button-create>
    <chat-post-routine-message v-if="isRoutineMessageOpen" @routineMessageRequestCancel="isRoutineMessageOpen = false" @onRoutineMessageRequest="onRoutineMessageRequest"></chat-post-routine-message>
    <chat-finish-consultation v-if="isFinishConsultationOpen" @finishConsultationClose="isFinishConsultationOpen = false"></chat-finish-consultation>
    <router-view>
    </router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as actionTypes from '../store/action-types'
import * as mutationTypes from '../store/mutation-types'
import * as Interview from '../store/modules/chatLogs/interview'
import BaseReloadLink from './base/BaseReloadLink'
import ChatLogListItem from './ChatLogListItem'
import ChatFooterLayout from './ChatFooter/ChatFooterLayout'
import chatTimeLineChannel from '../channels/ChatTimeLineChannel'
import ChatPlanSelect from './ChatPlanSelect'
import ChatButtonCreate from './ChatButtonCreate'
import ChatPostRoutineMessage from './ChatPostRoutineMessage'
import ChatFinishConsultation from './ChatFinishConsultation'
import axios from 'axios';

export default {
  name: 'ChatTimeLine',
  components: { ChatLogListItem, BaseReloadLink, ChatFooterLayout, ChatPlanSelect, ChatButtonCreate, ChatFinishConsultation, ChatPostRoutineMessage },
  data: () => {
    return {
      cableStatus: 'READY_CONNECT',
      isPlanSelectOpen: false,
      isButtonCreateOpen: false,
      isRoutineMessageOpen: false,
      isFinishConsultationOpen: false,
      consultationMedicalDepartments: [],
      selectedConsultationMedicalDepartment: {}
    }
  },
  async created() {
    let chatId = this.$route.params.chat_id;
    this.fetchReadMessage(chatId);
    this.fetchPatientReadMessage(chatId);
    await this.fetchChat({ id: chatId, callback: this.pageScrollToBottom });
    if (this.chat.consultation.consultation_medical_department){
      this.selectedConsultationMedicalDepartment = this.chat.consultation.consultation_medical_department
    } else {
      this.selectedConsultationMedicalDepartment = {name: '未選択', id: null}
    }
    this.fetchConsultationMedicalDepartments();
    const component = this;
    chatTimeLineChannel(component)
  },
  computed: {
    unreadChatLogHead () {
      if (this.readMessage === null) {
        return { id: null }
      }

      return this.chat.chat_logs.filter(chatLog => chatLog.created_at > this.readMessage.read_at)[0] || { id: null }
    },
    patientImage () {
      return this.chat.chat_logs[0].icon_url
    },
    ...mapGetters(['chat', 'readMessage', 'currentUser', 'isPatient'])
  },
  methods: {
    pageScrollToBottom () {
      window.setTimeout(
        () => window.scrollTo(0, 100000),
        1
      )
    },
    async onSubscriptionRequest(plan_type) {
      // メッセージはプランによって異なるのでサーバ側で設定
      await this.postChatLog({ chatId: this.chat.id, chatLog: {
          log_type: 'subscription_request',
          plan_type: plan_type
        }
      });
      this.isPlanSelectOpen = false
    },
    async onRoutineMessageRequest(routine_message_type) {
      await this.postChatLog({ chatId: this.chat.id, chatLog: {
          log_type: 'routine_message',
          routine_message_type: routine_message_type
        }
      });
      this.isRoutineMessageOpen = false
    },
    async fetchConsultationMedicalDepartments() {
      try {
        const res = await axios.get('/api/consultation_medical_departments');
        this.consultationMedicalDepartments = res.data;
        this.consultationMedicalDepartments.unshift({name: '未選択', id: null})
      } catch {
        alert('ERROR');
      }
    },
    async updateConsultation() {
      try {
        const res = await axios.patch(`/api/consultations/${this.chat.consultation_id}`, { consultation_medical_department_id: this.selectedConsultationMedicalDepartment.id })
        alert('診療科目を登録しました')
      } catch {
        alert('ERROR');
      }
    },
    setIsSatisfied (isSatisfied) {
      this.chat.consultation.is_satisfied = isSatisfied
    },
    ...mapActions({
      fetchChat: actionTypes.FETCH_CHAT,
      attachReadMessage: actionTypes.ATTACH_READ_MESSAGE,
      fetchReadMessage: actionTypes.FETCH_READ_MESSAGE,
      fetchPatientReadMessage: actionTypes.FETCH_PATIENT_READ_MESSAGE,
      postChatLog: actionTypes.POST_CHAT_LOG
    }),
    ...mapMutations({
      setChatLogs: mutationTypes.SET_CHAT_LOGS,
      setReadMessage: mutationTypes.SET_READ_MESSAGE,
      setResult: `chatLogInterview/${Interview.mutationTypes.SET_RESULT}`,
      removeResult: `chatLogInterview/${Interview.mutationTypes.REMOVE_RESULT}`
    })
  }
}
</script>