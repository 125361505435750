<template>
  <div :id="`cy-consultation-${consultation.id}`">
    <div class='panel consultation-panel'>
      <template v-if="consultation.patient_family_profile != null">
        <ConsultationListItemHeader :consultation="consultation"/>
        <div class='panel-body'>
          <div class='content'>
            <PregnancyTag v-if="isFemale && isNotPatient" :patientFamilyProfile="consultation.patient_family_profile"/>
            <p>{{consultation.content}}</p>
          </div>
          <div v-if="isReady && isPatient" class="btn btn-waiting disabled">
            <i class="fa fa-comments fa-fw" aria-hidden="true"></i>
            担当医師待ち
          </div>
          <a v-if="!isReady && isPatient" :href="`/chat/${consultation.chat.id}`" class="btn btn-brand">
            <i class="fa fa-comments fa-fw" aria-hidden="true"></i>
            チャットに入る
          </a>
          <div v-if="isPatient && consultation.can_delete_chat" class='col-xs-12'>
            <div class='to-detail text-center'>
              <a class='text-danger' :href="`/consultations/${consultation.id}`">
                相談を中止する
              </a>
            </div>
          </div>
        </div>
        <DoctorFooter v-if="!isPatient" v-bind:consultation="consultation"/>
      </template>
      <template v-else>
        <div class="panel-body">
          <h2>削除済みユーザーです</h2>
          <p>相談ID: {{consultation.id}}</p>
          <p>{{consultation.title}}</p>
          <p>{{consultation.content}}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PatientFamilyProfile from '../enums/PatientFamilyProfile'
import ConsultationListItemHeader from './ConsultationListItem/Header'
import DoctorFooter from './ConsultationListItem/DoctorFooter'
import PregnancyTag from './ConsultationListItem/PregnancyTag'

import ChatEnum from '../enums/Chat'
export default {
  name: 'ConsultationListItem',
  components: { PregnancyTag, ConsultationListItemHeader, DoctorFooter },
  props: ['consultation'],
  computed: {
    chat() {
      return this.consultation.chat;
    },
    isFemale () {
      return this.consultation.patient_family_profile.sex === PatientFamilyProfile.sex.female
    },
    isReady() {
      return this.chat.status === ChatEnum.Status.ready;
    },
    ...mapGetters(['currentUser', 'isPatient', 'isNotPatient'])
  }
}
</script>

<style scoped>
.pregnancy-tag:before {
content: '【'
}
.pregnancy-tag:after {
  content: '】'
}
</style>
