/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'
import VueRouter from 'vue-router'
import VueTextareaAutosize from 'vue-textarea-autosize'
import App from '../app.vue'
import store from '../store'
import * as types from "../store/mutation-types";
import axios from 'axios';
import qs from 'qs'

import NoticeAlert from '../components/NoticeAlert'
import NoticeAlerts from '../components/NoticeAlerts'
import BaseMultipleSelect from '../components/base/BaseMultipleSelect'
import BaseTitle from '../components/base/BaseTitle'
import BaseLoadingIcon from '../components/base/BaseLoadingIcon'
import BaseTextField from "../components/base/Form/BaseTextField"
import BaseRadioField from "../components/base/Form/BaseRadioField"
import BaseTextArea from "../components/base/Form/BaseTextArea"
import BaseSelectField from "../components/base/Form/BaseSelectField"
import BaseCheckBoxField from "../components/base/Form/BaseCheckBoxField"
import BaseOrderChangeButtonGroup from '../components/base/BaseOrderChangeButtonGroup'
import BaseNavTabs from "../components/base/BaseNavTabs";
import BaseNavTabItem from "../components/base/BaseNavTabItem";
import BaseTooltip from "../components/base/BaseTooltip";
import BaseUserIcon from "../components/base/BaseUserIcon";
import draggable from "vuedraggable"
import Multiselect from 'vue-multiselect'

import router from '../router'
import { MixpanelPlugin } from '../plugins/mixpanel'


Vue.use(require('vue-moment'));
Vue.use(VueRouter);
Vue.use(VueTextareaAutosize);
Vue.use(MixpanelPlugin)
Vue.component('Draggable', draggable);
Vue.component('multiselect', Multiselect);

Vue.component('BaseMultipleSelect', BaseMultipleSelect);
Vue.component('BaseTitle', BaseTitle);
Vue.component('BaseLoadingIcon', BaseLoadingIcon);
Vue.component('BaseTextField', BaseTextField);
Vue.component('BaseUserIcon', BaseUserIcon);
Vue.component('BaseTextArea', BaseTextArea);
Vue.component('BaseSelectField', BaseSelectField);
Vue.component('BaseRadioField', BaseRadioField);
Vue.component('BaseCheckBoxField', BaseCheckBoxField);
Vue.component('BaseOrderChangeButtonGroup', BaseOrderChangeButtonGroup)
Vue.component('NoticeAlert', NoticeAlert);
Vue.component('NoticeAlerts', NoticeAlerts);
Vue.component('BaseNavTabs', BaseNavTabs);
Vue.component('BaseNavTabItem', BaseNavTabItem);
Vue.component('BaseTooltip', BaseTooltip);

import BaseModal from '../components/base/BaseModal'
Vue.component('BaseModal', BaseModal);


document.addEventListener('DOMContentLoaded', () => {
  if (!document.getElementById('app')) {
    return
  }
  axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers['X-CSRF-TOKEN'] = document.getElementsByName('csrf-token')[0].getAttribute('content');
  const el = document.getElementById('app');

  if (el) {
    new Vue({
      el,
      store,
      router,
      render: h => h(App)
    });
  }
});

import '../pages/index';
