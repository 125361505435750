<template>
  <li :id="`cy-patient-family-profile-${patientFamilyProfile.id}`" class="profile-item">
    <router-link :to="`/patient_family_profiles/${patientFamilyProfile.id}`"
                 class="profile-item-link">
      <div class="profile-name">
        {{patientFamilyProfile.first_name + ' ' + patientFamilyProfile.last_name}}
      </div>

      <div class="profile-sex">
        {{patientFamilyProfile.sex === 'female' ? '女性' : '男性'}}
      </div>

      <div class="profile-birthday">
        {{patientFamilyProfile.birthday | moment}}
      </div>
      <div class="arrow">
        >
      </div>
    </router-link>
  </li>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'PatientFamilyProfileListItem',
    props: ['patientFamilyProfile', 'referrer'],
    filters: {
      moment (date) {
        return moment(date).format('YYYY/MM/DD');
      }
    },
  }
</script>

<style scoped lang="scss">
  .profile-item {
    background: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 1rem;

    .profile-item-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      padding: 16px;

      &:hover {
        opacity: 0.5;
      }

      .profile-name {
        color: #000;
        margin-right: 1rem;
      }

      .profile-sex {
        color: #7f8388;
        margin-right: 1rem;
        background: #fff;
        border-radius: 16px;
        padding: 5px 10px;
      }

      .profile-birthday {
        color: #7f8388;
      }

      .arrow {
        margin-left: auto;
      }
    }

  }

</style>
