<template>
  <div class="row input-row">
    <div class="col-md-3">
      <label class="control-label" :for="item.id">{{item.input_label}}</label>
    </div>
    <div class="col-md-6">
      <div v-if="dataType !== 'boolean'"
           class="progress"
           :style="progressStyle">
        <div class="progress-bar"
             :class="progressClassName"
             :style="progressBarStyle"
             role="progressbar"
             aria-valuenow="7.5"
             aria-valuemin="0"
             aria-valuemax="10" >
        </div>
        <div class="progress-separate">
          <span class="progress-separate_first"></span>
          <p class="progress-separate_one" :style="`left: ${item.red_lower_percent}%;`">
          </p>
          <p class="progress-separate_two" :style="`left: ${item.yellow_lower_percent}%;`">
          </p>
          <p class="progress-separate_three" :style="`left: ${item.yellow_upper_percent}%;`">
          </p>
          <p class="progress-separate_four" :style="`left: ${item.red_upper_percent}%;`">
          </p>
          <span class="progress-separate_end"></span>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <item-float-input :item="item" v-show="item.data_type === 'float'" />
      <item-integer-input :item="item" v-show="item.data_type === 'integer'" />
      <item-boolean-input :item="item" v-show="item.data_type === 'boolean'" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ItemFloatInput from './ItemFloatInput'
  import ItemIntegerInput from './ItemIntegerInput'
  import ItemBooleanInput from './ItemBooleanInput'
  export default {
    name: 'ItemInput',
    props: ['item'],
    components: { ItemFloatInput, ItemIntegerInput, ItemBooleanInput },
    computed: {
      dataType() {
        return this.item.data_type;
      },
      progressStyle() {
        return `
          background: linear-gradient(90deg, #ffb2c7 0%,
            #ffb2c7 ${this.item.red_lower_percent}%, #fdedc1 ${this.item.red_lower_percent}%,
            #fdedc1 ${this.item.yellow_lower_percent}%, #d1efd1 ${this.item.yellow_lower_percent}%,
            #d1efd1 ${this.item.yellow_upper_percent}%, #fdedc1 ${this.item.yellow_upper_percent}%,
            #fdedc1 ${this.item.red_upper_percent}%, #ffb2c7 ${this.item.red_upper_percent}%,
            #ffb2c7 100%);
        `.replace(/\r?\n/g, '');
      },
      progressClassName () {
        return `progress-bar-${this.progressStatus}`
      },
      progressBarStyle () {
        return `width: ${this.progressPercent}%;`
      },
      progressPercent() {
        switch(this.dataType) {
          case 'float':
          case 'integer':
            const range = this.item.upper_limit - this.item.lower_limit;
            const value = this.itemValue(this.item) - this.item.lower_limit;
            const percent = range <= 0 ? 0 : (value / range) * 100;
            if (percent < 0) {
              return 0
            } else if (percent > 100) {
              return 100
            } else {
              return percent
            }
          case 'boolean':
            return 0;
        }
      },
      progressStatus() {
        return this.itemStatus(this.item);
      },
      ...mapGetters(['itemValue', 'itemStatus'])
    },
  }
</script>

<style scoped>
  .input-row {
    border-bottom: 1px solid #eee;
  }
</style>
