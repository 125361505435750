<!-- Vue component -->
<template>
  <div>
    <multiselect v-model="value"
                 :options="options"
                 placeholder="Select one"
                 label="name"
                 track-by="code"></multiselect>

    <input type="hidden" :name="name" :value="value.code" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import Multiselect from 'vue-multiselect'

  // register globally
  Vue.component('base-incremental-select', Multiselect);

  export default {
    props: ['value', 'options', 'name'],
    components: {
      Multiselect
    }
  }
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

