<template>
  <div class="">
    <div class="row">
      <button @click="toggleHandle" type="button" :class="toggleLinkClass">検索条件</button>
    </div>
    <form class="form" v-if="toggle">
      <div class="form-group">
        <label class="control-label col-md-3" for="id_eq">ID</label>
        <div class="col-md-9">
          <div class="col-md-4">
            <input v-model="searchForm.idEq" type="number" id="id_eq" class="form-control">
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3" for="name_cont">相談者氏名</label>
        <div class="col-md-9">
          <input v-model="searchForm.nameCont" id="name_cont" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3" for="source_cont">sr</label>
        <div class="col-md-9">
          <input v-model="searchForm.sourceCont" id="source_cont" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3" for="email_cont">Email</label>
        <div class="col-md-9">
          <input v-model="searchForm.emailCont" id="email_cont" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3">相談日</label>
        <div class="col-md-9">
          <div class="col-md-4">
            <vuejs-datepicker v-model="searchForm.createdDateGtEq" input-class="form-control" :language="ja" id="created_date_gteq" format="yyyy-MM-dd">
            </vuejs-datepicker>
          </div>
          <div class="col-md-1">~</div>
          <div class="col-md-4">
            <vuejs-datepicker  v-model="searchForm.createdDateLtEq" input-class="form-control" :language="ja" id="created_date_lteq" format="yyyy-MM-dd">
            </vuejs-datepicker>
          </div>
          <div class="col-md-1">
            <button @click="resetCreatedDate" class='btn btn-default btn-xs' type="button">リセット</button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3">性別</label>
        <div class="col-md-9">
          <div class="checkbox">
            <input id="female" v-model="searchForm.sexIn" type="checkbox" value="female">
            <label for="female">
              <span class="checkbox-icon">
                女性
              </span>
            </label>
          </div>
          <div class="checkbox">
            <input id="male" v-model="searchForm.sexIn" type="checkbox" value="male">
            <label for="male">
              <span class="checkbox-icon">
                男性
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3">年齢</label>
        <div class="col-md-9">
          <div class="col-md-4">
            <input v-model="ageGtEq" type="number" class="form-control">
          </div>
          <div class="col-md-1">~</div>
          <div class="col-md-4">
            <input v-model="ageLtEq" type="number" class="form-control">
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3" for="content_cont">相談内容</label>
        <div class="col-md-9">
          <input v-model="searchForm.contentCont" id="content_cont" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <div class="checkbox">
          <input id="medical_dashboard" v-model="searchForm.medicalDashboard" type="checkbox" value="true">
          <label for="medical_dashboard" class="control-label col-md-3">
            <span class="checkbox-icon">
              おうちで健康チェック
            </span>
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-9">
          <button type="button" @click="submit" class="btn btn-info">検索</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import vuejsDatepicker from "vuejs-datepicker";
  import {ja} from 'vuejs-datepicker/dist/locale';
  import moment from 'moment';
  import {mapActions} from 'vuex';

  import * as actionType from '../store/action-types';
  import BaseTextField from "./base/Form/BaseTextField";
  import BaseCheckBoxField from "./base/Form/BaseCheckBoxField";
  export default {
    name: 'ConsultationSearchForm',
    components: {BaseCheckBoxField, vuejsDatepicker, BaseTextField},
    data() {
      return {
        searchForm: {
          idEq: null,
          nameCont: '',
          emailCont: '',
          sourceCont: '',
          createdDateGtEq: '',
          createdDateLtEq: '',
          sexIn: [],
          birthdayGtEq: '',
          birthdayLtEq: '',
          contentCont: '',
          medicalDashboard: false
        },
        toggle: false
      }
    },
    computed: {
      ja: () => ja,
      ageGtEq: {
        get() {
          if(this.searchForm.birthdayLtEq === '') {
            return ''
          }

          return moment().diff(this.searchForm.birthdayLtEq, "years")
        },
        set(age) {
          if(age === '') {
            this.searchForm.birthdayLtEq = '';
            return
          }
          this.searchForm.birthdayLtEq = moment().subtract(age, 'years')
        }
      },
      ageLtEq: {
        get() {
          if(this.searchForm.birthdayGtEq === '') {
            return ''
          }
          return moment().diff(this.searchForm.birthdayGtEq, "years")
        },
        set(age) {
          if(age === '') {
            this.searchForm.birthdayGtEq = '';
            return
          }
          this.searchForm.birthdayGtEq = moment().subtract(age, 'years')
        }
      },
      toParams() {
        return ({
          id_eq: this.searchForm.idEq,
          patient_family_profile_full_name_cont: this.searchForm.nameCont,
          user_email_cont: this.searchForm.emailCont,
          user_source_cont: this.searchForm.sourceCont,
          created_at_daily_gteq: this.searchForm.createdDateGtEq,
          created_at_daily_lteq: this.searchForm.createdDateLtEq,
          patient_family_profile_sex_in: this.searchForm.sexIn,
          patient_family_profile_birthday_gteq: this.searchForm.birthdayGtEq === '' ? '' : this.searchForm.birthdayGtEq.subtract(1, 'years').format('YYYY-MM-DD'),
          patient_family_profile_birthday_lteq: this.searchForm.birthdayLtEq === '' ? '' : this.searchForm.birthdayLtEq.format('YYYY-MM-DD'),
          content_cont: this.searchForm.contentCont,
          patient_family_profile_tracking_number_present: this.searchForm.medicalDashboard
        })
      },
      toggleLinkClass() {
        return `btn btn-link ${this.toggle ? "btn-link-open" : 'btn-link-close'}`
      }
    },
    methods: {
      toggleHandle() {
        this.toggle = !this.toggle
      },
      resetCreatedDate() {
        this.searchForm.createdDateGtEq = '';
        this.searchForm.createdDateLtEq = '';
      },
      submit() {
        this.$emit("search", { q: this.toParams })
      },
      ...mapActions({fetchConsultations: actionType.FETCH_CONSULTATIONS})
    }
  }
</script>

<style lang="scss" scoped>
  .control-label {
    margin-top: 8px;
  }
  .col-md-1 {
    text-align: center;
    margin-top: 2px;
    font-size: 1.6em;
  }
  .col-md-3 {
    padding-right: 8px;
  }
  .checkbox {
    display: inline-block;
  }
  input[type="checkbox"] {
    margin-left: -20px;
  }

  .btn-link {
    text-decoration: none;
  }
  .btn-link-open {
    &:after {
      content: '▲'
    }
  }
  .btn-link-close {
    &:after {
      content: '▼';
    }
  }
</style>
