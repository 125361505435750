<template>
  <div class="form-group">
    <base-text-area :label-text="groupMaster.message"
                    v-model="resultForm.content"
                    :name="`chat-interview-result-${groupMaster.master_id}`"
                    :hidden-label="true" :disabled="!isPatient">
    </base-text-area>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    name: 'ChatLogInterviewResultTextField',
    props: {
      groupMaster: {
        type: Object,
        required: true
      },
      resultForm: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters({isPatient: "isPatient"}),
    }
  }
</script>
