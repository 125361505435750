<template>
  <div class='panel-footer'>
    <ul class='list-inline'>
      <li>
        相談履歴:{{consultation.total_consultation_count}}件
      </li>
      <li>
        ID:{{consultation.id}}
      </li>
      <li>
        <i class='fa fa-flag fa-fw'></i>
        {{ consultation.user && consultation.user.source ? consultation.user.source : '--'}}
      </li>
      <li>
        <i class='fa fa-envelope fa-fw'></i>
        {{consultation.user ? consultation.user.email : '退会済みユーザー'}}
      </li>
      <li>
        <i class='fa fa-yen fa-fw'></i>
        {{subscriptStatusText}}
      </li>
      <li>
        <i class='fa fa-credit-card fa-fw'></i>
        {{chargeStatusText}}
      </li>
      <li>
        <i class='fa fa-info-circle fa-fw'></i>
        {{consultation.consultation_type_text}}
        投稿日時:{{consultation.created_at | moment('YYYY-MM-DD HH:mm')}}
      </li>
      <li v-if="allowChat">
        <a class='btn btn-success' :href="`/chat/${chat.id}`">
          <i class="fa fa-comments fa-fw" aria-hidden="true"></i>
          チャット
        </a>
      </li>
      <li v-if="chat.doctor_id === null">
        <button class='btn btn-success' @click="(_) => clickUpdateChat(consultation.chat.id)" :disabled="this.isDeletedConsultation">
          <i class="fa fa-comments fa-fw" aria-hidden="true"></i> 担当する
        </button>
       </li>
    </ul>
    <kenko-check-info v-if="hasTrackingNumber" :patient-family-profile="patientFamilyProfile" />
  </div>

</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes from '../../store/action-types'
  import KenkoCheckInfo from './KenkoCheckInfo'
  import User from '../../enums/User'
  export default {
    name: 'DoctorFooter',
    components: {KenkoCheckInfo},
    props: ['consultation'],
    computed: {
      patientFamilyProfile () {
        return this.consultation.patient_family_profile
      },
      chat (){
        return this.consultation.chat
      },
      hasTrackingNumber () {
        const trackingNumber = this.patientFamilyProfile.tracking_number;
        return trackingNumber !== null && !!trackingNumber.trim()
      },
      allowChat () {
        return this.chat.doctor_id !== null
      },
      isDeletedConsultation(){
        return this.consultation.deleted_at !== null
      },
      subscriptStatusText() {
        if (!this.consultation.user) return '';

        return this.consultation.user.is_subscribe ? '課金中' : '非課金'
      },
      chargeStatusText () {
        if (!this.consultation.user) return '';

        return this.consultation.user.is_creditcard_registered ? '登録済' : '未登録'
      },
      ...mapGetters(['currentUser'])
    },
    methods: {
      ...mapActions({clickUpdateChat: actionTypes.UPDATE_CHAT})
    }
  }
</script>

<style scoped>
</style>
