<template>
  <div class="p-onlineClinicHome">
    <div class="p-onlineClinicHome__info">
      <div class="p-onlineClinicHome__header">
        <p class="p-onlineClinicHome__status">
          現在のステータス
        </p>
      </div>

      <div class="p-onlineClinicHome__body">
        <p class="p-onlineClinicHome__title">
          予約時間になったら診療をうけましょう
        </p>
      </div>
      <p class="p-onlineClinicHome__date">診療時間</p>
      <p class="p-onlineClinicHome__date">{{examinationDatetime}}</p>
    </div>

    <router-link :to="{name: 'examination',params:{id: this.$props.examination.id}}" class="c-mainButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '予約内容を確認', userId: currentUser.id, source: currentUser.source })">
      診察をうける
    </router-link>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="this.$props.chat == null ? null : this.$props.chat.id"
    ></notification-button>


    <a href="/online_clinic/examinations" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '過去の診療', userId: currentUser.id, source: currentUser.source })">
      過去の診察
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">
      会員情報
    </a>
  </div>
</template>


<script>
  import moment from 'moment';
  import NotificationButton from "./buttons/NotificationButton";
  import {mapGetters} from "vuex";

  export default {
    name: 'AfterClinicReservation',
    props: ['examination', 'scheduleStart', 'scheduleEnd', 'chat'],
    components: {
      NotificationButton
    },
    data() {
      return {};
    },
    computed: {
      examinationDatetime() {
        if (this.$props.scheduleStart == null) {
          return '読込中...'
        } else {
          moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
          let startTime = moment(this.$props.scheduleStart).format('HH:mm');
          let endTime = moment(this.$props.scheduleEnd).format('HH:mm');
          let day = moment(this.$props.scheduleStart).format('YYYY/MM/DD(ddd)');
          return `${day} ${startTime} ~ ${endTime}`
        }
      },
      ...mapGetters(['currentUser'])
    },
  };
</script>
