const FIRST_REPLY = "first-reply"
const FIRST_REPLY_TO_KIREIDO_USER = "first-reply-to-kireido-user"
const PLAN_REGISTRATION_COMPLETED = "plan-registration-completed"
const HOW_TO_PURCHASE_SUPPLEMENTS = "how-to-purchase-supplements"
const FINISH_REQUEST = "finish-request"
const FINISH_REQUEST_WITH_SATISFACTION_QUESTIONNAIRE = "finish-request-with-satisfaction-questionnaire"

export default {
  HOW_TO_PURCHASE_SUPPLEMENTS,
  FINISH_REQUEST,
  FINISH_REQUEST_WITH_SATISFACTION_QUESTIONNAIRE,
  FIRST_REPLY,
  FIRST_REPLY_TO_KIREIDO_USER,
  PLAN_REGISTRATION_COMPLETED
}
