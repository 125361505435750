<template>
  <section class="p-onlineClinicHome">
    <flash-message />
    <notice-alert v-for="notice in notices" :notice="notice" />
    <div class="p-onlineClinicHome__mainTitle">
      <img src="../../../../assets/images/online_clinic/icon.png" alt="">
      <p>オンライン診療</p>
    </div>
    <div class="p-onlineClinicHome__medicalDepartment">
      <p>診療科目</p>
      <img src="../../../../assets/images/online_clinic/medical_department.png" alt="">
      <span>※他：内科、小児科、皮膚科、循環器内科</span>
    </div>
    <component :is="navigationComponent" :examination=examination :prescriptionId=prescriptionId :scheduleStart = scheduleStart :chat="chat"
    :scheduleEnd = scheduleEnd ></component>
    <div class="p-onlineClinicHome__guide">
    <a href="/online_clinic/guide" class="c-link--ceruleanBlue" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '使い方ガイド', userId: currentUser.id, source: currentUser.source })">使い方ガイド</a>
    </div>
    <footer-survey-link page="診療トップ"></footer-survey-link>
  </section>
</template>

<script>
import axios from 'axios';
import BeforeClinicReservation from '@/components/online_clinic/home/navigation/BeforeClinicReservation';
import AfterClinicReservation from '@/components/online_clinic/home/navigation/AfterClinicReservation';
import BeforeIssuingPrescription from '@/components/online_clinic/home/navigation/BeforeIssuingPrescription';
import AfterIssuingPrescription from '@/components/online_clinic/home/navigation/AfterIssuingPrescription';
import IssuingPrescriptionByMail from '@/components/online_clinic/home/navigation/IssuingPrescriptionByMail';
import CancelByAdmin from '@/components/online_clinic/home/navigation/CancelByAdmin';
import WithoutIssuingPrescription from '@/components/online_clinic/home/navigation/WithoutIssuingPrescription';
import FlashMessage from '@/components/online_clinic/FlashMessage';
import Progress from '../../../enums/Progress';
import {mapGetters} from "vuex";
import FooterSurveyLink from "@/components/footer/FooterSurveyLink";
import NoticeAlert from "@/components/NoticeAlert";

export default {
  components: {
    FooterSurveyLink,
    BeforeClinicReservation,
    AfterClinicReservation,
    BeforeIssuingPrescription,
    AfterIssuingPrescription,
    IssuingPrescriptionByMail,
    CancelByAdmin,
    WithoutIssuingPrescription,
    FlashMessage,
    NoticeAlert,
  },
  data() {
    return {
      examination: null,
      prescriptionId: null,
      progressState: 0,
      scheduleStart: null,
      scheduleEnd: null,
      chat: null
    };
  },
  computed: {
    navigationComponent() {
      switch (this.progressState) {
        case Progress.State.beforeClinicReservation:
          return 'BeforeClinicReservation';
        case Progress.State.afterClinicReservation:
          return 'AfterClinicReservation';
        case Progress.State.beforeIssuingPrescription:
          return 'BeforeIssuingPrescription';
        case Progress.State.afterIssuingPrescription:
          return 'AfterIssuingPrescription';
        case Progress.State.issuingPrescriptionByMail:
          return 'IssuingPrescriptionByMail';
        case Progress.State.withoutIssuingPrescription:
          return 'WithoutIssuingPrescription';
        case Progress.State.cancelByAdmin:
          return 'CancelByAdmin';
        case Progress.State.cancelByPatient:
          return 'BeforeClinicReservation';
      }
    },
    hasEmergencyConsultation() {
      return this.chat != null
    },
    isBeforeClinicReservation() {
      return this.progressState === Progress.State.beforeClinicReservation
    },
    isCancelByPatient() {
      return this.progressState === Progress.State.cancelByPatient
    },
    ...mapGetters(['currentUser', 'notices'])
  },
  async created() {
    const check_emergency_consultation_res = await axios.get(`/api/online_clinic/check_emergency_consultation`)
    this.chat = check_emergency_consultation_res.data.chat

    const res = await axios.get(`/api/online_clinic/progress`);
    this.progressState = res.data.state;
    if (res.data.examination) {
      this.examination = res.data.examination;
    }
    if (res.data.prescription_id) {
      this.prescriptionId = res.data.prescription_id;
    }
    if (res.data.doctor_schedule_start && res.data.doctor_schedule_end) {
      this.scheduleStart = res.data.doctor_schedule_start;
      this.scheduleEnd = res.data.doctor_schedule_end;
    }
  },
};
</script>
