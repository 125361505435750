<template>
  <div>
    <category-field v-for="category in categories"
                     :key="category.id"
                     :category="category"
                     :patientFamilyProfileCategory="patientCategory(category)"
                     :categorizeItems="categorizeItems(category)(patientFamilyProfile.medical_dashboard_items)" />

    <div class="form-group">
      <notice-alert v-for="notice in notices" :notice="notice" />
    </div>
    <div class="form-group">
      <button @click="patchPatient({ patientFamilyProfile })" type="button" class="btn btn-primary">更新する</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes from '../store/action-types'
  import NoticeAlert from './NoticeAlert'
  import CategoryField from './MedicalDashboard/CategoryField'
  import PatientCategoryField from './MedicalDashboard/PatientCategoryField'

  export default {
    name: 'MedicalDashboardForm',
    components: { NoticeAlert, CategoryField, PatientCategoryField },
    created: function() {
      this.fetchCategories();
      this.fetchPatient({ patientFamilyProfileId: this.$route.params.id });
    },
    computed: {
      ...mapGetters(['notices', 'categorizeItems']),
      ...mapGetters({
        patientFamilyProfile: 'medicalDashboardPatientFamilyProfile',
        categories: 'medicalDashboardCategories',
        patientCategories: 'medicalDashboardPatientFamilyProfileCategories'
      })
    },
    methods: {
      patientCategory(category) {
        return this.patientCategories.find(pc => pc.category_id === category.id)
      },
      ...mapActions({
        fetchPatient: actionTypes.FETCH_MEDICAL_DASHBOARD_PATIENT,
        fetchCategories: actionTypes.FETCH_MEDICAL_DASHBOARD_CATEGORIES,
        patchPatient: actionTypes.PATCH_MEDICAL_DASHBOARD_PATIENT
      })
    }
  }
</script>

<style scoped>
</style>
