<template>
  <li class="list-group-item">
    <div class="row">
      <div class="col-sm-11">
        <div class="col-sm-2">
          <is-publishing-icon :is-publishing="master.is_publishing" />
          <interview-type-icon :interview-type="master.interview_type" />
          <way-type :way-type="master.way_type"></way-type>
        </div>
        <div class="col-sm-3 col-sm-offset-7">
          <router-link :to="editPath" class="btn btn-info">編集</router-link>
        </div>
        <div class="col-sm-12">
          {{master.message}}
        </div>
      </div>
      <div class="col-sm-1">
        <Base-order-change-button-group></Base-order-change-button-group>
      </div>
    </div>
  </li>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes from '../../store/action-types'

  import InterviewTypeIcon from './MasterListItem/InterviewTypeIcon'
  import WayType from './MasterListItem/WayTypeIcon'
  import IsPublishingIcon from './MasterListItem/IsPublishingIcon'
  import BaseOrderChangeButtonGroup from '../base/BaseOrderChangeButtonGroup'

  export default {
    name: 'InterviewMasterItem',
    props: ['master'],
    components: { InterviewTypeIcon, WayType, IsPublishingIcon, BaseOrderChangeButtonGroup },
    computed: {
      editPath() {
        return `/interview/masters/${this.master.id}/edit`
      }
    }
  }
</script>
