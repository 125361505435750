<template>
  <form>
    <base-nl2br
      class="test"
      tag="p"
      :text="groupMaster.message"
      :text-filter="textFilterWithHref"
      style="font-size:12px !important;"
    />

    <component
      :is="fieldComponent"
      :group-master="groupMaster"
      :result-form="resultForm"
      @input="handleInput"
    />

    <div v-if="isPatient" class="button-wrapper">
      <button @click="$emit('back')" v-if="!isFirst" class="btn btn-waiting" type="button">戻る</button>
      <input type="submit" @click="handleSubmit" :disabled="!valid || submitDisabled" class="btn btn-brand" :value="submitButtonName"/>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {actionTypes} from '../../../store/modules/chatLogs/interview';
  import * as Helper from '../../../shared/Helper'
  import ResultTextField from './ResultTextField'
  import ResultCheckboxesField from './ResultCheckboxesField'
  import ResultRadioField from './ResultRadioField'
  import ResultFilesField from "./ResultFilesField";
  import { textFilterWithHref } from '../../../shared/Helper'

  export default {
    name: 'ChatLogInterviewResultField',
    components: {ResultRadioField},
    props: {
      groupMaster: {
        type: Object,
        required: true
      },
      chatLogId: {
        type: Number,
        required: true
      },
      isFirst: {
        type: Boolean,
        required: true
      },
      isLast: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        resultForm: {
          chatLogId: this.chatLogId,
          masterId: this.groupMaster.master_id,
          content: "",
          resultItems: this.groupMaster.master_items.map(mi => ({
            masterItem: mi,
            _destroy: true
          })),
          resultFiles: []
        },
        submitDisabled: false,
        answeredMsg: {
          content: '問診に回答しました'
        }
      }
    },
    computed: {
      fieldComponent() {
        switch (this.groupMaster.interview_type) {
          case 'text':
            return ResultTextField;
          case 'checkboxes':
            return ResultCheckboxesField;
          case 'radio':
            return ResultRadioField;
          case 'file':
            return ResultFilesField;
        }
      },
      valid() {
        if (this.groupMaster.interview_type === 'checkboxes') return true;
        if (this.groupMaster.interview_type === 'file') return this.resultForm.resultFiles.length > 0;
        return this.resultForm.resultItems.filter(ri => !ri._destroy).length > 0 || this.resultForm.content.length > 0;
      },
      submitButtonName() {
        if (this.isLast){
          return '提出'
        } else {
          return '次へ'
        }
      },
      ...mapGetters({
        isPatient: "isPatient",
        findResult: `chatLogInterview/findResult`}),
    },
    methods: {
      handleInput(val) {
        switch (this.groupMaster.interview_type) {
          case 'file': {
            this.resultForm.resultFiles = val
          }
        }
      },
      handleSubmit(event) {
        event.preventDefault();
        this.submitDisabled = true

        if (this.groupMaster.interview_type === 'file') {
          this.postResultWithFiles({result: this.resultForm})
            .then(response => {
              Helper.pageScrollToBottom
              //チャット問診に全て答えたら擬似メッセージ出力
              if (response.data.is_chat_finished == true){
                 this.postChatLog({ chatLog: this.$data.answeredMsg, chatId: response.data.chat_id });
              }
            })
        } else {
          this.postResult({result: this.resultForm})
            .then(response => {
              Helper.pageScrollToBottom
              //チャット問診に全て答えたら擬似メッセージ出力
              if (response.data.is_chat_finished == true){
                 this.postChatLog({ chatLog: this.$data.answeredMsg, chatId: response.data.chat_id });
              }
            })
        }
      },
      refreshForm(groupMaster) {
        this.$data.resultForm = {
          chatLogId: this.chatLogId,
          masterId: groupMaster.master_id,
          content: "",
          resultFiles: [],
          resultItems: groupMaster.master_items.map(mi => ({
            masterItem: mi,
            _destroy: true
          }))
        }
      },
      textFilterWithHref,
      ...mapActions({
        postResult: `chatLogInterview/${actionTypes.POST_RESULT}`,
        postResultWithFiles: `chatLogInterview/${actionTypes.POST_RESULT_WITH_FILES}`,
        postChatLog: "POST_CHAT_LOG"
      })
    },
    watch: {
      groupMaster(val) {
        this.submitDisabled = false
        this.refreshForm(val)
      }
    }
  }
</script>

<style scoped lang="scss">
.form-group {
  margin: 0;
}

.button-wrapper {
  display: flex;
  .btn {
    padding: 4px;
    border-radius: 25px;
    margin-bottom: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-brand {
  color: #FFFFFF;
  background: linear-gradient(41.64deg, #00BCD4 0.06%, #2196F3 100.01%);
}

.btn-waiting {
  border: 1px solid rgba(42, 48, 57, 0.2);
  background: #FFF;
  color: rgba(42, 48, 57, 0.6);
  outline: none;

}

.btn {
  border-radius: 100px;
  font-size: 12px;
  width: 100%;
  height: 45px;
  margin: 12px 2px;
}

p {
  font-size: 12px;
  margin: 16px 0;
}

.button-wrapper .btn[data-v-6a67cd88]:last-child {
  margin-right: 0;
}

.button-wrapper .btn[data-v-6a67cd88] {
  padding: 4px;
  border-radius: 25px;
  margin-bottom: 0;
  margin-right: 8px;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn[data-v-6a67cd88] {
  border-radius: 100px;
  font-size: 12px;
  width: 100%;
  height: 45px;
  margin: 12px 2px;
}

.btn-brand[data-v-6a67cd88] {
  color: #FFFFFF;
  background: linear-gradient(41.64deg, #00BCD4 0.06%, #2196F3 100.01%);
}

.btn-brand:hover {
  transform: none
}
</style>
