<template>
  <div>
    <loading :translucent="true" v-show="loading"></loading>
    <status-bar :currentStep = currentStep statusType = 'clinicReservation'></status-bar>
    <div class="l-topPadding">
      <keep-alive>
        <component :is="currentComponent"
                    ref="currentComponent"
                    :clinicReservationToCreate="examination"
                    @moveToNextStep="moveToNextStep"
                    @onCancel="onCancel"
                    @moveToPreviousStep="moveToPreviousStep"
                    @addExaminationData="addExaminationData"
                    @emitClinicReservationConfirmed="emitClinicReservationConfirmed"
                    :apiError="apiError"
                    :apiCreditError="apiCreditError"
                    :apiDoctorScheduleConflictError="apiDoctorScheduleConflictError"
                    :apiDoubleReservationError="apiDoubleReservationError"
                    :apiReservationDeadlineError="apiReservationDeadlineError"
                    />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import SelectClinic from '@/components/online_clinic/clinics/reservations/SelectClinic';
import SelectSchedule from '@/components/online_clinic/clinics/reservations/SelectSchedule';
import PatientInformation from '@/components/online_clinic/clinics/reservations/PatientInformation';
import DefaultInterview from '@/components/online_clinic/clinics/reservations/interviewForm/Default';
import ChineseMedicineInterview from '@/components/online_clinic/clinics/reservations/interviewForm/ChineseMedicine';
import EmergencyContraceptionInterview from '@/components/online_clinic/clinics/reservations/interviewForm/EmergencyContraception';
import PmsInterview from '@/components/online_clinic/clinics/reservations/interviewForm/Pms';
import ReservationConfirmation from '@/components/online_clinic/clinics/reservations/reservationConfirmation/Common';
import StatusBar from '@/components/Common/StatusBar';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import EventBus from '../../../../event-bus';
import Loading from '@/components/Common/Loading';
import InterviewFormComponent from '@/enums/online_clinic/InterviewFormComponent';
import * as mutationTypes from "../../../../store/mutation-types";
import { findPrefecture } from "../../../../shared/Helper";

export default {
  components: {
    SelectClinic,
    SelectSchedule,
    PatientInformation,
    DefaultInterview,
    ChineseMedicineInterview,
    EmergencyContraceptionInterview,
    PmsInterview,
    ReservationConfirmation,
    StatusBar,
    Loading,
  },
  props: {
    apiError: {
      type: Object
    },
    apiCreditError: {
      type: Object
    },
    apiDoctorScheduleConflictError: {
      type: Boolean
    },
    apiDoubleReservationError: {
      type: Boolean
    },
    apiReservationDeadlineError: {
      type: Boolean
    },
  },
  created() {
    EventBus.$on('back-page-event', () => {
      console.log("バックページします。")
      console.log('examination', this.examination)
      if (this.currentStep > 1) {
        this.moveToPreviousStep();
      } else if (this.currentStep == 1) {
        this.onCancel();
      }
    });

    //選択されたスケジュールをクリアする
    EventBus.$on('clear-examination-doctor-schedule', () => {
      this.examination.doctorSchedule = null;
    });
  },
  data() {
    return {
      currentStep: 1,
      formSteps: [
        'PatientInformation',
        'SelectClinic',
        'SelectSchedule',
        'InterviewForm',
        'ReservationConfirmation',
      ],
      examination: {},
      loading: false
    };
  },
  computed: {
    allStepsCount() {
      return this.formSteps.length;
    },

    currentComponent() {
      if (this.formSteps[this.currentStep - 1] == 'InterviewForm') {
        //問診
        return InterviewFormComponent.name[this.examination.medicalDepartment.interview_type.interview_table_name] + 'Interview'
      } else {
        return this.formSteps[this.currentStep - 1];
      }
    },
  },
  watch: {
    apiDoctorScheduleConflictError: function(val) {
      if (this.apiDoctorScheduleConflictError) {
        //スケジュールを再選択させる
        this.currentStep = 3
      }
    },
    apiDoubleReservationError: function(val) {
      if (this.apiDoubleReservationError) {
        this.$store.commit(mutationTypes.PUSH_NOTICE, { message: 'すでに予約されている診療があります', type: 'danger' }, { root: true })
        this.$router.push(`/online_clinic`);
      }
    },
    apiReservationDeadlineError: function(val) {
      if (this.apiReservationDeadlineError) {
        this.$store.commit(mutationTypes.PUSH_NOTICE, { message: '申し訳ありません。このスケジュールは予約期限を過ぎました', type: 'danger' }, { root: true })
        this.$router.push(`/online_clinic`);
      }
    },
  },
  methods: {
    moveToNextStep() {
      this.currentStep++;
    },
    moveToPreviousStep() {
      this.currentStep--;
    },
    addExaminationData(data) {
      this.examination = { ...this.examination, ...data };
    },
    onCancel() {
      this.$dialog
        .confirm(
          {
            title: 'キャンセル',
            body:
              '今までの保存内容が失われる可能性があります。キャンセルしてもよろしいですか？',
          },
          {
            okText: 'はい',
            cancelText: 'いいえ',
          }
        )
        .then(() => {
          this.loading = true
          window.location.href = '/online_clinic';
        });
    },
    emitClinicReservationConfirmed(callback) {
      this.loading = true
      let isMedicationMail = false
      let pharmacyId = this.examination.interview.selectedPharmacy.id

      // おうち便の場合
      if (this.examination.interview.selectedPharmacy.id == 'medication_mail') {
        isMedicationMail = true
        pharmacyId = null
      }

      let reservationFormattedParams = {
        doctor_schedule_id: this.examination.doctorSchedule.id,
        examination_attributes: {
          clinic_id: this.examination.clinic.id,
          medical_department_id: this.examination.medicalDepartment.id,
          expected_prescription_issued_date: this.examination.expectedPrescriptionIssuedDate,
          is_medication_mail: isMedicationMail
        },
        interview_attributes: {
          patient_first_name: this.examination.selectedFamilyProfile.first_name,
          patient_first_name_kana: this.examination.selectedFamilyProfile.first_name_kana,
          patient_last_name: this.examination.selectedFamilyProfile.last_name,
          patient_last_name_kana: this.examination.selectedFamilyProfile.last_name_kana,
          patient_email: this.examination.selectedFamilyProfile.email,
          patient_tel: this.examination.selectedFamilyProfile.phone_number,
          patient_birthday: this.examination.selectedFamilyProfile.birthday,
          patient_sex: this.examination.selectedFamilyProfile.sex,
          patient_pregnancy: this.examination.selectedFamilyProfile.pregnancy,
          patient_zip: this.examination.selectedFamilyProfile.zip_code,
          patient_address_prefectures: findPrefecture(this.examination.selectedFamilyProfile.address_prefecture_id) != null ? findPrefecture(this.examination.selectedFamilyProfile.address_prefecture_id).name : null,
          patient_address_city: this.examination.selectedFamilyProfile.address_city,
          patient_address_street: this.examination.selectedFamilyProfile.address_street,
          patient_address_other: this.examination.selectedFamilyProfile.address_other,
          patient_height: this.examination.selectedFamilyProfile.height,
          patient_weight: this.examination.selectedFamilyProfile.weight,
          patient_body_temperature: this.examination.selectedFamilyProfile
            .basal_body_temperature,
          patient_family_profile_id: this.examination.selectedFamilyProfile.id,
        },
      }

      if(InterviewFormComponent.name[this.examination.medicalDepartment.interview_type.interview_table_name] === 'Default'){
        //漢方以外

        reservationFormattedParams = {
          doctor_schedule_id: this.examination.doctorSchedule.id,
          examination_attributes: {
            clinic_id: this.examination.clinic.id,
            medical_department_id: this.examination.medicalDepartment.id,
            expected_prescription_issued_date: this.examination.expectedPrescriptionIssuedDate,
            pharmacy_id: pharmacyId,
            is_medication_mail: isMedicationMail
          },
          interview_attributes: {
            is_first_visit: this.examination.interview.isFirstVisit,
            symptom: this.examination.interview.symptom,
            from_when: this.examination.interview.fromWhen,
            medical_history_select: this.examination.interview.medicalHistorySelect.join(','),
            medical_history: this.examination.interview.medicalHistory,
            is_current_medicine: this.examination.interview.isCurrentMedicine,
            current_medicine: this.examination.interview.currentMedicine,
            allergy_select: this.examination.interview.allergySelect.join(','),
            allergy: this.examination.interview.allergy,
            smoking: this.examination.interview.smoking,
            smoking_past_age_from: this.examination.interview.smokingPastAgeFrom,
            smoking_past_age_to: this.examination.interview.smokingPastAgeTo,
            smoking_past_number: this.examination.interview.smokingPastNumber,
            smoking_present_age_from: this.examination.interview.smokingPresentAgeFrom,
            smoking_present_number: this.examination.interview.smokingPresentNumber,
            other_information: this.examination.interview.otherInformation,
            is_side_effect: this.examination.interview.isSideEffect,
            side_effect: this.examination.interview.sideEffect,
            generic: this.examination.interview.generic,
            is_current_supplement: this.examination.interview.isCurrentSupplement,
            current_supplement: this.examination.interview.currentSupplement,
            patient_insurance_card_image: this.examination.interview
              .insuranceCardImage,
            recipient_certificate_image: this.examination.interview
              .recipientCertificateImage,
            medicine_notebook_image: this.examination.interview.medicineNotebookImage,
            other_useful_image: this.examination.interview.otherUsefulImage,
            health_diagnosis_image: this.examination.interview.healthDiagnosisImage,
            patient_first_name: this.examination.selectedFamilyProfile.first_name,
            patient_first_name_kana: this.examination.selectedFamilyProfile.first_name_kana,
            patient_last_name: this.examination.selectedFamilyProfile.last_name,
            patient_last_name_kana: this.examination.selectedFamilyProfile.last_name_kana,
            patient_email: this.examination.selectedFamilyProfile.email,
            patient_tel: this.examination.selectedFamilyProfile.phone_number,
            patient_birthday: this.examination.selectedFamilyProfile.birthday,
            patient_sex: this.examination.selectedFamilyProfile.sex,
            patient_pregnancy: this.examination.selectedFamilyProfile.pregnancy,
            patient_zip: this.examination.selectedFamilyProfile.zip_code,
            patient_address_prefectures: findPrefecture(this.examination.selectedFamilyProfile.address_prefecture_id) != null ? findPrefecture(this.examination.selectedFamilyProfile.address_prefecture_id).name : null,
            patient_address_city: this.examination.selectedFamilyProfile.address_city,
            patient_address_street: this.examination.selectedFamilyProfile.address_street,
            patient_address_other: this.examination.selectedFamilyProfile.address_other,
            patient_height: this.examination.selectedFamilyProfile.height,
            patient_weight: this.examination.selectedFamilyProfile.weight,
            patient_body_temperature: this.examination.selectedFamilyProfile
              .basal_body_temperature,
            patient_family_profile_id: this.examination.selectedFamilyProfile.id,
          },
          is_copy_images_params: {
            is_copy_medicine_notebook_image: this.examination.interview.isCopyMedicineNotebookImage,
            is_copy_other_useful_image: this.examination.interview.isCopyOtherUsefulImage,
            is_copy_health_diagnosis_image: this.examination.interview.isCopyHealthDiagnosisImage,
            latest_interview_id:  this.examination.interview.latestInterviewId
          }
        };
      } else if (InterviewFormComponent.name[this.examination.medicalDepartment.interview_type.interview_table_name] === 'ChineseMedicine'){
        //漢方
        reservationFormattedParams = {
          doctor_schedule_id: this.examination.doctorSchedule.id,
          examination_attributes: {
            clinic_id: this.examination.clinic.id,
            medical_department_id: this.examination.medicalDepartment.id,
          },
          interview_attributes: {
            blood_pressure: this.examination.interview.bloodPressure,
            sleep_time: this.examination.interview.sleepTime,
            sleep_quality: this.examination.interview.sleepQuality.join(','),
            is_headache: this.examination.interview.isHeadache,
            mental_conditions: this.examination.interview.mentalConditions.join(','),
            cold_sensitivities: this.examination.interview.coldSensitivities.join(','),
            hot_sensitivities: this.examination.interview.hotSensitivities.join(','),
            sweat_conditions: this.examination.interview.sweatConditions.join(','),
            is_appetite: this.examination.interview.isAppetite,
            gastrointestinal_conditions: this.examination.interview.gastrointestinalConditions.join(','),
            bowel_movement: this.examination.interview.bowelMovement,
            urine_condition: this.examination.interview.urineCondition,
            skin_conditions: this.examination.interview.skinConditions.join(','),
            respiratory_conditions: this.examination.interview.respiratoryConditions.join(','),
            is_pains: this.examination.interview.isPains.join(','),
            systemic_symptoms: this.examination.interview.systemicSymptoms.join(','),
            is_menstrual_pain: this.examination.interview.isMenstrualPain,
            is_menstrual_mass: this.examination.interview.isMenstrualMass,
            current_medicine: this.examination.interview.currentMedicine,
            purpose: this.examination.interview.purpose,
            changes_in_physical_condition: this.examination.interview.changesInPhysicalCondition,
            tongue_image: this.examination.interview.tongueImage,
            basal_body_temperature_table: this.examination.interview.basalBodyTemperatureTable,
            patient_first_name: this.examination.selectedFamilyProfile.first_name,
            patient_first_name_kana: this.examination.selectedFamilyProfile.first_name_kana,
            patient_last_name: this.examination.selectedFamilyProfile.last_name,
            patient_last_name_kana: this.examination.selectedFamilyProfile.last_name_kana,
            patient_email: this.examination.selectedFamilyProfile.email,
            patient_tel: this.examination.selectedFamilyProfile.phone_number,
            patient_birthday: this.examination.selectedFamilyProfile.birthday,
            patient_sex: this.examination.selectedFamilyProfile.sex,
            patient_pregnancy: this.examination.selectedFamilyProfile.pregnancy,
            patient_zip: this.examination.selectedFamilyProfile.zip_code,
            patient_address: this.examination.selectedFamilyProfile.address,
            patient_height: this.examination.selectedFamilyProfile.height,
            patient_weight: this.examination.selectedFamilyProfile.weight,
            patient_body_temperature: this.examination.selectedFamilyProfile
              .basal_body_temperature,
            patient_family_profile_id: this.examination.selectedFamilyProfile.id,
          },
        };
      } else if (InterviewFormComponent.name[this.examination.medicalDepartment.interview_type.interview_table_name] === 'EmergencyContraception'){
        //緊急避妊問診
        reservationFormattedParams.interview_attributes.is_first_visit = this.examination.interview.isFirstVisit
        reservationFormattedParams.interview_attributes.medical_history = this.examination.interview.medicalHistory
        reservationFormattedParams.interview_attributes.is_current_medicine =  this.examination.interview.isCurrentMedicine,
        reservationFormattedParams.interview_attributes.current_medicine =  this.examination.interview.currentMedicine,
        reservationFormattedParams.interview_attributes.allergy = this.examination.interview.allergy
        reservationFormattedParams.interview_attributes.other_information = this.examination.interview.otherInformation
        reservationFormattedParams.interview_attributes.side_effect = this.examination.interview.sideEffect
        reservationFormattedParams.interview_attributes.generic = this.examination.interview.generic
        reservationFormattedParams.interview_attributes.patient_insurance_card_image = this.examination.interview.insuranceCardImage
        reservationFormattedParams.interview_attributes.datetime_of_sexual_intercourse = this.examination.interview.sexualIntercourseDatetime
        reservationFormattedParams.examination_attributes.pharmacy_id = pharmacyId;
      } else if (InterviewFormComponent.name[this.examination.medicalDepartment.interview_type.interview_table_name] === 'Pms'){
        reservationFormattedParams = {
          doctor_schedule_id: this.examination.doctorSchedule.id,
          examination_attributes: {
            clinic_id: this.examination.clinic.id,
            medical_department_id: this.examination.medicalDepartment.id,
            pharmacy_id: pharmacyId,
            expected_prescription_issued_date: this.examination.expectedPrescriptionIssuedDate,
            is_medication_mail: isMedicationMail
          },
          interview_attributes: {
            is_first_visit: this.examination.interview.isFirstVisit,
            symptom: this.examination.interview.symptom,
            from_when: this.examination.interview.fromWhen,
            latest_menstruation_date: this.examination.interview.latestMenstruationDate,
            is_possibility_of_pregnancy: this.examination.interview.isPossibilityOfPregnancy,
            medical_history_select: this.examination.interview.medicalHistorySelect.join(','),
            medical_history: this.examination.interview.medicalHistory,
            is_disease: this.examination.interview.isDisease,
            blood_pressure: this.examination.interview.bloodPressure,
            is_serious_disease_before: this.examination.interview.isSeriousDiseaseBefore,
            allergy_select: this.examination.interview.allergySelect.join(','),
            allergy: this.examination.interview.allergy,
            smoking: this.examination.interview.smoking,
            smoking_past_age_from: this.examination.interview.smokingPastAgeFrom,
            smoking_past_age_to: this.examination.interview.smokingPastAgeTo,
            smoking_past_number: this.examination.interview.smokingPastNumber,
            smoking_present_age_from: this.examination.interview.smokingPresentAgeFrom,
            smoking_present_number: this.examination.interview.smokingPresentNumber,
            is_current_medicine: this.examination.interview.isCurrentMedicine,
            current_medicine: this.examination.interview.currentMedicine,
            is_pills_before: this.examination.interview.isPillsBefore,
            pills: this.examination.interview.pills,
            is_side_effect: this.examination.interview.isSideEffect,
            side_effect: this.examination.interview.sideEffect,
            generic: this.examination.interview.generic,
            is_current_supplement: this.examination.interview.isCurrentSupplement,
            current_supplement: this.examination.interview.currentSupplement,
            other_information: this.examination.interview.otherInformation,
            patient_insurance_card_image: this.examination.interview
              .insuranceCardImage,
            recipient_certificate_image: this.examination.interview
              .recipientCertificateImage,
            pills_prescription_image: this.examination.interview.pillsPrescriptionImage,
            medicine_notebook_image: this.examination.interview.medicineNotebookImage,
            other_useful_image: this.examination.interview.otherUsefulImage,
            patient_first_name: this.examination.selectedFamilyProfile.first_name,
            patient_first_name_kana: this.examination.selectedFamilyProfile.first_name_kana,
            patient_last_name: this.examination.selectedFamilyProfile.last_name,
            patient_last_name_kana: this.examination.selectedFamilyProfile.last_name_kana,
            patient_email: this.examination.selectedFamilyProfile.email,
            patient_tel: this.examination.selectedFamilyProfile.phone_number,
            patient_birthday: this.examination.selectedFamilyProfile.birthday,
            patient_sex: this.examination.selectedFamilyProfile.sex,
            patient_pregnancy: this.examination.selectedFamilyProfile.pregnancy,
            patient_zip: this.examination.selectedFamilyProfile.zip_code,
            patient_address_prefectures: findPrefecture(this.examination.selectedFamilyProfile.address_prefecture_id) != null ? findPrefecture(this.examination.selectedFamilyProfile.address_prefecture_id).name : null,
            patient_address_city: this.examination.selectedFamilyProfile.address_city,
            patient_address_street: this.examination.selectedFamilyProfile.address_street,
            patient_address_other: this.examination.selectedFamilyProfile.address_other,
            patient_height: this.examination.selectedFamilyProfile.height,
            patient_weight: this.examination.selectedFamilyProfile.weight,
            patient_body_temperature: this.examination.selectedFamilyProfile
              .basal_body_temperature,
            patient_family_profile_id: this.examination.selectedFamilyProfile.id,
          },
          is_copy_images_params: {
            is_copy_pills_prescription_image: this.examination.interview.isCopyPillsPrescriptionImage,
            is_copy_medicine_notebook_image: this.examination.interview.isCopyMedicineNotebookImage,
            is_copy_other_useful_image: this.examination.interview.isCopyOtherUsefulImage,
            latest_interview_id:  this.examination.interview.latestInterviewId
          }
        };
      }
      this.$emit('clinicReservationConfirmed', reservationFormattedParams, () => {
        this.loading = false
        callback()
      });
    },
  },
};
</script>
