<template>
  <div>
    <section class="p-guide">
    <div class="p-guide__container">
      <div class="p-guide__header">
        <img src="@/../assets/images/health_checkup_support/guide/guide_header.png" alt="">
      </div>
      
      <keep-alive>
        <component :is="currentComponent"
                  ref="currentComponent"
        />
      </keep-alive>
      <button @click="moveToNextStepAndTrackEvent" class="p-guide__button">
        <span>次へ</span>
        <span class="p-guide__arrow"></span>
      </button>
      <div class="p-guide__footer">
        <ul class="p-guide__flow">
          <li class="p-guide__flowItem" :class="{ 'p-guide__active': currentStep === 1 }"></li>
          <li class="p-guide__flowItem" :class="{ 'p-guide__active': currentStep === 2 }"></li>
          <li class="p-guide__flowItem" :class="{ 'p-guide__active': currentStep === 3 }"></li>
          <li class="p-guide__flowItem" :class="{ 'p-guide__active': currentStep === 4 }"></li>
        </ul>
        <a @click="$mixpanel.track('CLICK BUTTON', { page: '健診サポートガイド', buttonName: 'スキップ', userId: currentUser.id, source: currentUser.source })" class="p-guide__skip" href="/health_checkup_support/home">skip</a>
      </div>
    </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import GuideFirst from "@/components/health_checkup_support/guides/GuideFirst";
  import GuideSecond from "@/components/health_checkup_support/guides/GuideSecond";
  import GuideThird from "@/components/health_checkup_support/guides/GuideThird";
  import GuideForth from "@/components/health_checkup_support/guides/GuideForth";

  export default {
    components: {
      GuideFirst,
      GuideSecond,
      GuideThird,
      GuideForth,
    },
    data() {
      return {
        currentStep: 1,
        guideSteps: ['GuideFirst', 'GuideSecond','GuideThird','GuideForth']
      }
    },
    computed: {
      currentComponent() {
        return this.guideSteps[this.currentStep - 1]
      },
      ...mapGetters(['currentUser'])
    },
    methods: {
      moveToNextStepAndTrackEvent() {
        this.$mixpanel.track('CLICK BUTTON', { page: '健診サポートガイド', buttonName: '次へ', userId: this.currentUser.id, source: this.currentUser.source })
        if (this.currentStep < 4){
          this.currentStep++
        } else {
          this.$router.push('/health_checkup_support/home')
        }
      },
    },

  }
</script>