<template>
  <ul class='list-inline'>
    <li>
      <span class='footer-label'>DEMECAL_ID</span>
      <span v-text="patientFamilyProfile.demecal_id" />
    </li>
    <li>
      <span class='footer-label'>お問い合わせ番号</span>
      <span v-text="patientFamilyProfile.tracking_number" />
    </li>
    <li>
      <a :href="`/medical_dashboard/patient_family_profiles/${patientFamilyProfile.id}`" class='btn btn-info btn-xs'>
        メディカルダッシュボード
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'KenkoCheckInfo',
    props: ['patientFamilyProfile'],
  }
</script>

<style scoped>
</style>
