<template>
  <section class="p-guide">
    <div class="p-guide__container">
      <div class="p-guide__header">
        <img src="../../../../assets/images/online_consultation/guide-header.png" alt="">
      </div>
      <div class="p-guide__image">
        <img src="../../../../assets/images/online_consultation/guide04.png" alt="guide-image">
      </div>
      <h1 class="p-guide__title">
        医師のマッチング
      </h1>
      <div class="p-guide__text_box">
        <div class="p-guide__text">
          <p>相談内容を元に担当医師が決まります</p>
          <p class="p-guide__additional_text">
            ※相談内容によってはお時間をいただくことがございます
          </p>
        </div>
      </div>

      <button @click="$emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '相談ガイド', buttonName: '次へ', userId: currentUser.id, source: currentUser.source })" class="p-guide__button">
        <span>次へ</span>
        <span class="p-guide__arrow"></span>
      </button>
      <div class="p-guide__footer">
        <ul class="p-guide__flow">
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem p-guide__active"></li>
          <li class="p-guide__flowItem"></li>
        </ul>
        <a @click="$mixpanel.track('CLICK BUTTON', { page: '相談ガイド', buttonName: 'スキップ', userId: currentUser.id, source: currentUser.source })" class="p-guide__skip" href="/online_consultation">skip</a>
      </div>
    </div>
  </section>
</template>


<script>
  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters(['currentUser'])
    }
  }
</script>
