import ActionCable from 'actioncable'
// NOTE: ここがconstだとCIで `Uncaught ReferenceError: Cannot access 'Xt' before initialization` エラーが出る
// 参考: https://teratail.com/questions/289387
var CableApp = CableApp || {};
CableApp.init = () => {
  const token = document.getElementsByName('cable-token')[0].getAttribute('content');
  CableApp.cable = ActionCable.createConsumer(`/cable?token=${token}`);
};

export default CableApp

