<template>
  <div>
    <notice-alert v-for="notice in notices" :notice="notice" />
    <base-title prev-path="/consultations">
      <h1>チャット問診票項目</h1>
      <template slot="tools">
        <button @click="showGroupForm" class="btn btn-info">新規グループ作成</button>
      </template>
    </base-title>
    <div v-if="isShowGroupForm" class="form-group">
      <base-text-field
        name="group_name"
        labelText="グループ名"
        v-model="groupForm.name"
        :value="groupForm.name"
        :errors="errors.name">
      </base-text-field>
      <button @click="submitGroup" class="btn btn-primary">作成</button>
    </div>
    <base-loading-icon :target="groups">
      <ul class="list-group">
        <li v-for="group in groups" :key="group.id" class="list-group-item">
          <div class="row">
            <div class="col-sm-10">
              {{group.name}}
            </div>
            <div class="col-sm-2">
              <router-link :to="`/chat_interview/groups/${group.id}`" class="btn btn-success">項目</router-link>
            </div>
          </div>
        </li>
      </ul>
    </base-loading-icon>
  </div>
</template>

<script>
  import {actionTypes} from '../../store/modules/chatInterview/groups'
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ChatInterviewGroups',
    created() {
      this.fetchGroups();
    },
    data(){
      return ({
        groupForm: {
          name: ""
        },
        errors: {
          name: null
        },
        isShowGroupForm: false
      })
    },
    computed: {
      ...mapGetters({
        notices: 'notices',
        groups: 'chatInterview/groups/query'})
    },
    methods: {
      showGroupForm() {
        this.$data.isShowGroupForm = true
      },
      submitGroup() {
        this.postGroup({group: this.groupForm})
          .then(_ => {  this.$data.groupForm.name = '' })
          .catch(errors => { this.$data.errors = errors})
      },
      ...mapActions({
        fetchGroups: `chatInterview/groups/${actionTypes.FETCH_QUERY}`,
        postGroup: `chatInterview/groups/${actionTypes.POST}`
      })
    },
    watch: {
    }
  }

</script>

<style scoped lang="scss">
</style>
