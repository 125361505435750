import axios from 'axios'
import User from '../../enums/User'
import * as rootMutationTypes from '../mutation-types'
import {LOADING, LOADING_QUERY} from '../loading'
import qs from 'qs'

export const actionTypes = {
  FETCH_USERS: 'FETCH_USERS',
  FETCH_ADMIN: 'FETCH_ADMIN',
  FETCH_CUSTOMER_SERVICE: 'FETCH_CUSTOMER_SERVICE',
  FETCH_DOCTOR: 'FETCH_DOCTOR',
  FETCH_PATIENT: 'FETCH_PATIENT',
  FETCH_PATIENT_COUNT: 'FETCH_PATIENT_COUNT',
  FETCH_USER: 'FETCH_USER',
  PATCH_USER_ROLE: 'PATCH_USER_ROLE'
};

export const mutationTypes = {
  SET_USER: 'SET_USER',
  SET_ADMIN: 'SET_ADMIN',
  SET_CUSTOMER_SERVICE: 'SET_CUSTOMER_SERVICE',
  SET_DOCTOR: 'SET_DOCTOR',
  SET_PATIENT: 'SET_PATIENT',
  SET_PATIENT_COUNT: 'SET_PATIENT_COUNT'
};

const state = {
  user: LOADING,
  admins: LOADING_QUERY,
  customerServices: LOADING_QUERY,
  doctors: LOADING_QUERY,
  patients: LOADING_QUERY,
  patientCount: LOADING_QUERY,
};

const getters = {
  user: state => state.user,
  admins: state => state.admins,
  doctors: state => state.doctors,
  customerServices: state => state.customerServices,
  patients: state => state.patients,
  patientCount: state => state.patientCount
};

const actions = {
  async [actionTypes.FETCH_USER] ({commit}, {id}) {
    const res = await axios.get(`/api/management_users/${id}`)
      .catch(({response}) =>
        commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } )
      );
    commit(mutationTypes.SET_USER, {user: res.data});
    return res.data
  },
  async [actionTypes.FETCH_USERS] ({commit}, {role}) {
    const res = await axios.get('/api/management_users', { params: {role}, paramsSerializer(params) { return qs.stringify(params) } })
      .catch(({response}) =>
        commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } )
      );
    return res
  },
  async [actionTypes.FETCH_PATIENT_COUNT] ({commit}) {
    const res = await axios.get('/api/consultations/patient_count', {paramsSerializer(params) { return qs.stringify(params) } })
      .catch(({response}) =>
        commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } )
      );
    commit(mutationTypes.SET_PATIENT_COUNT, {patientCount: res.data});
    return res.toString()
  },
  [actionTypes.FETCH_ADMIN] ({commit, dispatch}) {
    dispatch(actionTypes.FETCH_USERS, {role: 'admin'})
      .then(response => commit(mutationTypes.SET_ADMIN, {users: response.data}))
  },
  [actionTypes.FETCH_CUSTOMER_SERVICE] ({commit, dispatch}) {
    dispatch(actionTypes.FETCH_USERS, {role: 'customer_service'})
      .then(response => commit(mutationTypes.SET_CUSTOMER_SERVICE, {users: response.data}))
  },
  [actionTypes.FETCH_DOCTOR] ({commit, dispatch}) {
    dispatch(actionTypes.FETCH_USERS, {role: 'doctor'})
      .then(response => commit(mutationTypes.SET_DOCTOR, {users: response.data}))
  },
  [actionTypes.FETCH_PATIENT] ({commit, dispatch}) {
    dispatch(actionTypes.FETCH_USERS, {role: 'patient'})
      .then(response => commit(mutationTypes.SET_PATIENT, {users: response.data}))
  },

  async [actionTypes.PATCH_USER_ROLE] ({commit}, {id, role}) {
    const res = await axios.patch(`/api/management_users/${id}`, {user: {role}})
      .catch(({response}) => {
        commit(rootMutationTypes.PUSH_NOTICE, { message: "更新に失敗しました.", type: 'danger' }, { root: true } );
        throw response;
      });
    return res
  }
};

const mutations = {
  [mutationTypes.SET_ADMIN] (state, {users}) {
    state.admins = users
  },
  [mutationTypes.SET_CUSTOMER_SERVICE] (state, {users}) {
    state.customerServices = users
  },
  [mutationTypes.SET_DOCTOR] (state, {users}) {
    state.doctors = users
  },
  [mutationTypes.SET_PATIENT] (state, {users}) {
    state.patients = users
  },
  [mutationTypes.SET_USER] (state, {user}) {
    state.user = user
  },
  [mutationTypes.SET_PATIENT_COUNT] (state, {patientCount}) {
    state.patientCount = patientCount
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
