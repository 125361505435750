import axios from 'axios'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const state = {
  readMessage: null,
  patientReadMessage: null
};

const getters = {
  readMessage: state => state.readMessage,
  patientReadMessage: state => state.patientReadMessage,
  lastReadTime: state => state.lastReadTime
};

const actions = {
  [actionTypes.FETCH_READ_MESSAGE] ({ state, commit }, chatId) {
    axios.get(`/api/chats/${chatId}/read_message`)
      .then(response => commit(mutationTypes.SET_READ_MESSAGE, response.data))
  },
  [actionTypes.FETCH_PATIENT_READ_MESSAGE]({ state, commit }, chatId) {
    axios.get(`/api/chats/${chatId}/read_message/patient_read_message`)
      .then(response => commit(mutationTypes.SET_PATIENT_READ_MESSAGE, response.data))
  },
  [actionTypes.ATTACH_READ_MESSAGE] ({ state, commit, rootState }, readMessage) {
    if(readMessage.user_id === rootState.currentUser.currentUser.id ) {
      commit(mutationTypes.SET_READ_MESSAGE, readMessage);
    }
  }
};

const mutations = {
  [mutationTypes.SET_READ_MESSAGE] (state, readMessage) {
     state.readMessage = readMessage
  },
  [mutationTypes.SET_PATIENT_READ_MESSAGE](state, readMessage) {
    state.patientReadMessage = readMessage
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}

