<template>
  <div class="p-onlineConsultationList">
    <p class="p-onlineConsultationList__title">ご相談一覧</p>
    <div v-show="isContainerVisible" class="p-onlineConsultationList__container l-ignoreParentContainer">
      <div  class="p-onlineConsultationList__box" :class="addBoxClass(item.status)" v-for="(item, key) in consultations" :key="key" @click="goToChat(item.chat.id); $mixpanel.track('CLICK BUTTON', { page: 'ご相談一覧', buttonName: '【各相談内容へのリンク】', userId: currentUser.id, source: currentUser.source })">
        <div v-if="item.status > 0 && item.doctor_profiles[0]" class="p-onlineConsultationList__doctorProfile" @click.stop="displayModal(item.doctor_profiles[0])">
          <img :src="item.doctor_profiles[0].image.url">
          <span>{{displayDoctorName(item.doctor_profiles[0])}}</span>
        </div>
        <div class="p-onlineConsultationList__tagFrame">
          <div class="p-onlineConsultationList__tag" :class="addTagClass(item.status)">
            {{displayTag(item.status)}}
          </div>
        </div>
        <div class="p-onlineConsultationList__contents">
          {{displayContent(item.content)}}
        </div>
        <div class="p-onlineConsultationList__timeStamp">
          {{displayCreatedAt(item.created_at)}}
        </div>
      </div>
      <div v-show="isButtonVisible" class="c-subButton" @click="fetchConsultations()">
        もっと見る
      </div>
    </div>
    <div class="p-onlineConsultationList__footer">
      <a class="p-onlineConsultationList__back" @click="startConsultation(); $mixpanel.track('CLICK BUTTON', { page: 'ご相談一覧', buttonName: '新しい相談をはじめる', userId: currentUser.id, source: currentUser.source, isCvButton: true })">新しい相談をはじめる</a>
    </div>
    <doctor-profile-modal :profile='selectedDoctorProfile' v-on:close-modal="closeModal()" v-show="isModalVisible"></doctor-profile-modal>
    <consultation-type-select v-if="isConsultationTypeSelectOpen" @close-modal="isConsultationTypeSelectOpen = false"></consultation-type-select>
  </div>
</template>

<script>
import axios from 'axios';
import DoctorProfileModal from '@/components/online_consultation/consultations/DoctorProfileModal'
import { mapGetters, mapActions } from 'vuex';
import * as PatientFamilyProfile from  'store/modules/patientFamilyProfiles';
import ConsultationTypeSelect from "@/components/online_consultation/home/ConsultationTypeSelect";
export default {
  components: { DoctorProfileModal, ConsultationTypeSelect },
  data() {
    return {
      offset: 0,
      limit: 11,
      consultations: [],
      isContainerVisible: false,
      isButtonVisible: false,
      isModalVisible: false,
      selectedDoctorProfile: {
        imageUrl: '',
        firstName: '',
        lastName: '',
        sex: '',
        expert: '',
        graduateMedicalOffice: '',
        selfIntroduction: ''
      },
      isConsultationTypeSelectOpen: false
    };
  },
  created() {
    this.fetchConsultations()
    this.fetchPatientFamilyProfiles()
  },
  methods: {
    async fetchConsultations() {
      const res = await axios.get('/api/online_consultation/consultations', {
      params: { status: 'all', offset: this.offset, limit: this.limit },
      });
      this.consultations = this.consultations.concat(res.data.slice(0, 10));
      if (res.data.length > 10) {
        this.offset = this.offset + 10
        this.isButtonVisible = true
      } else {
        this.isButtonVisible = false
      }
      if (!this.isContainerVisible) {
        this.isContainerVisible = true
      }
    },
    displayContent(content) {
      if (content.length > 64) {
        return content.substr(0, 64) + '...'
      } else {
        return content
      }
    },
    displayCreatedAt(createdAt) {
      return createdAt.substr(0, 10) + ' ' + createdAt.substr(11, 5)
    },
    displayDoctorName(doctorProfile) {
      if (doctorProfile){
        return doctorProfile.first_name + ' ' + doctorProfile.last_name + ' 医師'
      }
    },
    displayTag(status) {
      if (status == 0){
        return '担当医師未定'
      } else if (status == 1 || status == 2){
        return '相談中'
      } else if (status == 3 || status == 4){
        return '相談終了'
      } else {
        return ''
      }
    },
    addTagClass(status) {
      if (status == 0){
        return 'p-onlineConsultationList__doctorUndecided'
      } else if (status == 1 || status == 2){
        return 'p-onlineConsultationList__inConsultation'
      } else if (status == 3 || status == 4){
        return 'p-onlineConsultationList__consultationFinished'
      } else {
        return ''
      }
    },
    addBoxClass(status) {
      if (status == 0 || status == 1 || status == 2){
        return 'p-onlineConsultationList__onGoing'
      } else if (status == 3 || status == 4){
        return 'p-onlineConsultationList__finished'
      } else {
        return ''
      }
    },
    displayModal(doctorProfile) {
      this.selectedDoctorProfile.imageUrl = doctorProfile.image.url
      this.selectedDoctorProfile.firstName = doctorProfile.first_name
      this.selectedDoctorProfile.lastName = doctorProfile.last_name
      this.selectedDoctorProfile.sex = doctorProfile.sex
      this.selectedDoctorProfile.expert = doctorProfile.expert
      this.selectedDoctorProfile.graduateMedicalOffice = doctorProfile.graduate_medical_office
      this.selectedDoctorProfile.selfIntroduction = doctorProfile.self_introduction
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    goToChat(chatId) {
      if (chatId){
        window.location.href = `/chat/${chatId}`
      }
    },
    startConsultation() {
      if (this.isCompletedLoading) {
        if (this.patientFamilyProfiles.length > 0) {
          this.isConsultationTypeSelectOpen = true
        } else {
          this.$router.push({ name: 'patient_family_profile_new', params: {isFromOnlineConsultationHome: true}})
        }
      }
    },
    ...mapActions({ fetchPatientFamilyProfiles: `patientFamilyProfiles/${PatientFamilyProfile.actionTypes.FETCH_QUERY}` })
  },
  computed: {
    isCompletedLoading() {
      return this.patientFamilyProfiles != 'LOADING_QUERY'
    },
    currentUrl() {
      return location.href
    },
    ...mapGetters(['currentUser']),
    ...mapGetters({patientFamilyProfiles: 'patientFamilyProfiles/query'})
  },
};
</script>
