<template>
  <div class="p-healthCheckupSupportConsultationHistories">
    <p class="p-healthCheckupSupportConsultationHistories__title">過去の健診サポート</p>
    <p v-if="!existConsultations">まだ過去の診察はありません。</p>
    <ul v-if="existConsultations" class="p-healthCheckupSupportConsultationHistories__list">
      <li class="p-healthCheckupSupportConsultationHistories__item" v-for="consultation in consultations" :value="consultation" :key="consultation.id">
        <div class="p-healthCheckupSupportConsultationHistories__box">
            <img src="@/../assets/images/online_clinic/status.png" alt="">
            <p class="p-healthCheckupSupportConsultationHistories__status">{{statusText(consultation.status)}}</p>
            <p class="p-healthCheckupSupportConsultationHistories__date">
              ビデオ通話日時：{{ formatDate(consultation.web_mtg_start) }}
            </p>
        </div>
        <p>{{ getDoctorName(consultation)}}</p>
        <a @click="goDetail(consultation.id)" class="c-subButton">
            詳細を確認
        </a>
      </li>
    </ul>
    <div class="p-healthCheckupSupportConsultationHistories__footer">
      <a class="p-healthCheckupSupportConsultationHistories__back" @click="$router.go(-1); $mixpanel.track('CLICK BUTTON', { page: '過去の診療', buttonName: 'もどる', userId: currentUser.id, source: currentUser.source })">もどる</a>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        consultations: [],
      }
    },
    async created() {
      const res = await axios.get(`/api/health_checkup_support/consultations`,
        {
          params:{
            status: 'completed_or_canceled'
          }
        }
      )
      this.consultations = res.data
    },
    computed: {
      existConsultations(){
        if (this.consultations.length > 0){
          return true;
        }else{
          return false;
        }
      },
      ...mapGetters(['currentUser']),
    },
    methods: {
      formatDate(dateTime) {
        moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
        return moment(dateTime).format('YYYY/MM/DD(ddd)');
      },
      statusText (status) {
        if (status === 'canceled') {
          return 'キャンセル'
        } else if (status === 'completed') {
          return '完了済み'
        }
      },
      getDoctorName(consultation) {
        return consultation.doctor.doctor_profile.first_name + ' ' + consultation.doctor.doctor_profile.last_name + ' 医師'
      },
      goDetail(id) {
        this.$router.push(`/health_checkup_support/consultation_histories/${id}`)
      }
    }
  }
</script>