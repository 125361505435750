<template>
  <div>
    <div class="consultation-date">
      <div class='status consultation-status'>
        <span class="label" :class="labelClass">{{labelText}}</span>
      </div>

      <div class='panel-time-header text-center'>
        {{consultation.created_at | moment('YYYY-MM-DD HH:mm')}}
      </div>
    </div>

    <div class="panel-title">
      <base-user-icon v-for="doctorProfile in consultation.doctor_profiles"
                       :key="doctorProfile.id"
                       :full-name="`${doctorProfile.first_name} ${doctorProfile.last_name}`"
                       :icon-url="doctorProfile.image.icon.url">

      </base-user-icon>
    </div>
  </div>
</template>

<script>
  import ChatEnum from '../../enums/Chat'

  const statusHash = {
    [ChatEnum.Status.active] : {
      labelClass: 'label-info',
      labelText: '相談中'
    },
    [ChatEnum.Status.finish_requested] : {
      labelClass: 'label-primary',
      labelText: '終了リクエスト中'
    },
    [ChatEnum.Status.finished] : {
      labelClass: 'label-default',
      labelText: '終了'
    },
    [ChatEnum.Status.ready] : {
      labelClass: 'label-warning',
      labelText: '未担当'
    }
  };

  export default {
    name: 'PatientHeader',
    props: ['consultation'],
    computed: {
      chat () {
        return this.consultation.chat
      },
      labelClass() {
        return statusHash[this.chat.status].labelClass;
      },

      labelText() {
        return statusHash[this.chat.status].labelText;
      }
    }
  }
</script>

<style scoped>
  .consultation-date {
    display: flex;
    align-items: center;
    margin: 0 -16px 20px;
    padding: 0 15px 15px;
    border-bottom: 1px solid #ebeced;
  }

  .panel-time-header {
    margin-left: auto;
  }

  .panel-title {
    font-size: 14px;
  }
</style>
