<template>
  <div v-show="patientFamilyProfileCategory" class="btn-group" role="group" >
    <button @click="active" :class="activeButtonClassName">有効</button>
    <button @click="disable" :class="disableButtonClassName">無効</button>
  </div>
</template>

<script>
  export default {
    name: 'CategoryField',
    props: ['patientFamilyProfileCategory'],
    computed: {
      activeButtonClassName () {
        return `btn btn-${ this.patientFamilyProfileCategory._destroy ? 'default' : 'success' }`
      },
      disableButtonClassName () {
        return `btn btn-${ this.patientFamilyProfileCategory._destroy ? 'success' : 'default' }`
      },
    },
    methods: {
      active () {
        this.patientFamilyProfileCategory._destroy = false;
      },
      disable () {
        this.patientFamilyProfileCategory._destroy = true;
      }
    }
  }
</script>

<style scoped>
</style>
