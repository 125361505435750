<template>
  <div class="p-patientFamilyProfile">
    <notice-alert v-for="notice in notices" :notice="notice"/>
    <h2 class="p-patientFamilyProfile__subTitle">プロフィールの確認</h2>
    <base-loading-icon :target="patientFamilyProfiles">
      <ul class="p-patientFamilyProfile__profileList">
        <patient-family-profile-list-item v-for="patientFamilyProfile in patientFamilyProfiles"
                                          :patient-family-profile="patientFamilyProfile"
                                          :key="patientFamilyProfile.id">
        </patient-family-profile-list-item>
      </ul>
    </base-loading-icon>

    <router-link slot="tools" :to="`/patient_family_profiles/new`" class="p-patientFamilyProfile__addlink">
      家族プロフィールの追加
    </router-link>

    <hr/>

    <h2 class="p-patientFamilyProfile__subTitle">支払い情報</h2>

    <div class="p-patientFamilyProfile__creditCard">
      <h3 class="p-patientFamilyProfile__creditCardHeader">現在登録のクレジットカード番号</h3>
      <div v-if="isCreditCardRegistered">
        <div class="p-patientFamilyProfile__textCenter">xxxx-xxxx-xxxx{{creditCardLast4}}</div>
        <router-link :to="'/stripe_authorization'" class="c-mainButton">
          クレジットカードを変更する
        </router-link>
      </div>
      <div v-else>
        <div>未登録</div>
        <router-link :to="'/stripe_authorization'" class="c-mainButton">
          クレジットカード情報の登録
        </router-link>
      </div>
    </div>

    <div class="p-patientFamilyProfile__currentPlan">
      <h3 class="p-patientFamilyProfile__currentPlanHeader">現在登録中のプラン</h3>
      <div class="p-patientFamilyProfile__currentPlanBody">
        <template v-if="isSubscribe">
          <span class="p-patientFamilyProfile__planName">{{subscriptingPlanName}}</span>
          <span class="p-patientFamilyProfile__unsubscribeLink">
            <a href="#" @click.stop="isPlanCancelModalShow = true">登録解除</a>
          </span>
        </template>
        <template v-else>
          <span>未登録</span>
        </template>
      </div>
    </div>

    <hr/>

    <h2 class="p-patientFamilyProfile__subTitle">設定</h2>

    <div class="p-patientFamilyProfile__setting">
      <input type="hidden" name="_method" value="patch">
      <div class="form-group">
        <label class="control-label" for="user_email">メールアドレス</label>
        <input type="email" id="user_email" v-model="user.email" class="form-control">
        <small class="p-patientFamilyProfile__emailAttention">
          「@docomo.ne.jp」「@ezweb.ne.jp」などキャリアのメールをご利用の場合は、仮会員登録メールが届かない可能性がありますので、その場合は各キャリアの<span>【受信設定】</span>をご参考いただき、受信許可リストに「@anamne.com」を登録してください。
        </small>
      </div>

      <div class="form-group">
        <label class="control-label" for="user_password">パスワード</label>
        <input type="password" id="user_password" v-model="user.password" class="form-control">
      </div>

      <div class="form-group">
        <label class="control-label" for="user_password_confirmation">パスワード確認</label>
        <input type="password" id="user_password_confirmation" v-model="user.password_confirmation" class="form-control">
      </div>
      <button @click="updateAccount" class="c-mainButton">変更する</button>
    </div>

    <a href="/users/auth/google" class="c-snsButton"><img src='../../assets/images/logo_google.png' alt="google logo">Googleと連携する</a>
    <a href="/users/auth/facebook" class="c-snsButton"><img src='../../assets/images/logo_facebook.png' alt="facebook logo">Facebookと連携する</a>
    <a href="/users/auth/apple" class="c-snsButton"><img src='../../assets/images/logo_apple.png' alt="apple logo">Appleと連携する</a>

    <hr />
    <div class="p-patientFamilyProfile__footer">
      <a @click="isCancelModalShow = true; $mixpanel.track('CLICK BUTTON', { page: '会員情報', buttonName: '退会する場合はこちら', userId: currentUser.id, source: currentUser.source })" class="p-patientFamilyProfile__withdraw">退会する場合はこちら</a>
      <a class="p-patientFamilyProfile__back" :href="referrer">もどる</a>
    </div>
    <cancel-modal v-if="isCancelModalShow" @closeModal="isCancelModalShow = false"></cancel-modal>
    <plan-cancel-modal v-if="isPlanCancelModalShow" @closeModal="isPlanCancelModalShow = false"></plan-cancel-modal>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import * as patientFamilyProfiles from '../store/modules/patientFamilyProfiles'
  import NoticeAlert from "./NoticeAlert";
  import PatientFamilyProfileListItem from './PatientFamilyProfiles/PatientFamilyProfileListItem'
  import BaseTitle from "./base/BaseTitle";
  import BaseLoadingIcon from "./base/BaseLoadingIcon";
  import {LOADING_QUERY} from '../store/loading'
  import * as actionTypes  from '../store/action-types'
  import PlanTypes from '@/const/plan_types'
  import CancelModal from "./CancelModal";
  import PlanCancelModal from "./PlanCancelModal";

  export default {
    name: 'PatientFamilyProfiles',
    components: { BaseLoadingIcon, BaseTitle, NoticeAlert, PatientFamilyProfileListItem, CancelModal, PlanCancelModal },

    data() {
      return {
        user: {
          email: null,
          password: null,
          password_confirmation: null
        },
        isCancelModalShow: false,
        isPlanCancelModalShow: false
      }
    },

    mounted() {
      this.fetchPatientFamilyProfiles()
      this.user.email = this.currentUser.email
    },

    computed: {
      ...mapGetters({
        notices: 'notices',
        patientFamilyProfiles: 'patientFamilyProfiles/query'
      }),
      isCreditCardRegistered() {
        return this.currentUser.is_creditcard_registered
      },
      creditCardLast4() {
        return this.currentUser.default_card.last4
      },
      isSubscribe() {
        return this.currentUser.is_subscribe
      },
      referrer() {
        if ( document.referrer && !document.referrer.match(/patient_family_profiles/)) {
          return document.referrer
        } else {
          return '/'
        }
      },
      subscriptingPlanName() {
        if (this.isSubscribe) {
          if (this.currentUser.subscripting_plan.plan_type == PlanTypes.BASIC_PLAN) {
            return "相談し放題 月額:980円"
          } else if (this.currentUser.subscripting_plan.plan_type == PlanTypes.TRIAL_PLAN) {
            return "相談し放題 月額:980円（初月無料）"
          } else if (this.currentUser.subscripting_plan.plan_type == PlanTypes.KANPO_PLAN) {
            return "相談し放題 月額:980円"
          } else if (this.currentUser.subscripting_plan.plan_type == PlanTypes.STANDARD_PLAN) {
            return "スタンダードプラン 月額7,700円"
          } else if (this.currentUser.subscripting_plan.plan_type == PlanTypes.PREMIUM_PLAN) {
            return "プレミアムプラン 月額16,500円"
          }
        } else {
          return null
        }
      },
      ...mapGetters(['currentUser', 'isPatient'])

    },

    methods: {
      ...mapActions({
        fetchPatientFamilyProfiles: `patientFamilyProfiles/${patientFamilyProfiles.actionTypes.FETCH_QUERY}`,
        updateCurrentUser: actionTypes.PATCH_CURRENT_USER
      }),
      updateAccount() {
        this.updateCurrentUser({ user: this.user }).then(() => {
          this.user.password = ''
          this.user.password_confirmation = ''
        })
      },
    }
  }
</script>
</style>
