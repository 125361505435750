const User = {};

User.Role = {
  admin: 0,
  doctor: 1,
  patient: 2,
  customerService: 3
};

export default User;
