<template>
  <span v-if="isShow" class="chat-log-content">
    <a :href="hrefUrl.href" :target="hrefUrl.target">
      <img :src="chatLog.image"/>
    </a>
  </span>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import * as actionTypes from '../../store/action-types'

  export default {
    name: 'ChatLogImage',
    props: ['chatLog', 'itemType'],
    methods: {
    },
    computed: {
      hrefUrl () {
        return { href: this.chatLog.full_image, target: '_blank' }
      },
      isShow () {
        return this.itemType === ChatLogEnum.ChatItemTypes.IMAGE
      },
      ...mapGetters(['currentUser', 'chat'])
    }
  }
</script>

<style scoped lang="scss">
  span {
    display: block;
  }

  .chat-log-content img {
    max-width:100%;
  }
</style>
