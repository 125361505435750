<template>
  <transition name="c-baseModal">
    <div class="c-baseModal__mask">
      <div class="c-baseModal__wrapper">
        <div class="c-baseModal__container">
          <div class="c-baseModal__header">
            <slot name="header"></slot>
          </div>
          <div class="c-baseModal__body">
            <slot name="body"></slot>
          </div>
          <div class="c-baseModal__footer">
            <slot name="footer">
              <button class="c-baseModal__defaultButton btn btn-default" @click="historyBack">
                戻る
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from '../../event-bus';
export default {
  methods: {
    historyBack() {
      if (this.$route.path == '/online_clinic/clinic/reservation/new') {
        this.closeCreditModal();
      } else {
        this.$router.go(-1);
      }
    },
    closeCreditModal: function() {
      EventBus.$emit('close-credit-modal');
    },
  },
};
</script>