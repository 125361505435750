import Vue from 'vue'
import Vuex from 'vuex'
import notices from './modules/notices';
import currentUser from './modules/currentUser';
import consultations from './modules/consultations';
import chat from './modules/chat';
import chatLog from './modules/chatLog';
import readMessage from './modules/readMessage';
import patientFamilyProfiles from './modules/patientFamilyProfiles';

import patientFamilyProfile from './modules/medicalDashboard/patientFamilyProfile';
import categories from './modules/medicalDashboard/categories';
import item from './modules/medicalDashboard/items';
import patientFamilyProfileCategories from './modules/medicalDashboard/patientFamilyProfileCategories';

import chatLogInterview from './modules/chatLogs/interview'

import interview from './modules/interview'
import chatInterview from './modules/chatInterview'
import doctor from './modules/doctor'
import users from './modules/users'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notices,
    currentUser,
    consultations,
    chat,
    chatLog,
    readMessage,
    chatLogInterview,
    doctor: {
      namespaced: true,
      modules: {
        patientFamilyProfiles: {
          namespaced: true,
          ...doctor.patientFamilyProfiles
        }
      }
    },
    patientFamilyProfiles: {
      namespaced: true,
      ...patientFamilyProfiles
    },
    medicalDashboard: {
      modules: {
        patientFamilyProfile,
        categories,
        item,
        patientFamilyProfileCategories
      }
    },
    interview: {
      namespaced: true,
      modules: {
        masters: {
          namespaced: true,
          ...interview.masters
        }
      }
    },
    chatInterview,
    users
  }
});

export default store;
