<template>
  <input class="form-control" :id="item.id" type="number" v-model="item.value_float"/>
</template>

<script>
  export default {
    name: 'ItemFloatInput',
    props: ['item'],
    computed: {
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
