import mixpanel from 'mixpanel-browser';
if (process.env.NODE_ENV == 'production') {
  mixpanel.init('cc492d8f9d80d1207591b2ba4c0a3ee9');
} else if (process.env.NODE_ENV == 'development') {
  mixpanel.init('6f861da02596fbba936af097fc114413', {debug: true});
}
const mixpanelWrapper = {
  track: (eventName, { isCvButton, source, ...params }) => {
    if (process.env.NODE_ENV == 'production' || 'development') {
      mixpanel.track(eventName, { isCvButton: isCvButton || false, source: source || null, ...params })
    }
  }
}

export const MixpanelPlugin = {
  install: function (Vue, options) {
    Vue.prototype.$mixpanel = mixpanelWrapper
  }
}
