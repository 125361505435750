<template>
  <div>
    <div class="form-group">
      <notice-alert v-for="notice in notices" :notice="notice" />
    </div>
    <div>
      <h1>問診項目新規作成</h1>
      <router-link to="/interview/masters" class="btn btn-default">
        戻る
      </router-link>
    </div>

    <master-form>
      <template slot="submit">
        <div class="btn-group">
          <input @click="postInterviewMaster({master, callback: (id => $router.push(`/interview/masters/${id}/edit`)) })" type="submit" value="作成" class="btn btn-primary">
        </div>
      </template>
    </master-form>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as Masters from '../store/modules/interview/masters'
  import NoticeAlert from "./NoticeAlert";
  import MasterForm from "./Interview/MasterForm";

  export default {
    name: 'InterviewMasterNew',
    components: { MasterForm, NoticeAlert },

    created () {
      this.initialInterviewMaster();
    },

    computed: {
      ...mapGetters({ notices: 'notices', master: 'interview/masters/get' })
    },

    methods: {
      ...mapActions({
        postInterviewMaster: `interview/masters/${Masters.actionTypes.POST_INTERVIEW_MASTER}`
      }),
      ...mapMutations({
        initialInterviewMaster: `interview/masters/${Masters.mutationTypes.INITIAL_INTERVIEW_MASTER}`
      })
    }
  }
</script>

<style scoped lang="scss">
</style>
