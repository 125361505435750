<template>
  <div class="p-onlineClinicSelectSchedule">
    <div v-if="apiDoctorScheduleConflictError">
      <p class="p-onlineClinicSelectSchedule__notice">選択いただいた予約枠が埋まってしまいました。お手数ですが、別の日程をご選択ください。</p>
    </div>
    <loading :translucent="true" v-show="loading"></loading>
    <div class="p-onlineClinicSelectSchedule__container l-ignoreParentContainer">
      <div class="p-onlineClinicSelectSchedule__box">
        <p class="p-onlineClinicSelectSchedule__label">診療科目</p>
        <p class="p-onlineClinicSelectSchedule__clinic">{{ selectedMedicalDepartmentName }}</p>
        <p class="p-onlineClinicSelectSchedule__label">診療機関</p>
        <p class="p-onlineClinicSelectSchedule__clinic">{{ selectedClinicName }}</p>
      </div>
    </div>

    <div class="p-onlineClinicSelectSchedule__primary">
      <p class="p-onlineClinicSelectSchedule__label">予約したい日時</p>
      <Datepicker class="p-onlineClinicSelectSchedule__select"
                  :language="ja"
                  :format="DatePickerFormat"
                  v-model='selectedDate'
                  :disabled-dates="disabledDates"
                  ></Datepicker>
      <div v-if="isDateSelected">
      <p class="p-onlineClinicSelectSchedule__label">{{ scheduleSelectLabelText }}</p>

      <div v-if='!isDoctorScheduleSelected'>
        <div v-if="noAvailableSchedules">
          <p class="p-onlineClinicSelectSchedule__clinic">診療可能な時間帯がありません。</p>
          <p class="p-onlineClinicSelectSchedule__clinic-supplement">お手数ですが、再度日付を選択し直してください</p>
        </div>

        <div v-else>
          <p class="p-onlineClinicSelectSchedule__selectMessage">下記より選択してください</p>
          <p class="p-onlineClinicSelectSchedule__prescriptionMessage">※クリニック本体の営業時間中に、処方せんを発行します。予約時間によっては、処方せん発行までお時間をいただく場合がございます。</p>
          <p class="p-onlineClinicSelectSchedule__prescriptionMessage">※開始2時間前からのキャンセルおよび無断キャンセルは、キャンセル料金［2,000円］が発生します。</p>
          <ul class="p-onlineClinicSelectSchedule__list">
            <li class="p-onlineClinicSelectSchedule__item" v-for="doctorSchedule in doctorSchedules"
                :key="doctorSchedule.id"
                @click="doctorScheduleSelected(doctorSchedule); $mixpanel.track('CLICK BUTTON', { page: '時間の選択', buttonName: '【各予約時間】', userId: currentUser.id, source: currentUser.source })">
              {{ formattedDoctorSchedule(doctorSchedule) }}
              {{ nextWorkingDate(doctorSchedule.end, clinicReservationToCreate.clinicSchedules) ? joinString(nextWorkingDate(doctorSchedule.end, clinicReservationToCreate.clinicSchedules).format("M/D")) : '' }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <p>{{ formattedDoctorSchedule(selectedDoctorSchedule) }}</p>
        <ul class="p-onlineClinicSelectSchedule__list">
            <li class="p-onlineClinicSelectSchedule__item" v-for="doctorSchedule in doctorSchedules"
                :key="doctorSchedule.id"
                @click="doctorScheduleSelected(doctorSchedule); $mixpanel.track('CLICK BUTTON', { page: '時間の選択', buttonName: '【各予約時間】', userId: currentUser.id, source: currentUser.source })">
              {{ formattedDoctorSchedule(doctorSchedule) }}
              {{ nextWorkingDate(doctorSchedule.end, clinicReservationToCreate.clinicSchedules) ? joinString(nextWorkingDate(doctorSchedule.end, clinicReservationToCreate.clinicSchedules).format("M/D")) : '' }}
            </li>
          </ul>
      </div>
    </div>

    <div class="p-onlineClinicSelectSchedule__footer">
      <button class="p-onlineClinicSelectSchedule__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '時間の選択', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import { ja } from 'vuejs-datepicker/dist/locale';
import EventBus from '../../../../event-bus';
import Loading from '@/components/Common/Loading';
import {mapGetters} from "vuex";

export default {
  name: 'SelectSchedule',
  props: {
    clinicReservationToCreate: {
      required: true,
      type: Object,
    },
    apiDoctorScheduleConflictError: {
      type: Boolean
    },
  },
  components: {
    Datepicker, Loading
  },
  data: () => {
    return {
      medicalDepartment: {},
      clinic: {},
      doctor: {},
      doctorSchedules: {},
      selectedDoctorSchedule: {},
      selectedDate: '',
      ja: ja,
      DatePickerFormat: 'yyyy-MM-dd',
      loading: false,
    };
  },
  created() {
    this.fetchMedicalDepartment();
    this.fetchClinic();

    EventBus.$on('clear-doctor-schedule', () => {
      this.selectedDate = '';

      // examinationの中のdoctorScheduleをクリアする
      EventBus.$emit('clear-examination-doctor-schedule');
    });
  },

  computed: {
    selectedMedicalDepartmentName() {
      return this.medicalDepartment['name'];
    },
    selectedClinicName() {
      return this.clinic['name'];
    },
    isDateSelected() {
      return this.selectedDate != '';
    },
    noAvailableSchedules() {
      return this.doctorSchedules.length == 0;
    },
    scheduleSelectLabelText() {
      return this.isDoctorScheduleSelected ? '選択した時間' : '予約可能な時間';
    },
    isDoctorScheduleSelected() {
      return this.selectedDoctorSchedule.id != undefined;
    },
    disabledDates() {
      const date = new Date();
      const availableDates = this.$props.clinicReservationToCreate.availableDates;
      return {
        to: new Date(date.setDate(date.getDate() - 1)),
        customPredictor: function(date) {
          const dateString = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
            if (!availableDates.includes(dateString)){
              return true
            }
        }
      };
    },
    latestDoctorScheduleDatetime() {
      const date = this.clinicReservationToCreate.latestDoctorSchedule
      return moment(date.start).format("M/D") + ' ' + moment(date.start).format("HH:mm") + '〜' + moment(date.end).format("HH:mm")
    },
    ...mapGetters(['currentUser'])
  },
  methods: {
    async fetchMedicalDepartment() {
      try {
        let res = await axios.get(
          `/api/online_clinic/medical_departments/${
            this.clinicReservationToCreate.medicalDepartment.id
          }`
        );
        this.medicalDepartment = res.data;
      } catch {
        alert('ERROR');
      }
    },
    async fetchClinic() {
      try {
        let res = await axios.get(
          `/api/online_clinic/clinics/${
            this.clinicReservationToCreate.clinic.id
          }`
        );
        this.clinic = res.data;
      } catch {
        alert('ERROR');
      }
    },
    async fetchDoctorSchedules(formattedDate) {
      this.loading = true
      try {
        let res = await axios.get('/api/online_clinic/doctor_schedules', {
          params: {
            date: formattedDate,
            clinic_id: this.clinicReservationToCreate.clinic.id,
            medical_department_id: this.clinicReservationToCreate.medicalDepartment.id,
          },
        });
        this.doctorSchedules = res.data;
      } catch {
        alert('ERROR');
      }
      this.loading = false
    },
    formattedDoctorSchedule(schedule) {
      let profile = schedule.user.doctor_profile;
      let doctorProfile = `${profile.first_name}${profile.last_name}`;
      moment.locale('ja', {
        weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
      });
      let startTime = moment(schedule.start).format('HH:mm');
      let endTime = moment(schedule.end).format('HH:mm');
      let day = moment(schedule.end).format('YYYY/MM/DD(ddd)');
      return `${day} ${startTime} ~ ${endTime} ${doctorProfile}`;
    },
    doctorScheduleSelected(schedule) {
      this.selectedDoctorSchedule = schedule;
      const nextWorkingDate = this.nextWorkingDate(schedule.end, this.clinicReservationToCreate.clinicSchedules)
      const expectedPrescriptionIssuedDate = 
        nextWorkingDate
          ? nextWorkingDate.format("YYYY-MM-DD")
          : null;

      const data = {
        doctorSchedule: this.selectedDoctorSchedule,
        expectedPrescriptionIssuedDate: expectedPrescriptionIssuedDate
      };
      this.$emit('addExaminationData', data);
      this.$emit('moveToNextStep');
    },
    nextWorkingDate(datetime, clinicSchedules) {
      // 前画面で選択したクリニックに、スケジュール登録されていない場合表示しない
      if (clinicSchedules.length === 0) return

      // 選択した医者のスケジュール以降に、クリニックスケジュールが登録されていない場合表示しない
      clinicSchedules = clinicSchedules.filter(function(schedule) {
        datetime = moment(datetime)
        const scheduleEnd = moment(schedule.end)
        return scheduleEnd.isAfter(datetime, 'minute')
      });
      if (clinicSchedules.length === 0) return

      // クリニックスケジュールを開始時刻でソートする
      clinicSchedules.sort((a, b) => moment(a.start) - moment(b.start))
      return moment(clinicSchedules[0].start)
    },
    joinString (string) {
      const forwardString = '※処方せん発行【'
      const backwardString = '】'
      return forwardString + string + backwardString
    },
  },
  watch: {
    selectedDate: function(val) {
      this.doctorSchedules = {};
      this.selectedDoctorSchedule = {};
      this.fetchDoctorSchedules(val);
    },
    clinicReservationToCreate: function(val) {
      this.fetchMedicalDepartment();
      this.fetchClinic();
    },
    apiDoctorScheduleConflictError: function(val) {
      if (this.apiDoctorScheduleConflictError) {
        this.selectedDate = ''
      }
    },
  },
};
</script>
