<template>
  <base-modal>
    <template slot="header">
      <h3>ボタン作成</h3>
    </template>
    <template slot="body">
        <base-text-optional-field
        label-text="テキスト"
        name="name"
        v-model="name"
        ></base-text-optional-field>
        <base-text-optional-field
        label-text="リンク先URL"
        name="url"
        v-model="url"
        ></base-text-optional-field>
    </template>
    <template slot="footer">
      <button type="button" @click="handleSubmit" class="btn btn-primary" id="js-require-payment" :disabled="!name || !url">ボタンを作成する</button>
      <button type="button" @click="$emit('buttonRequestCancel')" class="btn btn-link">キャンセル</button>
    </template>
  </base-modal>
</template>

<script>
  import BaseTextOptionalField from './base/Form/BaseTextUnitOptionalField';
  import EventBus from '../event-bus';

  export default {
    components: {
      BaseTextOptionalField,
    },
    data() {
      return {
        name: null,
        url: null
      }
    },
    methods: {
      handleSubmit() {
        EventBus.$emit('chat-button-create',this.name, this.url);
        this.$emit('buttonRequestCancel')
      }
    }
  }
</script>
