<template>
  <div v-show="!masterItem._destroy" class="master-item-field row">
    <div class="col-sm-11">
      <base-text-field label-text="選択肢名" name="master_item_label" v-model="masterItem.label" :errors="errors.label"></base-text-field>
      <base-check-box-field v-model="masterItem.is_supplement_text" label-text="追加テキストフィールド" name="master_item_is_supplement_text"></base-check-box-field>
      <base-check-box-field v-model="masterItem.is_publishing" label-text="公開中" name="master_item_is_publishing"></base-check-box-field>
      <button class="btn btn-danger" @click="onDestroy" type="button">削除</button>
    </div>
    <div class="col-sm-1">
      <base-order-change-button-group></base-order-change-button-group>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import * as Masters from '../../../store/modules/interview/masters'
  import BaseTextField from "../../base/Form/BaseTextField";
  import BaseCheckBoxField from "../../base/Form/BaseCheckBoxField";
  import BaseOrderChangeButtonGroup from '../../base/BaseOrderChangeButtonGroup'

  export default {
    name: 'MasterItemField',
    components: { BaseCheckBoxField, BaseTextField, BaseOrderChangeButtonGroup },
    props: ['masterItem'],
    computed: {
      errors() {
        return (this.masterItem.errors || {})
      }
    },
    methods: {
      onDestroy() {
        this.setInterviewMasterItemDestroy({masterItem: this.masterItem})
      },
      ...mapMutations({ setInterviewMasterItemDestroy: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTER_ITEM__DESTROY}` })
    }
  }

</script>
<style scoped lang="scss">
  .master-item-field {
    padding-bottom: 8px;
    border-bottom: #eee solid 1px;
    margin-bottom: 8px;
  }
  .master-item-field:last-child {
    border-bottom: 0;
  }
</style>
