<template>
  <select class="form-control" :id="item.id" v-model="item.value_boolean">
    <option></option>
    <option :value="true">{{item.true_label}}</option>
    <option :value="false">{{item.false_label}}</option>
  </select>
</template>

<script>
  export default {
    name: 'ItemBooleanInput',
    props: ['item'],
    computed: {
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
