<template>
  <i :class="className"></i>
</template>

<script>
  export default {
    name: 'InterviewTypeIcon',
    props: ['interviewType'],
    computed: {
      className() {
        switch(this.interviewType) {
          case 'text':
             return 'fa fa-font';
          case 'radio':
            return 'fa fa-stop-circle-o';
          case 'checkboxes':
            return 'fa fa-check-square-o';
          case 'file':
            return 'fa fa-file-o';
        }
      },
    }
  }
</script>

<style scoped lang="scss">
</style>
