<template>
  <div :class="invalidClass" class="form-group">
    <label v-if="!hiddenLabel" :for="name" class="control-label">{{labelText}}</label>
    <textarea
      :id="name"
      :name="name"
      :value="value"
      @input="onInput"
      :disabled="$attrs.disabled"
      class="form-control"
    />
    <span
      v-if="errors"
      v-for="error in errors"
      class="help-block"
    >
      {{error}}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'BaseTextArea',
    props: ['name', 'labelText', 'value', 'errors', 'hiddenLabel'],
    data () {
      return { text: this.value }
    },
    computed: {
      invalidClass () {
        return !!this.errors ? ' has-error' : ''
      }
    },
    methods: {
      onInput(e) {
        this.text = e.target.value;
        this.$emit('input', this.text)
      }
    }
  }
</script>
