<template>
  <section class="p-HealthCheckupSupportHome">
    <flash-message />
    <notice-alert v-for="(notice, index) in notices" :notice="notice" v-bind:key="index" />
    <component :is="navigationComponent" :consultation="consultation"></component>
    <div class="p-HealthCheckupSupportHome__guide">
      <a href="/health_checkup_support/guide" class="c-link--ceruleanBlue" @click="$mixpanel.track('CLICK BUTTON', { page: '健診サポートトップ', buttonName: '使い方ガイド', userId: currentUser.id, source: currentUser.source })">使い方ガイド</a>
    </div>
    <footer-survey-link page="健診サポートトップ"></footer-survey-link>
  </section>
</template>

<script>
import axios from 'axios';
import BeforeReservation from '@/components/health_checkup_support/home/BeforeReservation';
import BeforeWebMtg from '@/components/health_checkup_support/home/BeforeWebMtg';
import AfterWebMtg from '@/components/health_checkup_support/home/AfterWebMtg';
import FlashMessage from '@/components/online_clinic/FlashMessage';
import Progress from '../../../enums/Progress';
import {mapGetters} from "vuex";
import FooterSurveyLink from "@/components/footer/FooterSurveyLink";
import NoticeAlert from "@/components/NoticeAlert";

export default {
  components: {
    BeforeReservation,
    BeforeWebMtg,
    AfterWebMtg,
    FlashMessage,
    FooterSurveyLink,
    NoticeAlert,
  },
  data() {
    return {
      consultation: null,
      progressState: 0,
    };
  },
  computed: {
    navigationComponent() {
      switch (this.progressState) {
        case Progress.HealthCheckupSupportState.beforeReservation:
          return 'BeforeReservation';
        case Progress.HealthCheckupSupportState.beforeWebMtg:
          return 'BeforeWebMtg';
        case Progress.HealthCheckupSupportState.afterWebMtg:
          return 'AfterWebMtg';
      }
    },
    hasEmergencyConsultation() {
      return this.consultation != null && this.consultation.chat != null;
    },
    ...mapGetters(['currentUser', 'notices'])
  },
  created() {
    this.getProgress()
    this.getConsultation()  
  },
  methods: {
    async getProgress() {
      const res = await axios.get(`/api/health_checkup_support/progress`);
      this.progressState = res.data.state;
    },
    async getConsultation() {
      const res = await axios.get(`/api/health_checkup_support/consultations/latest`)
      this.consultation = res.data
    }
  }
};
</script>
