<template>
  <i v-bind:class="className"></i>
</template>

<script>
  export default {
    name: 'WayTypeIcon',
    props: ['wayType'],
    computed: {
      className() {
        switch(this.wayType) {
          case 'patient_question':
            return 'fa fa-file-text-o';
          case 'chat_log':
            return 'fa fa-commenting-o';
        }
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
