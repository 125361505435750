<template>
  <div class="p-onlineClinicStatusBar">
    <span class="p-onlineClinicStatusBar__straightLine"></span>
    <div
      class="p-onlineClinicStatusBar__circle01"
      :class="{
        active: this.examinationStatus === 0
      }"
    >
      <p>診療機関の選択</p>
    </div>

    <div
      class="p-onlineClinicStatusBar__circle02"
      :class="{
        active: this.examinationStatus === 1
      }"
    >
      <p>時間の選択</p>
    </div>
    <div
      class="p-onlineClinicStatusBar__circle03"
      :class="{
        active: this.examinationStatus === 2
      }"
    >
      <p>問診の回答</p>
    </div>
  </div>
</template>

<script>
import Examination from '../../enums/Examination';

export default {
  name: 'StatusBar',
  props: ['currentStep', 'statusType'],
  watch: {
    currentStep: {
      handler: function() {
        if (this.$props.statusType === 'clinicReservation') {
          if (this.$props.currentStep <= 2) {
            this.examinationStatus = Examination.Status.clinicSelection;
          } else if (this.$props.currentStep <= 3) {
            this.examinationStatus = Examination.Status.datetimeSelection;
          } else if (this.$props.currentStep <= 4) {
            this.examinationStatus = Examination.Status.interviewAnswer;
          } else {
            return null;
          }
        }
      },
      immediate: true,
    },
  },
  data: () => {
    return {
      examinationStatus: 0,
    };
  },
};
</script>