<template>
  <div class="result">
    <div class="check-mark"></div>
    <base-nl2br
      tag="p"
      :text="result.message"
      :text-filter="textFilterWithHref"
      tag-class="result-title"
    />
    <p class="result-content">
      {{result.content}}
    </p>
    <p v-for="resultItem in result.result_items" :key="resultItem.id" class="result-content">
      {{ resultItem.label }}
    </p>
    <div
      v-for="resultFile in result.result_files"
      :key="resultFile.id"
      class="file-wrapper"
    >
      <img
        :src="resultFile.url"
        alt="アップロードファイル"
      />
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {actionTypes} from '../../../store/modules/chatLogs/interview';
  import { textFilterWithHref } from '../../../shared/Helper'

  export default {
    name: 'ChatLogInterviewResult',
    props: {
      result: {
        type: Object,
        required: true
      },
      chatLogId: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapGetters({findResult: `chatLogInterview/findResult` })
    },
    methods: {
      textFilterWithHref
    }
  }
</script>

<style lang="scss" scoped>
.result {
  margin-bottom: 16px;

  .result-title {
    font-weight: 500;
    margin-bottom: 8px;
    color: #2196F3;
    line-height: 21px;
    display: inline;
    font-size: 12px;
  }

  .result-content {
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #2A3039;
  }
  .file-wrapper {
    img {
      width: 180px;
      height: auto;
      object-fit: scale-down;
    }
  }

  .check-mark {
    width: 12px;
    height: 8px;
    border-bottom: solid 2px #04AAE4;
    border-left: solid 2px #04AAE4;
    display: inline-block;
    transform: rotate(-45deg);
    margin: 0 0 3px 0;
  }

}
</style>
