const ChatLogEnum = {};

ChatLogEnum.logType = {
  message: 0,
  choice_board: 1,
  choice_answered: 2,
  interview: 3,
  finish_request: 10,
  finish_request_answered: 11,
  routine_message: 100,
  subscriptionRequest: 32490
};

ChatLogEnum.ChatItemTypes = {
  MESSAGE: 'MESSAGE',
  IMAGE: 'IMAGE',
  INTERVIEW: 'INTERVIEW',
  BOARD: 'BOARD',
  ROUTINE_MESSAGE: 'ROUTINE_MESSAGE',
  SUBSCRIPTION_REQUEST: 'SUBSCRIPTION_REQUEST'
};

ChatLogEnum.chatItemType = (chatLog) => {
  if (chatLog.image) {
    return (ChatLogEnum.ChatItemTypes.IMAGE);
  } else if (chatLog.log_type == ChatLogEnum.logType.message ||
    chatLog.log_type == ChatLogEnum.logType.choice_answered ||
    chatLog.log_type == ChatLogEnum.logType.finish_request ||
    chatLog.log_type == ChatLogEnum.logType.finish_request_answered ||
    chatLog.log_type == ChatLogEnum.logType.routine_message) {
    return (ChatLogEnum.ChatItemTypes.MESSAGE);
  } else if (chatLog.log_type === ChatLogEnum.logType.interview) {
    return ChatLogEnum.ChatItemTypes.INTERVIEW;
  } else if (chatLog.log_type === ChatLogEnum.logType.subscriptionRequest) {
    return ChatLogEnum.ChatItemTypes.SUBSCRIPTION_REQUEST;
  } else if (chatLog.log_type == ChatLogEnum.logType.choice_board && chatLog.choices != null) {
    return (ChatLogEnum.ChatItemTypes.BOARD);
  }

};

export default ChatLogEnum
