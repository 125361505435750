<template>
  <div class="row">
    <chat-image-input @close="$emit('close')">
      <div class="p-chat__formOption p-chat__wdRatio">
        <i class="fa fa-camera fa-3x" aria-hidden="true"></i>
        <p class="p-chat__helpOption">
          画像を選択
        </p>
      </div>
    </chat-image-input>
    <router-link :to="`/chat/${$route.params.chat_id}/interviews/new`"
                 @click.native="$emit('close')"
                 class="p-chat__formOption p-chat__wdRatio">
      <i class="fa fa-medkit fa-3x" aria-hidden="true"></i>
      <p class="p-chat__helpOption">
        問診表
      </p>
    </router-link>
    <router-link :to="`/chat/${$route.params.chat_id}/patient_family_profile`"
                 @click.native="$emit('close')"
                 class="p-chat__formOption p-chat__wdRatio">
      <i class="fa fa-stethoscope fa-3x" aria-hidden="true"></i>
      <p class="p-chat__helpOption">
        患者情報
      </p>
    </router-link>
    <button @click="createSubscriptionRequest" v-if="!isUserDeleted && !subscriptionRequesting && !chat.patient.is_subscribe" class="p-chat__formOption p-chat__wdRatio" id="requireSubscriptionButton">
      <i class="fa fa-credit-card-alt fa-3x" aria-hidden="true"></i>
      <span class="p-chat__helpOption">
        課金してもらう
      </span>
    </button>
    <button @click="createButtonRequest" class="p-chat__formOption p-chat__wdRatio">
      <i class="fa fa-tag fa-3x" aria-hidden="true"></i>
      <span class="p-chat__helpOption">
        ボタンを作成
      </span>
    </button>
    <button v-if="isAdmin" @click="copyInfoToClipboard" class="p-chat__formOption p-chat__wdRatio">
      <i class="fa fa-copy fa-3x" aria-hidden="true"></i>
      <span class="p-chat__helpOption">
        相談の基本情報をコピー
      </span>
    </button>
    <button @click="createRoutineMessageRequest" class="p-chat__formOption p-chat__wdRatio">
      <i class="fa fa-commenting-o fa-3x" aria-hidden="true"></i>
      <span class="p-chat__helpOption">
        定型文投稿
      </span>
    </button>
    <button v-if="isCustomerService" @click="copyToClipboard" class="p-chat__formOption p-chat__wdRatio">
      <i class="fa fa-clipboard fa-3x" aria-hidden="true"></i>
      <span class="p-chat__helpOption">
        依頼用定型文コピー
      </span>
    </button>
    <button id="cy-finish-request-button"
            class="p-chat__formOption p-chat__wdRatio"
            :disabled="this.$data.finishRequestDisabled"
            @click="finishConsultationRequest">
      <i class="fa fa-check-square-o fa-3x" aria-hidden="true"></i>
      <span class="p-chat__helpOption">終了する</span>
    </button>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as actionTypes from '../../store/action-types'
  import * as mutationTypes from '../../store/mutation-types'
  import ChatImageInput from './ChatImageInput'

  export default {
    name: 'ChatFormOption',
    components: { ChatImageInput },
    data() {
      return {
        finishRequestDisabled: false
      }
    },
    computed: {
      ...mapGetters(['isCustomerService', 'chat', 'subscriptionRequesting', 'isAdmin']),
      isUserDeleted() {
        return this.chat.patient == null
      }
    },
    methods: {
      createSubscriptionRequest() {
        this.$emit("clickSubscriptionRequest")
      },
      createButtonRequest() {
        this.$emit('clickButtonRequest');
      },
      async copyInfoToClipboard(){
        const url =  `${location.origin}/chat/${this.chat.id}`
        const text = `相談ID：${this.chat.consultation.id}\n性別：${this.chat.patient_family_profile.sex_text}\n年齢：${this.chat.patient_family_profile.age}\nURL：${url}`

        if (navigator.clipboard) {
          try {
            await navigator.clipboard.writeText(text)
            alert('クリップボードにコピーしました')
          } catch (err) {
            console.error('Failed to copy text: ', err)
          }
        } else {
          const pre = document.createElement('pre')
          pre.style.webkitUserSelect = 'auto'
          pre.style.userSelect = 'auto'
          pre.textContent = text
          document.body.appendChild(pre)
          document.getSelection().selectAllChildren(pre)
          const result = document.execCommand('copy')
          document.body.removeChild(pre)
          if (result) {
            alert('クリップボードにコピーしました')
          } else {
            alert('クリップボードにコピーできませんでした')
          }
        }
      },
      createRoutineMessageRequest() {
        this.$emit('clickRoutineMessageRequest');
      },
      async copyToClipboard(){
        //HTML要素を一時的に生成しクリップボードにコピーする
        const url =  `${location.origin}/chat/${this.chat.id}`
        const text = `【ご対応お願いいたします】\n相談ID：${this.chat.consultation.id}\n年齢：${this.chat.patient_family_profile.age}\n性別：${this.chat.patient_family_profile.sex_text}\n▼相談内容\n${this.chat.consultation.content}\n▼URL\n${url}`

        if (navigator.clipboard) {
          try {
            await navigator.clipboard.writeText(text)
            alert('クリップボードにコピーしました')
          } catch (err) {
            console.error('Failed to copy text: ', err)
          }
        } else {
          const pre = document.createElement('pre');

          pre.style.webkitUserSelect = 'auto';
          pre.style.userSelect = 'auto';
          pre.textContent = text;

          document.body.appendChild(pre);
          document.getSelection().selectAllChildren(pre);
          const result = document.execCommand('copy');

          document.body.removeChild(pre);

          if (result) {
            alert("クリップボードにコピーしました");
          } else {
            alert("クリップボードにコピーできませんでした");
          }
        }
      },
      finishConsultationRequest () {
        this.$emit('clickFinishRequest');
      },
      ...mapActions({
        postFinishRequest: actionTypes.POST_FINISH_REQUEST,
        postFinish: actionTypes.POST_FINISH,
        postChatLog: actionTypes.POST_CHAT_LOG
      }),
    }
  }
</script>

<style scoped lang="scss">
  a, button {
    color: #444;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
  .row {
    margin: 0 2%;
  }
</style>
