import axios from 'axios'
import {LOADING} from '../../loading'

export const actionTypes = {
  FETCH: 'FETCH_INTERVIEW',
  POST_RESULT: 'POST_RESULT',
  POST_RESULT_WITH_FILES: 'POST_RESULT_WITH_FILE_FILES',
  DELETE_RESULT: 'DELETE_RESULT'
};

export const mutationTypes = {
  SET_INTERVIEW: 'SET_INTERVIEW',
  SET_RESULT: 'SET_RESULT',
  REMOVE_RESULT: 'REMOVE_RESULT'
};

const state = {
  interviews: {}
};

const getters = {
  item: state => chatLogId => state.interviews[chatLogId] || LOADING,
  findResult: state => (chatLogId, master_id) => state.interviews[chatLogId].chat_interview_results.find(result => result.master_id === master_id)
};

const toParams = (resultForm) => ({
  chat_log_id: resultForm.chatLogId,
  master_id: resultForm.masterId,
  content: resultForm.content,
  result_items_attributes: resultForm.resultItems.map(ri => ({
    master_item_id: ri.masterItem.id,
    _destroy: ri._destroy
  }))
});

const toFormData = resultForm => {
  let formData = new FormData();
  formData.append('result[chat_log_id]', resultForm.chatLogId);
  formData.append('result[master_id]', resultForm.masterId);
  resultForm.resultFiles.map((resultFile, idx) => {
    formData.append(`result[result_files_attributes][${idx}][file]`, resultFile.file);
  })
  return formData
};

const actions = {
  [actionTypes.FETCH] ({commit}, {chatLogId}) {
    return axios.get(`/api/chat_logs/interviews/${chatLogId}`)
      .then(response => {
        commit(mutationTypes.SET_INTERVIEW, {interview: response.data})
      })
  },
  [actionTypes.POST_RESULT] ({commit}, {result}) {
    return axios.post('/api/chat_interview/results', { result: toParams(result) })
      .then(response => {
        commit(mutationTypes.SET_RESULT, { result: response.data })
        return response
      })
  },
  [actionTypes.POST_RESULT_WITH_FILES] ({commit}, {result}) {
    let config = { headers: { 'content-type': 'multipart/form-data' } };
    return axios.post('/api/chat_interview/results', toFormData(result), config)
      .then(response => {
        commit(mutationTypes.SET_RESULT, { result: response.data })
        return response
      })
  },
  [actionTypes.DELETE_RESULT] ({commit}, {result}) {
    return axios.delete(`/api/chat_interview/results/${result.id}`)
      .then(response => {
        commit(mutationTypes.REMOVE_RESULT, {result: result})
      })
  }
};

const mutations = {
  [mutationTypes.SET_INTERVIEW] (state, {interview}) {
    const interviews = {...state.interviews};
    interviews[interview.chat_log_id] = interview;
    state.interviews = interviews
  },
  [mutationTypes.SET_RESULT] (state, {result}) {
    const interview = state.interviews[result.chat_log_id];
    if(interview.chat_interview_results.map(r => r.id).includes(result.id)) { return }
    state.interviews[interview.chat_log_id].chat_interview_results = [...interview.chat_interview_results, result];
  },
  [mutationTypes.REMOVE_RESULT] (state, {result}) {
    const interview = state.interviews[result.chat_log_id];
    if(!interview.chat_interview_results.map(r => r.id).includes(result.id)) { return }
    state.interviews[interview.chat_log_id].chat_interview_results = interview.chat_interview_results.filter(r => r.id != result.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
