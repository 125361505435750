<template>
  <section class="p-patientFamilyProfile">
    <notice-alert v-for="notice in notices" :notice="notice"/>
    <base-title :prev-path="'/patient_family_profiles'">
      <h1 class="p-patientFamilyProfile__title" slot>
        プロフィール編集
      </h1>
      <div class="p-patientFamilyProfile__guidance">
      サービスをご利用される際には、プロフィールを登録してください。
      <br>※オンラインクリニックをご利用される場合には、すべての項目の登録が必要です。
      </div>
    </base-title>
    <patient-family-profile-form :isNewForm="false" :isFromOnlineClinic="isFromOnlineClinic" @submit="submit"></patient-family-profile-form>
  </section>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import NoticeAlert from './NoticeAlert';
  import PatientFamilyProfileForm from './PatientFamilyProfileForm';
  import * as patientFamilyProfiles from '../store/modules/patientFamilyProfiles';
  import BaseTitle from './base/BaseTitle';

  export default {
    name: 'PatientFamilyProfile',
    components: {BaseTitle, NoticeAlert, PatientFamilyProfileForm},
    created: function () {
      this.fetchPatientFamilyProfile({
        id: this.$route.params.id,
        then: this.fetchNewResultsOr,
      });
    },
    computed: {
      isFromOnlineClinic() {
        return this.$route.params['isFromOnlineClinicReservation']
      },
      ...mapGetters({
        notices: 'notices',
        patientFamilyProfiles: 'patientFamilyProfiles/query',
        patientFamilyProfile: 'patientFamilyProfiles/get',
        isRequireInterview: 'patientFamilyProfiles/isRequireInterview',
      }),
    },
    methods: {
      fetchNewResultsOr(patientFamilyProfile) {
        if (this.isRequireInterview(patientFamilyProfile)) {
          this.fetchNewResults();
        }
      },
      submit() {
        this.patchPatientFamilyProfile({
          patientFamilyProfile: this.patientFamilyProfile,
        }).then((response) => {
          if (response.status === 200){
            this.$router.back()
          }
        });
      },
      ...mapActions({
        fetchNewResults: `patientFamilyProfiles/${patientFamilyProfiles.actionTypes.FETCH_NEW_RESULTS}`,
        fetchPatientFamilyProfile: `patientFamilyProfiles/${patientFamilyProfiles.actionTypes.FETCH_GET}`,
        patchPatientFamilyProfile: `patientFamilyProfiles/${patientFamilyProfiles.actionTypes.PATCH}`,
      }),
    },
  };
</script>

