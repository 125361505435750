const Consultation = {};

Consultation.status = {
  new: 0,
  now: 1,
  request: 2,
  end: 3,
  deleted: 4
};

Consultation.consultationTypes = {
  normal: {
    value: 1,
    label: "医療相談"
  },
  supplement: {
    value: 2,
    label: "サプリメント"
  },
  kampo: {
    value: 3,
    label: "漢方"
  },
  examination: {
    value: 4,
    label: "遠隔診療"
  }
}

export default Consultation;
