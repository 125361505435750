import axios from 'axios'
import * as rootMutationTypes from '../../mutation-types'
import * as rootPatientFamilyProfile from '../patientFamilyProfiles'
import { LOADING } from '../../loading'

export const Sex = rootPatientFamilyProfile.Sex;
export const Pregnancy = rootPatientFamilyProfile.Pregnancy;

export const actionTypes = {
  FETCH_GET: 'FETCH_GET',
};

export const mutationTypes = {
  SET_GET: 'SET_GET',
  REFRESH_GET: 'REFRESH_GET'
};

const state = {
  patientFamilyProfile: LOADING
};

const getters = {
  get: state => state.patientFamilyProfile,
};

const actions = {
  [actionTypes.FETCH_GET] ({ commit }, { id }) {
    return axios.get(`/api/doctor/patient_family_profiles/${id}`)
      .then(response => {
        commit(mutationTypes.SET_GET, { patientFamilyProfile: response.data });
      })
      .catch((err) => {
        commit(rootMutationTypes.PUSH_NOTICE, { message: "データの取得に失敗しました", type: 'danger' }, {root: true})
      })
  }
};

const mutations = {
  [mutationTypes.SET_GET] (state, payload) {
    state.patientFamilyProfile = payload.patientFamilyProfile
  },
  [mutationTypes.REFRESH_GET] (state) {
    state.patientFamilyProfile = LOADING
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
