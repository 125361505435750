const Chat = {};

Chat.Status = {
  active: 0,
  finish_requested: 1,
  finished: 2,
  ready: null
};

export default Chat
