<template>
  <div class="p-healthCheckupSupportSelectSchedule">
    <div v-if="apiDoctorScheduleConflictError">
      <p class="p-healthCheckupSupportSelectSchedule__notice">選択いただいた予約枠が埋まってしまいました。お手数ですが、別の日程をご選択ください。</p>
    </div>
    <loading :translucent="true" v-show="loading"></loading>
    <div class="p-healthCheckupSupportSelectSchedule__container l-ignoreParentContainer">
      <div class="p-healthCheckupSupportSelectSchedule__box">
        <p class="p-healthCheckupSupportSelectSchedule__label">サポートの種類</p>
        <p class="p-healthCheckupSupportSelectSchedule__type">{{ consultationReservationToCreate.selectedType.name }}</p>
      </div>
    </div>

    <div class="p-healthCheckupSupportSelectSchedule__primary">
      <p class="p-healthCheckupSupportSelectSchedule__label">予約したい日時</p>
      <Datepicker class="p-healthCheckupSupportSelectSchedule__select"
                  :language="ja"
                  :format="DatePickerFormat"
                  v-model='selectedDate'
                  :disabled-dates="disabledDates"
                  ></Datepicker>
      <div v-if="isDateSelected">
      <p class="p-healthCheckupSupportSelectSchedule__label">{{ scheduleSelectLabelText }}</p>

      <div v-if='!isDoctorScheduleSelected'>
        <div v-if="noAvailableSchedules">
          <p class="p-healthCheckupSupportSelectSchedule__clinic">可能な時間帯がありません。</p>
          <p class="p-healthCheckupSupportSelectSchedule__clinic-supplement">お手数ですが、再度日付を選択し直してください</p>
        </div>

        <div v-else>
          <ul class="p-healthCheckupSupportSelectSchedule__list">
            <li class="p-healthCheckupSupportSelectSchedule__item" v-for="doctorSchedule in doctorSchedules"
                :key="doctorSchedule.id"
                @click="doctorScheduleSelected(doctorSchedule); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 予約日時の選択', buttonName: '【各予約時間】', userId: currentUser.id, source: currentUser.source })">
              {{ formattedDoctorSchedule(doctorSchedule) }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <p>{{ formattedDoctorSchedule(selectedDoctorSchedule) }}</p>
        <ul class="p-healthCheckupSupportSelectSchedule__list">
            <li class="p-healthCheckupSupportSelectSchedule__item" v-for="doctorSchedule in doctorSchedules"
                :key="doctorSchedule.id"
                @click="doctorScheduleSelected(doctorSchedule); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 予約日時の選択', buttonName: '【各予約時間】', userId: currentUser.id, source: currentUser.source })">
              {{ formattedDoctorSchedule(doctorSchedule) }}
            </li>
          </ul>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import { ja } from 'vuejs-datepicker/dist/locale';
import Loading from '@/components/Common/Loading';
import {mapGetters} from "vuex";

export default {
  props: {
    consultationReservationToCreate: {
      required: true,
      type: Object,
    },
    apiDoctorScheduleConflictError: {
      type: Boolean
    },
  },
  components: {
    Datepicker, Loading
  },
  data: () => {
    return {
      doctor: {},
      doctorSchedules: {},
      selectedDoctorSchedule: {},
      selectedDate: '',
      ja: ja,
      DatePickerFormat: 'yyyy-MM-dd',
      loading: false,
    };
  },
  created() {
  },

  computed: {
    isDateSelected() {
      return this.selectedDate != '';
    },
    noAvailableSchedules() {
      return this.doctorSchedules.length == 0;
    },
    scheduleSelectLabelText() {
      return this.isDoctorScheduleSelected ? '選択した時間' : '予約可能な時間';
    },
    isDoctorScheduleSelected() {
      return this.selectedDoctorSchedule.id != undefined;
    },
    disabledDates() {
      const date = new Date();
      const availableDates = this.$props.consultationReservationToCreate.availableDates;
      return {
        to: new Date(date.setDate(date.getDate() - 1)),
        customPredictor: function(date) {
          const dateString = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
            if (!availableDates.includes(dateString)){
              return true
            }
        }
      };
    },
    
    ...mapGetters(['currentUser'])
  },
  methods: {
    async fetchDoctorSchedules(formattedDate) {
      this.loading = true
      try {
        const res = await axios.get('/api/health_checkup_support/doctor_schedules', {
          params: {
            date: formattedDate,
            type_id: this.consultationReservationToCreate.selectedType.id,
          },
        });
        this.doctorSchedules = res.data;
      } catch {
        alert('ERROR');
      }
      this.loading = false
    },
    formattedDoctorSchedule(schedule) {
      const profile = schedule.user.doctor_profile;
      const doctorProfile = `${profile.first_name}${profile.last_name}先生`;
      moment.locale('ja', {
        weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
      });
      const startTime = moment(schedule.start).format('HH:mm');
      const endTime = moment(schedule.start).add(30, 'minutes').format('HH:mm');
      const day = moment(schedule.end).format('YYYY/MM/DD(ddd)');
      return `${day} ${startTime} ~ ${endTime} ${doctorProfile}`;
    },
    doctorScheduleSelected(schedule) {
      this.selectedDoctorSchedule = schedule;
      const data = {
        selectedDoctorSchedule: this.selectedDoctorSchedule,
      };
      this.$emit('addConsultationData', data);
      this.$emit('moveToNextStep');
    },
  },
  watch: {
    selectedDate: function(val) {
      this.doctorSchedules = {};
      this.selectedDoctorSchedule = {};
      this.fetchDoctorSchedules(val);
    },
    apiDoctorScheduleConflictError: function(val) {
      if (this.apiDoctorScheduleConflictError) {
        this.selectedDate = ''
      }
    },
  },
};
</script>