const Progress = {};

Progress.State = {
  beforeClinicReservation: 0,
  afterClinicReservation: 1,
  beforeIssuingPrescription: 2,
  afterIssuingPrescription: 3,
  issuingPrescriptionByMail: 4,
  afterPharmacyReservation: 5,
  afterMedicationCounseling: 6,
  cancelByAdmin: 7,
  withoutIssuingPrescription: 8,
  cancelByPatient: 9
};

Progress.HealthCheckupSupportState = {
  beforeReservation: 0,
  beforeWebMtg: 1,
  afterWebMtg: 2,
};

export default Progress;
