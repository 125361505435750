<template>
  <div class="p-healthCheckupSupportConsultationHistory">
    <h2 class="p-healthCheckupSupportConsultationHistory__title">健診サポートの詳細</h2>
    <div class="p-healthCheckupSupportConsultationHistory__container l-ignoreParentContainer">
      
      <div class="p-healthCheckupSupportConsultationHistory__form">
        <span class="p-healthCheckupSupportConsultationHistory__label">ビデオ通話日時日時</span>
        <p class="p-healthCheckupSupportConsultationHistory__item">{{ timeSchedule }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationHistory__form">
        <span class="p-healthCheckupSupportConsultationHistory__label">担当医師</span>
        <div class="p-healthCheckupSupportConsultationHistory__img">
          <img :src="doctorImage">
        </div>
        <p class="p-healthCheckupSupportConsultationHistory__name">{{ doctorName }}</p>
        <p class="p-healthCheckupSupportConsultationHistory__profile">{{ doctorIntroduction }}</p>
      </div>

      <div class="p-healthCheckupSupportConsultationHistory__form">
        <span class="p-healthCheckupSupportConsultationHistory__label">サポートを受けた方</span>
        <p class="p-healthCheckupSupportConsultationHistory__item">{{ patientName }}</p>
      </div>

    </div>
    <p class="p-healthCheckupSupportConsultationHistory__footer">
      <a class="p-healthCheckupSupportConsultationHistory__back" @click="$router.go(-1)">もどる</a>
    </p>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from "moment";
  import {mapGetters} from "vuex";

  export default {
    data: () => {
      return {
        consultation: null,
      }
    },
    computed: {
      timeSchedule() {
        if (this.consultation == null) {
          return '読込中...'
        } else {
          moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
          let startTime = moment(this.consultation.web_mtg_start).format('HH:mm');
          let endTime = moment(this.consultation.web_mtg_end).format('HH:mm');
          let day = moment(this.consultation.web_mtg_start).format('YYYY/MM/DD(ddd)');
          return `${day} ${startTime} ~ ${endTime}`
        }
      },
      doctorImage() {
        return this.consultation == null ? '読込中...' : this.consultation.doctor.doctor_profile.image.url
      },
      doctorName() {
        return this.consultation == null ? '読込中...' : `${this.consultation.doctor.doctor_profile.first_name}${this.consultation.doctor.doctor_profile.last_name} 先生`
      },
      doctorIntroduction() {
        return this.consultation == null ? '読込中...' : `${this.consultation.doctor.doctor_profile.self_introduction}`
      },
      patientName() {
        return this.consultation == null ? '読込中...' : `${this.consultation.patient_family_profile.first_name}${this.consultation.patient_family_profile.last_name} 様`
      },
      ...mapGetters(['currentUser']),
    },
    async created() {
      try {
        await this.fetchconsultation();
      } catch {
        alert("ERROR")
      }
    },
    methods: {
      async fetchconsultation() {
        try {
          const consultation = await axios.get(`/api/health_checkup_support/consultations/${this.$route.params.id}`);
          this.consultation = consultation.data
        } catch {
          alert("ERROR")
        }
      },
    }
  }
</script>
