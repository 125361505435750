const PatientFamilyProfile = {};

PatientFamilyProfile.sex = {
  female: 'female',
  male: 'male'
};

PatientFamilyProfile.pregnancy = {
  yes: 'yes',
  no: 'no'
};

export default PatientFamilyProfile;
