<template>
  <section class="p-guide">
    <div class="p-guide__container">
      <div class="p-guide__header">
        <img src="../../../../assets/images/online_clinic/guide-header.png" alt="">
      </div>
      <div class="p-guide__image">
        <img src="../../../../assets/images/online_clinic/guide04.png" alt="guide-image">
      </div>
      <h1 class="p-guide__title">
        お薬の受け取り
      </h1>
      <div class="p-guide__text_box">
        <div class="p-guide__text">
          <p>
            <span class="p-guide__step">STEP5</span>薬局からのお電話
          </p>
          <div class="p-guide__additional_container">
            <p class="p-guide__additional_text_icon">●</p>
            <p class="p-guide__additional_text">お薬の準備ができると薬局よりご登録の電話番号へ連絡が入ります。</p>
          </div>
          <p>
            <span class="p-guide__step">STEP6</span>お薬の受け取り
          </p>
          <div class="p-guide__additional_container">
            <p class="p-guide__additional_text_icon">●</p>
            <p class="p-guide__additional_text">処方せんの原本は必要ありません。後日診療機関より薬局に送付されます。</p>
          </div>
        </div>
      </div>

      <button @click="$router.push('/online_clinic'); $mixpanel.track('CLICK BUTTON', { page: '診療ガイド', buttonName: 'オンライン診療を使ってみる', userId: currentUser.id, source: currentUser.source })" class="p-guide__last_button">
        <span>オンライン診療を使ってみる</span>
      </button>
      <div class="p-guide__footer">
        <ul class="p-guide__flow">
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem p-guide__active"></li>
        </ul>
      </div>
    </div>
  </section>
</template>


<script>
  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters(['currentUser'])
    }
  }
</script>

