<template>
  <component :is="headerComponent" :consultation="consultation" class="panel-heading consultation-heading" />
</template>

<script>
  import { mapGetters } from 'vuex'
  import User from '../../enums/User'
  import DoctorHeader from './DoctorHeader'
  import PatientHeader from './PatientHeader'

  export default {
    name: 'ConsultationListItemHeader',
    props: ['consultation'],
    computed: {
      headerComponent () {
        return this.currentUser.role === User.Role.patient ?  PatientHeader : DoctorHeader
      },
      ...mapGetters(['currentUser'])
    }
  }
</script>

<style scoped>
  .panel-heading {
    background-color: #ffffff;
  }

</style>
