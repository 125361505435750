<template>
  <div class="onlineConsultation">
  <flash-message />
    <div class="onlineConsultation__container">
      <div class="onlineConsultation__input">
        <div class="onlineConsultation__label">相談者</div>
        <div class="onlineConsultation__inputed">
          <span>{{ patientName }}</span>
        </div>
      </div>
      <div class="onlineConsultation__input">
        <div class="onlineConsultation__label">症状</div>
        <div class="onlineConsultation__inputed">
          <span>{{ consultationContent }}</span>
        </div>
      </div>
    </div>
    <div class="onlineConsultation__footer">
      <button class="onlineConsultation__button" @click="moveToTop()">相談TOPに戻る</button>
    </div>
  </div>
</template>

<script>

import Home from '@/pages/online_consultation/home'
import axios from 'axios'
import FlashMessage from '@/components/online_clinic/FlashMessage';
export default {
  components: { Home, FlashMessage },
  data() {
    return {
      createdConsultation: null,
    };
  },
  async created() {
    const res = await axios.get(`/api/online_consultation/consultations/${this.$route.params.consultationId}`)
    this.createdConsultation = res.data
    this.selectedFamilyProfile = res.data[0]
  },
  computed: {
    patientName(){
      return this.createdConsultation == null ? '読み込み中...' : this.createdConsultation.patient_family_profile.first_name + this.createdConsultation.patient_family_profile.last_name
    },
    consultationContent() {
      return this.createdConsultation == null ? '読み込み中...' : this.createdConsultation.content
    }
  },
  methods: {
    moveToTop() {
      this.$router.push({ name: 'online_consultation_top' })
    }
  }
};

</script>