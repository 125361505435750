<template>
  <div :class="className"  role="alert" v-if="showFlash" >
    {{ this.$localStorage.get('flashMessage') }}
  </div>
</template>

<script>
import VueLocalStorage from 'vue-localstorage';
export default {
  name: 'FlashMessage',
  created() {
    const removeFlash = this.removeFlash;
    setTimeout(function() {
      removeFlash();
    }, 5000);
    this.setShowFlash();
  },
  data() {
    return {
      showFlash: false,
    };
  },
  computed: {
    className() {
      return `c-flashMessage--alert c-flashMessage--${this.$localStorage.get('flashType')}`;
    },
  },
  methods: {
    removeFlash() {
      this.$localStorage.remove('flashType');
      this.$localStorage.remove('flashMessage');
      this.showFlash = false;
    },
    setShowFlash() {
      this.showFlash =
        !(this.$localStorage.get('flashType') == 'undefined' || this.$localStorage.get('flashType') == null) &&
        !(this.$localStorage.get('flashMessage') == 'undefined' || this.$localStorage.get('flashMessage') == null);
    },
  },
};
</script>
