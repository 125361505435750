<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header">
        お悩み内容の例
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>
      <div class="c-mainModal__content">
        <p class="c-mainModal__contentTitle">
        不正出血が続いています
        </p>
        毎月、定期的に生理が来ていますが、数日前から鮮血の不正出血が続いています。婦人科で血液検査と超音波の検査を受けました。いずれも異常なしと診断されましたが、とても心配です。不正出血はどのくらいの期間続いたら異常になるのでしょうか？
      </div>
      <div class="c-mainModal__content">
        <p class="c-mainModal__contentTitle">
        経過観察で良いのかわからず不安です
        </p>
        健康診断で心室細動と診断を受け、先週末、内科で薬を処方されました。リスクなど説明を受けましたが、このまま薬を飲み続け、経過観察で良いのかわからず、とても不安です。
      </div>
      <div class="c-mainModal__content">
        <p class="c-mainModal__contentTitle">
        頭痛・めまい・イライラするなどの症状があります
        </p>
        昨日から食欲がなく胃もたれを感じ、頭痛・めまい・イライラするなどの症状があります。自律神経失調症のような症状が出ていて、とても辛いです。内科系でどこを受診したら良いのか教えてください。
      </div>
      <div class="c-mainModal__bottom">
      </div>
      </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrequentlyAskedConsultationsModal',
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
  },
};
</script>