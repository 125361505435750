<template>
  <div v-bind:id="`cy-chat-log-${chatLog.id}`" class="chat-talk chat-log-list-item">
    <div class="content-line">
      <div class="vue-clearfix">
        <slot name="chat-content" />
      </div>
    </div>
    <chat-send-at :send-at="chatLog.created_at" :chat-log="chatLog" :isReadedTextDisplay="true"></chat-send-at>
    <div v-if="chatLog.urls.length > 0" class="vue-clearfix vue-clear-both">
      <chat-log-ogp v-for="url in chatLog.urls" :url="url" :chat-log="chatLog" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatLogOgp from './ChatLogOgp';
import ChatSendAt from './ChatSendAt';

export default {
  name: 'MyselfLayout',
  props: ['chatLog'],
  components: { ChatLogOgp, ChatSendAt },
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style scoped>
.content-line {
    display:flex;
    justify-content: flex-end;
    width:100%;
  }

.vue-clearfix {
  width:auto;
  margin: 0 0 0 18%;
}

.vue-clearfix:after {
  clear: both;
  content: '.';
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.vue-clear-both{
  clear: both;
}

.chat-log-list-item {
  padding-right: 10px;
}

.chat-log-list-item >>> .chat-log-content {
  margin: 0 0 0 50px;
  margin-bottom: 10px;
  float: right;
  margin-right: 10px;
  border-bottom-right-radius: 0;
  background: linear-gradient(9.07deg, #00bcd4 .06%, #2196f3 100%);
  color: #fff;
  word-break: break-word;
}

.chat-log-list-item >>> .chat-log-content a {
  color: white;
}

.chat-log-list-item >>> .chat-log-interview {
  border-bottom-right-radius: 0;
}

.ogp-box {
  float: right;
}

.send-at {
  float: right;
  margin-right: 10px;
}


</style>
