<template>
  <div>
    <div>
      <h1>問診票項目</h1>
      <router-link to="/interview/masters/new" class="btn btn-info">新規項目作成</router-link>
    </div>
    <way-type-tabs></way-type-tabs>
    <draggable element="ul" class="list-group" v-model="mastersOrder">
      <master-list-item v-for="master in masters" :master="master" :key="master.id" :id="`cy-master-${master.id}`">
        {{master.message}}
      </master-list-item>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as Masters from '../store/modules/interview/masters'

  import MasterListItem from './Interview/MasterLIstItem'
  import WayTypeTabs from "./Interview/MasterListItem/WayTypeTabs";

  // TODO : 権限チェック
  export default {
    name: 'InterviewMasters',
    components: {WayTypeTabs, MasterListItem, draggable },
    created () {
      if (!this.$route.query.way_type) {
        this.$router.history.push('/interview/masters?way_type=patient_question')
      }
      this.fetchInterviewMasters({wayType: this.$route.query.way_type});
    },
    computed: {
      mastersOrder: {
        get() {
          return this.masters;
        },
        set(value) {
          return this.patchInterviewMastersPositions({ masters: value })
        }
      },
      ...mapGetters({ masters: 'interview/masters/query' })
    },
    methods: {
      ...mapActions({
        fetchInterviewMasters: `interview/masters/${Masters.actionTypes.FETCH_INTERVIEW_MASTERS}`,
        patchInterviewMastersPositions: `interview/masters/${Masters.actionTypes.PATCH_INTERVIEW_MASTERS_POSITIONS}`
      }),
      ...mapMutations({ setInterviewMasters: `interview/masters/${Masters.mutationTypes.SET_INTERVIEW_MASTERS}` })
    },

    watch: {
      '$route'() {
        this.fetchInterviewMasters({wayType: this.$route.query.way_type});
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
