<template>
  <section class="p-healthCheckupSupportWebMtg l-ignoreParentContainer">
    <iframe v-if="webMtgUrl && isWithinConsultationTime" class="p-healthCheckupSupportWebMtg__frame" :src="webMtgUrl" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
  </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
  data: () => {
    return {
      consultation: null,
    }
  },
  created() {
    this.fetchConsultation()
  },
  computed: {
    webMtgUrl() {
      if (this.consultation) {
        return this.consultation.web_mtg_url_for_patient
      }
    },
    isWithinConsultationTime() {
      if (!this.consultation) return false;

        const start = this.consultation.web_mtg_start;
        const end = this.consultation.web_mtg_end;

        const roomOpenTime = moment(start).subtract(10, 'minutes').format();
        const roomCloseTime = moment(end).add(15, 'minutes').format();
        const now = moment(new Date()).format();

        if (now >= roomOpenTime && now <= roomCloseTime) {
          return true;
        } else {
          alert("診療時間外です");
          return false;
        }
    }
  },
  methods: {
    async fetchConsultation() {
      try {
        const consultation = await axios.get(`/api/health_checkup_support/consultations/${this.$route.params.id}`);
        this.consultation = consultation.data
      } catch {
        alert("診療情報が取得できませんでした。インターネットへの接続状況をご確認ください。解決しない場合はアナムネまでお問い合わせください")
      }
    },
  }

};
</script>