<template>
  <section class="p-guide">
    <div class="p-guide__container">
      <div class="p-guide__header">
        <img src="../../../../assets/images/online_clinic/guide-header.png" alt="">
      </div>
      <div class="p-guide__image">
        <img src="../../../../assets/images/online_clinic/guide03.png" alt="guide-image">
      </div>
      <h1 class="p-guide__title">
        診察〜処方せん発行までの流れ
      </h1>
      <div class="p-guide__text_box">
        <div class="p-guide__text">
          <p>
            <span class="p-guide__step">STEP3</span>ビデオ通話での診察
          </p>
          <p>
            <span class="p-guide__step">STEP4</span>決済・処方せん発⾏
          </p>
          <div class="p-guide__additional_container">
            <p class="p-guide__additional_text_icon">●</p>
            <p class="p-guide__additional_text">お会計が完了するとFAXで薬局に処方せんが送信されます。</p>
          </div>
        </div>
      </div>

      <button @click="$emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '診療ガイド', buttonName: '次へ', userId: currentUser.id, source: currentUser.source })" class="p-guide__button">
        <span>次へ</span>
        <span class="p-guide__arrow"></span>
      </button>
      <div class="p-guide__footer">
        <ul class="p-guide__flow">
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem"></li>
          <li class="p-guide__flowItem p-guide__active"></li>
          <li class="p-guide__flowItem"></li>
        </ul>
      </div>
    </div>
  </section>
</template>


<script>
  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters(['currentUser'])
    }
  }
</script>


