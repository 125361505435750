<template>
  <div class="p-healthCheckupSupportConfirmation">
    <div class="p-healthCheckupSupportConfirmation__container l-ignoreParentContainer">
      <div class="p-healthCheckupSupportConfirmation__box">
        <div class="p-healthCheckupSupportConfirmation__label">サポートの種類</div>
        <div class="p-healthCheckupSupportConfirmation__item">{{consultationReservationToCreate.selectedType.name}}</div>
        <div class="p-healthCheckupSupportConfirmation__label">予約日時</div>
        <div class="p-healthCheckupSupportConfirmation__item">{{reservationDate}}</div>
        <div class="p-healthCheckupSupportConfirmation__label">担当医師</div>
        <div class="p-healthCheckupSupportConfirmation__item p-healthCheckupSupportConfirmation__doctorName">{{doctorName}}</div>
        <div class="p-healthCheckupSupportConfirmation__item p-healthCheckupSupportConfirmation__selfIntroduction">{{doctorSelfIntroduction}}</div>
        <div class="p-healthCheckupSupportConfirmation__label">サポートを受ける方</div>
        <div class="p-healthCheckupSupportConfirmation__item">{{patientName}}</div>
        <div class="p-healthCheckupSupportConfirmation__label">問診内容</div>
        <div class="p-healthCheckupSupportConfirmation__item">{{consultationReservationToCreate.interview.patientComment}}</div>
      </div>
    </div>
    <div class="p-healthCheckupSupportPatientInformation__footer">
      <div v-if="apiError">
        <p class="p-onlineClinicInterviewConfirmation__title p-onlineClinicInterviewConfirmation__textDanger">予約処理の際にエラーが発生しました。<br>ご迷惑をおかけして恐縮ですが、<a href="/contacts/new" target="_blank" class="p-onlineClinicInterviewConfirmation__contactsLink">こちら</a>よりお問い合わせください。</p>
      </div>
      <div v-if="apiCreditError">
        <p class="p-onlineClinicInterviewConfirmation__title p-onlineClinicInterviewConfirmation__textDanger">クレジットカードの有効性を確認できませんでした。<br>ご迷惑をおかけして恐縮ですが、<router-link class="c-link" to="/patient_family_profiles" @click.native="$mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答（2）', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">会員情報</router-link>よりクレジットカードを変更してください。</p>
      </div>
        <button class="c-mainButton" @click="goReservation(); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答（2）', buttonName: 'クレジットカードで予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })">クレジットカードで予約する</button>

        <p class="p-healthCheckupSupportConfirmation__hasCoupon">連携コードをお持ちの方</p>
        <button class="p-healthCheckupSupportConfirmation__reservationWithCoupon" @click="goReservationWithCoupon(); $mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答（2）', buttonName: '連携コードで予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })">連携コードで予約する</button>
        <component :is="currentModalComponent"
                    ref="currentComponent"
                    @close-modal="closeModal"
                    @change-modal-component="changeModalComponent"
                    @create-reservation="createReservation"
                    @addConsultationData="addConsultationData"
                    :apiCreditError="apiCreditError"
                    :apiInvalidCouponError="apiInvalidCouponError"
                    />

    </div>
  </div>
</template>
<script>
import moment from 'moment';
import CreditCardRegistration from '@/components/health_checkup_support/modal/CreditCardRegistration';
import Reservation from '@/components/health_checkup_support/modal/Reservation';
import ReservationWithCoupon from '@/components/health_checkup_support/modal/ReservationWithCoupon';
import {mapGetters} from "vuex";

export default {
  components: {
    CreditCardRegistration,
    Reservation,
    ReservationWithCoupon
  },
  props: {
    consultationReservationToCreate: {
      type: Object,
      required: true,
    },
    apiCreditError: {
      type: Object
    },
    apiInvalidCouponError: {
      type: Boolean
    },
    apiError: {
      type: Boolean
    },
  },
  
  data: () => {
    return {
      currentModalComponent: '',
      isRequesting: false,
    };
  },
  methods: {
    closeModal(args = {}) {
      this.currentModalComponent = '';
    },
    goReservation() {
      if (this.isCreditCardRegistered) {
        this.currentModalComponent = 'Reservation'
      } else {
        this.currentModalComponent = 'creditCardRegistration'
      }
    },
    goReservationWithCoupon() {
      this.currentModalComponent = 'ReservationWithCoupon'
    },
    changeModalComponent(component) {
      this.currentModalComponent = component
    },
    createReservation() {
      this.isRequesting = true;
      this.$emit('create-reservation', () => {
        this.isRequesting = false
      });
    },
    addConsultationData(couponCode) {
      const data = {
          couponCode: couponCode,
      };
      this.$emit('addConsultationData', data);
    },
  },
  computed: {
    reservationDate() {
      const schedule = this.consultationReservationToCreate.selectedDoctorSchedule
      moment.locale('ja', {
        weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
      });
      const startTime = moment(schedule.start).format('HH:mm');
      const endTime = moment(schedule.end).format('HH:mm');
      const day = moment(schedule.end).format('YYYY/MM/DD(ddd)');
      return `${day} ${startTime} ~ ${endTime}`;
    },
    patinent() {
      return this.consultationReservationToCreate.selectedFamilyProfile
    },
    patientName() {
      return this.patinent.first_name + ' ' + this.patinent.last_name
    },
    doctor() {
      return this.consultationReservationToCreate.selectedDoctorSchedule.user
    },
    doctorName() {
      return this.doctor.doctor_profile.first_name + ' ' + this.doctor.doctor_profile.last_name + '先生'  
    },
    doctorSelfIntroduction() {
      return this.doctor.doctor_profile.self_introduction
    },
    isCreditCardRegistered() {
      return this.currentUser.is_creditcard_registered
    },
    ...mapGetters(['currentUser']),
  },
  watch: { 
    apiError() {
      if (this.apiError) {
        this.closeModal(this.currentModalComponent)
      }
    },
    apiCreditError() {
      if (this.apiCreditError) {
        this.closeModal(this.currentModalComponent)
      }
    } 
      
  }
};
</script>