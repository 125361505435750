<!-- Vue component -->
<template>
  <div>
    <multiselect v-model="value"
                 tag-placeholder="Add this as new tag"
                 placeholder="Search or add a tag"
                 label="name"
                 track-by="code"
                 :options="options"
                 :multiple="true"
                 :taggable="true">

    </multiselect>
    <input v-for="v in value" type="hidden" :name="name" :value="v.code" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import Multiselect from 'vue-multiselect'

  // register globally
  Vue.component('multiselect', Multiselect);

  export default {
    props: ['value', 'options', 'name'],
    components: {
      Multiselect
    }
  }
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

