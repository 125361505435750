<template>
  <div>
    <div class="onlineClinic-confirmation__box">
      <div class="onlineClinic-confirmation__label">問診内容</div>
      <div class="onlineClinic-confirmation__label-interview">血圧の高さについて、あてはまるものにチェックしてください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.blood_pressure}}</div>

      <div class="onlineClinic-confirmation__label-interview">普段の睡眠時間について教えてください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.sleep_time}}</div>

      <div class="onlineClinic-confirmation__label-interview">睡眠の状態について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.sleep_quality.split(',')" :key="`first-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">頭痛がありますか？あてはまるものにチェックしてください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.is_headache}}</div>

      <div class="onlineClinic-confirmation__label-interview">精神状態はいかがですか？あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.mental_conditions.split(',')" :key="`second-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">冷えの状態はいかがですか？あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.cold_sensitivities.split(',')" :key="`third-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">熱の有無について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.hot_sensitivities.split(',')" :key="`fourth-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">汗の状態について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.sweat_conditions.split(',')" :key="`fifth-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">食欲はありますか？</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.is_appetite}}</div>

      <div class="onlineClinic-confirmation__label-interview">胃腸の状態について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.gastrointestinal_conditions.split(',')" :key="`sixth-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">便通について、1日の排便の回数と便の状態「普通・軟便・硬い」を記載してください。</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.bowel_movement}}</div>

      <div class="onlineClinic-confirmation__label-interview">お小水について、1日の回数と夜間尿の回数と出やすさ「出やすい・出にくい」を記載してください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.urine_condition}}</div>

      <div class="onlineClinic-confirmation__label-interview">皮膚の状態について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.skin_conditions.split(',')" :key="`seventh-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">呼吸器について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.respiratory_conditions.split(',')" :key="`eighth-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">痛みの有無について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.is_pains.split(',')" :key="`ninth-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div class="onlineClinic-confirmation__label-interview">全身症状について、あてはまるものにチェックしてください</div>
      <div v-for="(item, key) in clinicReservationToCreate.interview_for_department.systemic_symptoms.split(',')" :key="`tenth-${key}`" class="onlineClinic-confirmation__item-interview-checkbox">
        {{item}}
      </div>

      <div v-if="this.$props.clinicReservationToCreate.interview_for_department.patient_sex === 'female'">
        <div class="onlineClinic-confirmation__label-interview">生理痛はありますか？</div>
        <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.is_menstrual_pain}}</div>
      </div>

      <div v-if="this.$props.clinicReservationToCreate.interview_for_department.patient_sex === 'female'">
        <div class="onlineClinic-confirmation__label-interview">生理時の塊はありますか？</div>
        <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.is_menstrual_mass}}</div>
      </div>

      <div class="onlineClinic-confirmation__label-interview">その他服用しているお薬やサプリメントがあれば教えてください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.current_medicine}}</div>

      <div v-if="clinicReservationToCreate.interview_for_department.purpose">
        <div class="onlineClinic-confirmation__label-interview">今回のご相談の目的を教えてください</div>
        <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.purpose}}</div>
      </div>

      <div v-if="clinicReservationToCreate.interview_for_department.changes_in_physical_condition">
        <div class="onlineClinic-confirmation__label-interview">前回のご利用時と比べて体調面などの変化を教えてください</div>
        <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.changes_in_physical_condition}}</div>
      </div>

      <div class="onlineClinic-confirmation__label-interview">舌の写真<br>※クリックすると拡大されます</div>
      <div class="onlineClinic-confirmation__item-interview">
        <img :src="clinicReservationToCreate.interview_for_department.tongue_image.url" @click="openTongueImageModal" class="onlineClinic-confirmation__card_image">
      </div>

      <div v-if="clinicReservationToCreate.interview_for_department.basal_body_temperature_table.url">
        <div class="onlineClinic-confirmation__label-interview">基礎体温表<br>※クリックすると拡大されます</div>
        <div class="onlineClinic-confirmation__item-interview">
          <img :src="clinicReservationToCreate.interview_for_department.basal_body_temperature_table.url" @click="openBasalBodyTemperatureTableModal" class="onlineClinic-confirmation__card_image">
        </div>
      </div>

    </div>
    <div>
      <ImageView :image="clinicReservationToCreate.interview_for_department.tongue_image.url" v-if="isTongueImageViewModal"></ImageView>
    </div>
    <div>
      <ImageView :image="clinicReservationToCreate.interview_for_department.basal_body_temperature_table.url" v-if="isBasalBodyTemperatureTableViewModal"></ImageView>
    </div>
  </div>
</template>
<script>
// TODO yes noの変換の仕組みはどこかにまとめたい
import Interview from '@/enums/online_clinic/Interview';
import EventBus from '@/event-bus';
import ImageView from '@/components/Common/ImageView';

export default {
  components: {
    ImageView,
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isTongueImageViewModal: false,
      isBasalBodyTemperatureTableViewModal: false,
    };
  },
  created() {
    EventBus.$on('close-image-modal', () => {
      this.closeImageModal();
    });
  },
  methods: {
    openTongueImageModal() {
      this.isTongueImageViewModal = true;
    },
    closeImageModal() {
      this.isTongueImageViewModal = false;
      this.isBasalBodyTemperatureTableViewModal = false;
    },
    openBasalBodyTemperatureTableModal() {
      this.isBasalBodyTemperatureTableViewModal = true;
    },
  },
};
</script>

<style scope lang="scss">
.onlineClinic-confirmation {
  margin: auto;
  padding: 100px 0 25px;
  text-align: center;

  &__title {
    font-size: 14px;
    font-weight: 500;
    color: #04aae4;
  }

  &__container {
    background: #f3f4f5;
    color: #2a3039;
    padding: 2px 16px 16px 16px;
    font-size: 14px;
    text-align: left;
  }

  &__box {
    max-width: 312px;
    margin: 24px auto;
    padding: 0 15px;
  }

  &__label {
    font-weight: 500;
    margin-bottom: 18px;
  }

  &__item {
    margin-left: 20px;
    font-weight: 200;
  }

  &__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  &__label-interview {
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  &__item-interview {
    font-size: 12px;
    font-weight: 200;
    margin: 0px 20px 8px 20px;
    word-break: break-word;
  }

  &__item-interview-checkbox {
    font-size: 12px;
    font-weight: 200;
    margin: 0px 20px 8px 20px;
  }

  &__footer {
    text-align: center;
  }

  &__button {
    display: block;
    max-width: 312px;
    width: 100%;
    height: 56px;
    background: #04aae4;
    border-radius: 60px;
    color: #fff;
    text-align: center;
    padding-top: 6px;
    text-decoration: none;
    margin: 20px auto;
    border: none;

    &:disabled {
      background: #e4e4e9;
    }
  }

  &__nobutton {
    color: #97979a;
    font-size: 12px;
    background: none;
    border: none;
    text-decoration: underline;
    margin-bottom: 30px;
  }

  &__register_credit {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  &__card_image {
    width: 100px;
    height: 100px;
  }
}

</style>
