<template>
  <div class="p-onlineConsultationNew">
    <loading :translucent="true" v-show="loading"></loading>
    <p class="p-onlineConsultationNew__headline">お悩みの症状などについて教えて下さい</p>
    <div class="p-onlineConsultationNew__select">
      <div class="p-onlineConsultationNew__label">どなたについてのご相談ですか？</div>
      <select v-model="selectedFamilyProfile" class="p-onlineConsultationNew__selectUser">
        <option v-for="patientFamilyProfile in patientFamilyProfiles" :value="patientFamilyProfile" :key="patientFamilyProfile.id">
           {{ patientFamilyProfile.first_name }}{{patientFamilyProfile.last_name}}
        </option>
      </select>
    </div>

    <div class="p-onlineConsultationNew__input">
      <label for="symptom" class="p-onlineConsultationNew__label">どのような症状ですか？
      </label>
      <textarea rows="6" v-model="consultationContent" id="symptom"></textarea>
    </div>
    <div class="p-onlineConsultationNew__example">
      <p>
        例えばこんなお悩みのご相談をいただいています
      </p>
      <div>
        不正出血が続いています
      </div>
      <div>
        経過観察で良いのかわからず不安です
      </div>
      <div>
        頭痛•めまい•イライラするなどの症状があります
      </div>
      <button @click="displayModal(); $mixpanel.track('CLICK BUTTON', { page: '医療相談投稿', buttonName: 'もっと見る', userId: currentUser.id, source: currentUser.source })">
        もっと見る
        <img class="logo-img" src="@/../assets/images/online_consultation/see_more.png"/>
      </button>
    </div>
    <div class="p-onlineConsultationNew__footer">
      <button class="c-mainButton" @click="createConsultation(); $mixpanel.track('CLICK BUTTON', { page: '医療相談投稿', buttonName: 'この内容で相談する', userId: currentUser.id, source: currentUser.source, isCvButton: true })" :disabled="!isValid">この内容で相談する</button>
      <button class="p-onlineConsultationNew__nobutton" @click="onCancel(); $mixpanel.track('CLICK BUTTON', { page: '医療相談投稿', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
    </div>
    <frequently-asked-consultations-modal v-show="isModalVisible" v-on:close-modal="closeModal()"></frequently-asked-consultations-modal>
  </div>
</template>

<script>

import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import * as actionTypes from '../../../store/action-types'
import VuejsDialog from 'vuejs-dialog';
import ConsultationCreate from '@/pages/online_consultation/consultations/create'
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import FlashMessage from '@/components/online_clinic/FlashMessage';
import FrequentlyAskedConsultationsModal from '@/components/online_consultation/consultations/FrequentlyAskedConsultationsModal'
import Loading from '@/components/Common/Loading';
import ConsultationEnum from '@/enums/Consultation'
export default {
  components: { ConsultationCreate, FlashMessage, FrequentlyAskedConsultationsModal, Loading },
  data() {
    return {
      patientFamilyProfiles: [],
      selectedFamilyProfile: {},
      consultationContent: '',
      isModalVisible: false,
      loading: false,
      selectedConsultationType: null,
    };
  },
  async created() {
    const res = await axios.get(`/api/patient_family_profiles`)
    this.patientFamilyProfiles = res.data
    this.selectedFamilyProfile = res.data[0]
    this.selectedConsultationType = this.$route.query.consultationType
  },
  computed: {
    isValid() {
      const requiredInputFields = [
        this.selectedFamilyProfile,
        this.consultationContent
      ]
      return !requiredInputFields.some(field => (field == null || field == ''))
    },
    ...mapGetters(['currentUser'])
  },
  methods: {
    async createConsultation() {
      this.loading = true;
      let consultation;
      try {
        consultation = await axios.post('/api/online_consultation/consultations',
          { content: this.consultationContent,
            patient_family_profile_id: this.selectedFamilyProfile.id,
            consultation_type: this.selectedConsultationType
          });
      } catch {
        alert('ERROR');
        this.loading = false;
      }
      this.createChat(consultation.data, this.currentUser)
    },
    onCancel() {
      this.$dialog
        .confirm(
          {
            title: 'キャンセル',
            body:
              '今までの内容が失われます。キャンセルしてもよろしいですか？',
          },
          {
            okText: 'はい',
            cancelText: 'いいえ',
          }
        )
        .then(() => {
          this.$router.push({ name: 'online_consultation_top' })
        });
    },
    displayModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    ...mapActions({createChat: actionTypes.CREATE_CHAT}),
  }
};

</script>
