<template>
  <div class="content-header">
    <slot></slot>
    <div class="tools">
      <slot name="tools"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseTitle',
  }
</script>


<style scoped lang="scss">
  .content-header {
    position: relative;
    margin-bottom: 20px;

    h1, h2, h3 {
      margin: 0;
      display: inline-block;
    }

    .tools {
      text-align: right;
    }
  }

</style>
