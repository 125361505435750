export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_TOTAL_CONSULTATIONS_COUNT = 'FETCH_TOTAL_CONSULTATIONS_COUNT';
export const FETCH_RESPONDING_CONSULTATIONS_COUNT = 'FETCH_RESPONDING_CONSULTATIONS_COUNT';
export const FETCH_CONSULTATIONS = 'FETCH_CONSULTATIONS';
export const CLEAR_CONSULTATIONS = 'CLEAR_CONSULTATIONS';
export const FETCH_ADDITIONAL_CONSULTATIONS = 'FETCH_ADDITIONAL_CONSULTATIONS';
export const FETCH_DOCTORS_NEW_CONSULTATIONS = 'FETCH_DOCTORS_NEW_CONSULTATIONS';
export const FETCH_PATIENT = 'FETCH_PATIENT';
export const FETCH_PATIENT_COUNT = 'FETCH_PATIENT_COUNT';
export const CREATE_CHAT = 'CREATE_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const POST_FINISH_REQUEST = 'POST_FINISH_REQUEST';
export const POST_FINISH = 'POST_FINISH';

export const FETCH_CHAT = 'FETCH_CHAT';
export const FETCH_OGP = 'FETCH_OGP';
export const PATCH_FINISH_REQUEST = 'PATCH_FINISH_REQUEST';
export const DELETE_FINISH_REQUEST = 'DELETE_FINISH_REQUEST';
export const POST_CHAT_LOG = 'POST_CHAT_LOG';
export const POST_CHAT_LOG_CHOICE = 'POST_CHAT_LOG_CHOICE';
export const POST_CHAT_LOG_IMAGE = 'POST_CHAT_LOG_IMAGE';
export const DELETE_CHAT_LOG = 'DELETE_CHAT_LOG';

export const FETCH_MEDICAL_DASHBOARD_PATIENT = 'FETCH_MEDICAL_DASHBOARD_PATIENT';
export const PATCH_MEDICAL_DASHBOARD_PATIENT = 'PATCH_MEDICAL_DASHBOARD_PATIENT';
export const FETCH_MEDICAL_DASHBOARD_CATEGORIES = 'FETCH_MEDICAL_DASHBOARD_CATEGORIES';

export const FETCH_READ_MESSAGE = 'FETCH_READ_MESSAGE';
export const FETCH_PATIENT_READ_MESSAGE = 'FETCH_PATIENT_READ_MESSAGE';
export const ATTACH_READ_MESSAGE = 'ATTACH_READ_MESSAGE';

export const POST_SUBSCRIPTION = 'POST_SUBSCRIPTION';
export const POST_AUTHORIZATION = 'POST_AUTHORIZATION';
export const UPDATE_AUTHORIZATION = 'UPDATE_AUTHORIZATION';

export const CHECK_USER_AGENT = 'CHECK_USER_AGENT'

export const PATCH_CURRENT_USER = 'PATCH_CURRENT_USER';
