<template>
  <div class="form-group">
    <label :for="name" class="control-label">{{labelText}}</label>
    <select :id="name" :value="value" @change="onChange" class="form-control">
      <slot></slot>
    </select>
  </div>
</template>
<script>
  export default {
    name: 'BaseSelectField',
    props: ['name', 'labelText', "value"],
    data () {
      return { selected: this.value }
    },
    methods: {
      onChange(e) {
        this.selected = e.target.value;
        this.$emit('input', this.selected)
      }
    }
  }
</script>
