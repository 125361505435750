<template>
  <div class="p-onlineClinicHistoryDetails">
    <h2 class="p-onlineClinicHistoryDetails__title">診察詳細</h2>
    <div class="p-onlineClinicHistoryDetails__container l-ignoreParentContainer">
      <div class="p-onlineClinicHistoryDetails__form">
        <span class="p-onlineClinicHistoryDetails__label">診療機関</span>
        <p class="p-onlineClinicHistoryDetails__item">{{ clinicName }}</p>
      </div>
      <div class="p-onlineClinicHistoryDetails__form">
        <span class="p-onlineClinicHistoryDetails__label">薬局名</span>
        <p class="p-onlineClinicHistoryDetails__item">{{ pharmacyName }}</p>
      </div>

      <div class="p-onlineClinicHistoryDetails__map">
        <GoogleMap
        :lat="this.examination.pharmacy.lat"
        :lng="this.examination.pharmacy.lng"
        :pharmacies="[this.examination.pharmacy]"
        isDefaultPin="true"
        v-if="mapShowable"/>
      </div>

      <div class="p-onlineClinicHistoryDetails__form">
        <span class="p-onlineClinicHistoryDetails__label">診療科目</span>
        <p class="p-onlineClinicHistoryDetails__item">{{ medicalDepartmentName }}</p>
      </div>

      <div class="p-onlineClinicHistoryDetails__form">
        <span class="p-onlineClinicHistoryDetails__label">予約日時</span>
        <p class="p-onlineClinicHistoryDetails__item">{{ timeSchedule }}</p>
      </div>

      <div class="p-onlineClinicHistoryDetails__form">
        <span class="p-onlineClinicHistoryDetails__label">担当医師</span>
        <div class="p-onlineClinicHistoryDetails__img">
          <img :src="doctorImage">
        </div>
        <p class="p-onlineClinicHistoryDetails__name">{{ doctorName }}</p>
        <p class="p-onlineClinicHistoryDetails__profile">{{ doctorIntroduction }}</p>
      </div>

      <div class="p-onlineClinicHistoryDetails__form">
        <span class="p-onlineClinicHistoryDetails__label">来院される方</span>
        <p class="p-onlineClinicHistoryDetails__item">{{ patientName }}</p>
      </div>

      <div class="p-onlineClinicHistoryDetails__form" v-if="symptomContent">
        <span class="p-onlineClinicHistoryDetails__label">問診内容</span>
        <p class="p-onlineClinicHistoryDetails__item">{{ symptomContent }}</p>
      </div>
      <div v-if="prescription_image">
        <span class="p-onlineClinicHistoryDetails__label">処方せん・請求書</span>
        <a :href="prescription_image"  :target="!this.isIOS? '_blank':false" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療詳細', buttonName: '処方せんを確認', userId: currentUser.id, source: currentUser.source }); checkPrescriptionOrBillingViewed()">処方せんを確認</a>
        <a :href="clinic_billing_image" :target="!this.isIOS? '_blank':false"  class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療詳細', buttonName: 'クリニックの請求書', userId: currentUser.id, source: currentUser.source }); checkPrescriptionOrBillingViewed()">クリニックの請求書</a>
      </div>
      <div v-else>
        <span class="p-onlineClinicHistoryDetails__label">請求書</span>
        <a :href="clinic_billing_image" :target="!this.isIOS? '_blank':false"  class="c-subButton">クリニックの請求書</a>
      </div>
    </div>
    <p class="p-onlineClinicHistoryDetails__footer">
      <a class="p-onlineClinicHistoryDetails__back" @click="$router.go(-1)">もどる</a>
    </p>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from "moment";
  import GoogleMap from '@/components/online_clinic/GoogleMap'
  import {ja} from 'vuejs-datepicker/dist/locale'
  import {mapGetters} from "vuex";

  export default {
    name: 'ExaminationCompleted',
    components: { GoogleMap },

    data: () => {
      return {
        examination: null,
        currentDatetime: moment(),
      }
    },
    computed: {
      isIOS(){
        if(window.navigator.userAgent.indexOf('anamne-app')!=-1){
          return true;
        }else {
          return false;
        }
      },
      clinicName() {
        return this.examination == null ? '読込中...' : this.examination.clinic.name
      },
      pharmacyName() {
        if(this.examination == null){
          return '読込中...';
        }else if(this.examination.pharmacy == null){
          return '利用しない';
        }else if(this.examination.prescription.prescription_image == null){
          return '-'
        }else{
          return this.examination.pharmacy.name
        }
      },
      mapShowable() {
        return this.examination && this.examination.pharmacy
      },
      medicalDepartmentName() {
        return this.examination == null ? '読込中...' : this.examination.medical_department.name
      },
      timeSchedule() {
        if (this.examination == null) {
          return '読込中...'
        } else {
          moment.locale('ja', {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
          let startTime = moment(this.examination.doctor_schedule.start).format('HH:mm');
          let endTime = moment(this.examination.doctor_schedule.end).format('HH:mm');
          let day = moment(this.examination.doctor_schedule.end).format('YYYY/MM/DD(ddd)');
          return `${day} ${startTime} ~ ${endTime}`
        }
      },
      doctorImage() {
        return this.examination == null ? '読込中...' : this.examination.doctor.doctor_profile.image.url
      },
      doctorName() {
        return this.examination == null ? '読込中...' : `${this.examination.doctor.doctor_profile.first_name}${this.examination.doctor.doctor_profile.last_name} 先生`
      },
      doctorIntroduction() {
        return this.examination == null ? '読込中...' : `${this.examination.doctor.doctor_profile.self_introduction}`
      },
      patientName() {
        return this.examination == null ? '読込中...' : `${this.examination.interview_for_department.patient_last_name}${this.examination.interview_for_department.patient_first_name} 様`
      },
      symptomContent() {
        return this.examination == null ? '読込中...' : this.examination.interview_for_department.symptom
      },
      examinable() {
        let start = this.examination == null ? null : moment(this.examination.doctor_schedule.start)
        if (start == null) {
          return false
        } else if (start > this.currentDatetime) {
          return start.diff(this.currentDatetime, 'minutes') < 5
        } else {
          return start.add(30, 'm') > this.currentDatetime
        }
      },
      prescription_image(){
        if (this.isIOS){
          return this.examination == null ? '読込中...' : "anamne-app-settings://anamne-open-web?"+this.examination.prescription.prescription_image
        }else{
          return this.examination == null ? '読込中...' : this.examination.prescription.prescription_image
        }
      },
      clinic_billing_image(){
        if (this.isIOS){
          return this.examination == null ? '読込中...' : "anamne-app-settings://anamne-open-web?"+this.examination.billing_image
        }else{
          return this.examination == null ? '読込中...' : this.examination.billing_image
        }
      },
      pharmacy_billing_image(){
        if(this.examination != null){
          if(this.examination.pharmacy_id != null){
            if (this.isIOS){
              return this.examination == null ? '読込中...' : "anamne-app-settings://anamne-open-web?"+this.examination.prescription.billing_image
            }else{
              return this.examination == null ? '読込中...' : this.examination.prescription.billing_image
            }
          }else{
            return null;
          }
        }
      },
      shipping_label(){
        if(this.examination != null){
          if(this.examination.pharmacy_id != null){
            if (this.isIOS){
              return this.examination == null ? '読込中...' : "anamne-app-settings://anamne-open-web?"+this.examination.prescription.shipping_label_image
            }else{
              return this.examination == null ? '読込中...' : this.examination.prescription.shipping_label_image
            }
          }else{
            return null;
          }
        }
      },
      ...mapGetters(['currentUser']),
    },
    async created() {
      try {
        await this.fetchExamination();
      } catch {
        alert("ERROR")
      }
    },
    mounted() {
      setInterval(() => {
        this.currentDatetime = moment();
      }, 5000)
    },
    methods: {
      async checkPrescriptionOrBillingViewed(){
        try {
          await axios.post(`/api/online_clinic/examinations/${this.examination.id}/read`);
        } catch(e) {
          console.error(e);
          console.log("examination post error")
        }
      },
      async fetchExamination() {
        try {
          let examination = await axios.get(`/api/online_clinic/examinations/${this.$route.params.id}`);
          this.examination = examination.data
        } catch {
          alert("ERROR")
        }
      },
    }
  }
</script>
