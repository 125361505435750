<template>
  <span class="stillReplyLabel">患者からの返信を最後に返信していません</span>
</template>

<script>
  export default {
    name: 'StillReplyLabel'
  }
</script>

<style scoped lang="scss">
  .stillReplyLabel {
    color: white;
    background-color: #f7526c;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    padding: .5rem;
  }
</style>
