<template>
  <component :is="component"
             :data-tooltip-message="message"
             class="baseTooltip">
    <slot></slot>
  </component>
</template>

<script>
  export default {
    props: {
      component: {
        require: true
      },
      message: {
        type: String,
        require: true
      }
    },
  }
</script>

<style scoped lang="scss">
  .baseTooltip {
    position: relative;
    &:hover {
      &::before {
        content: attr(data-tooltip-message);
        top: -32px;
        right: 0;
        font-size: 1.2rem;
        position: absolute;
        padding: 4px;
        border-radius: 4px;
        background: #111;
        color: #fff;
        word-break: keep-all;
      }
    }
  }
</style>
