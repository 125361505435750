import Vue from 'vue'
import BaseMultipleSelect from '../../components/base/BaseMultipleSelect'
import { hCollectionToArray } from '../../shared/Helper'


const ItemMasters = (() => {
  const init = () => {
    if (document.getElementById('js-item-masters-form') !== null) {
      Array.prototype.forEach.call(document.getElementsByClassName('vue-multiple-select'), el => {
        const name = el.getAttribute('name');
        const options = hCollectionToArray(el.options).map(optionToObject);
        const value = getSelectValues(el).map(optionToObject);
        new Vue({
          el,
          data: {
            options: options
          },
          render: h => h(BaseMultipleSelect, { props: { value: value, options: options, name: name }})
        });
      });
    }
  };

  const getSelectValues = select => {
    return Array.prototype.slice.call(select.options).filter(opt => opt.selected);
  };

  const optionToObject = opt => { return { name: opt.innerHTML, code: opt.value } };

  return { init: init };
})();

export default ItemMasters;
