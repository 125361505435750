<template>
  <div id="chat-form" class="p-chat__footerBody">
    <div class="p-chat__footerIcon">
      <div class="centering text-primary">
        <chat-image-input v-if="isPatient" :disabled="disabled">
          <img src="@/../assets/images/online_consultation/chat-image.png">
        </chat-image-input>
        <chat-toggle-button v-if="isNotPatient" :is-toggle-open="isToggleOpen" :toggle="toggle" />
      </div>
    </div>
    <div class="p-chat__footerTxt">
      <textarea-autosize
        id="chat-message-textarea"
        class="p-chat__messageInput"
        name="chat_log[message]"
        placeholder="メッセージを入力してください"
        ref="someName"
        rows="1"
        v-model="newChatLog.content"
        :disabled="disabled"
        :min-height="40"
        :max-height="350" />
    </div>
    <div class="p-chat__sendMessageIcon">
      <button type="button" :disabled="disabled || $data.newChatLog.content.length === 0" @click="submit">
        <img class="message-send-button" src="@/../assets/images/online_consultation/chat-send.png">
      </button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  import * as actionTypes from '../../store/action-types'
  import * as mutationTypes from '../../store/mutation-types'

  import ChatToggleButton from './ChatToggleButton'
  import ChatImageInput from './ChatImageInput'
  import EventBus from '../../event-bus';

  export default {
    name: 'ChatMessageForm',
    props: ['isToggleOpen', 'toggle'],
    created() {
      EventBus.$on('chat-button-create', (name, url) => {
        const buttonTag = ' <button-create>{"name":"' + name + '","url":"' + url + '"}<button-create> '
        this.newChatLog.content = this.newChatLog.content + buttonTag
      });
    },
    data: () => {
      return {
        newChatLog: {
          content: ''
        }
      }
    },
    components: {ChatToggleButton, ChatImageInput},
    computed: {
      disabled() {
        return false //プラン登録リクエスト中も、メッセージ送信できるように修正（2022/3/31）
        // return this.isPatient && !this.currentUser.is_subscribe && this.subscriptionRequesting
      },
      ...mapGetters({
        chat: 'chat',
        isNotPatient: 'isNotPatient',
        currentUser: 'currentUser',
        isPatient: 'isPatient',
        subscriptionRequesting: 'subscriptionRequesting'
      })
    },
    methods: {
      submit() {
        this.postChatLog({ chatLog: this.$data.newChatLog, chatId: this.chat.id });
        this.$data.newChatLog = {
          content: ''
        }
      },
      ...mapActions({ postChatLog: actionTypes.POST_CHAT_LOG }),
    },
  }
</script>

<style scoped>
  #chat-message-textarea {
    border-radius: 20px;
  }
</style>
