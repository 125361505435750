<template>
  <span v-if="isShow" class="chat-log-content">
    <ul class="list-unstyled">
      <li v-for="choice in chatLog.choices">
        <input @click="postChatLogChoice({ chatLog, choice })" type="radio" class="" :name="chatLog.id" :value="choice.id" v-model="picked" :disabled="isDisabled" />
        {{choice.name}}
      </li>
    </ul>
  </span>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import * as actionTypes from '../../store/action-types'

  export default {
    name: 'ChatLogChoiceBoard',
    props: ['chatLog', 'itemType'],
    data: () => {
      return { picked: null }
    },
    methods: {
      ...mapActions({ postChatLogChoice: actionTypes.POST_CHAT_LOG_CHOICE })
    },
    computed: {
      isDisabled () {
        return !this.isPatient
      },
      isShow () {
        return this.itemType === ChatLogEnum.ChatItemTypes.BOARD
      },
      ...mapGetters(['currentUser', 'isPatient'])
    }
  }
</script>

<style scoped lang="scss">
</style>
