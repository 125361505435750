<template>
  <div>
    <loading v-show="loading"></loading>
    <component v-if="examination" :clinicReservationToCreate="errorPrevention" :examination="examination" :isEdit="true" @updateExamination="updateExamination" @onCancel="onCancel" :is="interviewComponent"></component>
  </div>
</template>

<script>
  import axios from 'axios'
  import InterviewFormComponent from '@/enums/online_clinic/InterviewFormComponent';
  import DefaultInterview from '@/components/online_clinic/clinics/reservations/interviewForm/Default';
  import ChineseMedicineInterview from '@/components/online_clinic/clinics/reservations/interviewForm/ChineseMedicine';
  import EmergencyContraceptionInterview from '@/components/online_clinic/clinics/reservations/interviewForm/EmergencyContraception';
  import PmsInterview from '@/components/online_clinic/clinics/reservations/interviewForm/Pms';
  import Loading from '@/components/Common/Loading';

  export default {
    name: 'ExaminationEdit',
    data: () => {
      return {
        errorPrevention: {},//エラー防止のため空のオブジェクトだけ渡す
        examination: null,
        loading: false
      }
    },
    components: {
      DefaultInterview,
      ChineseMedicineInterview,
      EmergencyContraceptionInterview,
      PmsInterview,
      Loading
    },
    async created() {
      await this.fetchExamination();
    },
    methods: {
      async fetchExamination() {
        try {
          this.loading = true
          const examination = await axios.get(`/api/online_clinic/examinations/${this.$route.params.id}`);
          this.examination = examination.data
        } catch {
          alert("ERROR")
        } finally {
          this.loading = false
        }
      },
      async updateExamination(examinationdParams) {
        try {
          this.loading = true

          if (examinationdParams.examination_attributes.pharmacy_id == 'medication_mail') {
            // おうち便の場合
            examinationdParams.examination_attributes.pharmacy_id = null
            examinationdParams.examination_attributes.is_medication_mail = true
          } else {
            examinationdParams.examination_attributes.is_medication_mail = false
          }
          
          const examination = await axios.patch(`/api/online_clinic/examinations/${this.$route.params.id}`, { examination: examinationdParams })
          this.$localStorage.set('flashType', 'info')
          this.$localStorage.set('flashMessage', '問診内容を修正しました')
          this.$router.push(`/online_clinic/examinations/${examination.data.id}`);
        } catch  {
          alert("問診内容の修正に失敗しました")
          this.$router.push(`/online_clinic/examinations/${this.$route.params.id}`);
        } finally {
          this.loading = false
        }
      },
      onCancel() {
        this.$router.push(`/online_clinic/examinations/${this.$route.params.id}`);
      },
    },
    computed: {
      interviewComponent() {
        return InterviewFormComponent.name[this.examination.interview_type.interview_table_name] + 'Interview'
      },
    }
  }
</script>


