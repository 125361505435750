const BASIC_PLAN = "basic-plan"
const TRIAL_PLAN = "trial-plan"
const KANPO_PLAN = "kanpo-plan"
const STANDARD_PLAN = "standard-plan"
const PREMIUM_PLAN = "premium-plan"

export default {
  BASIC_PLAN,
  TRIAL_PLAN,
  KANPO_PLAN,
  STANDARD_PLAN,
  PREMIUM_PLAN
}
