<template>
  <i v-bind:class="className"></i>
</template>

<script>
  export default {
    name: 'IsPublishingIcon',
    props: ['isPublishing'],
    computed: {
      className() {
        return this.isPublishing ? 'fa fa-check-circle' : 'fa fa-times-circle'
      }
    }
  }
</script>

<style scoped lang="scss">
  .fa-check-circle {
    color: #66cb66;
  }
</style>
