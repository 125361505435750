<template>
  <div class="form-group">
    <base-text-field
      label-text="姓"
      v-model="firstName"
      name="first_name"
      :errors="errors.first_name"
    ></base-text-field>
    <component
      :is="textFieldComponent"
      label-text="姓（フリガナ）"
      v-model="firstNameKana"
      name="first_name_kana"
      :errors="errors.first_name_kana"
    ></component>

    <base-text-field label-text="名" v-model="lastName" name="last_name" :errors="errors.last_name"></base-text-field>
    <component :is="textFieldComponent" label-text="名（フリガナ）" v-model="lastNameKana" name="last_name_kana" :errors="errors.last_name_kana"></component>

    <div class="form-group">
      <div class="p-patientFamilyProfile__dateSelect">
        <label class="control-label" for="birthday">
          生年月日
          <abbr title="required">必須</abbr>
        </label>
        <select v-model="year" id="year">
          <option v-for="(item, key) in 101" :value="item + startYear" :key="key">
            {{ item + startYear }}
          </option>
        </select>/
        <select v-model="month" id="month">
          <option v-for="(item, key) in 12" :value="item" :key="key">
            {{ item }}
          </option>
        </select>/
        <select v-model="day" id="day">
          <option v-for="(item, key) in 31" :value="item" :key="key">
            {{ item }}
          </option>
        </select>
      </div>
    </div>

    <component
      :is="textFieldComponent"
      v-model="zipCode"
      label-text="郵便番号"
      name="zip_code"
      :errors="errors.zip_code"
    ></component>
    <p class="control-label">住所</p>

    <base-select-field label-text="都道府県" v-model="addressPrefectureId" name="prefecture_id">
      <option v-for="prefecture in prefectures" :value="prefecture.code" :key="prefecture.code">
        {{ prefecture.name }}
      </option>
    </base-select-field>

    <component
      :is="textFieldComponent"
      v-model="addressCity"
      label-text="市区郡町村"
      name="address_city"
      :errors="errors.address_city"
    ></component>
    <component
      :is="textFieldComponent"
      v-model="addressStreet"
      label-text="町・番地"
      name="address_street"
      :errors="errors.address_street"
    ></component>
    <base-text-optional-field
      v-model="addressOther"
      label-text="建物名・部屋番号"
      name="address_other"
      :errors="errors.address_other"
    ></base-text-optional-field>
    <div class="p-patientFamilyProfile__formNotice p-patientFamilyProfile__attentionForm">
      <span>保険診療の適用のため、保険証と同じ住所を入力してください</span>
    </div>
    <component
      :is="textFieldComponent"
      v-model="phoneNumber"
      label-text="携帯番号"
      name="phone_number"
      :errors="errors.phone_number"
    ></component>
    <div class="p-patientFamilyProfile__formNotice p-patientFamilyProfile__attentionForm">
      <span>緊急時にご連絡するため、正しい携帯番号を入力してください</span>
    </div>
    <div class="form-group form-group radio_buttons required user_patient_family_profiles_sex">
      <div class="control-group">
        <label class="control-label radio_buttons required">
          性別
          <abbr title="required">必須</abbr>
        </label>
        <base-radio-field v-model="sex" checkedValue="male" label-text="男性"></base-radio-field>
        <br/>
        <base-radio-field v-model="sex" checkedValue="female" label-text="女性"></base-radio-field>
      </div>
    </div>

    <div class="form-group">
      <div v-if="sex === 'female'" class="control-group">
        <label class="control-label radio_buttons required">妊娠</label>
        <base-radio-field v-model="pregnancy" checkedValue="no" label-text="妊娠していない"></base-radio-field>
        <br/>
        <base-radio-field v-model="pregnancy" checkedValue="yes" label-text="妊娠している"></base-radio-field>
      </div>
    </div>

    <component
      :is="textUnitFieldComponent"
      label-text="身長"
      unit="cm"
      v-model="height"
      name="height"
      :errors="errors.height"
    ></component>

    <component
      :is="textUnitFieldComponent"
      label-text="体重"
      unit="kg"
      v-model="weight"
      name="weight"
      :errors="errors.weight"
    ></component>

    <component
      :is="textUnitFieldComponent"
      label-text="基礎体温"
      unit="℃"
      v-model="basalBodyTemperature"
      name="basal_body_temperature"
      :errors="errors.basal_body_temperature"
    ></component>

    <base-text-field
      v-if="currentUser.source === 'kenko-check'"
      label-text="DEMECAL_ID"
      v-model="demecalId"
      name="demecal_id"
      :errors="errors.demecal_id"
    ></base-text-field>

    <base-text-field
      v-if="currentUser.source === 'kenko-check'"
      label-text="お問い合わせ番号"
      v-model="trackingNumber"
      name="tracking_number"
      :errors="errors.tracking_number"
    ></base-text-field>

    <result-field v-for="result in interviewResults" :result="result" :key="result.id"></result-field>

    <div class="p-patientFamilyProfile__textCenter">
      <button @click="emitSubmit()" class="c-mainButton">送信</button>
      <div v-if="!isNewForm">
        <button @click="deleteProfile()" class="c-subButton--denger">家族プロフィールの削除</button>
        <div class="p-deleteProfile__guidance">
          ※診療や相談などをご利用の場合、削除できません
        </div>
      </div>
      <div class="p-patientFamilyProfile__back">
        <router-link :to="`/patient_family_profiles`">キャンセル</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex';
  import BaseTextField from './base/Form/BaseTextField';
  import BaseTextOptionalField from './base/Form/BaseTextOptionalField';
  import BaseTextUnitField from './base/Form/BaseTextUnitField';
  import BaseTextUnitOptionalField from './base/Form/BaseTextUnitOptionalField';
  import vuejsDatepicker from 'vuejs-datepicker';
  import {ja} from 'vuejs-datepicker/dist/locale';
  import * as PatientFamilyProfiles from '../store/modules/patientFamilyProfiles';
  import BaseRadioField from './base/Form/BaseRadioField';
  import ResultField from './PatientFamilyProfileForm/ResultField';
  import * as mutationTypes from '../store/mutation-types';
  import moment from 'moment';
  import { Core as YubinBangoCore } from 'yubinbango-core'
  import { PREFECTURES } from "../const/prefectures";

  export default {
    name: 'PatientFamilyProfileForm',
    components: {
      ResultField,
      BaseRadioField,
      BaseTextField,
      BaseTextUnitField,
      BaseTextOptionalField,
      BaseTextUnitOptionalField,
      vuejsDatepicker,
    },
    props: ['isFromOnlineClinic', 'isNewForm'],
    computed: {
      ja: () => ja,
      textFieldComponent() {
        if (this.isFromOnlineClinic) {
          return 'base-text-field';
        } else {
          return 'base-text-optional-field';
        }
      },
      textUnitFieldComponent() {
        if (this.isFromOnlineClinic) {
          return 'base-text-unit-field';
        } else {
          return 'base-text-unit-optional-field';
        }
      },
      firstName: {
        get() {
          return this.patientFamilyProfile.first_name;
        },
        set(firstName) {
          this.setFirstName({firstName});
        },
      },
      firstNameKana: {
        get() {
          return this.patientFamilyProfile.first_name_kana;
        },
        set(firstNameKana) {
          this.setFirstNameKana({firstNameKana});
        },
      },
      lastName: {
        get() {
          return this.patientFamilyProfile.last_name;
        },
        set(lastName) {
          this.setLastName({lastName});
        },
      },
      lastNameKana: {
        get() {
          return this.patientFamilyProfile.last_name_kana;
        },
        set(lastNameKana) {
          this.setLastNameKana({lastNameKana});
        },
      },
      birthday: {
        get() {
          return this.patientFamilyProfile.birthday;
        },
        set(birthday) {
          this.setBirthday({birthday});
        },
      },
      year: {
        get() {
          return this.patientFamilyProfile.year;
        },
        set(year) {
          this.setYear({year});
        },
      },
      month: {
        get() {
          return this.patientFamilyProfile.month;
        },
        set(month) {
          this.setMonth({month});
        },
      },
      day: {
        get() {
          return this.patientFamilyProfile.day;
        },
        set(day) {
          this.setDay({day});
        },
      },
      zipCode: {
        get() {
          return this.patientFamilyProfile.zip_code;
        },
        set(zipCode) {
          this.setZipCode({zipCode});
        },
      },
      addressPrefectureId: {
        get() {
          return this.patientFamilyProfile.address_prefecture_id;
        },
        set(addressPrefectureId) {
          this.setAddressPrefectureId({addressPrefectureId});
          const prefecture = this.prefectures.find(value => value.code == this.addressPrefectureId)
          this.addressPrefectures = prefecture.name
        },
      },
      addressPrefectures: {
        get() {
          return this.patientFamilyProfile.address_prefectures;
        },
        set(addressPrefectures) {
          this.setAddressPrefectures({addressPrefectures});
        },
      },
      addressCity: {
        get() {
          return this.patientFamilyProfile.address_city;
        },
        set(addressCity) {
          this.setAddressCity({addressCity});
        },
      },
      addressStreet: {
        get() {
          return this.patientFamilyProfile.address_street;
        },
        set(addressStreet) {
          this.setAddressStreet({addressStreet});
        },
      },
      addressOther: {
        get() {
          return this.patientFamilyProfile.address_other;
        },
        set(addressOther) {
          this.setAddressOther({addressOther});
        },
      },
      phoneNumber: {
        get() {
          return this.patientFamilyProfile.phone_number;
        },
        set(phoneNumber) {
          this.setPhoneNumber({phoneNumber});
        },
      },
      sex: {
        get() {
          return this.patientFamilyProfile.sex;
        },
        set(sex) {
          this.setSex({sex});
        },
      },
      pregnancy: {
        get() {
          return this.patientFamilyProfile.pregnancy;
        },
        set(pregnancy) {
          this.setPregnancy({pregnancy});
        },
      },
      height: {
        get() {
          return this.patientFamilyProfile.height;
        },
        set(height) {
          this.setHeight({height});
        },
      },
      weight: {
        get() {
          return this.patientFamilyProfile.weight;
        },
        set(weight) {
          this.setWeight({weight});
        },
      },
      basalBodyTemperature: {
        get() {
          return this.patientFamilyProfile.basal_body_temperature;
        },
        set(basalBodyTemperature) {
          this.setBasalBodyTemperature({basalBodyTemperature});
        },
      },
      demecalId: {
        get() {
          return this.patientFamilyProfile.demecal_id;
        },
        set(demecalId) {
          this.setDemecalId({demecalId});
        },
      },
      trackingNumber: {
        get() {
          return this.patientFamilyProfile.tracking_number;
        },
        set(trackingNumber) {
          this.setTrackingNumber({trackingNumber});
        },
      },
      interviewResults() {
        return this.patientFamilyProfile.interview_results;
      },
      isValid() {
        if (this.isFromOnlineClinic) {
          const requiredInputFields = [
            this.firstName,
            this.firstNameKana,
            this.lastName,
            this.lastNameKana,
            this.year,
            this.month,
            this.day,
            this.zipCode,
            this.addressPrefectures,
            this.addressCity,
            this.addressStreet,
            this.phoneNumber,
            this.sex,
            this.height,
            this.weight,
            this.basalBodyTemperature
          ]
          return !requiredInputFields.some(field => (field == null || field == ''))
        } else {
          const requiredInputFields = [
            this.firstName,
            this.lastName,
            this.year,
            this.month,
            this.day,
            this.sex,
          ]
          return !requiredInputFields.some(field => (field == null || field == ''))
        }
      },
      startYear () {
        return moment().get('year') - 101
      },
      prefectures () {
        return PREFECTURES
      },
      currentProfileId() {
        const profile = this.$store.getters['patientFamilyProfiles/get'];
        return profile ? profile.id : null;
      },
      ...mapGetters({
        currentUser: 'currentUser',
        patientFamilyProfile: 'patientFamilyProfiles/get',
        errors: 'patientFamilyProfiles/errors',
      }),
    },
     watch: {
      firstNameKana(firstNameKana) {
        if (!firstNameKana || !!firstNameKana.match(/^[ァ-ヶー　]*$/)) {
          this.errors.first_name_kana = null
        } else {
          this.errors.first_name_kana = '姓（フリガナ）は全角カタカナで入力してください'
        }
      },
      lastNameKana(lastNameKana) {
        if (!lastNameKana || !!lastNameKana.match(/^[ァ-ヶー ]*$/)) {
          this.errors.last_name_kana = null
        } else {
          this.errors.last_name_kana = '名（フリガナ）は全角カタカナで入力してください'
        }
      },
      zipCode(zipCode) {
        if (!zipCode || !!zipCode.match(/^\d{7}$/)) {
          this.errors.zip_code = null
        } else {
          this.errors.zip_code = '郵便番号は7桁の半角数字で入力してください。'
        }

        new YubinBangoCore(this.zipCode, (addr)=> {
          this.addressPrefectureId = addr.region_id
          this.addressPrefectures = addr.region // 都道府県
          this.addressCity = addr.locality // 市区町村
          this.addressStreet = addr.street // 町域
        })
      },
      phoneNumber(phoneNumber) {
        if (!phoneNumber || !!phoneNumber.match(/^\d{11}$/)) {
          this.errors.phone_number = null
        } else {
          this.errors.phone_number = '携帯番号はハイフンなし、半角数字で入力してください'
        }
      },
    },
    methods: {
      ...mapMutations({
        setFirstName: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_FIRST_NAME}`,
        setFirstNameKana: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_FIRST_NAME_KANA}`,
        setLastName: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_LAST_NAME}`,
        setLastNameKana: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_LAST_NAME_KANA}`,
        setBirthday: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_BIRTHDAY}`,
        setYear: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_YEAR}`,
        setMonth: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_MONTH}`,
        setDay: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_DAY}`,
        setZipCode: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_ZIP_CODE}`,
        setAddressPrefectureId: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_ADDRESS_PREFECTURE_ID}`,
        setAddressPrefectures: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_ADDRESS_PREFECTURES}`,
        setAddressCity: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_ADDRESS_CITY}`,
        setAddressStreet: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_ADDRESS_STREET}`,
        setAddressOther: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_ADDRESS_OTHER}`,
        setPhoneNumber: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_PHONE_NUMBER}`,
        setSex: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_SEX}`,
        setPregnancy: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_PREGNANCY}`,
        setHeight: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_HEIGHT}`,
        setWeight: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_WEIGHT}`,
        setBasalBodyTemperature: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_BASAL_BODY_TEMPERATURE}`,
        setDemecalId: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_DEMECAL_ID}`,
        setTrackingNumber: `patientFamilyProfiles/${PatientFamilyProfiles.mutationTypes.SET_TRACKING_NUMBER}`,
      }),
      ...mapActions({
        deletePatientFamilyProfile: `patientFamilyProfiles/${PatientFamilyProfiles.actionTypes.DELETE}`,
      }),
      emitSubmit() {
        if (this.isValid){
          this.$emit('submit')
        } else {
          this.$store.commit(mutationTypes.PUSH_NOTICE, { message: '必須項目を入力してください', type: 'danger' }, { root: true })
        }
      },
      async deleteProfile() {
        if (confirm('家族プロフィールを削除しますか？') === false) return;

        try {
          await this.deletePatientFamilyProfile({ id: this.currentProfileId })
          this.$router.push('/patient_family_profiles');
        } catch (error) {
          console.error(error)
        }
      },
    },
  };
</script>

