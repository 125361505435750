<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header">
        お支払い方法の確認
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>
      <div class="c-mainModal__content">
        <div class="c-mainModal__formGroup">
          <label for="coupon_code">連携コード
          </label>
          <input id="coupon_code" :class="{'p-healthCheckupSupportReservationWithCouponModal__errorInput': apiInvalidCouponError}" v-model="couponCode" placeholder="1234 1234 1234 1234">
        </div>
        <p v-if="apiInvalidCouponError" class="p-healthCheckupSupportReservationWithCouponModal__error">
          連携コードが無効です
        <p>
      <button class="c-mainButton" @click="submit" :disabled="submitDisabled">予約を確定する</button>
      <p class="p-healthCheckupSupportReservationWithCouponModal__noticeTitle">連携コードの注意事項</p>
      <div class="p-healthCheckupSupportReservationWithCouponModal__notice">
        <p>・おうち病院 健診サポートの連携コードをお持ちの方は、こちらへ入力して予約してください。<br>・「連携コードが無効です」と表示された場合は、入力値の誤りまたは有効期限が切れている可能性があり
ます。専用ページにてご確認ください。</p>
      </div>
      </div>
      
    </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '@/store/action-types';

export default {
  name: 'RegistrationWithCoupon',
  computed: {
    ...mapGetters(['currentUser']),
  },
  props: {
    apiInvalidCouponError: {
      type: Boolean
    }
  },
  data() {
    return {
      couponCode: '',
      submitDisabled: false,
    };
  },
  
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
    submit() {
      this.submitDisabled = true;
      this.$emit('addConsultationData', this.couponCode);
      this.$emit('create-reservation');
      this.$mixpanel.track('CLICK BUTTON', { page: '健診サポート 問診の回答（2）', buttonName: '予約を確定する', userId: this.currentUser.id, source: this.currentUser.source, isCvButton: true });
      this.submitDisabled = false;
    },
    backPatientFamilyProfiles() {
      this.$router.push(`/patient_family_profiles`);
    },
    ...mapActions({ postSAuthorization: actionTypes.POST_AUTHORIZATION, updateAuthorization: actionTypes.UPDATE_AUTHORIZATION }),
  },
};
</script>
