import CableApp from './CableApp'

const chatTimeLineChannel = (component) => {
  CableApp.init();
  CableApp.ChatTimelineChannel = CableApp.cable.subscriptions.create({ channel: 'ChatTimeLineChannel', chat_id: component.$route.params.chat_id }, {
    connected: function() {
      component.$data.cableStatus = 'CONNECTED'
    },
    disconnected: function () {
      component.$data.cableStatus = 'DISCONNECTED'
    },
    received: function(data) {
      switch(data.type) {
        case 'RESTORE_TOKEN':
          $('meta[name="cable-token"]').attr('content', data.payload.token);
          return;
        case 'GET_CHATS':
          component.setReadMessage({ read_at: data.payload.read_at });
          component.setChatLogs({ chatLogs: data.payload.chat.chat_logs });
          component.pageScrollToBottom();
          this.perform('update_read_at', {});
          return;
        case 'GET_RESULT':
          component.setReadMessage({ read_at: data.payload.read_at });
          component.setResult({result: data.payload.result});
          component.pageScrollToBottom();
          this.perform('update_read_at', {});
          return;
        case 'REMOVE_RESULT':
          component.setReadMessage({ read_at: data.payload.read_at });
          component.removeResult({result: data.payload.result});
          component.pageScrollToBottom();
          this.perform('update_read_at', {});
          return;
        case 'GET_READ_MESSAGE':
          component.attachReadMessage(data.payload);
          return;
        case 'GET_IS_SATISFIED':
          component.setIsSatisfied(data.payload)
        default:
          return;
      }
    }
  });
};
export default chatTimeLineChannel
