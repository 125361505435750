<template>
  <div class="form-group">
    <div v-for='resultItem in resultForm.resultItems.filter(ri => ri.masterItem.is_publishing || !ri._destroy)' class="radio" :id="`cy-chat-interview-result-item-${resultItem.masterItem.id}`">
      <input :id="`interview-result-item-${resultItem.masterItem.id}`"
              type="radio"
              :value="resultItem.masterItem.id"
              v-model="resultItemsForm"
              :disabled="!isPatient"
              :name="`chat-interview-result-${groupMaster.masterId}`">
      <label :for="`interview-result-item-${resultItem.masterItem.id}`">
        {{resultItem.masterItem.label}}
      </label>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    name: 'ChatLogInterviewResultRadioField',
    props: {
      groupMaster: {
        type: Object,
        required: true
      },
      resultForm: {
        type: Object,
        required: true
      }
    },
    computed: {
      resultItemsForm: {
        get() {
          return (this.resultForm.resultItems.find(ri => !ri._destroy) || {masterItem: {id: null}}).masterItem.id
        },
        set(masterItemId) {
          this.resultForm.resultItems = this.resultForm.resultItems.map(ri =>
             (ri.masterItem.id === masterItemId) ? {...ri, _destroy: false} :  {...ri, _destroy: true}
          )
        },
      },
      ...mapGetters({isPatient: 'isPatient'}),
    }
  }
</script>

<style scoped lang="scss">
  .radio input[type="radio"] {
    margin-left: 0px;
  }

  .radio input + label:before {
    background: #FFF;
    width: 24px;
    height: 24px;
    border: 1px solid #E4E4E9;
    border-radius: 100px;
    top: -2px;
    left: -5px;
  }

  .radio input:checked + label:before {
    background: #FFF;
  }

  .radio input:checked + label:after  {
    background: linear-gradient(41.64deg, #00BCD4 0.06%, #2196F3 100.01%);
    width: 10px;
    height: 10px;
    top: 5px;
    left: 2px;
  }
</style>
