<template>
  <div class="form-group">
    <base-text-area :label-text="result.master.message" v-model="result.content" :name="`interview-result-${result.master.id}`"></base-text-area>
  </div>
</template>
<script>
  import BaseTextArea from "../base/Form/BaseTextArea";
  export default {
    name: 'ResultTextField',
    components: {BaseTextArea},
    props: ['result'],
  }
</script>
