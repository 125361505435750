<template>
  <div>
    <notice-alerts></notice-alerts>
    <base-title :prev-path="`/chat_interview/groups/${$route.params.group_id}`">
      <h1>問診項目編集</h1>
    </base-title>
    <chat-interview-master-form :master-form="masterForm" :errors="errors" :errors-children="errorsChildren">
      <template slot="submit">
        <button @click="handleSubmit" class="btn btn-primary">
          更新
        </button>
      </template>
    </chat-interview-master-form>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import {actionTypes}  from '../../store/modules/chatInterview/masters'
  import ChatInterviewMasterForm from "./MasterForm";
  export default {
    name: 'ChatInterviewMasterNew',
    components: {ChatInterviewMasterForm},
    created() {
      this.fetchMaster(this.$route.params.id).then(master => {
          this.$data.masterForm = master
        }
      )
    },
    data() {
      return {
        masterForm: {
          message: "",
          interview_type: 'text',
          interval: 0,
          is_publishing: true,
          master_items: [],
          group_ids: []
        },
        errors: {
        },
        errorsChildren: []
      }
    },
    methods: {
      handleSubmit() {
        this.patchMaster({master: this.$data.masterForm})
          .then(() => this.$router.history.push(`/chat_interview/groups/${this.$route.params.group_id}`) )
          .catch((errors) => {
            this.errors = errors.errors;
            this.errorsChildren = errors.children;
          })
      },
      ...mapActions({
        fetchMaster: `chatInterview/masters/${actionTypes.FETCH}`,
        patchMaster: `chatInterview/masters/${actionTypes.PATCH}`
      })
    }
  }
</script>
