<script>
  import Vue from 'vue'
  const BaseNl2Br = Vue.component('base-nl2br', {
    functional: true,
    props: ['tag','text', 'textFilter', 'tagClass'],
    render: function (createElement, context) {
      const textFilter = context.props.textFilter(createElement, context) || (text => text);
      const texts = [...(context.props.text || "").split("\n"), ''];
      return createElement(context.props.tag,
        { ...context.parent.$attrs, class: context.props.tagClass },
        texts.reduce(function(x,y) {
            if (!Array.isArray(x)) {
              return [textFilter(x), createElement('br'), textFilter(y)];
            }
            return x.concat([createElement('br'), textFilter(y)]);
        }));
    }
  });

  export default BaseNl2Br
</script>
