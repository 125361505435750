import VueRouter from 'vue-router'

import ChatTimeLine from './components/ChatTimeLine'
import Consultations from './components/Consultations'
import ConsultationNew from './components/ConsultationNew'
import MedicalDashboardForm from './components/MedicalDashboardForm'
import InterviewMasters from './components/InterviewMasters'
import InterviewMasterNew from './components/InterviewMasterNew'
import InterviewMasterEdit from './components/InterviewMasterEdit'
import PatientFamilyProfiles from './components/PatientFamilyProfiles'
import PatientFamilyProfile from './components/PatientFamilyProfile'
import PatientFamilyProfileNew from './components/PatientFamilyProfileNew'
import PatientFamilyProfileModal from './components/PatientFamilyProfileModal'
import ChatInterviewNewModal from './components/ChatInterviewNewModal'

import ChatInterviewGroups from './components/ChatInterview/Groups'
import ChatInterviewGroup from './components/ChatInterview/Group'
import ChatInterviewMasterNew from './components/ChatInterview/MasterNew'
import ChatInterviewMasterEdit from './components/ChatInterview/MasterEdit'
import StripeCardForm from './components/StripeCardForm'
import StripeCardGetAuthorizationForm from './components/StripeCardGetAuthorizationForm'
import ManagementUsers from "./components/ManagementUsers";
import RoleEditModal from "./components/RoleEditModal";
import OperatorInvitations from "./components/OperatorInvitations";

import OnlineClinicHome from './pages/online_clinic/home'
import OnlineClinicGuide from './pages/online_clinic/guide'
import ClinicReservationNew from './pages/online_clinic/clinics/reservations/new'
import Examination from './pages/online_clinic/examinations/show'
import Examinations from './pages/online_clinic/examinations'
import ExaminationCompleted from './pages/online_clinic/examinations/completed'
import ExaminationEdit from './pages/online_clinic/examinations/edit'
import WebMtg from './pages/online_clinic/web_mtg'

import OnlineConsultationHome from './pages/online_consultation/home'
import OnlineConsultationGuide from './pages/online_consultation/guide'
import OnlineConsultationNew from './pages/online_consultation/consultations/new'
import ConsultationCreate from './pages/online_consultation/consultations/create'
import OnlineConsultations from './pages/online_consultation/consultations'

import HealthCheckupSupportHome from './pages/health_checkup_support/home'
import HealthCheckupSupportGuide from './pages/health_checkup_support/guide'
import HealthCheckupSupportConsultationsNew from './pages/health_checkup_support/consultations/new'
import HealthCheckupSupportConsultation from './pages/health_checkup_support/consultations/show'
import HealthCheckupSupportConsultationHistories from './pages/health_checkup_support/consultation_histories/index'
import HealthCheckupSupportConsultationHistory from './pages/health_checkup_support/consultation_histories/show'
import NativeAppGuide from './pages/native-app/guide/index'


import DoctorHome from './pages/doctor/home'
import HealthCheckupSupportConsultationWebMtg from './pages/health_checkup_support/web_mtg/index';

const routes = [
  {
    path: '/consultations',
    component: Consultations,
    children: [
      {
        path: ':consultation_id/patient_family_profile',
        component: PatientFamilyProfileModal
      }
    ]
  },
  {
    path: '/consultations/new',
    component: ConsultationNew
  },
  {
    path: '/chat/:chat_id',
    component: ChatTimeLine,
    children: [
      {
        path: 'patient_family_profile',
        component: PatientFamilyProfileModal
      }, {
        path: 'interviews/new',
        component: ChatInterviewNewModal
      }, {
        path: 'subscription',
        component: StripeCardForm
      }
    ]
  },
  {
    path: '/stripe_authorization',
    component: StripeCardGetAuthorizationForm
  },
  {path: '/medical_dashboard/patient_family_profiles/:id/edit', component: MedicalDashboardForm},

  {path: '/interview/masters', component: InterviewMasters},
  {path: '/interview/masters/new', component: InterviewMasterNew},
  {path: '/interview/masters/:id/edit', component: InterviewMasterEdit},

  {path: '/chat_interview/groups', component: ChatInterviewGroups},
  {path: '/chat_interview/groups/:id', component: ChatInterviewGroup},
  {path: '/chat_interview/groups/:group_id/masters/new', component: ChatInterviewMasterNew},
  {path: '/chat_interview/groups/:group_id/masters/:id/edit', component: ChatInterviewMasterEdit},

  { path: '/patient_family_profiles', component: PatientFamilyProfiles},
  { path: '/patient_family_profiles/new', component: PatientFamilyProfileNew, name: 'patient_family_profile_new'},
  { path: '/patient_family_profiles/:id', component: PatientFamilyProfile, name: 'patient_family_profile'},
  {
    path: '/management_users',
    component: ManagementUsers,
    children: [
      {path: ':id/role', component: RoleEditModal}
    ]
  }, {
    path: '/operator_invitations',
    component: OperatorInvitations
  },

  // onlien clinic
  {
    path: '/online_clinic',
    component: OnlineClinicHome,
    name: 'online_clinic_home'
  },
  {
    path: '/online_clinic/guide',
    component: OnlineClinicGuide,
  },
  {
    path: '/online_clinic/clinic/reservation/new',
    component: ClinicReservationNew,
    name: 'clinic_reservation_new'
  },
  {
    path: '/online_clinic/examinations/:id/edit',
    component: ExaminationEdit,
  },
  {
    path: '/online_clinic/examinations/:id',
    component: Examination,
    name: 'examination'
  },
  {
    path: '/online_clinic/examinations',
    component: Examinations,
  },

  {
    path: '/online_clinic/examinations/completed/:id',
    component: ExaminationCompleted,
    name: 'examination_completed'
  },
  {
    path: '/online_clinic/web_mtg/:id',
    component: WebMtg,
    name: 'web_mtg'
  },

  //online consultation
  {
    path: '/online_consultation',
    component: OnlineConsultationHome,
    name: 'online_consultation_top'
  },
  {
    path: '/online_consultation/guide',
    component: OnlineConsultationGuide,
  },
  {
    path: '/online_consultation/consultations/new',
    component: OnlineConsultationNew,
    name: 'online_consultation_consultations_new',
  },
  {
    path: '/online_consultation/consultations/create',
    component: ConsultationCreate,
    name: 'online_consultation_consultations_create',
  },
  {
    path: '/online_consultation/consultations',
    component: OnlineConsultations,
    name: 'online_consultation_consultations',
  },

  //doctor
  {
    path: '/doctor',
    component: DoctorHome
  },

  //health_checkup_support
  {
    path: '/health_checkup_support/home',
    component: HealthCheckupSupportHome,
  },
  {
    path: '/health_checkup_support/guide',
    component: HealthCheckupSupportGuide,
  },
  {
    path: '/health_checkup_support/consultations/new',
    component: HealthCheckupSupportConsultationsNew,
  },
  {
    path: '/health_checkup_support/consultations/:id',
    component: HealthCheckupSupportConsultation,
  },
  {
    path: '/health_checkup_support/consultation_histories',
    component: HealthCheckupSupportConsultationHistories,
  },
  {
    path: '/health_checkup_support/consultation_histories/:id',
    component: HealthCheckupSupportConsultationHistory,
  },
  {
    path: '/health_checkup_support/consultations/:id/web_mtg',
    component: HealthCheckupSupportConsultationWebMtg,
  },

  // native-ap
  {
    path: '/native_app/guide',
    component: NativeAppGuide,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
