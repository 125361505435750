export const PUSH_NOTICE = 'PUSH_NOTICE';
export const REMOVE_NOTICE = 'REMOVE_NOTICE';

export const SET_CHAT = 'SET_CHAT';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_PATIENT_COUNT = 'SET_PATIENT_COUNT';

export const SET_CONSULTATIONS = 'SET_CONSULTATIONS';
export const SET_ADDITIONAL_CONSULTATIONS = 'SET_ADDITIONAL_CONSULTATIONS';
export const SET_CONSULTATIONS_META = 'SET_CONSULTATIONS_META';
export const SET_TOTAL_CONSULTATIONS_COUNT = 'SET_TOTAL_CONSULTATIONS_COUNT';
export const SET_RESPONDING_CONSULTATIONS_COUNT = 'SET_RESPONDING_CONSULTATIONS_COUNT';
export const SET_DOCTORS_NEW_CONSULTATIONS = 'SET_DOCTORS_NEW_CONSULTATIONS';

export const SET_CHAT_LOG_OGP = 'SET_CHAT_LOG_OGP';
export const SET_CHAT_LOG = 'SET_CHAT_LOG';
export const PUSH_CHAT_LOG = 'PUSH_CHAT_LOG';
export const DELETE_CHAT_LOG = 'DELETE_CHAT_LOG';
export const SET_CHAT_LOGS = 'SET_CHAT_LOGS';
export const FINISH_CHAT = 'FINISH_CHAT';

export const SET_MEDICAL_DASHBOARD_PATIENT = 'SET_MEDICAL_DASHBOARD_PATIENT';
export const SET_MEDICAL_DASHBOARD_CATEGORIES = 'SET_MEDICAL_DASHBOARD_CATEGORIES';

export const SET_READ_MESSAGE = 'SET_READ_MESSAGE';
export const SET_PATIENT_READ_MESSAGE = 'SET_PATIENT_READ_MESSAGE';

export const SET_IS_FROM_OUCHI_BYOUIN_APP = 'SET_IS_FROM_OUCHI_BYOUIN_APP';

