const Interview = {};

Interview.sex = {
  female: 'female',
  male: 'male'
};

Interview.pregnancy = {
  yes: 'yes',
  no: 'no'
};

Interview.generic = {
  yes: 'yes',
  no: 'no'
};

export default Interview;
