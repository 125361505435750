<template>
  <base-image-modal style="z-index:9998;">
    <template slot="header">
       <img :src="imagePath" class="card_image">
    </template>
  </base-image-modal>

</template>

<script>
import BaseImageModal from '@/components/base/BaseImageModal';

export default {
  name: 'ImageView',
  data: () => {
    return {
      imagePath: null,
    };
  },
  props: ['image'],
  components: { BaseImageModal },
  created() {
    this.imagePath = this.$props.image;
  },
};
</script>

<style scoped lang="scss">
.card_image {
  width: 90%;
  height: 90%;
  max-height: 300px;
  max-width: 300px;
  object-fit: contain;
  margin: 0 auto;
}
</style>
