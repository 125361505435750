<template>
  <div class="info-box">
    <span class="info-box-icon" :class="bgClass"><i class="fa" :class="iconClass"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">{{title}}</span>
      <span class="info-box-number">{{text}}</span>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: 'ConsultationSummaryCard',
    props: ['iconClass', 'bgClass', 'title', 'text']
  }
</script>

<style scoped>
  .info-box {
    display: block;
    min-height: 70px;
    background: #fff;
    width: 95%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    border-radius: 2px;
    margin-bottom: 15px;
  }
  .info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 70px;
    width: 70px;
    text-align: center;
    font-size: 45px;
    line-height: 70px;
    background: rgba(0,0,0,0.2);
  }
  .info-box-content {
    padding: 5px 10px;
    margin-left: 90px;
  }
  .info-box-text {
    text-transform: uppercase;
  }
  .info-box-text {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
  }
  .bg-aqua{
    background-color: #6cbeec !important;
  }
  .bg-red{
    background-color: #dd4b39 !important;
  }
  .bg-green{
    background-color: #6ecd6e !important;
  }
  .bg-yellow{
    background-color: #f39c12!important;
  }
</style>

