<template>
  <div>
    <div class="onlineClinic-confirmation__box">

      <div class="onlineClinic-confirmation__label">問診内容</div>

      <div class="onlineClinic-confirmation__label-interview">保険証<br>※クリックすると拡大されます</div>
      <div class="onlineClinic-confirmation__item-interview">
        <img :src="clinicReservationToCreate.interview_for_department.patient_insurance_card_image.url" @click="openImageModal" class="onlineClinic-confirmation__card_image">
      </div>

      <div class="onlineClinic-confirmation__label-interview">緊急避妊薬（アフターピル）は性交渉後72h以内で服用する必要があります。性行為の時間をご入力ください。</div>
      <div class="onlineClinic-confirmation__item-interview">{{setSexualIntercourseDatetime()}}</div>

      <div class="onlineClinic-confirmation__label-interview">現在治療中あるいは、今までに病気や手術をしたことがありますか？</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.medical_history}}</div>

      <div class="onlineClinic-confirmation__label-interview">現在服用中のお薬はありますか？</div>
      <div class="onlineClinic-confirmation__item-interview">{{convertLabeltext(clinicReservationToCreate.interview_for_department.is_current_medicine)}}</div>

      <div class="onlineClinic-confirmation__label-interview">現在服用中のお薬がある場合は記入してください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.current_medicine}}</div>

      <div class="onlineClinic-confirmation__label-interview">アレルギーはありますか？</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.allergy}}</div>

      <div class="onlineClinic-confirmation__label-interview">その他、事前に医師にお伝えすべきことや、ご質問があれば記載ください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.other_information}}</div>

      <div class="onlineClinic-confirmation__label-interview">今までお薬を飲んで副作用が出たことはありますか？あればそのお薬名を記載してください</div>
      <div class="onlineClinic-confirmation__item-interview">{{clinicReservationToCreate.interview_for_department.side_effect}}</div>

      <div class="onlineClinic-confirmation__label-interview">先進医薬品をご希望ですか？（※ジェネリック医薬品は効能効果は先発品と同じで、飲みやすく改良されているお薬です。安価な為患者様の負担も軽減できます。）</div>
      <div class="onlineClinic-confirmation__item-interview">{{convertLabeltext(clinicReservationToCreate.interview_for_department.generic)}}</div>
    </div>
    <div>
      <ImageView :image="clinicReservationToCreate.interview_for_department.patient_insurance_card_image.url" v-if="isImageViewModal"></ImageView>
    </div>
  </div>
</template>
<script>
// TODO yes noの変換の仕組みはどこかにまとめたい
import moment from "moment";
import Interview from '@/enums/online_clinic/Interview';
import EventBus from '@/event-bus';
import ImageView from '@/components/Common/ImageView';

const genericHash = {
  [Interview.generic.yes]: {
    labelText: 'はい',
  },
  [Interview.generic.no]: {
    labelText: 'いいえ',
  },
};
export default {
  components: {
    ImageView,
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isImageViewModal: false,
      isRecipientCertificateImageViewModal: false,
    };
  },
  filters: {
    convertGenericValueToText(value) {
      return genericHash[value].labelText;
    },
  },
  created() {
    EventBus.$on('close-image-modal', () => {
      this.closeImageModal();
    });
  },
  methods: {
    openImageModal() {
      this.isImageViewModal = true;
    },
    closeImageModal() {
      this.isImageViewModal = false;
      this.isRecipientCertificateImageViewModal = false;
    },
    openRecipientCertificateImageModal() {
      this.isRecipientCertificateImageViewModal = true;
    },
    convertLabeltext(text) {
      switch (text) {
        case 'yes':
          return 'はい'
        case 'no':
          return 'いいえ'
        case 'neither':
          return 'どちらともいえない'
        default:
          return ''
      }
    },
    setSexualIntercourseDatetime(){
      if (this.clinicReservationToCreate.interview_for_department.datetime_of_sexual_intercourse){
        return this.format_datetime(moment(this.clinicReservationToCreate.interview_for_department.datetime_of_sexual_intercourse))
      }
    },
    format_datetime(datetime) {
      if (datetime.hour() === 0) {
        return datetime.format('YYYY年M月D日') + ' AM 0時'
      } else if (datetime.hour() === 12) {
        return datetime.format('YYYY年M月D日') + ' 正午'
      } else {
        return datetime.format('YYYY年M月D日 A h時')
      }
    }
  },
};
</script>

<style scope lang="scss">
.onlineClinic-confirmation {
  margin: auto;
  padding: 100px 0 25px;
  text-align: center;

  &__title {
    font-size: 14px;
    font-weight: 500;
    color: #04aae4;
  }

  &__container {
    background: #f3f4f5;
    color: #2a3039;
    padding: 2px 16px 16px 16px;
    font-size: 14px;
    text-align: left;
  }

  &__box {
    max-width: 312px;
    margin: 24px auto;
    padding: 0 15px;
  }

  &__label {
    font-weight: 500;
    margin-bottom: 18px;
  }

  &__item {
    margin-left: 20px;
    font-weight: 200;
  }

  &__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  &__label-interview {
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
  }

  &__item-interview {
    font-size: 12px;
    font-weight: 200;
    margin: 20px 20px 8px 20px;
    word-break: break-word;
  }

  &__footer {
    text-align: center;
  }

  &__button {
    display: block;
    max-width: 312px;
    width: 100%;
    height: 56px;
    background: #04aae4;
    border-radius: 60px;
    color: #fff;
    text-align: center;
    padding-top: 6px;
    text-decoration: none;
    margin: 20px auto;
    border: none;

    &:disabled {
      background: #e4e4e9;
    }
  }

  &__nobutton {
    color: #97979a;
    font-size: 12px;
    background: none;
    border: none;
    text-decoration: underline;
    margin-bottom: 30px;
  }

  &__register_credit {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  &__card_image {
    width: 100px;
    height: 100px;
  }
}

</style>
