const InterviewFormComponent = {};

InterviewFormComponent.name = {
  interview: 'Default', //通常問診
  chinese_medicine_interview: 'ChineseMedicine', //漢方問診
  emergency_contraception_interview: 'EmergencyContraception', //緊急避妊問診
  pms_interview: 'Pms', //月経困難症・PMS外来
};

export default InterviewFormComponent;

