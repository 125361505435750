import axios from 'axios'
import * as mutationTypes from '../mutation-types'
import * as actionTypes from '../action-types'

const actions = {
  [actionTypes.POST_CHAT_LOG_CHOICE] ({ commit }, payload) {
    axios.post('/api/chats/update_log_type', { id: payload.chatLog.id })
      .then(firstResponse => {
        commit(mutationTypes.SET_CHAT_LOG, { chatLog: firstResponse.data })
        axios.post('/api/chats/create_question_answer', { id: payload.choice.id, chat_id: payload.chatLog.chat_id })
          .then(response => commit(mutationTypes.PUSH_CHAT_LOG, { chatLog: response.data }))
          .catch(response => console.log(response))
      })
      .catch(response => console.log(response))
  },

  [actionTypes.POST_CHAT_LOG] ({ commit }, payload) {
    return axios.post(`/api/chats/${payload.chatId}/chat_logs`, { chat_log: payload.chatLog })
      .then(response => commit(mutationTypes.PUSH_CHAT_LOG, { chatLog: response.data }))
      .catch(response => console.log(response))
  },

  [actionTypes.POST_CHAT_LOG_IMAGE] ({ commit }, { image, chatId }) {
    let formData = new FormData();
    formData.append('chat_log[image]', image);
    let config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    axios.post(`/api/chats/${chatId}/chat_logs`, formData, config)
      .then(response => commit(mutationTypes.PUSH_CHAT_LOG, { chatLog: response.data }))
      .catch(response => console.log(response))
  },

  [actionTypes.DELETE_CHAT_LOG]({ commit }, payload) {
    return axios.delete(`/api/chats/${payload.chatId}/chat_logs/${payload.chatLogId}`)
      .then(response => commit(mutationTypes.DELETE_CHAT_LOG, { chatLogId: response.data }))
      .catch(response => console.log(response))
  },

};

export default {
  actions,
}

