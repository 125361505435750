<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header">
        お支払い方法の確認
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>

      <div class="c-mainModal__content">
        <div class="c-mainModal__formGroup">
          <label class="control-label">カード番号
          <img :src="visaLogo" alt="visa" style="height: 15px"/>
          <img :src="masterCardLogo" alt="masterCard" style="height: 29px"/>
          <img :src="amexLogo" alt="amex" style="height: 19px"/>
          <img :src="jcbLogo" alt="jcb" style="height: 19px"/>
          </label>
          <card-number class="form-control"
                       ref="cardNumber"
                       :stripe="stripePublishableKey"
                       :options="options"
                       @change="number = $event.complete"
          />
        </div>

        <p v-if="errorMessage" class="error-text">{{errorMessage}}</p>

        <div class="c-mainModal__formGroup">
          <label class="control-label">カード有効期限</label>
          <card-expiry class="form-control"
                       ref="cardExpiry"
                       :stripe="stripePublishableKey"
                       :options="options"
                       @change="expiry = $event.complete"
                       :divider="true"
          />
        </div>

        <div class="c-mainModal__formGroup">
          <label class="control-label">セキュリティコード</label>
          <card-cvc class='form-control'
                    ref='cardCvc'
                    :stripe='stripePublishableKey'
                    :options='options'
                    @change="cvc = $event.complete"
          />
        </div>

        <div class="p-healthCheckupSupportCreditCardRegistrationModal__serviceCharge">
          <span class="p-healthCheckupSupportCreditCardRegistrationModal__serviceChargeTag">健診サポート1回</span>
          <p>7,700<span>円（税込）</span></p>
        </div>
      <button class="c-mainButton" @click="submit" :disabled="submitDisabled">カードを登録する</button>
      <p class="p-healthCheckupSupportCreditCardRegistrationModal__noticeTitle">クレジットカード決済の注意事項</p>
      <div class="p-healthCheckupSupportCreditCardRegistrationModal__notice">
        <p>・本クレジットカード決済は決済代行サービス提供会社（Stripe社）による機能を使用して提供されます。<br>・お客様のクレジットカード番号はSSLというセキュリティシステムを利用し暗号化されているた
め、漏えい等のご心配はございません。安心してサービスをご利用ください。<br>・お客様のクレジットカード情報は弊社データベースには格納されません。</p>
      </div>
      </div>

    </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>

</template>

<script>

import amexLogo from '@/../assets/images/cards/amex.gif';
import visaLogo from '@/../assets/images/cards/visa.png';
import masterCardLogo from '@/../assets/images/cards/master_card.svg';
import jcbLogo from '@/../assets/images/cards/jcb.gif';

import {
  CardNumber,
  CardExpiry,
  CardCvc,
  createToken,
} from 'vue-stripe-elements-plus';

import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '@/store/action-types';

export default {
  name: 'CreditCardRegistration',
  components: { CardNumber, CardExpiry, CardCvc },
  computed: {
    amexLogo: () => amexLogo,
    visaLogo: () => visaLogo,
    masterCardLogo: () => masterCardLogo,
    jcbLogo: () => jcbLogo,
    ...mapGetters(['currentUser']),
    isCreditCardRegistered() {
      return this.currentUser.is_creditcard_registered
    },
  },
  data() {
    const token = document
      .getElementsByName('stripe-public-key')[0]
      .getAttribute('content');
    return {
      submitDisabled: false,
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      token: null,
      stripePublishableKey: token,
      options: {},
      errorMessage: null,
    };
  },
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
  },
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
    confirmCancel() {
      this.token = null;
    },
    submit() {
      this.submitDisabled = true;
      createToken().then(data => {
        if (!!data.error) {
          this.errorMessage = data.error.message;
          this.submitDisabled = false;
          return;
        }
        let submitAction = null;
        if(this.isCreditCardRegistered) {
          submitAction = this.updateAuthorization
        } else {
          submitAction = this.postSAuthorization
        }
        submitAction(data.token.id).then(response => {
          this.submitDisabled = false;
          this.$emit('change-modal-component','Reservation')
        });
      });
    },
    backPatientFamilyProfiles() {
      this.$router.push(`/patient_family_profiles`);
    },
    update() {
      this.complete = this.number && this.expiry && this.cvc;
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
    },
    ...mapActions({ postSAuthorization: actionTypes.POST_AUTHORIZATION, updateAuthorization: actionTypes.UPDATE_AUTHORIZATION }),
  },
};
</script>
