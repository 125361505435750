<template>

  <div class="p-onlineClinicHome">
    <div class="p-onlineClinicHome__info">
      <div class="p-onlineClinicHome__header">
        <p class="p-onlineClinicHome__status">現在のステータス</p>
      </div>

      <div class="p-onlineClinicHome__body">
        <p class="p-onlineClinicHome__title">処方箋が郵送されました</p>
        <p class="p-onlineClinicHome__comment">
          処方せんをご自宅宛に郵送しております。<br>
          ご到着までいましばらくお待ちください。<br><br>
          お手元に届きましたら、<br>
          お近くの薬局でお薬を受け取ってください。
        </p>
        <a :href="prescription_image" :target="!this.isIOS? '_blank':false" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '処方せんを表示', userId: currentUser.id, source: currentUser.source })" class="c-subButton">処方箋を表示</a>
        <p class="p-onlineClinicHome__tap-instruction">タップすると画像が開きます。</p>
        <p class="p-onlineClinicHome__recovery-message">お大事になさってください</p>
      </div>
    </div>

    <router-link to="/online_clinic/clinic/reservation/new" class="c-mainButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '診察を予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })">
      診療を予約する
    </router-link>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="this.$props.chat == null ? null : this.$props.chat.id"
    ></notification-button>

    <a href="/online_clinic/examinations" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '過去の診療', userId: currentUser.id, source: currentUser.source })">
      過去の診察
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">
      会員情報
    </a>
  </div>
</template>


<script>
import NotificationButton from "./buttons/NotificationButton";
import {mapGetters} from "vuex";
export default {
  name: 'IssuingPrescriptionByMail',
  props: ['chat', 'examination'],
  components: {
    NotificationButton
  },
  computed: {
    ...mapGetters(['currentUser']),
    isIOS() {
      if (window.navigator.userAgent.indexOf('anamne-app') != -1) {
        return true;
      } else {
        return false;
      }
    },
    prescription_image() {
      if (this.isIOS) {
        return this.examination == null ? '読込中...' : "anamne-app-settings://anamne-open-web?" + this.examination.prescription.prescription_image
      } else {
        return this.examination == null ? '読込中...' : this.examination.prescription.prescription_image
      }
    },
  }
};
</script>
