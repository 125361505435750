import Vue from 'vue'
import BaseIncrementalSelect from '../components/base/BaseIncrementalSelect'
import { hCollectionToArray } from '../shared/Helper'


const Notifications = (() => {
  const init = () => {
    if (document.getElementById('js-notification-form') !== null) {
      Array.prototype.forEach.call(document.getElementsByClassName('vue-select'), el => {
        const name = el.getAttribute('name');
        const options = hCollectionToArray(el.options).map(optionToObject);
        const value = optionToObject(getSelectValue(el));
        new Vue({
          el,
          data: {
            options: options
          },
          render: h => h(BaseIncrementalSelect, { props: { value: value, options: options, name: name }})
        });
      });
    }
  };

  const getSelectValue = select => {
    return Array.prototype.slice.call(select.options).find(opt => opt.selected);
  };

  const optionToObject = opt => { return { name: opt.innerHTML, code: opt.value } };

  return { init: init };
})();

export default Notifications;
