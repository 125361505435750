<template>
  <div class="p-onlineClinicHome">
    <div class="p-onlineClinicHome__info">
      <div class="p-onlineClinicHome__header">
        <p class="p-onlineClinicHome__status">現在のステータス</p>
      </div>

      <div class="p-onlineClinicHome__body">
        <p class="p-onlineClinicHome__title">処方箋が発行されました</p>
      </div>
      <p class="p-onlineClinicHome__prescription-pickup" v-if="this.examination.pharmacy">「{{ this.examination.pharmacy.name }}」の受付で処方せんをご提示いただき、お薬を受け取ってください。</p>
      <div v-if="this.examination.pharmacy">
        <GoogleMap
        :lat="examination.pharmacy.lat"
        :lng="examination.pharmacy.lng"
        :pharmacies="[examination.pharmacy]"
        />
      </div>
      <a :href="prescription_image" :target="!this.isIOS? '_blank':false" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '処方せんを表示', userId: currentUser.id, source: currentUser.source }); checkPrescriptionOrBillingViewed()" class="c-subButton">処方箋を表示</a>
      <p class="p-onlineClinicHome__tap-instruction">タップすると画像が開きます。</p>
      <p class="p-onlineClinicHome__recovery-message">お大事になさってください</p>
    </div>
    <router-link to="/online_clinic/clinic/reservation/new" class="c-mainButton" @click.native="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '診察を予約する', userId: currentUser.id, source: currentUser.source, isCvButton: true })">
      診療を予約する
    </router-link>

    <notification-button
      :hasEmergencyConsultation="this.$parent.hasEmergencyConsultation"
      :chat-id="this.$props.chat == null ? null : this.$props.chat.id"
    ></notification-button>

    <a href="/online_clinic/examinations" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '過去の診療', userId: currentUser.id, source: currentUser.source })">
      過去の診察
    </a>

    <a href="/patient_family_profiles" class="c-subButton" @click="$mixpanel.track('CLICK BUTTON', { page: '診療トップ', buttonName: '会員情報', userId: currentUser.id, source: currentUser.source })">
      会員情報
    </a>
  </div>
</template>


<script>
  import axios from 'axios'
  import NotificationButton from "./buttons/NotificationButton";
  import GoogleMap from '@/components/online_clinic/GoogleMap'
  import {mapGetters} from "vuex";

  export default {
    name: 'AfterIssuingPrescription',
    props: ['examination', 'prescriptionId', 'chat'],
    components: {
      GoogleMap, NotificationButton
    },
    data() {
      return {
        mapAddressLat: 0,
        mapAddressLng: 0,
      }
    },
    computed: {
      isIOS() {
        if (window.navigator.userAgent.indexOf('anamne-app') != -1) {
          return true;
        } else {
          return false;
        }
      },
      prescription_image() {
        if (this.isIOS) {
          return this.examination == null ? '読込中...' : "anamne-app-settings://anamne-open-web?" + this.examination.prescription.prescription_image
        } else {
          return this.examination == null ? '読込中...' : this.examination.prescription.prescription_image
        }
      },
      ...mapGetters(['currentUser'])
    },
    methods:{
      async checkPrescriptionOrBillingViewed(){
        try {
          await axios.post(`/api/online_clinic/examinations/${this.examination.id}/read`);
        } catch(e) {
          console.error(e);
          console.log("examination post error")
        }
      },
    }
    // methods: {
    //   async fetchSelectablePharmacies(address) {
    //     try {
    //       const res = await axios.get(`/api/online_clinic/pharmacies`,
    //         { params: { address: address }});
    //       this.pharmacies = res.data;
    //       this.pharmacies.push({name: '処方箋を郵便で受け取る', id: null})
    //       this.form.selectedPharmacy = res.data[0]; // TODO 一件もない時の考慮
    //     } catch {
    //       alert('ERROR');
    //     }
    //   },
    // }
  };
</script>
