import axios from 'axios'
import * as rootMutationTypes from '../../mutation-types'
import {LOADING_QUERY, LOADING} from '../../loading'

export const actionTypes = {
  FETCH: 'FETCH_CHAT_INTERVIEW_MASTER',
  POST: 'POST_CHAT_INTERVIEW_MASTER',
  PATCH: 'PATCH_CHAT_INTERVIEW_MASTER'
};

export const mutationTypes = {
};

const state = {
};

const getters = {
};

const toCreateParams = (masterForm) => ({
  message: masterForm.message,
  interview_type: masterForm.interview_type,
  interval: 0,
  is_publishing: masterForm.is_publishing,
  group_ids: masterForm.group_ids,
  master_items_attributes: masterForm.master_items.map(mi => ({
    label: mi.label,
    is_supplement_text: mi.is_supplement_text,
    is_publishing: mi.is_publishing,
    position: mi.position,
    _destroy: mi._destroy
  }))
});

const toUpdateParams = (masterForm) => ({
  message: masterForm.message,
  interview_type: masterForm.interview_type,
  interval: 0,
  is_publishing: masterForm.is_publishing,
  group_ids: masterForm.group_ids,
  master_items_attributes: masterForm.master_items.map(mi => ({
    id: mi.id,
    label: mi.label,
    is_supplement_text: mi.is_supplement_text,
    is_publishing: mi.is_publishing,
    position: mi.position,
    _destroy: mi._destroy
  }))
});

const actions = {
  [actionTypes.FETCH] ({commit}, id) {
    return axios.get(`/api/chat_interview/masters/${id}`,)
      .then(response => response.data)
      .catch(({ response })  => {
        if (response.status === 422) {
          throw response.data
        }
        commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } )
      })
  },
  [actionTypes.POST] ({ commit }, { master }) {
    return axios.post('/api/chat_interview/masters', { master: toCreateParams((master)) })
      .then(_ => {
        commit(rootMutationTypes.PUSH_NOTICE, { message: "項目を作成しました", type: 'success' }, { root: true } );
        return true
      })
      .catch(({ response })  => {
        if (response.status === 422) {
          throw response.data
        }
        commit(rootMutationTypes.PUSH_NOTICE, { message: "作成に失敗しました", type: 'danger' }, { root: true } )
      })
  },
  [actionTypes.PATCH] ({ commit }, { master }) {
    return axios.patch(`/api/chat_interview/masters/${master.id}`, { master: toUpdateParams((master)) })
      .then(_ => {
        commit(rootMutationTypes.PUSH_NOTICE, { message: "項目を編集しました", type: 'success' }, { root: true } );
        return true
      })
      .catch(({ response })  => {
        if (response.status === 422) {
          throw response.data
        }
        commit(rootMutationTypes.PUSH_NOTICE, { message: "作成に失敗しました", type: 'danger' }, { root: true } )
      })
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

