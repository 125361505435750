import * as mutationTypes from '../mutation-types'

const state = {
  notices: []
};

const getters = {
  notices: state => state.notices
};

const mutations = {
  [mutationTypes.PUSH_NOTICE] (state, notice) {
    state.notices = [...state.notices, notice]
  },
  [mutationTypes.REMOVE_NOTICE] (state, notice) {
    state.notices = state.notices.filter(n => n !== notice)
  },
};

export default {
  state,
  getters,
  mutations
}

