<template>
  <div class="c-mainModal">
    <div class="c-mainModal__box">
      <div class="c-mainModal__header">
        その他の薬局を選択される場合
        <img src="@/../assets/images/batsu.png" @click="closeModal()">
      </div>
      <div class="c-mainModal__container">
        その他の薬局を選択される場合には、<span class="p-onlineClinicInterview__emphasis">事前にご自身で薬局にお問い合わせいただき「FAXによる処方せんの受け付けていますか？」とご確認ください。</span>
        <div class="p-onlineClinicInterview__pharmacyDescription">
          <img src="@/../assets/images/online_clinic/question.svg" alt="">
          「その他の薬局」とは
          <p>
          厚生局の情報を元に表示している薬局です。FAX処方せんを受け付けている場合、アナムネよりFAXを送ることが可能です。最寄りの薬局でFAX処方せんを受け付けている場合などにご選択ください。
          </p>
        </div>
        <div class="p-onlineClinicInterview__pharmacyDescription">
          <img src="@/../assets/images/online_clinic/question.svg" alt="">
          「アナムネ連携薬局」とは
          <p>
          アナムネと提携しFAXによる処方せんを受け付けている薬局です。確認の手間なく薬のお受け取りが可能です。
          </p>
        </div>
      </div>
    </div>
    <div class="c-mainModal__overlay">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal(){
      this.$emit('close-modal')
    },
  },
};
</script>