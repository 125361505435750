import axios from 'axios'
import * as rootMutationTypes from '../../mutation-types'

export const actionTypes = {
  FETCH_INTERVIEW_MASTERS: 'FETCH_INTERVIEW_MASTERS',
  FETCH_INTERVIEW_MASTER: 'FETCH_INTERVIEW_MASTER',
  POST_INTERVIEW_MASTER: 'POST_INTERVIEW_MASTER',
  PATCH_INTERVIEW_MASTER: 'PATCH_INTERVIEW_MASTER',
  PATCH_INTERVIEW_MASTERS_POSITIONS: 'PATCH_INTERVIEW_MASTERS_POSITIONS'
};

export const mutationTypes = {
  SET_INTERVIEW_MASTERS: 'SET_INTERVIEW_MASTERS',
  SET_INTERVIEW_MASTER: 'SET_INTERVIEW_MASTER',
  INITIAL_INTERVIEW_MASTER: 'INITIAL_INTERVIEW_MASTER',
  SET_INTERVIEW_MASTER_MESSAGE: 'SET_INTERVIEW_MASTER_MESSAGE',
  SET_INTERVIEW_MASTER_INTERVIEW_TYPE: 'SET_INTERVIEW_MASTER_INTERVIEW_TYPE',
  SET_INTERVIEW_MASTER_WAY_TYPE: 'SET_INTERVIEW_MASTER_WAY_TYPE',
  SET_INTERVIEW_MASTER_IS_PUBLISHING: 'SET_INTERVIEW_MASTER_IS_PUBLISHING',
  PUSH_INTERVIEW_MASTER_ITEM: 'PUSH_INTERVIEW_MASTER_ITEM',
  SET_INTERVIEW_MASTER_ITEM__DESTROY: 'SET_INTERVIEW_MASTER_ITEM__DESTROY',
  SET_INTERVIEW_MASTER_ITEMS_POSITIONS: 'SET_INTERVIEW_MASTER_ITEMS_POSITIONS'
};

const defaultMaster = () => ({
  message: '',
  interview_type: 'text',
  way_type: 'patient_question',
  is_publishing: true,
  master_items: [],
});

const defaultMasterItem = (lastPosition) => ({
  label: '',
  position: lastPosition + 1,
  is_supplement_text: false,
  is_publishing: true,
  _destroy: false
});

const state = {
  masters: [],
  master: {
    master_items: []
  }
};

const getters = {
  query: state => state.masters,
  get: state => state.master,
  errors: state => (state.master.errors || {}),
  toParam: _state => master => ({
    master: {
      interview_type: master.interview_type,
      way_type: master.way_type,
      message: master.message,
      is_publishing: master.is_publishing,
      master_items_attributes: master.master_items.map((master_item) => ({
        id: master_item.id,
        label: master_item.label,
        position: master_item.position,
        is_supplement_text: master_item.is_supplement_text,
        is_publishing: master_item.is_publishing,
        _destroy: master_item._destroy
      }))
    }
  })
};

const actions = {
  [actionTypes.FETCH_INTERVIEW_MASTER] ({ commit }, { id }) {
    axios.get(`/api/interview/masters/${id}`)
      .then(response => {
        commit(mutationTypes.SET_INTERVIEW_MASTER, { master: response.data })
      })
      .catch(_response => commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } ) )
  },
  [actionTypes.FETCH_INTERVIEW_MASTERS] ({ commit }, { wayType }) {
    axios.get(`/api/interview/masters`, { params: { way_type: wayType } })
      .then(response => {
        commit(mutationTypes.SET_INTERVIEW_MASTERS, { masters: response.data })
      })
      .catch(_response => commit(rootMutationTypes.PUSH_NOTICE, { message: "取得に失敗しました", type: 'danger' }, { root: true } ) )
  },

  [actionTypes.POST_INTERVIEW_MASTER] ({ commit, getters }, { master, callback }) {
    axios.post(`/api/interview/masters`, getters.toParam(master))
      .then(response => {
        commit(mutationTypes.SET_INTERVIEW_MASTER, { master: response.data });
        commit(rootMutationTypes.PUSH_NOTICE, { message: "作成に成功しました", type: 'success' }, { root: true } )
        callback(response.data.id)
      })
      .catch(response => {
        commit(mutationTypes.SET_INTERVIEW_MASTER, { master: response.data });
        commit(rootMutationTypes.PUSH_NOTICE, { message: "作成に失敗しました", type: 'danger' }, { root: true })
      })
  },

  [actionTypes.PATCH_INTERVIEW_MASTER] ({ commit, getters }, { master }) {
    axios.patch(`/api/interview/masters/${master.id}`, getters.toParam(master))
      .then(response => {
        commit(mutationTypes.SET_INTERVIEW_MASTER, { master: response.data });
        commit(rootMutationTypes.PUSH_NOTICE, { message: "更新に成功しました", type: 'success' }, { root: true } )
      })
      .catch(({ response }) => {
        commit(mutationTypes.SET_INTERVIEW_MASTER, { master: response.data });
        commit(rootMutationTypes.PUSH_NOTICE, { message: "更新に失敗しました", type: 'danger' }, { root: true } )
      })
  },

  [actionTypes.PATCH_INTERVIEW_MASTERS_POSITIONS] ({ commit }, { masters }) {
    let values = masters.map((m, i) => ({ ...m, position: i }));
    commit(mutationTypes.SET_INTERVIEW_MASTERS, { masters: values });
    axios.patch(`/api/interview/masters`, { masters: values })
      .catch(response => commit(rootMutationTypes.PUSH_NOTICE, { message: "更新に失敗しました", type: 'danger' }, { root: true } ) )
  }
};

const mutations = {
  [mutationTypes.SET_INTERVIEW_MASTERS] (state, { masters }) {
    state.masters = masters
  },
  [mutationTypes.SET_INTERVIEW_MASTER] (state, { master }) {
    state.master = master
  },
  [mutationTypes.INITIAL_INTERVIEW_MASTER] (state) {
    state.master = defaultMaster()
  },
  [mutationTypes.SET_INTERVIEW_MASTER_MESSAGE] (state, { message }) {
    state.master = { ...state.master, message: message }
  },
  [mutationTypes.SET_INTERVIEW_MASTER_INTERVIEW_TYPE] (state, { interviewType }) {
    state.master = { ...state.master, interview_type: interviewType }
  },
  [mutationTypes.SET_INTERVIEW_MASTER_WAY_TYPE] (state, { wayType }) {
    state.master = { ...state.master, way_type: wayType }
  },
  [mutationTypes.SET_INTERVIEW_MASTER_IS_PUBLISHING] (state, { isPublishing }) {
    state.master = { ...state.master, is_publishing: isPublishing }
  },
  [mutationTypes.PUSH_INTERVIEW_MASTER_ITEM] (state) {
    let lastPosition = (state.master.master_items.slice(-1)[0] || { position: 0 }).position;
    state.master.master_items = [...state.master.master_items, defaultMasterItem(lastPosition)]
  },
  [mutationTypes.SET_INTERVIEW_MASTER_ITEM__DESTROY] (state, { masterItem }) {
    state.master.master_items = state.master.master_items.map((mi) => {
      if (mi === masterItem) {
        return {...mi, _destroy: true}
      }
      return mi
    })
  },
  [mutationTypes.SET_INTERVIEW_MASTER_ITEMS_POSITIONS] (state, { masterItems }) {
    let values = masterItems.map((mi, i) => ({ ...mi, position: i }));
    state.master.master_items = values
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}

