<template>
  <div v-if="isShow" class="chat-log-interview panel panel-default">
    <div class="panel-heading">問診票</div>
    <base-loading-icon :target="interview">
      <div class="panel-body" style="padding:15px 3px;">
        <step-bar v-if="!showResultsEnabled" :field-count="groupMasters.length" :answered-count="results.length">
        </step-bar>
        <chat-log-interview-result v-if="showResultsEnabled"
                                    v-for="result in results"
                                    :key="result.id"
                                    :result="result"
                                    :chat-log-id="chatLog.id">

        </chat-log-interview-result>
        <chat-log-interview-result-field :group-master="unSubmittedGroupMaster"
                                          :chat-log-id="chatLog.id"
                                          :is-first="results.length === 0"
                                          :is-last="results.length === groupMasters.length - 1"
                                          @back="handleBack"
                                          v-if="!!unSubmittedGroupMaster">
        </chat-log-interview-result-field>
      </div>
    </base-loading-icon>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import * as Helper from '../../shared/Helper'
  import ChatLogEnum from '../../enums/ChatLogEnum'
  import {actionTypes} from '../../store/modules/chatLogs/interview'
  import ChatLogInterviewResult from "./ChatLogInterview/Result";
  import ChatLogInterviewResultField from "./ChatLogInterview/ResultField";
  import StepBar from "./ChatLogInterview/StepBar";

  export default {
    name: 'ChatLogInterview',
    props: ['chatLog', 'itemType'],
    components: {StepBar, ChatLogInterviewResultField, ChatLogInterviewResult },
    created() {
      if (this.isShow) {
        this.fetchInterview({chatLogId: this.chatLog.id})
          .then(Helper.pageScrollToBottom)
      }
    },
    computed: {
      isShow () {
        return this.itemType === ChatLogEnum.ChatItemTypes.INTERVIEW
      },
      interview() {
        return this.interviewItem(this.chatLog.id)
      },
      groupMasters() {
        return this.interview.chat_interview_public_group_masters || []
      },
      results() {
        return this.interview.chat_interview_results || []
      },
      showResultsEnabled() {
        return this.isNotPatient || this.results.length === this.groupMasters.length
      },
      unSubmittedGroupMaster() {
        const resultMasterIds = this.results.map(cir => cir.master_id);
        return this.groupMasters.find(gm => !resultMasterIds.includes(gm.master_id))
      },
      ...mapGetters({interviewItem: 'chatLogInterview/item', isNotPatient: 'isNotPatient' }),
    },
    methods: {
      handleBack() {
        this.deleteResult({result: this.results.slice(-1)[0]});
      },
      ...mapActions({
        fetchInterview: `chatLogInterview/${actionTypes.FETCH}`,
        deleteResult: `chatLogInterview/${actionTypes.DELETE_RESULT}`
      })
    }
  }
</script>

<style scoped>
  .panel {
    border: 0;
  }

  .panel-body {
    padding: 15px 3px;
  }

  .panel-heading {
    padding: 2px 0 0 0 !important;
    text-align: center;
    color: #FFFFFF !important;
    background: linear-gradient(41.64deg, #00BCD4 0.06%, #2196F3 100.01%);
    border: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    border-radius: 4px;
    height: 25px;
  }

  p {
    margin-bottom: 0;
  }
  .chat-log-interview {
    padding:12px;
    background-color: #eaeaea;
    margin: 60px 8px 10px 10px;
    border-radius: 20px;
    min-width:220px;
  }
</style>
