<template>
  <div>
    <create-wizard @create-reservation="createReservation" :apiError="apiError" :apiCreditError="apiCreditError" :apiDoctorScheduleConflictError="apiDoctorScheduleConflictError" :apiDoubleReservationError="apiDoubleReservationError" :apiInvalidCouponError="apiInvalidCouponError"></create-wizard>
  </div>
</template>

<script>
  import CreateWizard from '@/components/health_checkup_support/consultations/CreateWizard'
  import axios from 'axios'
  export default {
    components: {
      CreateWizard
    },
    data() {
      return {
        apiError: null,
        apiCreditError: null,
        apiDoctorScheduleConflictError: null,
        apiDoubleReservationError: null,
        apiInvalidCouponError: null,
      }
    },
    methods: {
      async createReservation(reservationFormattedParams, callback) {
        this.apiDoctorScheduleConflictError = null
        this.apiDoubleReservationError = null
        try {
          const consultation = await axios.post(`/api/health_checkup_support/consultations`, { reservations: reservationFormattedParams })
          this.$localStorage.set('flashType', 'info')
          this.$localStorage.set('flashMessage', 'ありがとうございます。下記内容でご予約が完了しました')
          window.location.href = `/health_checkup_support/consultations/${consultation.data.id}`
        } catch (error) {
          if (error.response.status === 409) {
            this.apiDoctorScheduleConflictError = true
          } else if (error.response.status === 422 && error.response.data && error.response.data.error_type == 'double_reservation_error') {
            this.apiDoubleReservationError = true
          } else if (error.response.status === 422 && error.response.data && error.response.data.error_type == 'invalid_coupon_error') {
            this.apiInvalidCouponError = true
          } else if (error.response.data.type === 'creditError') {
            alert("クレジットカードの有効性を確認できませんでした。")
            this.apiCreditError = true
          } else {
            this.apiError = true
          }
          callback()
        }

      }
    }
  }
</script>
