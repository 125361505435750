<template>
  <div>
    <div class="form-group">
      <notice-alert v-for="notice in notices" :notice="notice" />
    </div>
    <div>
      <h1>問診項目編集</h1>
      <router-link to="/interview/masters" class="btn btn-default">
        戻る
      </router-link>
    </div>

    <master-form>
      <template slot="submit">
        <div class="btn-group">
          <input @click="patchInterviewMaster({master})" type="submit" value="更新" class="btn btn-primary">
        </div>
      </template>
    </master-form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as Masters from '../store/modules/interview/masters'
  import NoticeAlert from "./NoticeAlert";
  import MasterForm from "./Interview/MasterForm";

  export default {
    name: 'InterviewMasterEdit',
    components: { MasterForm, NoticeAlert },

    created () {
      this.fetchInterviewMaster({ id: this.$route.params.id });
    },

    computed: {
      ...mapGetters({ notices: 'notices', master: 'interview/masters/get' })
    },

    methods: {
      ...mapActions({
        fetchInterviewMaster: `interview/masters/${Masters.actionTypes.FETCH_INTERVIEW_MASTER}`,
        patchInterviewMaster: `interview/masters/${Masters.actionTypes.PATCH_INTERVIEW_MASTER}`
      }),
    }
  }
</script>

<style scoped lang="scss">
</style>
