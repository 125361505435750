<template>
  <div class="p-onlineClinicInterview">
    <loading :translucent="true" v-show="loading"></loading>
    <interview-autofill-confirm-modal v-if="isConfirmDialogOpen" @close-modal="isConfirmDialogOpen = false" @set-latest-interview="setLatestInterview"></interview-autofill-confirm-modal>
    <button v-if="!isFirstInterview" class="c-mainButton p-onlineClinicInterview__latest-interview" @click="isConfirmDialogOpen = true">前回の問診をコピーする</button>
    <div>
      <div class="p-onlineClinicInterview__input">
        <label for="from_pharmacy_address" class="p-onlineClinicInterview__label">お薬を受け取る薬局を選択してください
        </label>
        <select class="p-onlineClinicInterview__select" v-model="form.selectedPharmacy" :disabled="restrictInput.pharmacy">
          <option v-for="pharmacy in pharmacies" :value="pharmacy" :key="pharmacy.id">
            {{ pharmacy.name }}
          </option>
        </select>

        <p class='supplement'>
          ※「おくすりおうち便」は処方薬の配送サービスです。<br>
          <a href="https://anamne.wraptas.site/a3c9ec905cbc4fdaa43e37314886ce3e" target="_blank" rel="noopener noreferrer">
            → 詳しくはこちら
          </a>
        </p>
        <p class='supplement'>ご自宅住所付近の薬局を表示しています。別の場所で受け取りたい場合、住所入力で薬局を検索できます。</p>
        <input id="from_pharmacy_address"
               v-model="form.pharmacyAddress"
               placeholder='例) 東京都港区赤坂'>
        <button class="c-mainButton" @click="changePharmacyAddress(); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: '薬局を探す', userId: currentUser.id, source: currentUser.source })">薬局を探す</button>
      </div>
      <GoogleMap
        :lat="mapAddressLat"
        :lng="mapAddressLng"
        :pharmacies="pharmacies"
        v-if="mapShowable"/>
        <div class="p-onlineClinicInterview__iconDescription">
        <img src="@/../assets/images/google_map/anamne.svg" alt="">
        連携薬局
        <!-- <img src="@/../assets/images/google_map/other.svg" alt="">
        その他の薬局 -->
      </div>
      <!-- <div class="p-onlineClinicInterview__pharmacyDescription">
        <img src="@/../assets/images/online_clinic/question.svg" alt="">
        「アナムネ連携薬局」とは
        <p>
        アナムネと提携しFAXによる処方せんを受け付けている薬局です。確認の手間なく薬のお受け取りが可能です。
        </p>
      </div> -->
      <!-- <div class="p-onlineClinicInterview__pharmacyGuidance" @click="isPharmacyGuidanceOpen = true">
        <p>その他の薬局を選択される場合は、 <br>こちらをご確認ください</p>
        <img src="@/../assets/images/online_clinic/right_triangle.svg" alt="">
      </div>
      <pharmacy-guidance v-if="isPharmacyGuidanceOpen" @close-modal="isPharmacyGuidanceOpen = false"></pharmacy-guidance> -->
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">初診ですか？</div>
      <label><input type="radio" v-model="form.isFirstVisit" value="yes" :disabled="restrictInput.isFirstVisit">はい</label>
      <label><input type="radio" v-model="form.isFirstVisit" value="no" :disabled="restrictInput.isFirstVisit">いいえ</label>
    </div>


    <div class="p-onlineClinicInterview__file">
      <div class="p-onlineClinicInterview__label">保険証<abbr title="required">必須</abbr>
      </div>
      <div class="p-onlineClinicInterview__photoExample">写真の例</div>
      <img src="../../../../../../assets/images/online_clinic/ok.png" alt="" class="p-onlineClinicInterview__photoExampleImage">
      <img src="../../../../../../assets/images/online_clinic/ng.png" alt="" class="p-onlineClinicInterview__photoExampleImage">
      <div class="p-onlineClinicInterview__notice">※記載内容がはっきりと読み取れる画像を  お選びください。また国民健康保険の場合は、有効期限内かご確認ください。</div>
      <input id="insurance_card_image" type="file" @change="selectedInsuranceCardImage" accept="image/*" :disabled="restrictInput.insuranceCardImage">
      <label for="insurance_card_image">写真を撮ってアップロード</label>
      <div class="p-onlineClinicInterview__previewImage">
        <img v-if="form.insuranceCardImage || insuranceCardImagePreview" :src="form.insuranceCardImage || insuranceCardImagePreview">
      </div>
      <span>{{insuranceCardImageName}}</span>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="medical_history" class="p-onlineClinicInterview__label">緊急避妊薬（アフターピル）は性交渉後72h以内で服用する必要があります。性行為の時間をご入力ください。</label>
      <div class="p-onlineClinicInterview__datetimeSelect">
        <Datepicker class="p-onlineClinicInterview__datepicker"
                    :language="ja"
                    :format="DatePickerFormat"
                    v-model='selectedDate'
                    ></Datepicker>
        <select class="p-onlineClinicInterview__timeSelect" v-model="selectedAmOrPm">
          <option  value="AM">
            AM
          </option>
          <option  value="PM">
            PM
          </option>
        </select>
        <select class="p-onlineClinicInterview__timeSelect" v-model="selectedTime">
          <option v-for="option in timeOptions" :value="option" :key="option.id">
            {{ option }}
          </option>
        </select>
      </div>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="medical_history" class="p-onlineClinicInterview__label">現在治療中あるいは、今までに病気や手術をしたことがありますか？</label>
      <textarea id="medical_history" v-model="form.medicalHistory" maxlength="1000" :disabled="restrictInput.medicalHistory"></textarea>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">現在服用中のお薬はありますか？</div>
      <label><input type="radio" v-model="form.isCurrentMedicine" value="yes" :disabled="restrictInput.isCurrentMedicine">はい</label>
      <label><input type="radio" v-model="form.isCurrentMedicine" value="no" :disabled="restrictInput.isCurrentMedicine">いいえ</label>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="current_medicine" class="p-onlineClinicInterview__label">現在服用中のお薬がある場合は記入してください</label>
      <textarea id="current_medicine" v-model="form.currentMedicine" maxlength="1000" :disabled="restrictInput.currentMedicine"></textarea>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="allergy" class="p-onlineClinicInterview__label">アレルギーはありますか？</label>
      <textarea id="allergy" v-model="form.allergy" maxlength="1000" :disabled="restrictInput.allergy"></textarea>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="other_information" class="p-onlineClinicInterview__label">その他、事前に医師にお伝えすべきことや、ご質問があれば記載ください</label>
      <textarea id="other_information" v-model="form.otherInformation" maxlength="1000" :disabled="restrictInput.otherInformation"></textarea>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="side_effect" class="p-onlineClinicInterview__label">今までお薬を飲んで副作用が出たことはありますか？あればそのお薬名を記載してください</label>
      <textarea id="side_effect" v-model="form.sideEffect" maxlength="1000" :disabled="restrictInput.sideEffect"></textarea>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">ジェネリック医薬品がある場合、ジェネリック医薬品をご希望ですか？</div>
      <label><input type="radio" v-model="form.generic" value="yes" :disabled="restrictInput.generic">はい</label>
      <label><input type="radio" v-model="form.generic" value="no" :disabled="restrictInput.generic">いいえ</label>
    </div>

    <div v-if="!isEdit">
      <div class="p-onlineClinicInterview__footer">
        <button class="c-mainButton" @click="addExaminationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })"
                :disabled="!isValid">次へ
        </button>
        <button class="p-onlineClinicInterview__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
      </div>
    </div>
    <div v-if="isEdit">
      <div class="p-onlineClinicInterview__footer">
        <button class="c-mainButton" @click="emitUpdateExamination(); $mixpanel.track('CLICK BUTTON', { page: '問診修正', buttonName: '保存', userId: currentUser.id, source: currentUser.source })"
                :disabled="!isValid">保存
        </button>
        <button class="p-onlineClinicInterview__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診修正', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from 'axios';
import GoogleMap from '@/components/online_clinic/GoogleMap'
import Loading from '@/components/Common/Loading';
import PharmacyGuidance from '@/components/online_clinic/clinics/reservations/interviewForm/PharmacyGuidance';
import InterviewAutofillConfirmModal from '@/components/online_clinic/clinics/reservations/dialog/InterviewAutofillConfirmModal'
import Datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';
import {mapGetters} from "vuex";


export default {
  components: { GoogleMap, Loading, PharmacyGuidance, InterviewAutofillConfirmModal, Datepicker},
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
    examination: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    }
  },
  data() {
    return {
      pharmacies: [],
      recipientCrtificateImageRequired: 'yes',
      mapAddressLat: 0,
      mapAddressLng: 0,
      mapShowable: false,
      loading: false,
      insuranceCardImageName: '',
      recipientCertificateImageName: '',
      isPharmacyGuidanceOpen: false,
      isConfirmDialogOpen: false,
      DatePickerFormat: 'yyyy-MM-dd',
      selectedDate: '',
      ja: ja,
      selecteDate: '',
      selectedAmOrPm: '',
      timeOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      selectedTime: null,
      form: {
        isFirstVisit: 'yes',
        insuranceCardImage: null,
        recipientCertificateImage: null,
        medicalHistory: null,
        isCurrentMedicine: 'no',
        currentMedicine: null,
        allergy: null,
        otherInformation: null,
        sideEffect: null,
        generic: 'yes',
        selectedPharmacy: null,
        pharmacyAddress: '',
        sexualIntercourseDatetime: ''
      },
      restrictInput: {
        isFirstVisit: false,
        insuranceCardImage: false,
        medicalHistory: false,
        isCurrentMedicine: false,
        currentMedicine: false,
        allergy: false,
        otherInformation: false,
        sideEffect: false,
        generic: false,
        pharmacy: false
      },
      insuranceCardImagePreview: '',
    };
  },
  computed: {
    isValid() {
      let insuranceCardImageValidation;
      if (this.isEdit){
        insuranceCardImageValidation = true
      } else {
        insuranceCardImageValidation = this.form.insuranceCardImage
      }
      return (
        insuranceCardImageValidation &&
        this.form.isCurrentMedicine

      );
    },
    isFirstInterview() {
      return !this.clinicReservationToCreate.latestInterview
    },
    ...mapGetters(['currentUser'])
  },
  async created() {
    if (!this.isFirstInterview){
      this.form.isFirstVisit = 'no'
    }
    await this.fetchSelectablePharmacies(null);
    await this.fetchMapCenter(null);
    this.mapShowable = true;
    if (this.isEdit) {
      this.setExamination()
      this.setRestrictInput()
    }
  },
  methods: {
    async fetchSelectablePharmacies(address) {
      try {
        const res = await axios.get(`/api/online_clinic/pharmacies`,
          { params: { address: address, is_emergency_contraception_department: true }});
        this.pharmacies = res.data;
        this.form.selectedPharmacy = res.data[0]; // TODO 一件もない時の考慮
      } catch {
        alert('ERROR');
      }
    },
    async selectedInsuranceCardImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const image = await this.getBase64(files[0]);
      this.form.insuranceCardImage = image;
      this.insuranceCardImageName = files[0].name
    },
    async selectedRecipientCertificateImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const image = await this.getBase64(files[0]);
      this.form.recipientCertificateImage = image;
      this.recipientCertificateImageName = files[0].name
    },
    async fetchMapCenter(address){
      try {
        const res = await axios.get(`/api/online_clinic/geocoders/fetch_latlng_from_user_address`, {params: { address: address }})
        this.mapAddressLat = res.data['lat'];
        this.mapAddressLng = res.data['lng'];
      } catch {
        alert('ERROR');
      }
    },
    async changePharmacyAddress(){
      try{
        this.mapShowable = false;
        this.loading = true
        await this.fetchMapCenter(this.form.pharmacyAddress)
        await this.fetchSelectablePharmacies(this.form.pharmacyAddress)
        this.mapShowable = true;
        this.loading = false
      }catch{
        alert('ERROR');
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    addExaminationData() {
      this.setSexualIntercourseDatetime()
      const data = {
        interview: this.form,
      };
      this.$emit('addExaminationData', data);
    },
    setLatestInterview() {
      this.form.medicalHistory = this.clinicReservationToCreate.latestInterview.medical_history
      this.form.isCurrentMedicine = this.clinicReservationToCreate.latestInterview.is_current_medicine
      this.form.currentMedicine = this.clinicReservationToCreate.latestInterview.current_medicine
      this.form.allergy = this.clinicReservationToCreate.latestInterview.allergy
      this.form.otherInformation = this.clinicReservationToCreate.latestInterview.other_information
      this.form.sideEffect = this.clinicReservationToCreate.latestInterview.side_effect
      this.form.generic = this.clinicReservationToCreate.latestInterview.generic

      //薬局の設定
      // MEMO: 緊急避妊外来は、現在閉じているため検証していない
      // MEMO: 念の為 薬局論理削除対応だけしている
      if (this.clinicReservationToCreate.latestInterview.examination.pharmacy && this.clinicReservationToCreate.latestInterview.examination.pharmacy.deleted_at === null){
        if (this.pharmacies.some((item) => item.id === this.clinicReservationToCreate.latestInterview.examination.pharmacy.id)) {
          //前回選択した薬局が選択肢としてすでに含まれている場合。追加しない
          this.form.selectedPharmacy = this.clinicReservationToCreate.latestInterview.examination.pharmacy
        } else {
          this.pharmacies.unshift(this.clinicReservationToCreate.latestInterview.examination.pharmacy)
          this.form.selectedPharmacy = this.clinicReservationToCreate.latestInterview.examination.pharmacy
        }
      }

      this.isConfirmDialogOpen = false
    },
    setExamination() {
      this.form.isFirstVisit = this.examination.interview_for_department.is_first_visit
      this.form.medicalHistory = this.examination.interview_for_department.medical_history
      this.form.isCurrentMedicine = this.examination.interview_for_department.is_current_medicine
      this.form.currentMedicine = this.examination.interview_for_department.current_medicine
      this.form.allergy = this.examination.interview_for_department.allergy
      this.form.otherInformation = this.examination.interview_for_department.other_information
      this.form.sideEffect = this.examination.interview_for_department.side_effect
      this.form.generic = this.examination.interview_for_department.generic

      this.insuranceCardImagePreview = this.examination.interview_for_department.patient_insurance_card_image.url
      //薬局の設定
      if (this.pharmacies.some((item) => item.id === this.examination.pharmacy.id)) {
        //もともとの薬局が選択肢としてすでに含まれている場合。追加しない
        this.form.selectedPharmacy = this.examination.pharmacy
      } else {
        this.pharmacies.unshift(this.examination.pharmacy)
        this.form.selectedPharmacy = this.examination.pharmacy
      }
    },
    emitUpdateExamination() {
      const examinationParams = {
        examination_attributes: {
          pharmacy_id: this.form.selectedPharmacy.id
        },
        interview_attributes: {
          is_first_visit: this.form.isFirstVisit,
          medical_history: this.form.medicalHistory,
          is_current_medicine: this.form.isCurrentMedicine,
          current_medicine: this.form.currentMedicine,
          allergy: this.form.allergy,
          other_information: this.form.otherInformation,
          side_effect: this.form.sideEffect,
          generic: this.form.generic,
          patient_insurance_card_image: this.form.insuranceCardImage,
        }
      }
      this.$emit('updateExamination', examinationParams)
    },
    setRestrictInput() {
      if (this.examination.billing_amount){
        //クリニック請求後は全て編集不可
        for (let key in this.restrictInput) {
          this.restrictInput[key] = true
        }
      } else if (moment(this.examination.doctor_schedule.start) < moment()) {
        //診療開始後
        for (let key in this.restrictInput) {
          if (key != 'pharmacy' && key != 'insuranceCardImage'){
            this.restrictInput[key] = true
          }
        }
      }
    },
    setSexualIntercourseDatetime() {
      if (this.selectedDate && this.selectedAmOrPm && this.selectedTime) {
        const selectedDate = moment(this.selectedDate)
        const hour = this.get_24_hour(this.selectedAmOrPm, Number(this.selectedTime))
        const sexualIntercourseDatetime = moment([selectedDate.year(), selectedDate.month(), selectedDate.date(), hour]).format("YYYY-MM-DD HH:mm:ss");
        this.form.sexualIntercourseDatetime = sexualIntercourseDatetime
      }
    },
    get_24_hour(am_or_pm, hour) {
      if (am_or_pm == 'AM') {
        return hour
      } else {
        return hour + 12
      }
    }
  },
  watch: {
    clinicReservationToCreate: function(val) {
      this.fetchSelectablePharmacies();
    },
  },
};
</script>
