<template>
  <div>
    <notice-alert v-for="notice in notices" :notice="notice" />
    <base-title prev-path="/chat_interview/groups">
      <h1>{{group.name}}: チャット問診票項目</h1>
      <template slot="tools">
        <router-link :to="`/chat_interview/groups/${$route.params.id}/masters/new`" class="btn btn-info">
          新規項目作成
        </router-link>
      </template>
    </base-title>
    <base-loading-icon :target="group">
      <draggable element="ul" class="list-group" v-model="groupMastersOrder">
        <li v-for="groupMaster in group.group_masters" :key="groupMaster.id" class="list-group-item">
          <div class="row">
            <div class="col-sm-2">
              <is-publishing-icon :is-publishing="groupMaster.is_publishing"></is-publishing-icon>
              <interview-type-icon :interview-type="groupMaster.interview_type"></interview-type-icon>
            </div>
            <div class="col-md-6 message-wrapper">
              <base-nl2br
                tag="p"
                :text="groupMaster.message"
                :text-filter="textFilterWithHref"
              />
            </div>
            <div class="col-md-2">
              <span
                v-for="g in groupMaster.groups"
                :key="g.id"
                class="btn btn-success btn-xs groupNameLabel">
                {{g.name}}
              </span>
            </div>
            <div class="col-md-1">
              <router-link :to="`/chat_interview/groups/${$route.params.id}/masters/${groupMaster.master_id}/edit`"
                           class="btn btn-info">
                編集
              </router-link>
            </div>
            <div class="col-sm-1">
              <base-order-change-button-group></base-order-change-button-group>
            </div>
          </div>
        </li>
      </draggable>
    </base-loading-icon>

    <router-link to="/chat_interview/groups">戻る</router-link>
  </div>
</template>

<script>
  import {actionTypes} from '../../store/modules/chatInterview/groups'
  import {mapGetters, mapActions} from 'vuex'
  import IsPublishingIcon from "../Interview/MasterListItem/IsPublishingIcon";
  import InterviewTypeIcon from "../Interview/MasterListItem/InterviewTypeIcon";
  import { textFilterWithHref } from '../../shared/Helper'

  export default {
    name: 'ChatInterviewGroup',
    components: {InterviewTypeIcon, IsPublishingIcon},
    created() {
      this.fetchGroup(this.$route.params.id);
    },
    computed: {
      groupMastersOrder: {
        get() {
          return this.group.group_masters;
        },
        set(value) {
          const ordered = value.map((gm, i) => ({...gm, position: i * 10}));
          this.bulkPatchGroupMasters({groupId: this.$route.params.id, groupMasters: ordered});
          this.group.group_masters = ordered;
        },
      },
      ...mapGetters({
        notices: 'notices',
        group: 'chatInterview/groups/item'})
    },
    methods: {
      textFilterWithHref,
      ...mapActions({
        fetchGroup: `chatInterview/groups/${actionTypes.FETCH}`,
        bulkPatchGroupMasters: `chatInterview/groups/${actionTypes.BULK_PATCH_GROUP_MASTERS}`,
        clearGroup: `chatInterview/groups/${actionTypes.CLEAR}`,
      })
    },
    destroyed() {
      this.clearGroup()
    }
  }
</script>

<style lang="scss" scoped>
.message-wrapper {
  p {
   word-break: break-all;
  }
}
.groupNameLabel {
  white-space:normal;
  width: 100%;
}
</style>
