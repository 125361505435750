<template>
  <base-loading-icon :target="target"></base-loading-icon>
</template>
<script>
  import BaseLoadingIcon from "./base/BaseLoadingIcon";
  import { LOADING } from '../store/loading'

  export default {
    name: 'ConsultationNew',
    components: {BaseLoadingIcon},
    mounted() {
      window.location.href = "/consultations/new"
    },
    computed: {
      target() { return LOADING }
    }
  }
</script>

<style lang="scss" scoped>
</style>
