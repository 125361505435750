import axios from 'axios'
import * as mutationTypes from '../../mutation-types'
import * as actionTypes from '../../action-types'

const state = {
  categories: []
};

const getters = {
  medicalDashboardCategories: state => state.categories,
  categorizeItems: (_, getters) => (category) => (items) =>
    items.map(getters.categorizeItem(category))
      .filter(ci => ci.categoryItemMaster !== undefined)
      .sort(ci => ci.categoryItemMaster.row_order)
};

const mutations = {
  [mutationTypes.SET_MEDICAL_DASHBOARD_CATEGORIES] (state, categories) {
    state.categories = categories
  }
};

const actions = {
  [actionTypes.FETCH_MEDICAL_DASHBOARD_CATEGORIES] ({ commit }) {
    axios.get(`/api/medical_dashboard/categories`)
      .then(response => {
        commit(mutationTypes.SET_MEDICAL_DASHBOARD_CATEGORIES, response.data)
      })
      .catch(response => console.log(response))
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}

