<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <h3>世界の今の時刻</h3>
          </div>

          <div class="modal-body">
            <div class='clock-box'>
              <clock :time="timeToClock(franceTime)"></clock>
              <clock :time="timeToClock(englandTime)"></clock>
              <clock :time="timeToClock(americaTime)"></clock>
            </div>
            <div class='locale-info-box'>
              <div class='locale-info-detail'>
                <p>フランス</p>
                <p>{{displayTime(franceTime)}}</p>
              </div>
              <div class='locale-info-detail'>
                <p>イギリス</p>
                <p>{{displayTime(englandTime)}}</p>
              </div>
              <div class='locale-info-detail'>
                <p>アメリカ(東海岸)</p>
                <p>{{displayTime(americaTime)}}</p>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button class="modal-default-button" @click="$emit('close')">
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Clock from 'vue-clock2';

  export default {
    name: 'WorldTimeModal',
    components: { Clock },
    data: () => {
      return  {
        franceTime: new Date(),
        englandTime: new Date(),
        americaTime: new Date()
      }
    },
    created: function() {
      setInterval(() => {
        this.franceTime = this.fetchDatetime(-7)
        this.englandTime = this.fetchDatetime(-8)
        this.americaTime = this.fetchDatetime(-13)
      }, 1000)
    },
    methods: {
      fetchDatetime(hourDifference) {
        var dt = new Date();
        return new Date(dt.setHours(dt.getHours() + hourDifference))
      },
      timeToClock(t) {
        return t.toLocaleTimeString()
      },
      displayTime(t) {
        return t.toLocaleString()
      }
    }
  }
</script>

<style scoped>

  .clock {
    width: 30%
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 80%;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #6cbeec;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .clock-box {
    display: flex;
    justify-content: space-around;
  }

  .locale-info-box {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    font-weight: bold;
  }

  .locale-info-detail {
    font-weight: bold;
    text-align: center;
  }
</style>
