<template>
  <base-modal>
    <template slot="header">
      <h3>相談終了</h3>
    </template>
    <template slot="body">
      <button style="margin: 5px;" type="button" @click="handleFinish" class="btn btn-primary" id="js-require-payment">強制終了する</button>
      <button style="margin: 5px;" type="button" @click="isUserDeleted ? handleFinish() : handleFinishRequest(false)" class="btn btn-primary" id="js-require-payment" >終了リクエストを送る</button>
      <button style="margin: 5px;" type="button" @click="isUserDeleted ? handleFinish() : handleFinishRequest(true)" class="btn btn-primary" id="js-require-payment" >終了リクエストを送る（満足度アンケート）</button>
    </template>
    <template slot="footer">
      <button type="button" @click="$emit('finishConsultationClose')" class="btn btn-link">キャンセル</button>
    </template>
  </base-modal>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as actionTypes from '../store/action-types'
  export default {
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters(['chat', 'subscriptionRequesting']),
      isUserDeleted() {
        return this.chat.patient == null
      }
    },
    methods: {
      async handleFinishRequest(isSatisfactionRequest) {
        if(confirm('終了リクエストを送りますか？')) {
          await this.postFinishRequest({ chatId: this.chat.id, isSatisfactionRequest: isSatisfactionRequest })
            .catch(_ => {
              this.$emit('finishConsultationClose')
            });
          this.$emit('finishConsultationClose')
        }
      },
      async handleFinish() {
        if(confirm('強制終了しますか？')) {
          await this.postFinish({ chatId: this.chat.id })
            .catch(_ => {
              this.$emit('finishConsultationClose')
            });
          this.$emit('finishConsultationClose')
        }
      },
      ...mapActions({
        postFinishRequest: actionTypes.POST_FINISH_REQUEST,
        postFinish: actionTypes.POST_FINISH,
      }),
    }
  }
</script>

<style scoped="scss">
  .btn {
    white-space: normal;
  }
</style>
