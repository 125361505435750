<template>
  <div>
    <i class="fa fa-caret-up"></i>
    <i class="fa fa-caret-down"></i>
  </div>
</template>

<script>
  export default {
    name: 'BaseOrderChangeButtonGroup',
  }
</script>

<style scoped lang="scss">
  div {
    text-align: right;
    i {
      display: block;
      font-size: 1.4em;
      color: #666666;
    }
  }
</style>
