class LoadingQuery extends String {
  find(_) {
    return null
  }

  map(_) {
    return []
  }

  filter(_) {
    return []
  }

  includes(_) {
    return false
  }
}

export const LOADING_QUERY = new LoadingQuery('LOADING_QUERY');
export const LOADING = 'LOADING';
