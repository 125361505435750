<template>
  <div class="form-group">
    <div
      v-for='resultItem in resultForm.resultItems.filter(ri => ri.masterItem.is_publishing || !ri._destroy)'
      class="checkbox"
      :id="`cy-chat-interview-result-item-${resultItem.masterItem.id}`"
    >
      <input :id="`interview-result-item-${resultItem.masterItem.id}`"
             type="checkbox"
             :value="resultItem.masterItem.id"
             @input="_ => handleCheckbox(resultItem.masterItem.id)"
             :checked="!resultItem._destroy"
             :disabled="!isPatient"
             :name="`chat-interview-result-${groupMaster.master_id}`">
      <label :for="`interview-result-item-${resultItem.masterItem.id}`">
        <span class="checkbox-icon">
          {{resultItem.masterItem.label}}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    name: 'ChatLogInterviewResultCheckboxesField',
    props: {
      groupMaster: {
        type: Object,
        required: true
      },
      resultForm: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters({isPatient: 'isPatient'}),
    },
    methods: {
      handleCheckbox(masterItemId) {
        this.resultForm.resultItems = this.resultForm.resultItems.map(ri =>
          (ri.masterItem.id === masterItemId) ? {...ri, _destroy: !ri._destroy } : ri
        )
      }
    }
  }
</script>

<style scoped lang="scss">
.checkbox input[type="checkbox"] {
  margin-left: 0px;
}

.checkbox input[type="checkbox"]:checked + label > .checkbox-icon:before {
  width: 22px;
  height: 22px;
  background: #FFF;
  border: 1px solid #E4E4E9;
}
.checkbox input[type="checkbox"] + label > .checkbox-icon:before {
  width: 22px;
  height: 22px;
  background: #FFF;
}

.checkbox input[type="checkbox"]:checked + label > .checkbox-icon:after {
  border-bottom: solid 2px #04AAE4;
  border-left: solid 2px #04AAE4;
}

.checkbox input[type="checkbox"] + label > .checkbox-icon:after {
  top: 4px;
  left: 5px;
  height: 8px;
  width: 12px;
}
</style>
